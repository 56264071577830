import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import Button from '../../utils/Button';
import Typography from '../../utils/Typography';

const RootStyled = styled.div`
  max-width: 600px;
  padding: 4rem 2rem;
  margin: 0 auto;
  text-align: center;
`;

const ContactUsLinkStyled = styled.a``;

export const OutletVersionsMismatch = ({ onCancel, onSubmit, ...props }) => {
  const outlet = useSelector((state) => state.outlet);
  return (
      <RootStyled>
        <Typography tag="h1" style={{ margin: '0 0 2rem 0' }}>
          There is a new version!
        </Typography>
        <Typography style={{ margin: '0 0 2rem 0' }}>
          For the best experience we strongly recommend you update to the latest
          verison of this application by clicking the button below.
        </Typography>

        <p>
          <Button onClick={() => (onSubmit ? onSubmit() : false)}>
            Reload site with newer version
          </Button>
        </p>
        <p>
          <Typography tag="a" onClick={() => (onCancel ? onCancel() : false)}>
            Don't get newer version, return me to site.
          </Typography>
        </p>
      </RootStyled>
  );
};
export default OutletVersionsMismatch;
