import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';

export default function PrivateRoute({
                                       children,
                                       authIsRequired = true,
                                       ...rest
                                     }) {
  const auth = useSelector((state) => state.auth);
  //const session = useSelector((state) => state.session);
  const userIsAuthenticated = auth && auth.userIsAuthenticated;

  return (
      <Route
          {...rest}
          render={({ location }) =>
              userIsAuthenticated || authIsRequired === false ? (
                  children
              ) : (
                  <Redirect
                      to={{
                        pathname: '/login',
                        state: { from: location },
                      }}
                  />
              )
          }
      />
  );
}
