import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import AuthContainer from '../components/auth/AuthContainer';
import Contain from '../components/app/Contain';

function LoginView() {
  const outlet = useSelector((state) => state.outlet);
  //const userSession = useSelector((state) => state.session);
  const history = useHistory();
  const location = useLocation();
  const { from } = location.state || { from: { pathname: '/' } };
  const login = () => {
    history.replace(from);
  };
  const onCreateAccount = () => {
    history.push(from);
  };

  if (outlet && outlet.LoginURL) {
    window.location.replace(outlet.LoginURL);
    return null;
  } else {
    return (
        <Contain maxWidth={600} fillHeight>
          <div style={{ margin: '4rem 0' }}>
            <AuthContainer onLogin={login} onCreateAccount={onCreateAccount}/>
          </div>
        </Contain>
    );
  }
}

export default LoginView;
