import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Alert from '../../utils/Alert';
import Button from '../../utils/Button';
import Typography from '../../utils/Typography';
import { CheckboxInput } from '../../utils/Form';
import { formatCurrency } from '../../utils/utilities';

export class CheckoutDeliveryMethods extends Component {
  static defaultProps = {
    error: null,
    deliveryMethods: [],
    loading: false,
    onSubmit: null,
  };

  static propTypes = {
    deliveryMethods: PropTypes.array,
    loading: PropTypes.bool,
    onSubmit: PropTypes.func,
  };

  state = {
    deliveryMethod: this.props.deliveryMethod || null,
  };

  handleSelectDeliveryMethod = (e, deliveryMethod = null) => {
    this.setState({
      deliveryMethod: e.target.checked ? deliveryMethod : null,
    });
  };

  handleSubmitDeliveryMethod = () => {
    this.props.onSubmit(this.state.deliveryMethod);
  };

  render() {
    const { deliveryMethods, error, loading } = this.props;

    if (!Array.isArray(deliveryMethods) || !deliveryMethods.length)
      return <Typography tag="h4">No delivery methods</Typography>;

    return (
        <div id="deliverymethod-form">
          {deliveryMethods.map((dm, index) => {
            const isChecked =
                this.state.deliveryMethod &&
                this.state.deliveryMethod.DeliveryMethodID === dm.DeliveryMethodID
                    ? true
                    : false;
            return (
                <CheckboxInput
                    key={index}
                    checked={isChecked}
                    onChange={(e) => this.handleSelectDeliveryMethod(e, dm)}
                    className="add-delivery-method-checkbox"
                    id={'add-delivery-method-checkbox-' + dm.DeliveryMethodID}
                    //value={this.state.deliveryMethod}
                    //error={this.state.formErrorsTerms}
                >
                  <React.Fragment>
                    {dm.OverrideName} {' - '}
                    {!dm.OverridePrice || Number(dm.OverridePrice) <= 0 ? (
                        'Free'
                    ) : (
                        <span>{formatCurrency(dm.OverridePrice)}</span>
                    )}
                    {dm.OverrideNotes && (
                        <span style={{ opacity: 0.8, fontSize: '1.2rem' }}>
                    <br/>
                          {dm.OverrideNotes}
                  </span>
                    )}
                  </React.Fragment>
                </CheckboxInput>
            );
          })}
          {error && <Alert style={{ margin: '2rem 0' }}>{error}</Alert>}
          <div style={{ margin: '3rem 0 ' }}>
            <Button
                disabled={!this.state.deliveryMethod || loading ? true : false}
                fullWidth
                loading={loading}
                onClick={this.handleSubmitDeliveryMethod}
                id="add-delivery-method-btn"
            >
              Continue
            </Button>
          </div>
        </div>
    );
  }
}

export default CheckoutDeliveryMethods;
