import PropTypes from 'prop-types';
import React from 'react';
import Error from '../../utils/Error';
import { FormActions, TextInput } from '../../utils/Form';
//import { useSelector } from "react-redux";
import { addToast } from '../toast/actions';
import { changeUserPassword } from '../auth/actions';

export class UserPasswordUpdate extends React.Component {
  state = {
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
    formErrors: {},
    editPasswordSuccess: false,
    loading: false,
    error: null,
  };
  //const api = useSelector((state) => state.api);
  //const userFanRequestListIsLoading = api && api.loading.includes("GET_USER_FAN_REQUESTS");
  handleSubmit = () => {
    const { currentPassword, newPassword, confirmPassword } = this.state;
    const { emailAddress } = this.props;
    const formErrors = {};

    //CHANGE_USER_PASSWORD

    if (!currentPassword) {
      formErrors['currentPassword'] = 'Current password required';
    }

    if (newPassword.length < 7) {
      formErrors['newPassword'] =
          'Password must contain at least 7 characters.';
    }
    if (newPassword.match(/[a-z]/i) === null) {
      formErrors['newPassword'] =
          'Password must contain at least 1 alpha character.';
    }
    if (newPassword.match(/\d/i) === null) {
      formErrors['newPassword'] =
          'Password must contain at least 1 numeric character.';
    }
    if (!newPassword) {
      formErrors['newPassword'] = 'New password required';
    }
    if (!confirmPassword) {
      formErrors['confirmPassword'] = 'Confirm password required';
    }
    if (newPassword !== confirmPassword) {
      formErrors['confirmPassword'] = 'Passwords don\'t match';
    }

    if (Object.keys(formErrors).length) {
      this.setState({ formErrors, loading: false, error: null });
      return false;
    }
    this.setState({ formErrors: {}, loading: true, error: null });
    changeUserPassword({
      currentPassword,
      newPassword,
    }).then((data) => {
      this.setState({
        loading: false,
        editPasswordSuccess: true,
        formErrors: {},
        currentPassword: '',
        newPassword: '',
        confirmPassword: '',
      });
      addToast({
        title: 'Success',
        description: 'Your account password has been updated',
        type: 'success',
      });
    }).catch((error) => this.setState({ loading: false, error }));
  };

  handleInput = (name) => (event) => {
    this.setState({ [name]: event.target.value });
    const { currentPassword, newPassword, confirmPassword, formErrors } =
        this.state;

    if (currentPassword) {
      formErrors['currentPassword'] = '';
    }
    if (newPassword) {
      formErrors['newPassword'] = '';
    }
    if (confirmPassword) {
      formErrors['confirmPassword'] = '';
    }
  };

  render() {
    const { onCancel } = this.props;
    const {
      currentPassword,
      newPassword,
      confirmPassword,
      editPasswordSuccess,
      formErrors,
      loading,
      error,
    } = this.state;

    return (
        <div>
          <TextInput
              name="currentPassword"
              label="Current Password"
              error={formErrors['currentPassword']}
              fullWidth
              value={currentPassword}
              onChange={this.handleInput('currentPassword')}
              type="password"
              id="currentPassword"
          />

          <TextInput
              name="newPassword"
              label="New Password"
              error={formErrors['newPassword']}
              fullWidth
              value={newPassword}
              onChange={this.handleInput('newPassword')}
              type="password"
              id="newPassword"
              autoComplete="new-password"
          />

          <TextInput
              name="confirmPassword"
              label="Confirm New Password"
              error={formErrors['confirmPassword']}
              fullWidth
              value={confirmPassword}
              onChange={this.handleInput('confirmPassword')}
              type="password"
              id="confirmNewPassword"
              autoComplete="new-password"
          />

          {error && (
              <div style={{ margin: '20px 0 10px 0' }}>
                <Error> {error} </Error>
              </div>
          )}

          <FormActions
              submitText={loading ? 'Loading' : 'Save'}
              handleSubmit={() => this.handleSubmit()}
              handleCancel={onCancel}
              loading={loading}
              fullWidth
          />
        </div>
    );
  }
}

UserPasswordUpdate.propTypes = {
  emailAddress: PropTypes.string,
  onCancel: PropTypes.func,
  onSuccess: PropTypes.func,
};

UserPasswordUpdate.defaultProps = {
  loading: false,
  error: null,
  emailAddress: '',
  onCancel: null,
  onSuccess: null,
};

export default UserPasswordUpdate;
