import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import ContentTypeAlert from './ContentTypeAlert';
import ContentTypeNews from './ContentTypeNews';

const ContentBlockRoot = styled.div`
  margin: 0 0 ${(props) => (props.hasMarginBottom ? '0.5rem' : '0')} 0;
`;

const CONTENT_COMPONENTS = {
  CONTENT_TYPE_ALERT_INFORMATION: ContentTypeAlert,
  CONTENT_TYPE_ALERT_WARNING: ContentTypeAlert,
  CONTENT_TYPE_ALERT_CRITICAL: ContentTypeAlert,
  CONTENT_TYPE_NEWS: ContentTypeNews,
};

const LocationToPage = [
  { location: 'login', page: 'CONTENT_PAGE_LOGIN' },
  { location: 'event', page: 'CONTENT_PAGE_EVENT_DETAILS' },
  { location: 'events', page: 'CONTENT_PAGE_EVENT_LIST' },
  { location: '/', page: 'CONTENT_PAGE_EVENT_LIST' },
  //{ location: "/", page: "CONTENT_PAGE_DASHBOARD" },
];

const ContentBlock = ({ page, type, zone }) => {
  const location = useLocation();
  const outlet = useSelector((state) => state.outlet);
  let contentList = outlet.OutletContents ? [...outlet.OutletContents] : [];
  // //console.log("Content Block location => ", location);
  // //console.log("Content Block page => ", page);
  // //console.log("Content Block type => ", type);
  // //console.log("Content Block zone => ", zone);
  let pageLookupValue;
  if (page) {
    pageLookupValue = page;
  } else {
    let pathname =
        location && location.pathname && location.pathname.split('/')[1];

    // //console.log("Content Block pathname => ", pathname);
    // //console.log("Content Block pathname root => ", pathname === "");
    if (pathname === '') {
      pageLookupValue = 'CONTENT_PAGE_EVENT_LIST';
    } else {
      let found = LocationToPage.find((x) => x.location === pathname);
      pageLookupValue = found && found.page;
      //if (!outlet.DisplayDashboardPage && pathname === "") {
      // pageLookupValue = "CONTENT_PAGE_EVENT_LIST";
      //}
    }
  }
  // //console.log("Content Block pageLookupValue => ", pageLookupValue);
  if (!contentList.length) return null;
  // //console.log("Content Block contentList => ", contentList);
  //if (page) {
  contentList = contentList.filter(
      (c) =>
          c.ContentToPage.ContentPage.ConstantValue === 'CONTENT_PAGE_GLOBAL' ||
          c.ContentToPage.ContentPage.ConstantValue === pageLookupValue,
  );
  //}
  //console.log("contentList 1 filtered => ", contentList);
  if (type) {
    contentList = contentList.filter(
        (c) => c.ContentToPage.Content.ContentType.ConstantValue === type,
    );
  }
  //console.log("contentList 2 filtered => ", contentList);
  if (zone) {
    contentList = contentList.filter(
        (c) => c.ContentToPage.Content.ContentZone.ConstantValue === zone,
    );
  }
  //console.log("contentList 3 filtered => ", contentList);
  return contentList.map((c, index) => {
    const type = c.ContentToPage.Content.ContentType.ConstantValue;
    const location = c.ContentToPage.ContentPage.ConstantValue;
    if (!type || !location) return null;
    const ContentComponent = CONTENT_COMPONENTS[type];
    if (ContentComponent) {
      return (
          <ContentBlockRoot
              key={index}
              //hasMarginBottom={index + 1 < contentList.length}
          >
            <ContentComponent {...c.ContentToPage.Content} />
          </ContentBlockRoot>
      );
    }
    return null;
  });
};

ContentBlock.defaultProps = {
  page: null,
  type: null,
  zone: null,
};

ContentBlock.propTypes = {
  page: PropTypes.oneOf([
    'CONTENT_PAGE_GLOBAL',
    'CONTENT_PAGE_DASHBOARD',
    'CONTENT_PAGE_EVENT_LIST',
    'CONTENT_PAGE_EVENT_DETAILS',
  ]),
  zone: PropTypes.oneOf([
    'CONTENT_ZONE_ABOVE_HEADER',
    'CONTENT_ZONE_BELOW_HEADER',
    'CONTENT_ZONE_ABOVE_FOOTER',
    'CONTENT_ZONE_NEWS',
    'CONTENT_ZONE_EVENT_INFO',
  ]),
  type: PropTypes.oneOf([
    'CONTENT_TYPE_ALERT_CRITICAL',
    'CONTENT_TYPE_ALERT_WARNING',
    'CONTENT_TYPE_ALERT_INFORMATION',
    'CONTENT_TYPE_NEWS',
    'CONTENT_TYPE_TAB',
  ]),
};

export default ContentBlock;
