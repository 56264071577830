/**
 * Default theme values for an outlet
 */
export const outletThemeDefault = {
  // mobile portrait: less than 640px
  // mobile landscape: > 640px
  // tablet portrait: > 768px
  // tablet landscape: > 1024px
  // laptop: > 1280px
  // ALERT_HIGH_PRIORITY_BACKGROUND: "#f35a39"
  // ALERT_HIGH_PRIORITY_TEXT_COLOR: "#ffffff"
  // ALERT_LOW_PRIORITY_BACKGROUND: "#f1f1f1"
  // ALERT_LOW_PRIORITY_TEXT_COLOR: "#636363"
  // ALERT_MEDIUM_PRIORITY_BACKGROUND: "#00ab9d"
  // ALERT_MEDIUM_PRIORITY_TEXT_COLOR: "#ffffff"
  // FOOTER_BACKGROUND: "#1a1a1a"
  // FOOTER_TEXT_COLOR: "#ffffff"
  // HEADER_BACKGROUND: "#423549"
  // HEADER_TEXT_COLOR: "#ffffff"
  // HEADLINE_TEXT_COLOR: "#1a1a1a"
  // PRIMARY_BUTTON_BACKGROUND: "#423549"
  // PRIMARY_BUTTON_TEXT_COLOR: "#ffffff"
  PRIMARY_LINK_COLOR: '#00ab9d',
  PRIMARY_TEXT_COLOR: '#636363',
  // SECONDARY_BUTTON_BACKGROUND: "#ff5e00"
  // SECONDARY_BUTTON_TEXT_COLOR: "#ffffff"
  SECONDARY_LINK_COLOR: '#828282',
  MAXIMUM_CONTAINER_WIDTH: 1020,
  NAV_DRAWER_BACKGROUND: '#ffffff',
  APP_BACKGROUND: '#ffffff',
  BREAKPOINT_MOBILE_WIDTH: 769,
  HEADER_TEXT_FONT_FAMILY: 'Fjalla One',
  BUTTON_TEXT_FONT_FAMILY: 'Fjalla One',
  BUTTON_BORDER_RADIUS: '3rem',
  ERROR_TEXT_COLOR: '#BD2C2C',
  PRIMARY_GRAY: '#e0dede',

  // attention: {
  //   info: {
  //     color: "#FFFFFF",
  //     backgroundColor: "#978357",
  //     borderColor: "#978357",
  //     linkColor: "#2D8D9B",
  //   },
  //   warning: {
  //     color: "#FFFFFF",
  //     backgroundColor: "#BE9F24",
  //     borderColor: "#BE9F24",
  //     linkColor: "#2D8D9B",
  //   },
  //   critical: {
  //     color: "#FFFFFF",
  //     backgroundColor: "#BD2C2C",
  //     borderColor: "#BD2C2C",
  //     linkColor: "#2D8D9B",
  //   },
  // },
  // error: {
  //   color: "#BD2C2C",
  // },
  // breakpoints: {
  //   mobile: "769",
  // },
  // lottery: {
  //   card: {
  //     header: {
  //       color: "#ffffff",
  //       backgroundColor: "#145A64",
  //       backgroundColorSelected: "#be9f24",
  //       backgroundColorSelected2: "#cccccd",
  //     },
  //     header2: {
  //       color: "#ffffff",
  //       backgroundColor: "#2d8d9b",
  //     },
  //     header3: {
  //       color: "#3c3c3c",
  //       backgroundColor: "#f1f1f1",
  //     },
  //     body: {
  //       color: "#3c3c3c",
  //       backgroundColor: "#fff",
  //       borderColor: "#eee",
  //     },
  //   },
  //   importantInfo: {
  //     borderColor: "#3c3c3c",
  //     header: {
  //       color: "#2d8d9b",
  //       backgroundColor: "#f1f1f1",
  //     },
  //     body: {
  //       color: "#3c3c3c",
  //       backgroundColor: "#fff",
  //     },
  //   },
  // },
  // realTime: {
  //   card: {
  //     header: {
  //       color: "#ffffff",
  //       backgroundColor: "#145A64",
  //       backgroundColorSelected: "#be9f24",
  //       backgroundColorSelected2: "#cccccd",
  //     },
  //     header2: {
  //       color: "#ffffff",
  //       backgroundColor: "#2d8d9b",
  //     },
  //     header3: {
  //       color: "#3c3c3c",
  //       backgroundColor: "#f1f1f1",
  //     },
  //     body: {
  //       color: "#3c3c3c",
  //       backgroundColor: "#fff",
  //       borderColor: "#eee",
  //     },
  //   },
  //   importantInfo: {
  //     borderColor: "#3c3c3c",
  //     header: {
  //       color: "#2d8d9b",
  //       backgroundColor: "#f1f1f1",
  //     },
  //     body: {
  //       color: "#3c3c3c",
  //       backgroundColor: "#fff",
  //     },
  //   },
  // },
  // card: {
  //   header: {
  //     color: "#ffffff",
  //     backgroundColor: "#145A64",
  //     color2: "#145A64",
  //     backgroundColor2: "#fff",
  //   },
  //   body: {
  //     color: "#2D8D9B",
  //     backgroundColor: "#f1f1f1",
  //     color2: "#3c3c3c",
  //     backgroundColor2: "#fff",
  //   },
  // },
  // drawer: {
  //   backgroundColor: "#ffffff",
  //   linkColor: "#2D8D9B",
  //   linkColorHover: "#2D8D9B",
  //   linkColorActive: "#3C3C3C",
  // },
  // general: {
  //   color: "#3c3c3c",
  //   backgroundColor: "#ffffff",
  //   backgroundColorAlt: "#f1f1f1",
  //   linkColor: "#2D8D9B",
  //   linkColorHover: "#2D8D9B",
  //   linkColorSecondary: "#3c3c3ccc",
  //   linkColorSecondarHover: "#3c3c3ccc",
  //   fontFamily: "Roboto",
  // },
  // columns: {
  //   left: {
  //     minWidth: 320,
  //     backgroundColor: "#eee",
  //   },
  //   right: {
  //     minWidth: 320,
  //     backgroundColor: "#ffffff",
  //   },
  // },
  // typography: {
  //   h1: {
  //     fontFamily: "Fjalla One",
  //   },
  //   h2: {
  //     fontFamily: "Fjalla One",
  //   },
  //   h3: {
  //     fontFamily: "Fjalla One",
  //   },
  //   h4: {
  //     fontFamily: "Roboto",
  //   },
  //   h5: {
  //     fontFamily: "Fjalla One",
  //   },
  // },
  // buttons: {
  //   borderRadius: "30px",
  //   fontFamily: "Fjalla One",
  //   primary: {
  //     color: "#fff",
  //     backgroundColor: "#145A64",
  //     backgroundColorHover: "#1D383C",
  //     backgroundColorActive: "#154A52",
  //   },
  //   secondary: {
  //     color: "#fff",
  //     backgroundColor: "#978357",
  //     backgroundColorHover: "#4F442C",
  //     backgroundColorActive: "#7B6D4E",
  //   },
  // },
  // header: {
  //   color: "#ffffff",
  //   backgroundColor: "#1A1A1A",
  //   iconColor: "#2D8D9B",
  // },
  // footer: {
  //   color: "#ffffff",
  //   backgroundColor: "#1A1A1A",
  //   iconColor: "#ffffff",
  //   borderColor: "#145A64",
  //   borderSize: "10",
  //   linkColor: "#9B9B9B",
  // },
};

export const testOE = {
  SuppressSeatInfo: true,
  OutletEventID: 797,
  EventID: 744,
  AnnounceDateOverride: '2020-04-26T15:55:00.000Z',
  EventImageOverride:
      'https://s3.amazonaws.com/images.iconexchange.com/samuraitix/Screen Shot 2020-04-22 at 8.47.58 AM.png',
  OutletEventOverrideName: 'LB alt name realtime',
  OutletEventDescription: 'Cool event description here',
  OutletEventDoorTime:
      'Thu Jul 23 2020 19:30:00 GMT-0400 (Eastern Daylight Time)',
  OutletSeriesID: 106,
  ExternalSalesURL: null,
  SalesTypeID: 1,
  OutletEventContent: [],
  OutletEventItems: [
    {
      OnSaleDateOverride: '2020-04-06T12:50:00.000Z',
      OffSaleDateOverride: null,
      Sort: 1,
      Image: null,
      OutletEventItemID: 510,
      EventItemID: 902,
      Description: 'hi there',
      Name: 'Concert Tickets',
      EventItem: {
        OnSaleDate: '2020-04-06T12:50:00.000Z',
        OffSaleDate: null,
        EventItemID: 902,
        Name: 'Concert Tickets',
        EventTypeID: 2,
        EventItemTypeID: 1,
        SuppressOnEDP: false,
        __typename: 'EventItemType',
      },
      PriceLevels: [
        {
          PriceLevelID: 849,
          OutletEventItemID: 510,
          EventItemLevelID: 1235,
          HoldID: null,
          PriceLevelName: 'Pit',
          PriceLevelValue: 250,
          PriceLevelFee: null,
          PriceLevelLimit: 8,
          StartTime: '2020-04-06T12:50:00.000Z',
          EndTime: '2020-11-21T01:00:00.000Z',
          QAvail: 0,
          SellID: null,
          Description:
              '<p>jaoiwje fpoiaj wepoifj apoweijfpoiajw epofij aowpefi</p>',
          BaseFee: 25,
          PaymentFee: 1,
          FacilityFee: 2,
          ArtistRebate: 3,
          PartnerRebate: 1,
          createdAt: '2020-06-11T15:56:30.000Z',
          updatedAt: '2020-06-11T15:56:30.000Z',
          deletedAt: null,
          EnableOptOut: null,
          ChildHoldTypeID: 3963,
          ChildSellTypeID: 1558,
          PriceLevelMinimum: 0,
          PriceLevelMultiplier: 1,
          HideFromView: false,
          Sort: 1,
          __typename: 'PriceLevelType',
        },
      ],
      __typename: 'OutletEventItemType',
    },
    {
      OnSaleDateOverride: '2020-06-01T18:20:00.000Z',
      OffSaleDateOverride: '2021-05-21T18:20:00.000Z',
      Sort: 2,
      Image: null,
      OutletEventItemID: 511,
      EventItemID: 909,
      Description: 'Cool spots for your car',
      Name: 'Parking',
      EventItem: {
        OnSaleDate: '2020-06-01T18:20:00.000Z',
        OffSaleDate: '2021-05-21T18:20:00.000Z',
        EventItemID: 909,
        Name: 'Parking',
        EventTypeID: 2,
        EventItemTypeID: 2,
        SuppressOnEDP: false,
        __typename: 'EventItemType',
      },
      PriceLevels: [
        {
          PriceLevelID: 851,
          OutletEventItemID: 511,
          EventItemLevelID: 1257,
          HoldID: null,
          PriceLevelName: 'Grass Lot B',
          PriceLevelValue: 20,
          PriceLevelFee: null,
          PriceLevelLimit: 8,
          StartTime: '2020-06-01T18:20:00.000Z',
          EndTime: '2021-05-21T18:20:00.000Z',
          QAvail: 0,
          SellID: null,
          Description:
              '<p>zxnmvz,xm ncvkzxjncv kjzxncvkjz xcvzx cvjzxcvjkzxcxcvzxcv</p>',
          BaseFee: 2,
          PaymentFee: 1,
          FacilityFee: 1,
          ArtistRebate: null,
          PartnerRebate: null,
          createdAt: '2020-06-16T18:22:34.000Z',
          updatedAt: '2020-06-16T18:22:34.000Z',
          deletedAt: null,
          EnableOptOut: null,
          ChildHoldTypeID: 3963,
          ChildSellTypeID: 1558,
          PriceLevelMinimum: 0,
          PriceLevelMultiplier: 1,
          HideFromView: false,
          Sort: 0,
          __typename: 'PriceLevelType',
        },
        {
          PriceLevelID: 850,
          OutletEventItemID: 511,
          EventItemLevelID: 1256,
          HoldID: null,
          PriceLevelName: 'Stadium Lot A',
          PriceLevelValue: 75,
          PriceLevelFee: null,
          PriceLevelLimit: 8,
          StartTime: '2020-06-01T18:20:00.000Z',
          EndTime: '2021-05-21T18:20:00.000Z',
          QAvail: 0,
          SellID: null,
          Description:
              '<p>aoiwje fopijaw peofij paowiejfpoiaj wpoefij awef</p>',
          BaseFee: 7.5,
          PaymentFee: 1,
          FacilityFee: 1,
          ArtistRebate: null,
          PartnerRebate: null,
          createdAt: '2020-06-16T18:22:16.000Z',
          updatedAt: '2020-06-16T18:22:16.000Z',
          deletedAt: null,
          EnableOptOut: null,
          ChildHoldTypeID: 3963,
          ChildSellTypeID: 1558,
          PriceLevelMinimum: 0,
          PriceLevelMultiplier: 1,
          HideFromView: false,
          Sort: 1,
          __typename: 'PriceLevelType',
        },
      ],
      __typename: 'OutletEventItemType',
    },
    {
      OnSaleDateOverride: '2020-06-03T18:24:00.000Z',
      OffSaleDateOverride: '2021-06-17T18:24:00.000Z',
      Sort: 3,
      Image:
          'https://s3.amazonaws.com/images.iconexchange.com/samuraitix/leon010025-leon-bridges-neon-tour-t-shirt-black-z-5bc0d85b.jpeg',
      OutletEventItemID: 512,
      EventItemID: 910,
      Description: null,
      Name: '2020 Tour Shirt',
      EventItem: {
        OnSaleDate: '2020-06-03T18:24:00.000Z',
        OffSaleDate: '2021-06-17T18:24:00.000Z',
        EventItemID: 910,
        Name: '2020 Tour Shirt',
        EventTypeID: 2,
        EventItemTypeID: 2,
        SuppressOnEDP: true,
        __typename: 'EventItemType',
      },
      PriceLevels: [
        {
          PriceLevelID: 854,
          OutletEventItemID: 512,
          EventItemLevelID: 1258,
          HoldID: null,
          PriceLevelName: 'Size Small',
          PriceLevelValue: 25,
          PriceLevelFee: null,
          PriceLevelLimit: 1,
          StartTime: '2020-06-03T18:24:00.000Z',
          EndTime: '2021-06-17T18:24:00.000Z',
          QAvail: 0,
          SellID: null,
          Description: '',
          BaseFee: 2.5,
          PaymentFee: 2,
          FacilityFee: 4,
          ArtistRebate: null,
          PartnerRebate: null,
          createdAt: '2020-06-16T18:26:11.000Z',
          updatedAt: '2020-06-16T18:26:18.000Z',
          deletedAt: null,
          EnableOptOut: null,
          ChildHoldTypeID: 3963,
          ChildSellTypeID: 1558,
          PriceLevelMinimum: 0,
          PriceLevelMultiplier: 1,
          HideFromView: false,
          Sort: 0,
          __typename: 'PriceLevelType',
        },
        {
          PriceLevelID: 855,
          OutletEventItemID: 512,
          EventItemLevelID: 1259,
          HoldID: null,
          PriceLevelName: 'Size Medium',
          PriceLevelValue: 25,
          PriceLevelFee: null,
          PriceLevelLimit: 1,
          StartTime: '2020-06-03T18:24:00.000Z',
          EndTime: '2021-06-17T18:24:00.000Z',
          QAvail: 0,
          SellID: null,
          Description: '',
          BaseFee: 2.5,
          PaymentFee: null,
          FacilityFee: null,
          ArtistRebate: null,
          PartnerRebate: null,
          createdAt: '2020-06-16T21:09:02.000Z',
          updatedAt: '2020-06-16T21:09:30.000Z',
          deletedAt: null,
          EnableOptOut: null,
          ChildHoldTypeID: 3963,
          ChildSellTypeID: 1558,
          PriceLevelMinimum: 0,
          PriceLevelMultiplier: 1,
          HideFromView: false,
          Sort: 2,
          __typename: 'PriceLevelType',
        },
        {
          PriceLevelID: 856,
          OutletEventItemID: 512,
          EventItemLevelID: 1260,
          HoldID: null,
          PriceLevelName: 'Size Large',
          PriceLevelValue: 25,
          PriceLevelFee: null,
          PriceLevelLimit: 2,
          StartTime: '2020-06-03T18:24:00.000Z',
          EndTime: '2021-06-17T18:24:00.000Z',
          QAvail: 0,
          SellID: null,
          Description: '',
          BaseFee: 2.5,
          PaymentFee: null,
          FacilityFee: null,
          ArtistRebate: null,
          PartnerRebate: null,
          createdAt: '2020-06-16T21:09:21.000Z',
          updatedAt: '2020-06-16T21:09:21.000Z',
          deletedAt: null,
          EnableOptOut: null,
          ChildHoldTypeID: 3964,
          ChildSellTypeID: 1558,
          PriceLevelMinimum: 0,
          PriceLevelMultiplier: 1,
          HideFromView: false,
          Sort: 3,
          __typename: 'PriceLevelType',
        },
      ],
      __typename: 'OutletEventItemType',
    },
  ],
  Event: {
    Name: 'LB alt name',
    DoorTime: '2020-07-23T23:30:00.000Z',
    EventImage:
        'https://s3.amazonaws.com/images.iconexchange.com/samuraitix/Screen Shot 2020-04-22 at 8.47.58 AM.png',
    ShowDate: '2020-07-24T01:00:00.000Z',
    DisplayShowTime: true,
    EventImageMobile: null,
    SeatMapImage: null,
    Venue: {
      VenueID: 167,
      VenueName: 'PNC Bank Arts Center',
      Address1: '116 Garden State Pkwy',
      City: 'Holmdel',
      State: 'NJ',
      Country: 'USA',
      PostalCode: '07733',
      GoogleMapLink: 'https://goo.gl/maps/95zdG1ABEQG2',
      __typename: 'VenueType',
    },
    Artist: {
      ArtistID: 67,
      ArtistName: 'Leon Bridges',
      ArtistDescription: null,
      __typename: 'ArtistTableType',
    },
    __typename: 'EventsListType',
  },
  __typename: 'OutletEventType',
};
