import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Alert from '../../utils/Alert';
import Button from '../../utils/Button';
import Modal from '../../utils/Modal';
import Typography from '../../utils/Typography';
import { CheckboxInput, TextInput } from '../../utils/Form';
import { loginUser } from './actions';
import { submitWhenEnterIsPressed } from '../../utils/utilities';
import { validateEmail } from '../../utils/Validation';

export class Login extends React.Component {
  state = {
    password: '',
    showModal: false,
    modalContent: '',
    modalTitle: '',
    formErrors: {},
    loading: false,
    error: null,
    acceptTermsAndPrivacyPolicy: false,
    email:
        this.props.match.params && this.props.match.params.email
            ? this.props.match.params.email
            : '',
  };

  handleCheckboxChange = (event) => {
    this.setState({ acceptTermsAndPrivacyPolicy: event.target.checked });
  };

  showModal = () => {
    this.setState({ showModal: true });
  };

  hideModal = () => {
    this.setState({ showModal: false });
  };

  handlePolicyBtn = (policy) => {
    this.setState(
        { modalTitle: policy.Headline, modalContent: policy.PolicyText },
        () => {
          this.showModal();
        },
    );
  };

  renderPolicyLink = (policy) => {
    return (
        <Typography tag="a" onClick={() => this.handlePolicyBtn(policy)}>
          {policy.Headline}
        </Typography>
    );
  };

  handleInput = (name) => (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    this.setState({ [name]: value });
  };

  handleLogin = () => {
    const { acceptTermsAndPrivacyPolicy, email, password } = this.state;
    const formErrors = {};
    if (!email) {
      formErrors.email = 'Email Required';
    }
    if (!password) {
      formErrors.password = 'Password Required';
    }
    if (!acceptTermsAndPrivacyPolicy) {
      formErrors.terms = 'You must accept the terms and policies';
    }
    if (Object.keys(formErrors).length) {
      this.setState({ formErrors });
      return false;
    }
    this.setState(
        {
          loading: true,
          error: null,
          formErrors,
        },
        () => {
          validateEmail(email).then((res) => {
            const emailValid = res.valid;
            if (emailValid === false) {
              this.setState({
                loading: false,

                formErrors: {
                  email: 'Please verify your email address and try again',
                },
              });
            } else {
              loginUser({
                id: email,
                password,
              }).then((res) => {
                this.props.onLogin && this.props.onLogin();
              }).catch((error) => {
                this.setState({
                  loading: false,
                  error,
                });
              });
            }
          }).catch((error) => {
            this.setState({
              loading: false,
              error: 'Error verifying your email address',
            });
          });
        },
    );
  };

  render() {
    const {
      match,
      outlet,
      showFaildLoginAttempts,
      failedLoginAttempts,
    } = this.props;
    const {
      acceptTermsAndPrivacyPolicy,
      email,
      password,
      showModal,
      formErrors,
      modalContent,
      modalTitle,
      loading,
      error,
    } = this.state;

    //Filter outlet policies that are allowed in Login
    //Check if sales type = 'All'
    //Lindsay's comment on RLMTS-1027 - Check if policy type = 'privacy policy' OR 'terms & conditions'
    const policies =
        outlet &&
        outlet.OutletPolicies.filter(
            (policy) =>
                policy.SalesTypeID === -1 &&
                (policy.OutletPolicyTypeID === 1 || policy.OutletPolicyTypeID === 2),
        );

    return (
        <>
          <Modal title={modalTitle} open={showModal} onClose={this.hideModal}>
            <div dangerouslySetInnerHTML={{ __html: modalContent }}/>
          </Modal>

          <TextInput
              name="email"
              placeholder="Email"
              value={
                match.params && match.params.email ? match.params.email : email
              }
              onChange={this.handleInput('email')}
              onKeyPress={(e) =>
                  submitWhenEnterIsPressed(e, () => this.handleLogin({}))
              }
              error={formErrors && formErrors.email}
              fullWidth
          />
          <TextInput
              name="password"
              placeholder="Password"
              type="password"
              value={password}
              onChange={this.handleInput('password')}
              //validate={required}
              onKeyPress={(e) =>
                  submitWhenEnterIsPressed(e, () => this.handleLogin({}))
              }
              error={formErrors && formErrors.password}
              fullWidth
          />
          <CheckboxInput
              checked={acceptTermsAndPrivacyPolicy}
              onChange={this.handleCheckboxChange}
              value={acceptTermsAndPrivacyPolicy}
              error={formErrors && formErrors.terms}
              id="accept-terms-login-checkbox"
          >
            {/* policies are empty when outlets are initially setup. outlets must be setup with terms/privacy policies
          before they are acceesed by the public in order to be legally compliant.
           */}
            I accept the{' '}
            {policies?.map((policy, index) => {
              return (
                  <span key={policy.OutletPolicyID}>
                {this.renderPolicyLink(policy)}
                    {index !== policies.length - 1 && ' and '}
              </span>
              );
            })}
          </CheckboxInput>

          {error && (
              <div style={{ margin: '2rem 0' }}>
                <Alert type="error"> {error} </Alert>
              </div>
          )}
          {showFaildLoginAttempts ? (
              <div style={{ margin: '2rem 0' }}>
                <Alert type="error">
                  Failed login attempts {failedLoginAttempts} of 3
                </Alert>
              </div>
          ) : null}

          <div style={{ margin: '2rem 0 0 0' }}>
            <Button
                fullWidth
                onClick={() => this.handleLogin({})}
                loading={loading}
                id="sign-in-btn"
            >
              Sign In
            </Button>
          </div>
        </>
    );
  }
}

Login.propTypes = {
  onLogin: PropTypes.func,
  view: PropTypes.string,
  showFaildLoginAttempts: PropTypes.bool,
};

Login.defaultProps = {
  onLogin: () => false,
  view: '',
  apiErrors: [],
  apiLoading: [],
  showFaildLoginAttempts: false,
};

function mapStateToProps(state = {}) {
  const { outlet, app, api } = state;
  return {
    apiErrors: api?.error,
    apiLoading: api?.loading,
    outlet,
    failedLoginAttempts: app?.failedLoginAttempts,
  };
}

export default withRouter(connect(mapStateToProps)(Login));
