import { getV1ApiURL } from './apiClient';

export const required = (value) =>
    new Promise(function (resolve, reject) {
      value ? resolve() : reject('Required');
    });

export const maxLength = (max) => (value) =>
    value && value.length > max ? `Must be ${max} characters or less` : undefined;

export const maxLength15 = maxLength(15);

export const minLength = (min) => (value) =>
    value && value.length < min ? `Must be ${min} characters or more` : undefined;

export const minLength2 = minLength(2);

export const number = (value) =>
    value && isNaN(Number(value)) ? 'Must be a number' : undefined;

export const minValue = (min) => (value) =>
    value && value < min ? `Must be at least ${min}` : undefined;

export const minValue18 = minValue(18);

export const tooOld = (value) =>
    value && value > 65 ? 'You might be too old for this' : undefined;

export const alphaNumeric = (value) =>
    value && /[^a-zA-Z0-9 ]/i.test(value)
        ? 'Only alphanumeric characters'
        : undefined;

export const phoneNumber = (value) =>
    value && !/^(0|[1-9][0-9]{9})$/i.test(value)
        ? 'Invalid phone number, must be 10 digits'
        : undefined;

/**
 *
 * @param {string} email email address to validate
 * @return {Promise}
 */
export const validateEmail = (email) => {
  const V1API_URL = getV1ApiURL();
  const VALIDATE_EMAIL_PATHNAME = `validateemail`;
  const VALIDATE_EMAIL_API_URL = `${V1API_URL}/${VALIDATE_EMAIL_PATHNAME}`;
  return fetch(
      VALIDATE_EMAIL_API_URL + '?email=' +
      encodeURIComponent(email),
  ).
      then((res) => res.json()).
      catch((error) => console.error(`Fetch network error: validating email address: ${email}. `, error));
};

