import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import Modal from '../../utils/Modal';
import PriceLevelSelectorInstant from '../priceLevel/PriceLevelSelectorInstant';
import Typography from '../../utils/Typography';

const AddonRoot = styled.div``;

const ImageZoomButton = styled.div`
  color: ${(props) => props.theme.PRIMARY_LINK_COLOR};
  cursor: pointer;
  text-align: center;
`;

const ItemContainer = styled.div`
  @media (min-width: ${(props) => props.theme.BREAKPOINT_MOBILE_WIDTH}px) {
    display: flex;
  }
  padding: 0 0 2rem 0;
  margin: 0 0 2rem 0;
  border-bottom: solid 1px ${(props) => props.theme.PRIMARY_GRAY};
`;

const ItemImage = styled.div`
  padding: 0;
  width: 100%;
  margin: 0 auto;
  cursor: pointer;
  img {
    width: 100%;
  }
  @media (min-width: ${(props) => props.theme.BREAKPOINT_MOBILE_WIDTH}px) {
    margin: 0 2rem 0 0;
    width: 20rem;
  }
`;

const ItemContent = styled.div`
  margin: 0 0 0 0;
  padding: 0;

  @media (min-width: ${(props) => props.theme.BREAKPOINT_MOBILE_WIDTH}px) {
    flex: 1;
  }
`;

class CheckoutAddons extends React.Component {
  state = {
    selectedPriceLevels: [],
    modalIsVisible: false,
    modalTitle: '',
    modalImage: '',
  };

  setModalIsVisible = (modalIsVisible = false) => {
    this.setState({ modalIsVisible });
  };

  componentDidMount() {
    // this.props.handleAddOnQtyChange &&
    //   this.props.handleAddOnQtyChange(this.props.selectedPriceLevels);

    if (Array.isArray(this.props.selectedPriceLevels)) {
      this.setState({
        selectedPriceLevels: [...this.props.selectedPriceLevels],
        possiblyDirty: false,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.modal && this.props.modal && prevProps.modal.isVisible != this.props.modal.isVisible &&
        this.state.possiblyDirty) {
      //if (prevProps.modal.isVisible != this.props.modal.isVisible && this.state.possiblyDirty){
      this.setState({
        possiblyDirty: false,
        selectedPriceLevels: [
          ...this.props.cart.lineItems.filter(
              (li) => li.isAddOn !== false,
          )],
      });
    }
    if (prevProps.modal && this.props.modal && prevProps.modal.isVisible != this.props.modal.isVisible) {
      //if (prevProps.modal.isVisible != this.props.modal.isVisible){
      this.setState({ possiblyDirty: true });
    }
    if (this.props.cart) {
      let newSelectedPriceLevels = this.props.cart.lineItems.filter(
          (li) => li.isAddOn !== false,
      );
      if (newSelectedPriceLevels.toString() !== prevProps.selectedPriceLevels.toString()) {
        this.setState({
          selectedPriceLevels: [
            ...this.props.cart.lineItems.filter(
                (li) => li.isAddOn !== false,
            )],
        });
      }
    }
    // if (this.props.selectedPriceLevels !== prevProps.selectedPriceLevels) {
    //   this.setState({
    //     selectedPriceLevels: [...this.props.selectedPriceLevels],
    //   });
    // }
  }

  updatePriceLevelQty =
      (outletEventItems) =>
          (priceLevel, inc = 1, alreadySelected) => {
            //console.log("updatePriceLevelQty", priceLevel, inc, alreadySelected);
            let selectedPriceLevels = [...this.state.selectedPriceLevels];
            const index = selectedPriceLevels.findIndex(
                (pl) => pl.PriceLevelID === priceLevel.PriceLevelID,
            );
            const increment = parseInt(inc, 10);

            if (index === -1) {
              let selectedPriceLevel = {
                ...priceLevel,
                oei: outletEventItems.find((oei) =>
                    oei.PriceLevels.find(
                        (pl) => pl.PriceLevelID === priceLevel.PriceLevelID,
                    ),
                ),
              };
              if (increment && increment > 0) {
                selectedPriceLevel.quantity = increment;
              } else {
                selectedPriceLevel.quantity = 0;

              }

              //selectedPriceLevel.quantity = increment;
              selectedPriceLevels.push(selectedPriceLevel);
            } else {
              let spl = selectedPriceLevels[index];
              let currentQty = parseInt(spl.quantity ?? spl.Quantity, 10);

              //console.log("updatePriceLevelQty currentQty", currentQty);
              if (isNaN(currentQty)) {
                currentQty = 0;
              }
              let newQty = parseInt(currentQty + increment, 10);
              //console.log("updatePriceLevelQty newQty", newQty);
              if (newQty < 1) {
                //&& !alreadySelected
                selectedPriceLevels.splice(index);
              } else {
                selectedPriceLevels[index].quantity = newQty;
                selectedPriceLevels[index].Quantity = newQty;
              }
            }

            this.setState({ selectedPriceLevels });
            this.props.handleAddOnQtyChange &&
            this.props.handleAddOnQtyChange(selectedPriceLevels);
          };

  render() {
    console.log('CheckoutAddons state = ', this.state);
    console.log('CheckoutAddons addons props = ', this.props);
    const { selectedPriceLevels } = this.state;
    const { outletEventItems, showTitle, showDescription, showPriceLevels } =
        this.props;
    if (!outletEventItems || !Array.isArray(outletEventItems)) return null;
    return (
        <AddonRoot>
          <Modal
              title={this.state.modalTitle}
              open={this.state.modalIsVisible}
              onClose={() => {
                this.setState({
                  modalTitle: '',
                  modalImage: '',
                  modalIsVisible: false,
                });
              }}
          >
            <img
                src={this.state.modalImage}
                alt={this.state.modalTitle}
                style={{ width: '100%', display: 'block' }}
            />
          </Modal>
          {outletEventItems.map((oei, key) => {
            return (
                <ItemContainer key={key}>
                  {oei.Image && (
                      <ItemImage>
                        <img
                            src={oei.Image}
                            alt={oei.Name}
                            onClick={() => {
                              this.setState({
                                modalTitle: oei.Name,
                                modalImage: oei.Image,
                                modalIsVisible: true,
                              });
                            }}
                        />
                        <ImageZoomButton
                            onClick={() => {
                              this.setState({
                                modalTitle: oei.Name,
                                modalImage: oei.Image,
                                modalIsVisible: true,
                              });
                            }}
                        >
                          + Zoom
                        </ImageZoomButton>
                      </ItemImage>
                  )}
                  <ItemContent>
                    {showTitle && <Typography tag="h4">{oei.Name}</Typography>}
                    {showDescription && (
                        <Typography
                            tag="p"
                            dangerouslySetInnerHTML={{
                              __html: oei.Description,
                            }}
                        />
                    )}
                    {showPriceLevels &&
                    oei.PriceLevels &&
                    oei.PriceLevels.length ? (
                        oei.PriceLevels.map((priceLevel) => {
                          const selectedPriceLevel = selectedPriceLevels.find(
                              (spl) =>
                                  Number(spl.PriceLevelID) ===
                                  Number(priceLevel.PriceLevelID),
                          );

                          const priceLevelAlreadySelected =
                              this.state.selectedPriceLevels.find(
                                  (spl) =>
                                      Number(spl.PriceLevelID) ===
                                      Number(priceLevel.PriceLevelID),
                              );
                          return (
                              <div
                                  key={priceLevel.PriceLevelID}
                                  name={'addon-' + priceLevel.PriceLevelID}
                              >
                                <PriceLevelSelectorInstant
                                    displayFees={this.props.displayFees}
                                    priceLevelAlreadySelected={priceLevelAlreadySelected}
                                    priceLevel={
                                      selectedPriceLevel ? selectedPriceLevel : priceLevel
                                    }
                                    updatePriceLevelQty={this.updatePriceLevelQty(
                                        outletEventItems,
                                    )}
                                />
                              </div>
                          );
                        })
                    ) : (
                        <Typography>
                          There are currently no price levels for sale
                        </Typography>
                    )}
                  </ItemContent>
                </ItemContainer>
            );
          })}
        </AddonRoot>
    );
  }
}

CheckoutAddons.propTypes = {
  outletEventItems: PropTypes.array.isRequired,
  showTitle: PropTypes.bool,
  showDescription: PropTypes.bool,
  showPriceLevels: PropTypes.bool,
  handleAddOnQtyChange: PropTypes.func,
  displayFees: PropTypes.bool,
};

CheckoutAddons.defaultProps = {
  selectedPriceLevels: [],
  outletEventItems: [],
  showTitle: true,
  showDescription: true,
  showPriceLevels: true,
  handleAddOnQtyChange: undefined,
  displayFees: false
};

export default CheckoutAddons;
