import { formatCurrency } from '../../utils/utilities';

/**
 * Gets the price level label display
 * Used to show both standard and "all-in pricing"
 * @param {object} pl The price level
 * @param {array} eventList The event list
 * @param {number} outletEventId The outlet event ID
 * @param {boolean} showPriceLevelName Whether to show the price level name
 * @param {boolean} showTicketLimit Whether to display the ticket limit
 * @return {string}
 */
export const getPriceLevelLabel = (pl, outletEventId, eventList, showPriceLevelName = false, showTicketLimit = false) => {
  let priceLevelVal = `${formatCurrency(pl.PriceLevelValue)}`;
  let labelParts = ['', priceLevelVal];

  if (showPriceLevelName) {
    labelParts[0] = `${pl.PriceLevelName} -`;
  }

  if (showTicketLimit) {
    labelParts.push(`(Ticket Limit: ${pl.PriceLevelLimit})`);
  }

  // Find the outlet event
  const outletEvent = eventList.find((oe) => oe.OutletEventID.toString() === outletEventId.toString());

  if (outletEvent && outletEvent.ShowAllInPricing === 1) {
    // Display a combined price of the face value and fee
    const feeItems = pl.FeeGroups.filter((fg) => fg.ConstantValue === "FEEGROUP_FEE");
    const feeGroupTotal = feeItems.reduce((acc, curr) => {
      return acc + curr.Value;
    }, 0);

    labelParts[1] = `${formatCurrency(pl.PriceLevelValue + feeGroupTotal)}`;
  }

  return labelParts.join(' ').trim();
};
