import styled from 'styled-components';
import React, { memo } from 'react';

const DotStyled = styled.div`
  padding: 5px;
  margin-right: 5px;
  // cursor: pointer;
  border-radius: 50%;
  background: ${(props) => (props.active ? '#333' : 'white')};
  border: solid 1px #333;
`;
const DotsStyled = styled.div`
  position: absolute;
  bottom: 25px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const MemoDot = memo(DotStyled);
const Dots = ({ slides, activeSlide, onClickDot }) => (
    <DotsStyled active>
      {slides.map((slide, i) => (
          <MemoDot
              key={i}
              active={activeSlide === i}
              onClick={() => (onClickDot ? onClickDot(i) : false)}
          />
      ))}
    </DotsStyled>
);

export default Dots;
