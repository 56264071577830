import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const AttentionRoot = styled.div`
  font-size: 1.6rem;
  margin: 0;
  padding: 1.5rem 0;
  outline: none;
  p:first-child {
    margin: 0;
  }
`;

const Info = styled(AttentionRoot)`
  color: ${(props) => props.theme.ALERT_LOW_PRIORITY_TEXT_COLOR};
  background-color: ${(props) => props.theme.ALERT_LOW_PRIORITY_BACKGROUND};
`;

const Warning = styled(AttentionRoot)`
  color: ${(props) => props.theme.ALERT_MEDIUM_PRIORITY_TEXT_COLOR};
  background-color: ${(props) => props.theme.ALERT_MEDIUM_PRIORITY_BACKGROUND};
`;

const Critical = styled(AttentionRoot)`
  color: ${(props) => props.theme.ALERT_HIGH_PRIORITY_TEXT_COLOR};
  background-color: ${(props) => props.theme.ALERT_HIGH_PRIORITY_BACKGROUND};
`;

const Attention = ({ loading, status, children, ...props }) => {
  let variant = Info;
  if (status === 'warning') {
    variant = Warning;
  }
  if (status === 'critical') {
    variant = Critical;
  }
  return (
      <AttentionRoot disabled={loading} as={variant} {...props}>
        {loading ? '...loading' : children}
      </AttentionRoot>
  );
};

Attention.propTypes = {
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  status: PropTypes.oneOf(['info', 'warning', 'critical']),
  size: PropTypes.string,
};

Attention.defaultProps = {
  loading: false,
  status: 'info',
  children: ' ',
};

export default Attention;
