import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import Typography from './Typography';

const RootStyled = styled.div`
  font-size: 1.6rem;
  margin: 0;
  padding: 0.5rem;
  outline: none;
  color: ${(props) => props.theme.ERROR_TEXT_COLOR};
`;

class Alert extends React.Component {
  render() {
    let { children, type, ...props } = this.props;
    return (
        <RootStyled type={type} {...props}>
          <Typography>{children}</Typography>
        </RootStyled>
    );
  }
}

Alert.defaultProps = {
  type: 'info',
};

Alert.propTypes = {
  type: PropTypes.string,
};
export default Alert;
