import React from 'react';
import styled from 'styled-components';

import Typography from './Typography';
import { formatCurrency, getCountryList } from './utilities';
import { FeeItemRow } from '../components/cart/LineItemSummary';

const BtnStyled = styled(Typography)`
  color: ${(props) => props.theme.PRIMARY_LINK_COLOR};
  text-decoration: none;
  cursor: pointer;
  &:hover {
    text-decoration: none;
  }
`;
const SeriesTotalsLineItemStyled = styled.div`
  display: flex;
  margin: 0 0 0.5rem 0;
`;

const SeriesTotalsLabelStyled = styled(Typography)`
  flex: 1;
`;
const SeriesTotalsTotalStyled = styled(Typography)`
  padding: 0 0 0 0.5rem;
`;

export const DisplaySeriesTotals = ({ seriesSummary }) => {
  //console.log("DisplaySeriesTotals params", seriesSummary);
  if (!seriesSummary) return null;
  return (
      <div>
        {seriesSummary &&
            seriesSummary.LotteryOERequests &&
            seriesSummary.LotteryOERequests.map((request, idx) => {
              const Event = request.OutletEvent && request.OutletEvent.Event;
              return (
                  <SeriesTotalsLineItemStyled
                      key={idx}
                      style={{ fontStyle: 'italic' }}
                  >
                    <SeriesTotalsLabelStyled>
                      {Event &&
                          Event.ShowDate &&
                          new Date(Event.ShowDate).toLocaleDateString('en-US', {
                            month: '2-digit',
                            day: '2-digit',
                            year: 'numeric',
                            timeZone: 'America/New_York',
                          })}{' '}
                      Event Max
                    </SeriesTotalsLabelStyled>
                    <SeriesTotalsTotalStyled>
                      {request.Totals &&
                          request.Totals.total &&
                          new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: 'USD',
                          }).format(request.Totals.total)}
                    </SeriesTotalsTotalStyled>
                  </SeriesTotalsLineItemStyled>
              );
            })}

        {seriesSummary &&
            seriesSummary.Totals &&
            seriesSummary.Totals.shipping > -1 && (
                <SeriesTotalsLineItemStyled style={{ fontStyle: 'italic' }}>
                  <SeriesTotalsLabelStyled>Shipping</SeriesTotalsLabelStyled>
                  <SeriesTotalsTotalStyled>
                    {new Intl.NumberFormat('en-US', {
                      style: 'currency',
                      currency: 'USD',
                    }).format(seriesSummary.Totals.shipping)}
                  </SeriesTotalsTotalStyled>
                </SeriesTotalsLineItemStyled>
            )}

        <SeriesTotalsLineItemStyled style={{ fontWeight: 'bold' }}>
          <SeriesTotalsLabelStyled>Maximum Order Total</SeriesTotalsLabelStyled>
          <SeriesTotalsTotalStyled>
            {seriesSummary &&
                seriesSummary.Totals &&
                new Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: 'USD',
                }).format(seriesSummary.Totals.total)}
          </SeriesTotalsTotalStyled>
        </SeriesTotalsLineItemStyled>
      </div>
  );
};

export const DisplayHeader = ({ title, onClickBtn, btnText, btnName }) => {
  if (title) {
    if (!btnName) {
      btnName = '';
    }
    return (
        <div
            style={{
              display: 'flex',
              alignItems: 'baseline',
              margin: '0 0 1.5rem 0',
            }}
        >
          <div>
            <Typography tag="h1">{title}</Typography>
          </div>
          {onClickBtn && btnText && (
              <div style={{ fontWeight: 'bold', margin: '0 0 0 2rem', flex: 1 }}>
                <BtnStyled
                    onClick={onClickBtn}
                    name={btnName}
                    className={'header-' + btnName}
                >
                  {btnText}
                </BtnStyled>
              </div>
          )}
        </div>
    );
  } else {
    return null;
  }
};

export const DisplayShippingAddress = ({ address }) => {
  console.log('DisplayShippingAddress ', address);
  if (address && Object.keys(address).length) {
    const {
      AddressID,
      CompanyName,
      AddressLineOne,
      AddressLineTwo,
      City,
      CountryID,
      First,
      Last,
      Phone,
      State,
      Zip,
      EmailAddres,
    } = address;
    const country = getCountryList().find((x) => x.id == Number(CountryID));
    console.log('DisplayShippingAddress country', country);
    return (
        <Typography>
          <div>
            {First} {Last}
          </div>
          <div>{CompanyName}</div>
          <div>{AddressLineOne}</div>
          {AddressLineTwo && <div>{AddressLineTwo}</div>}
          <div>
            {City}, {State} {Zip}
          </div>
          {country && country.name && <div>{country.name}</div>}
          <div>{Phone}</div>
          {EmailAddres && <div>{EmailAddres}</div>}
        </Typography>
    );
  } else {
    return 'No address info';
  }
};

export const DisplayPaymentMethod = ({ paymentMethod }) => {
  // AddressID: null
  // AuthorizePaymentMethodID: "1507410481"
  // CardHolderName: "Derek Bernier"
  // Description: ""
  // Expiration: "2023-11"
  // LastFour: "1111"
  // PaymentMethodID: 106
  // PaymentMethodUUID: "ed766029-d45e-4bdb-ba6d-3bab5a425157"
  // UserID: 98
  if (paymentMethod) {
    return (
        <Typography>
          <div>{paymentMethod.CardHolderName}</div>
          <div>
            {paymentMethod.Type} ***{paymentMethod.LastFour}
          </div>
          <div>Exp {paymentMethod.Expiration}</div>
        </Typography>
    );
  } else {
    return <div>No payment info</div>;
  }
};

export const DisplayFees = (params) => {
  const {
    FeeGroups,
    quantity = 1,
    hasLeftBorder = false,
    hasRightBorder = false,
    layout = 'horizontal',
  } = params;

  const feeListFormatted = Array.isArray(FeeGroups) ? FeeGroups.map(feeGroup => {
    const { Total, Value } = feeGroup;
    const feeTotal = (Total || Value) * quantity;
    return ({ feeTotal, ...feeGroup });
  }) : [];

  return feeListFormatted.map((fee, idx) => {
    if (layout === 'horizontal') {
      return (<FeeItemRow
          key={idx}
          label={fee.Name}
          total={fee.feeTotal}
          description={fee.Description}
          hasLeftBorder={hasLeftBorder}
          hasRightBorder={hasRightBorder}
      />);
    } else {
      return (
          <Typography
              key={idx}
              style={{ marginBottom: '1rem' }}>
            {fee.Name}<br/>{formatCurrency(fee.feeTotal)}
          </Typography>);
    }
  });
};
