import React from 'react';
import { useSelector } from 'react-redux';

export default function SubHeaderThemeImage() {
  const outlet = useSelector((state) => state.outlet);

  if (outlet.OutletTheme && outlet.OutletTheme.SubHeaderImageURL) {
    const { SubHeaderImageURL, SubHeaderLinkURL, SubHeaderTitle } =
        outlet.OutletTheme;

    return (
        <img
            src={SubHeaderImageURL}
            alt={SubHeaderTitle || 'Sub Header'}
            title={SubHeaderTitle || 'Sub Header'}
            onClick={() =>
                SubHeaderLinkURL ? window.open(SubHeaderLinkURL, '_blank') : false
            }
            style={{
              maxHeight: 'auto',
              width: '100%',
              display: 'block',
              cursor: SubHeaderLinkURL ? 'pointer' : 'none',
            }}
        />
    );
  }
  return null;
}
