import PropTypes from 'prop-types';
import React from 'react';

import Alert from './Alert';

export class AuthnetPaymentMethodCreate extends React.Component {
  state = {
    authNetFormAction: '/customer/addPayment',
    authNetFormTarget: 'authnetProfileIframe',
    iFrameHeight: '1000px',
    iFrameWidth: '100%',
    showIframe: false,
  };

  showIframe = () => {
    this.setState({ showIframe: true });
  };

  hideIframe = () => {
    this.setState({ showIframe: false });
  };

  componentDidMount() {
    // Simple function for parsing response from auth.net iFrame
    function parseQueryString(str) {
      var vars = [];
      var arr = str.split('&');
      var pair;
      for (var i = 0; i < arr.length; i++) {
        pair = arr[i].split('=');
        //vars[pair[0]] = unescape(pair[1]);
        vars[pair[0]] = pair[1];
      }
      return vars;
    }

    // Attach callback function to the window so we can listen to the auth.net iframe
    window.CommunicationHandler = {};
    window.CommunicationHandler.onReceiveCommunication = (argument) => {
      console.log('Authnet iFrame response => ', argument);

      var params = parseQueryString(argument.qstr);

      switch (params['action']) {
        case 'resizeWindow':
          //{"qstr":"action=resizeWindow&width=691&height=1147","parent":"https://test.authorize.net/payment/payment"}
          var height = parseInt(params['height']);
          //var width = parseInt(params["width"]);
          this.setState({ iFrameHeight: height });
          break;
        case 'successfulSave':
          //{"qstr":"action=successfulSave","parent":"https://test.authorize.net/customer/addPayment"}
          this.hideIframe();
          this.props.onSuccess();
          break;
        case 'cancel':
          //{"qstr":"action=cancel","parent":"https://test.authorize.net/customer/addPayment"}
          this.hideIframe();
          this.props.onCancel();
          break;
        case 'transactResponse':
          //var transResponse = JSON.parse(params["response"]);
          this.hideIframe();
          this.props.onSuccess();
          break;
        default:
          console.log('authent iFrame unknown action: ' + params['action']);
          break;
      }
    };

    // Manually submit the hidden auth.net form
    if (this.props.authnetProfileToken) {
      this.setState(
          {
            showIframe: true,
          },
          () => {
            const form = document.getElementById('authnetProfileForm');
            form.submit();
          },
      );
    }
  }

  render() {
    const { authnetProfileToken, authnetEndPoint } = this.props;
    const {
      authNetFormAction,
      authNetFormTarget,
      iFrameWidth,
      iFrameHeight,
      showIframe,
    } = this.state;

    if (!authnetProfileToken) {
      return <Alert type="warning">No token provided</Alert>;
    }

    return (
        <div id="create-payment-method-form">
          <iframe
              id={authNetFormTarget}
              name={authNetFormTarget}
              width={iFrameWidth}
              height={iFrameHeight}
              frameBorder="0"
              scrolling="no"
              hidden={!showIframe}
              title="create payment method iframe"
          />

          <form
              id="authnetProfileForm"
              method="post"
              action={authnetEndPoint + authNetFormAction}
              target={authNetFormTarget}
          >
            <input
                type="hidden"
                name="token"
                value={authnetProfileToken ? authnetProfileToken : ''}
            />
          </form>
        </div>
    );
  }
}

AuthnetPaymentMethodCreate.defaultProps = {
  authnetProfileToken: null,
  authnetEndPoint: null,
  onCancel: () => false,
  onSuccess: () => false,
};

AuthnetPaymentMethodCreate.propTypes = {
  authnetProfileToken: PropTypes.string,
  authnetEndPoint: PropTypes.string,
  onSuccess: PropTypes.func,
  onCancel: PropTypes.func,
};
export default AuthnetPaymentMethodCreate;
