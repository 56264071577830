import styled from 'styled-components';
import Typography from '../../utils/Typography';

export const StyledNoOrdersContainer = styled.div`
  padding: 4rem 0;
  text-align: center;
`;

export const StyledNoOrdersTitle = styled.div`
  margin: 0 0 4rem 0
  font-weight: 700;
`;

export const StyledOrderHeader = styled.div`
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr;
  margin: 2rem 0 0 0;
  font-size: 1.8rem;
  align-items: center;
  justify-content: space-between;
  @media (min-width: ${(props) => props.theme.BREAKPOINT_MOBILE_WIDTH}px) {
    grid-template-columns: auto 220px auto;
  }
`;

export const OrderNumberStyled = styled(Typography)`
  cursor: pointer;
  color: ${(props) => props.theme.PRIMARY_LINK_COLOR}!important;
`;

export const StyledOrderTotalsSummaryContainer = styled.div`
  border-bottom: solid 1px ${(props) => props.theme.PRIMARY_GRAY}80;
  display: flex;
  padding: 1rem 0 1rem 0;
  margin: 0 0 6rem 0;
  justify-content: flex-end;
`;

export const StyledOrderTotalsSummaryContent = styled.div`
  width: 225px;
`;

export const StyledOrderTotalsSummaryBlock = styled.div`
  border-top: solid 1px ${(props) => props.theme.PRIMARY_GRAY}80;
  padding: 1rem 0;
  margin: 1rem 0;
`;

export const StyledEventListTable = styled.table`
  display: grid;
  border-collapse: collapse;
  min-width: 100%;
  grid-template-columns: ${(props) => props.gridTemplateColumns};
  @media (max-width: ${(props) => props.theme.BREAKPOINT_MOBILE_WIDTH}px) {
    display: none;
  }
  border-bottom: solid 1px ${(props) => props.theme.PRIMARY_GRAY}80;
  thead,
  tbody,
  tr {
    display: contents;
  }
  th,
  td {
    padding: 2rem 1rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-top: solid 1px ${(props) => props.theme.PRIMARY_GRAY}80;
  }
  td.allowOverflow {
    overflow: visible;
    text-overflow: initial;
    white-space: normal;
  }
  th {
    border: none;
  }
  th:last-child {
    border: 0;
  }

  tr:nth-child(even) td {
    background: ${(props) => props.theme.PRIMARY_GRAY}40;
  }
  th {
    text-align: left;
    font-weight: 700;
    text-transform: uppercase;
  }
`;

export const StyledRowSpan = styled.div`
  display: ${(props) => (props.visible ? 'initial' : 'none')};
  padding: ${(props) => (props.visible ? '2rem 0rem' : 'none')};
  grid-column: span ${(props) => props.span};
`;

export const StyledIconContainer = styled.span`
  display: inline-block;
  vertical-align: text-bottom;
  cursor: pointer;
  color: ${(props) => props.theme.PRIMARY_LINK_COLOR}!important;
`;

export const StyledEventListMobileContainer = styled.div`
  margin: 0 0 2rem 0;
  border: solid 1px #d6d6d6;
  box-shadow: ${(props) =>
    props.isExpanded ? '0 0  10px 1px #d6d6d6' : '0 0 0 0'};
  display: block;
  grid-template-columns: 50px 90px auto 30px 1fr;
  @media (min-width: ${(props) => props.theme.BREAKPOINT_MOBILE_WIDTH}px) {
    display: none;
  }
`;

export const StyledEventListMobileGrid = styled.div`
  display: grid;
  grid-template-columns: ${(props) =>
    props.prioritySelectIsVisible ? '40px 100px auto 30px' : '100px auto 30px'};
  grid-gap: 10px;
  padding: 1rem;
`;

export const StyledLabelColumn = styled.div`
  color: #4a4a4a;
  text-transform: uppercase;
  font-weight: 600;
`;

export const StyledToggleIconColumn = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledExpandedRow = styled.div`
  border-top: solid 1px #d6d6d6;
`;

