import PropTypes from 'prop-types';
import React from 'react';

import Alert from '../../utils/Alert';
import Button from '../../utils/Button';
import Typography from '../../utils/Typography';
import { TextInput } from '../../utils/Form';
import { goToUrl } from '../../utils/utilities';
import { loginUser, resetUserPassword } from './actions';

//import { connect, useSelector } from "react-redux";

export class ResetPassword extends React.Component {
  state = {
    emailAddress: '',
    password: '',
    confirmPassword: '',
    formErrors: {},
    loading: false,
    error: null,
  };

  handleSubmit = () => {
    const { emailAddress, password, confirmPassword } = this.state;
    const { token, onSuccess } = this.props;
    const formErrors = {};

    if (!emailAddress) {
      formErrors['emailAddress'] = 'Email required';
    }
    if (!token) {
      formErrors['token'] = 'Token required';
    }
    if (!password || !confirmPassword) {
      formErrors['password'] = 'Password required';
    } else {
      if (password.length < 7) {
        formErrors['password'] = 'Password must contain at least 7 characters.';
      }
      if (password.match(/[a-z]/i) === null) {
        formErrors['password'] =
            'Password must contain at least 1 alpha character.';
      }
      if (password.match(/\d/i) === null) {
        formErrors['password'] =
            'Password must contain at least 1 numeric character.';
      }
    }
    if (password !== confirmPassword) {
      formErrors['confirmPassword'] = 'Passwords don\'t match';
    }
    this.setState({ formErrors });
    //console.log("Reset Password formErrors =>", formErrors);
    if (Object.keys(formErrors).length) {
      //console.log("Reset Password formErrors 2 =>", formErrors);
      return false;
    } else {
      this.setState({
        loading: true,
        error: null,
      });

      resetUserPassword({
        emailAddress,
        password,
        token,
      }).then((data) => {
        //Automatically log user in after successfull reset
        loginUser({
          id: emailAddress,
          password,
        }).then((res) => {
          this.setState({
            loading: false,
            error: null,
          });
          onSuccess ? onSuccess() : goToUrl('/');
        }).catch((error) => {
          this.setState({
            loading: false,
            error,
          });
        });
      }).catch((error) => {
        this.setState({
          loading: false,
          error:
              error || 'System error during reset password, please try again',
        });
      });
    }
  };

  handleInput = (name) => (event) =>
      this.setState({ [name]: event.target.value });

  render() {
    //console.log("Reset Password props =>", this.props);
    //console.log("Reset Password state =>", this.state);

    const { onCancel, token } = this.props;
    const {
      error,
      loading,
      password,
      formErrors,
      emailAddress,
      confirmPassword,
    } = this.state;

    return (
        <div>
          <Typography tag="h1" style={{ textAlign: 'center' }}>
            Reset Password
          </Typography>
          <div style={{ marginTop: '25px' }}>
            <form
                autoComplete="off"
                onSubmit={(e) => {
                  e.preventDefault();
                }}
            >
              <TextInput
                  name="token"
                  label="Token"
                  error={formErrors['token']}
                  fullWidth
                  value={token}
                  onChange={() => false}
                  required
                  disabled
              />

              <TextInput
                  name="emailAddress"
                  label="Email Address"
                  error={formErrors['emailAddress']}
                  fullWidth
                  value={emailAddress}
                  onChange={this.handleInput('emailAddress')}
                  required
                  id="emailAddress"
              />

              <TextInput
                  name="password"
                  label="New Password"
                  error={formErrors['password']}
                  fullWidth
                  value={password}
                  onChange={this.handleInput('password')}
                  required
                  type="password"
                  id="newPassword"
                  autoComplete="new-password"
              />

              <TextInput
                  name="confirmPassword"
                  label="Confirm New Password"
                  type="password"
                  error={formErrors['confirmPassword']}
                  fullWidth
                  value={confirmPassword}
                  onChange={this.handleInput('confirmPassword')}
                  required
                  id="confirmNewPassword"
                  autoComplete="new-password"
              />

              {error && (
                  <div style={{ margin: '20px 0 10px 0' }}>
                    <Alert type="error"> {error} </Alert>
                  </div>
              )}

              <Button
                  fullWidth
                  loading={loading}
                  onClick={() => this.handleSubmit()}
              >
                Reset Password
              </Button>

              {!loading && onCancel && (
                  <div style={{ margin: '25px 0 0 0', textAlign: 'center' }}>
                    <Typography
                        tag="a"
                        secondary
                        disabled={loading}
                        onClick={onCancel}
                    >
                      Cancel
                    </Typography>
                  </div>
              )}
            </form>
          </div>
        </div>
    );
  }
}

ResetPassword.propTypes = {
  onCancel: PropTypes.func,
  onSuccess: PropTypes.func,
  token: PropTypes.string,
};

ResetPassword.defaultProps = {
  token: null,
  onCancel: null,
  onSuccess: null,
  apiErrors: [],
  apiLoading: [],
};
export default ResetPassword;
// function mapStateToProps(state) {
//   const { outlet, app, api } = state;
//   return {
//     apiErrors: api.error,
//     apiLoading: api.loading,
//     outlet,
//     userFailedLoginAttemptsCheckoutRealTime:
//       app.userFailedLoginAttemptsCheckoutRealTime,
//   };
// }
// export default withRouter(connect(mapStateToProps)(ResetPassword));
