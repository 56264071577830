import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import SeatDetails from '../cart/SeatDetails';

const SummaryRoot = styled.div`
  border: solid 1px #dbdbdb;
  margin: 0 0 5rem 0;
`;
const EventItemNameStyled = styled.div`
  padding: 1rem;
  text-align: center;
  font-size: 1.6rem;
`;
const PriceLevel = styled.div`
  padding: 1rem;
  text-align: center;
  font-size: 1.6rem;
`;

function SeatAssignmentSummary(props = {}) {
  const {
    //cartLineItems,
    outletEvent,
    //outletEventItems,
    suppressSeatInfo,
    cart,
  } = props;
  console.log('SeatAssignmentSummary props cart = ', cart);
  console.log('SeatAssignmentSummary props outletEvent = ', outletEvent);

  if (
      cart &&
      cart.lineItems &&
      Array.isArray(cart.lineItems) &&
      outletEvent &&
      outletEvent.OutletEventItems &&
      Array.isArray(outletEvent.OutletEventItems)
  ) {
    const selectedOEIs = [];

    outletEvent.OutletEventItems.forEach((oei) => {
      let oeiAlreadyMapped = false;
      let outletEventItem;
      if (oei.PriceLevels) {
        oei.PriceLevels.forEach((pl) => {
          cart.lineItems.forEach((li) => {
            let priceLevel;
            if (Number(pl.PriceLevelID) === Number(li.priceLevelID)) {
              priceLevel = { ...pl, quantity: li.quantity };

              //selectedPriceLevels.push(priceLevel);
              if (oeiAlreadyMapped) {
                outletEventItem = {
                  ...oei,
                  PriceLevels: [...outletEventItem.PriceLevels, priceLevel],
                };
              } else {
                outletEventItem = { ...oei, PriceLevels: [priceLevel] };
              }
              oeiAlreadyMapped = true;
            }
          });
        });
      }

      if (outletEventItem) {
        selectedOEIs.push(outletEventItem);
      }
    });
    //console.log("SeatAssignmentSummary selectedOEIs = ", selectedOEIs);

    return selectedOEIs.map((outletEventItem, idx) => {
      // Only confirm ticket type

      if (
          outletEventItem.EventItem
          //&& outletEventItem.EventItem.EventItemTypeID == 1 // no longer filter on type after morning meeting on 7-28
      ) {
        return (
            <SummaryRoot key={idx}>
              <EventItemNameStyled>
                {outletEventItem.EventItem.Name}
              </EventItemNameStyled>

              {outletEventItem.PriceLevels &&
                  outletEventItem.PriceLevels.map((pl) =>
                      cart.lineItems.map((li, liidx) => {
                        if (Number(li.priceLevelID) === Number(pl.PriceLevelID)) {
                          let quantity = li.quantity;
                          let seatInfo = li.seatInfo;
                          let seatInfoIsVisible =
                              suppressSeatInfo === false &&
                              seatInfo &&
                              seatInfo.manifest &&
                              Array.isArray(seatInfo.manifest)
                                  ? true
                                  : false;

                          return (
                              <div key={liidx}>
                                <PriceLevel>
                                  {pl.PriceLevelName} x {quantity}
                                </PriceLevel>
                                {seatInfoIsVisible && (
                                    <SeatDetails manifest={seatInfo.manifest}/>
                                )}
                              </div>
                          );
                        }
                        return null;
                      }),
                  )}
            </SummaryRoot>
        );
      } else {
        return null;
      }
    });
  }
  return null;
}

SeatAssignmentSummary.propTypes = {
  outletEventItems: PropTypes.array.isRequired,
  suppressSeatInfo: PropTypes.bool,
  cartLineItems: PropTypes.array,
  cart: PropTypes.object,
  // seatInfo: PropTypes.oneOfType([PropTypes.object, PropTypes.bool])
  //   .isRequired,
};

SeatAssignmentSummary.defaultProps = {
  cart: null,
  outletEvent: null,
  cartLineItems: [],
  priceLevel: null,
  outletEventItems: [],
  suppressSeatInfo: false,
};

export default SeatAssignmentSummary;
