import PropTypes from 'prop-types';
import styled from 'styled-components';
import React, { useState } from 'react';
import Alert from '../../utils/Alert';
import Button from '../../utils/Button';
import { SelectInput, TextInput } from '../../utils/Form';
import {
  formatCurrency,
  getCanadaProvinces, getCountryList, getUnitedStates,
} from '../../utils/utilities';
import {
  GUEST_LIST_INVITATION_GUEST_CHOICE_DELIVERY_OPTION_ID,
  GUEST_LIST_INVITATION_SHIPPING_AND_MOBILE_DELIVERY_OPTION_ID,
  GUEST_LIST_INVITATION_WILL_CALL_ONLY_DELIVERY_OPTION_ID,
  DELIVERY_METHOD_TYPE_MOBILE_TICKETING_ID,
  DELIVERY_METHOD_TYPE_WILL_CALL_ID,
  DELIVERY_METHOD_TYPE_SHIPPABLE_DOMESTIC_ID, DELIVERY_METHOD_TYPE_SHIPPABLE_INTERNATIONAL_ID,
} from '../../utils/constants';

const OptionContainerStyled = styled.div`
  padding: 0 0 0 0;
  margin: 0 0 1rem 0;
`;
const OptionLabelStyled = styled.label`
  font-size: 1.6rem;
  margin: 0 0 0 0;
  padding: 0 0 0 1rem;
`;
const RadioInputStyled = styled.input`
  width: 16px;
  height: 16px;
  opacity: 1;
  color: ${(props) => props.theme.PRIMARY_LINK_COLOR};
  accent-color: ${(props) => props.theme.PRIMARY_LINK_COLOR};
`;
const WillCallNameInputContainerStyled = styled.div`
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr 1fr;
`;

const blankAddress = {
  AddressID: '',
  First: '',
  Last: '',
  AddressLineOne: '',
  AddressLineTwo: '',
  City: '',
  State: '',
  Zip: '',
  CountryID: '',
  Phone: '',
  Note: '',
};

const getMobileDeliveryMethod = (outlet) => {
  const { OutletDeliveryMethods } = outlet;
  if (Array.isArray(OutletDeliveryMethods)) {
    return OutletDeliveryMethods.find(x => x.DeliveryMethodTypeID === DELIVERY_METHOD_TYPE_MOBILE_TICKETING_ID);
  }
  return null;
};

const isMobileDeliveryEnabledForCheckout = (params) => {
  const { cart, outletEvents, mobileDeliveryMethod } = params;
  // RLMTS 3901 / 3889 - Enable Mobile Delivery Rules:
  // 1. Outlet must have an active delivery method of 'Mobile Delivery' type;
  // 2. User's request(cart) must have at least one event that belongs to a series with 'EnableMobileDelivery' flag.
  let isEnabled = false;
  if (mobileDeliveryMethod?.Active && cart && Array.isArray(cart.priceLevels) && Array.isArray(outletEvents)) {
    cart.priceLevels.forEach(priceLevelInCart => {
      outletEvents.find(oe => {
        const { OutletEventItems, OutletEventGroup } = oe;
        if (OutletEventGroup && Array.isArray(OutletEventItems)) {
          const { EnableMobileDelivery } = OutletEventGroup;
          OutletEventItems.forEach(oei => {
            if (Array.isArray(oei.PriceLevels)) {
              const priceLevelMatch = oei.PriceLevels.find(pl => pl.PriceLevelID === priceLevelInCart.PriceLevelID);
              if (priceLevelMatch && EnableMobileDelivery) {
                isEnabled = true;
              }
            }
          });
        }
      });
    });
  }
  return isEnabled;
};

const getMobileDeliveryNotes = (params) => {
  // RLMTS-3976 - Display mobile delivery shipping note rules:
  // 1. Display shipping note found on the series
  // 2. If rule above is null then use the default note on the delivery method object.
  const { cart, outletEvents, mobileDeliveryMethod } = params;
  let mobileDeliveryShippingNote = mobileDeliveryMethod?.OverrideNotes || '';
  if (cart && Array.isArray(cart.priceLevels) && Array.isArray(outletEvents)) {
    cart.priceLevels.forEach(priceLevelInCart => {
      outletEvents.find(oe => {
        const { OutletEventItems, OutletEventGroup } = oe;
        if (OutletEventGroup && Array.isArray(OutletEventItems)) {
          const { EnableMobileDelivery, ShippingNote } = OutletEventGroup;
          OutletEventItems.forEach(oei => {
            if (Array.isArray(oei.PriceLevels)) {
              const priceLevelMatch = oei.PriceLevels.find(pl => pl.PriceLevelID === priceLevelInCart.PriceLevelID);
              if (priceLevelMatch && EnableMobileDelivery && ShippingNote) {
                mobileDeliveryShippingNote = ShippingNote;
              }
            }
          });
        }
      });
    });
  }
  return mobileDeliveryShippingNote;
};

const getShippableDeliveryMethods = (deliveryMethodList = []) => {
  let filteredDeliveryMethods = [];
  if (Array.isArray(deliveryMethodList)) {
    filteredDeliveryMethods = deliveryMethodList.filter(
        (x) => x.DeliveryMethodTypeID === DELIVERY_METHOD_TYPE_SHIPPABLE_DOMESTIC_ID || x.DeliveryMethodTypeID ===
            DELIVERY_METHOD_TYPE_SHIPPABLE_INTERNATIONAL_ID,
    );
  }
  return filteredDeliveryMethods;
};

export const GuestListCheckoutDeliveryInfo = (props = {}) => {
  const {
    cart,
    error,
    outlet,
    loading,
    onSubmit,
    outletEvents,
    defaultAddress,
    userAddressList,
    deliveryMethodList,
    guestListInvitation,
  } = props;

  const [address, setAddress] = useState(defaultAddress || blankAddress);
  const [formErrors, setFormErrors] = useState({});
  const [deliveryOption, setDeliveryOption] = useState(cart && cart.deliveryOption || null);
  const [GuestFirstName, setWillCallPickupFirstName] = useState(cart && cart.GuestFirstName || '');
  const [GuestLastName, setWillCallPickupLastName] = useState(cart && cart.GuestLastName || '');
  const [selectedDeliveryMethod, setSelectedDeliveryMethod] = useState(
      cart && cart.deliveryMethodUUID || '',
  );

  const handleSubmitAllFormData = () => {
    let payload;
    const errors = {};
    if (deliveryOption === 1) {// will call
      if (!GuestFirstName.trim()) {
        errors.GuestFirstName = 'First Name Required';
      }
      if (!GuestLastName.trim()) {
        errors.GuestLastName = 'Last Name Required';
      }
    }
    if (deliveryOption === 2) {// shipping
      if (!address.First) {
        errors.First = 'First Name Required';
      }
      if (!address.Last) {
        errors.Last = 'Last Name Required';
      }
      if (!address.CountryID) {
        errors.CountryID = 'Country Required';
      }
      if (!address.AddressLineOne) {
        errors.AddressLineOne = 'Street Address Required';
      } else {
        // RLMTS-1586 (check for PO box)
        const regExPattern = new RegExp(
            '^(((p[\\s\\.]?[o\\s][\\.]?)\\s?)|(post\\s?office\\s?))((box|bin|b\\.?)?\\s?(num|number|#)?\\s?\\d+)',
            'igm',
        );

        if (regExPattern.test(address.AddressLineOne)) {
          errors.AddressLineOne =
              'Please enter a valid street name for shipping address';
        }
      }
      if (!address.City) {
        errors.City = 'City Required';
      }

      if (!address.State) {
        errors.State = 'State Required';
      }

      if (!address.Zip) {
        errors.Zip = 'Zip Code Required';
      }

      if (!selectedDeliveryMethod) {
        errors.selectedDeliveryMethod = 'Delivery Method Required';
      }
    }
    setFormErrors(errors);
    if (Object.keys(errors).length) {
      setFormErrors(errors);
      return false;
    } else {
      const outletDeliveryMethodList = outlet && Array.isArray(outlet.OutletDeliveryMethods)
          ? [...outlet.OutletDeliveryMethods]
          : [];
      if (deliveryOption === 1) {// will call
        const deliveryMethod = outletDeliveryMethodList.find(
            (dm) => dm.DeliveryMethodType && dm.DeliveryMethodType.ConstantValue === 'DELIVERY_METHOD_TYPE_WILL_CALL');

        if (deliveryMethod) {
          payload = {
            deliveryMethod,
            shippingAddress: null,
            GuestFirstName: GuestFirstName,
            GuestLastName: GuestLastName,
            deliveryMethodUUID: deliveryMethod && deliveryMethod.OutletDeliveryMethodUUID,
            deliveryOption,
          };
        } else {
          setFormErrors({ DeliveryMethod: 'No will call delivery method found' });
          return false;
        }
      }
      if (deliveryOption === 2) {// shipping
        const deliveryMethod = outletDeliveryMethodList.find(
            (dm) => dm.OutletDeliveryMethodUUID === selectedDeliveryMethod);
        payload = {
          deliveryMethod,
          shippingAddress: address,
          deliveryMethodUUID: deliveryMethod && deliveryMethod.OutletDeliveryMethodUUID,
          deliveryOption,
        };
      }
      if (deliveryOption === 3) {// mobile ticketing
        const deliveryMethod = outletDeliveryMethodList.find(
            (dm) => dm.DeliveryMethodType && dm.DeliveryMethodType.ConstantValue ===
                'DELIVERY_METHOD_TYPE_MOBILE_TICKETING');
        if (deliveryMethod) {
          payload = {
            deliveryMethod,
            shippingAddress: null,
            deliveryMethodUUID: deliveryMethod.OutletDeliveryMethodUUID,
            deliveryOption,
          };
        } else {
          setFormErrors({ DeliveryMethod: 'No mobile ticket delivery method found' });
          return false;
        }
      }
      if (onSubmit && payload) {
        onSubmit(payload);
      }
    }
  };

  const handleSelectAddress = (e) => {
    const AddressID = e.target.value;
    if (!AddressID) {
      setAddress(blankAddress);
    } else {
      if (Array.isArray(userAddressList)) {
        const newAddress = userAddressList.find(
            (a) => Number(a.AddressID) === Number(AddressID),
        );
        setAddress(newAddress);
      } else {
        setAddress(blankAddress);
      }
    }
  };

  const handleAddressInput = (event) => {
    const n = event.target.name;
    const value = event.target.value;
    const newAddress = Object.assign({}, address);
    newAddress[n] = value;
    if (n === 'CountryID') {
      newAddress.State = '';
      setSelectedDeliveryMethod('');
    }
    setAddress(newAddress);
  };

  const countryOptionList = [
    { value: '', label: '-- Select Country (required) --' },
  ];
  getCountryList().forEach((c) =>
      countryOptionList.push({
        value: c.id,
        label: c.name,
      }),
  );
  const countryID = address && address.CountryID ? parseInt(address.CountryID, 10) : 1;

  const statesList = countryID === 2 ? getCanadaProvinces() : getUnitedStates();

  const stateOptionList = [
    { value: '', label: '-- Select State (required) --' },
  ];

  statesList.forEach((c) =>
      stateOptionList.push({
        value: c.abbreviation,
        label: c.name,
      }));

  const userAddressOptions = [{ value: '', label: '-- Use a new address --' }];
  if (Array.isArray(userAddressList)) {
    userAddressList.forEach((x) => {
      const value = x.AddressID;
      const label = `${x.AddressLineOne}, ${x.City} ${x.State}, ${x.Zip}`;
      userAddressOptions.push({ label, value });
    });
  }
  const deliveryMethodOptionList = [{ value: '', label: '-- Shipping Method (required) --' }];
  const shippableDeliveryMethods = getShippableDeliveryMethods(deliveryMethodList);
  let filteredDeliveryMethods = Array.isArray(deliveryMethodList) ? [...deliveryMethodList] : [];
  if (address && Number(address.CountryID) === 1) {
    // United States is CountryID === 1
    filteredDeliveryMethods = shippableDeliveryMethods.filter(
        (x) =>  x.DeliveryMethodTypeID === DELIVERY_METHOD_TYPE_SHIPPABLE_DOMESTIC_ID,
    );
  } else {
    filteredDeliveryMethods = shippableDeliveryMethods.filter(
        (x) =>  x.DeliveryMethodTypeID === DELIVERY_METHOD_TYPE_SHIPPABLE_INTERNATIONAL_ID,
    );
  }
  let deliveryOptions = []; // options are added dynamically based on guest list invitation rules below
  let mobileDeliveryDescription = '';
  if (guestListInvitation) {
    const { OutletDeliveryMethodID } = guestListInvitation;
    const mobileDeliveryMethod = getMobileDeliveryMethod(outlet);
    const mobileDeliveryIsEnabled = isMobileDeliveryEnabledForCheckout({ mobileDeliveryMethod, cart, outletEvents });
    mobileDeliveryDescription = getMobileDeliveryNotes({ cart, outletEvents, mobileDeliveryMethod });

    if (Number(OutletDeliveryMethodID) === GUEST_LIST_INVITATION_SHIPPING_AND_MOBILE_DELIVERY_OPTION_ID) {
      if(shippableDeliveryMethods.length){
        deliveryOptions = [{ id: 2, name: 'Shipping' }];
      }
      if (mobileDeliveryIsEnabled) {
        deliveryOptions.push({ id: 3, name: 'Mobile Delivery' });
      }
      filteredDeliveryMethods = filteredDeliveryMethods.filter(
          (dm) => dm.DeliveryMethodTypeID !== DELIVERY_METHOD_TYPE_WILL_CALL_ID,
      );
    }
    if (Number(OutletDeliveryMethodID) === GUEST_LIST_INVITATION_WILL_CALL_ONLY_DELIVERY_OPTION_ID) {
      deliveryOptions = [{ id: 1, name: 'Will Call' }];
      filteredDeliveryMethods = filteredDeliveryMethods.filter(
          (dm) => dm.DeliveryMethodTypeID === DELIVERY_METHOD_TYPE_WILL_CALL_ID,
      );
    }

    if (Number(OutletDeliveryMethodID) === GUEST_LIST_INVITATION_GUEST_CHOICE_DELIVERY_OPTION_ID) {
      deliveryOptions = [{ id: 1, name: 'Will Call' }];
      if(shippableDeliveryMethods.length){
        deliveryOptions.push({ id: 2, name: 'Shipping' });
      }
      if (mobileDeliveryIsEnabled) {
        deliveryOptions.push({ id: 3, name: 'Mobile Delivery' });
      }
    }
  }

  if (deliveryOption === 2) {
    if (!address || !address.CountryID) {
      deliveryMethodOptionList.push({ value: '-1', label: 'Please select a country above', disabled: true });
    } else {
      filteredDeliveryMethods = filteredDeliveryMethods.filter(
          (dm) => dm.DeliveryMethodTypeID !== DELIVERY_METHOD_TYPE_WILL_CALL_ID,
      );
      filteredDeliveryMethods.forEach((dm) => {
        const value = dm.OutletDeliveryMethodUUID;
        let label = dm.OverrideName + ' - ';
        if (!dm.OverridePrice || Number(dm.OverridePrice) <= 0) {
          label += 'Free';
        } else {
          label += formatCurrency(dm.OverridePrice);
        }
        // const notes = dm.OverrideNotes;
        deliveryMethodOptionList.push({ value, label });
      });
    }
  }

  return (
      <div id="deliverymethod-form">
        {deliveryOptions.map((option, idx) => {
          return (
              <OptionContainerStyled key={idx}>
                <RadioInputStyled
                    type="radio"
                    id={option.id}
                    value={option.id}
                    name={option.name}
                    checked={deliveryOption === option.id}
                    onChange={(e) => {
                      setFormErrors({});
                      setDeliveryOption(Number(e.target.value));
                    }}
                />
                <OptionLabelStyled htmlFor={option.id}>
                  {option.name}
                </OptionLabelStyled>
              </OptionContainerStyled>
          );
        })}

        {deliveryOption === 1 && (
            <WillCallNameInputContainerStyled>
              <TextInput
                  required
                  fullWidth
                  name="GuestFirstName"
                  placeholder="*First Name"
                  value={GuestFirstName}
                  onChange={(e) => setWillCallPickupFirstName(e.target.value)}
                  error={formErrors['GuestFirstName']}
              />
              <TextInput
                  required fullWidth
                  name="GuestLastName"
                  placeholder="*Last Name"
                  value={GuestLastName}
                  onChange={(e) => setWillCallPickupLastName(e.target.value)}
                  error={formErrors['GuestLastName']}
              />
            </WillCallNameInputContainerStyled>
        )}

        {deliveryOption === 2 && (
            <>
              <div style={{ margin: '0 0 2rem 0' }}>
                Please enter a shipping address for this request. This
                information will be used in the approval process and to ship
                tickets if you selected a shipping method.
              </div>
              <div>
                <SelectInput
                    id="select-existing-address-input"
                    name="address"
                    value={address && address.AddressID}
                    onChange={handleSelectAddress}
                    style={{ width: '100%' }}
                    optionList={userAddressOptions}
                />
                <TextInput
                    name="First"
                    label="*First Name"
                    error={formErrors.First}
                    fullWidth
                    required
                    value={address.First}
                    onChange={handleAddressInput}
                />
                <TextInput
                    name="Last"
                    label="*Last Name"
                    error={formErrors.Last}
                    fullWidth
                    value={address.Last}
                    onChange={handleAddressInput}
                />
                <TextInput
                    name="CompanyName"
                    label="Company"
                    error={formErrors.CompanyName}
                    fullWidth
                    value={address.CompanyName}
                    onChange={handleAddressInput}
                />
                <SelectInput
                    name="CountryID"
                    value={address.CountryID}
                    error={formErrors.CountryID}
                    onChange={handleAddressInput}
                    optionList={countryOptionList}
                />
                <TextInput
                    name="AddressLineOne"
                    label="*Street Address"
                    error={formErrors.AddressLineOne}
                    fullWidth
                    value={address.AddressLineOne}
                    onChange={handleAddressInput}
                />
                <TextInput
                    name="AddressLineTwo"
                    label="Apt / Suite / Other"
                    error={formErrors.AddressLineTwo}
                    fullWidth
                    value={address.AddressLineTwo}
                    onChange={handleAddressInput}
                />
                <TextInput
                    name="City"
                    label="*City"
                    error={formErrors.City}
                    fullWidth
                    value={address.City}
                    onChange={handleAddressInput}
                />
                <SelectInput
                    name="State"
                    value={address.State}
                    onChange={handleAddressInput}
                    error={formErrors.State}
                    optionList={stateOptionList}
                />
                <TextInput
                    name="Zip"
                    label="*Zip/Postal Code"
                    error={formErrors.Zip}
                    fullWidth
                    value={address.Zip}
                    onChange={handleAddressInput}
                />
              </div>

              <SelectInput
                  required
                  style={{ width: '100%' }}
                  name="deliveryMethod"
                  value={selectedDeliveryMethod}
                  optionList={deliveryMethodOptionList}
                  error={formErrors.selectedDeliveryMethod}
                  onChange={(e) => setSelectedDeliveryMethod(e.target.value)}
              />
            </>
        )}
        {deliveryOption === 3 && (
            <>
              <div
                  dangerouslySetInnerHTML={{
                    __html: mobileDeliveryDescription,
                  }}
              />
            </>
        )}

        {error && <Alert style={{ margin: '2rem 0' }}>{error}</Alert>}

        {formErrors && Object.keys(formErrors).length ? (
            <Alert style={{ margin: '2rem 0' }}>
              {Object.keys(formErrors).map((key) => {
                return (
                    <div key={key}>{formErrors[key]}</div>
                );
              })}
            </Alert>
        ) : null}

        <div style={{ margin: '3rem 0 ' }}>
          {deliveryOption && (<Button
              fullWidth
              loading={loading}
              id="add-delivery-method-btn"
              onClick={handleSubmitAllFormData}
          >
            Continue
          </Button>)}
        </div>

      </div>
  );
};

GuestListCheckoutDeliveryInfo.defaultProps = {
  cart: null,
  error: null,
  loading: false,
  onSubmit: null,
  defaultAddress: null,
  userAddressList: [],
  deliveryMethodList: [],
  guestListInvitation: null,
};

GuestListCheckoutDeliveryInfo.propTypes = {
  cart: PropTypes.object,
  error: PropTypes.any,
  loading: PropTypes.bool,
  onSubmit: PropTypes.func,
  defaultAddress: PropTypes.any,
  userAddressList: PropTypes.array,
  deliveryMethodList: PropTypes.array,
  guestListInvitation: PropTypes.object,
  defaultDeliveryMethod: PropTypes.object,

};

export default GuestListCheckoutDeliveryInfo;
