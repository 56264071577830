import React from 'react';
import PropTypes from 'prop-types';
import Typography from '../../utils/Typography';
import { htmlHasValidContent } from '../../utils/utilities';

export const FanRequestMobileDeliveryDetails = (props = {}) => {
  const {
    fanRequest,
    mobileDeliveryMethod,
    isShippingHeaderVisible,
  } = props;
  let headerText = 'Mobile Delivery';
  let mobileDeliveryNote = mobileDeliveryMethod?.OverrideNotes;
  if (htmlHasValidContent(fanRequest?.seriesSummary?.OutletEventGroup?.ShippingNote)) {
    mobileDeliveryNote = fanRequest.seriesSummary.OutletEventGroup.ShippingNote;
  }
  return (
      <>
        <Typography tag="h1" style={{ marginBottom: '1rem' }}>
          {headerText}
        </Typography>
        {htmlHasValidContent(mobileDeliveryNote) && (
            <div
                style={{ marginBottom: '1rem' }}
                dangerouslySetInnerHTML={{
                  __html: mobileDeliveryNote,
                }}
            />
        )}
        <Typography style={{ marginBottom: '1rem' }}>Shipping addresses are requested in case it is not
          possible to deliver an item in your order by mobile.</Typography>
        {isShippingHeaderVisible &&
            (<Typography tag="h1" style={{ marginBottom: '1rem' }}>Shipping Address</Typography>)}

      </>
  );
};
FanRequestMobileDeliveryDetails.defaultProps = {
  fanRequest: null,
  mobileDeliveryMethod: null,
  isShippingHeaderVisible: false,
};

FanRequestMobileDeliveryDetails.propTypes = {
  fanRequest: PropTypes.object,
  mobileDeliveryMethod: PropTypes.object,
  isShippingHeaderVisible: PropTypes.bool,
};