import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

import Contain from './Contain';
import Modal from '../../utils/Modal';
import { IconFacebook, IconInstagram, IconTwitter } from '../../utils/Icon';
import { Typography } from '../../utils/Typography';

const Footer = styled.div`
  font-size: 1.4rem;
  margin: ${(props) => (props.margin ? '55px 0 0 0' : '0')};
  padding: 2.2rem 0;
  outline: none;
  user-select: none;
  color: ${(props) => props.theme.FOOTER_TEXT_COLOR};
  background-color: ${(props) => props.theme.FOOTER_BACKGROUND};
  border-top: solid 1rem ${(props) => props.theme.PRIMARY_LINK_COLOR};
  @media (min-width: ${(props) => props.theme.BREAKPOINT_MOBILE_WIDTH}px) {
    font-size: 1.6rem;
    margin: ${(props) => (props.margin ? '75px 0 0 0' : '0')};
    padding: 3.2rem 0;
  }
`;

const LeftColumn = styled.div`
  flex: 1;
`;

const RightColumn = styled.div`
  padding: 0 0 0 15px;
`;

const TitleContainer = styled.div`
  margin: 0 0 1rem 0;
  @media (min-width: ${(props) => props.theme.BREAKPOINT_MOBILE_WIDTH}px) {
    margin: 0 0 1.6rem 0;
  }
`;

const LinkContainer = styled.div`
  margin: 0 0 1.2rem 0;
  @media (min-width: ${(props) => props.theme.BREAKPOINT_MOBILE_WIDTH}px) {
    margin: 0 0 1.6rem 0;
  }
`;

const SocialIcon = styled.div`
  height: 22px;
  display: block;
  margin: 0 0 10px 0;
  svg {
    height: 22px !important;
    width: 22px !important;
    color: ${(props) => props.theme.FOOTER_TEXT_COLOR};
  }
  @media (min-width: ${(props) => props.theme.BREAKPOINT_MOBILE_WIDTH}px) {
    height: 28px;
    display: inline-block;
    margin: 0 0 0 16px;
    svg {
      height: 28px !important;
      width: 28px !important ;
    }
  }
`;

const Copyright = styled.span`
  font-size: 1.2rem;
  @media (min-width: ${(props) => props.theme.BREAKPOINT_MOBILE_WIDTH}px) {
    font-size: 1.4rem;
  }
`;

export class AppFooter extends React.Component {
  state = { showModal: false, modalTitle: '', modalContent: '' };

  showModal = () => {
    this.setState({ showModal: true });
  };

  hideModal = () => {
    this.setState({ showModal: false });
  };
  handlePolicyClick = (policy) => {
    this.setState(
        { modalTitle: policy.Headline, modalContent: policy.PolicyText },
        () => {
          this.showModal();
        },
    );
  };

  render() {
    const { outlet, children } = this.props;
    //const outlet = useSelector((state) => state.outlet);
    //Find outlet policies that are allowed in footer:
    //1. sales type must = 'All'
    //2. policy type must = 'privacy policy' or 'terms & cond' or 'shipping'
    const policies =
        outlet && Array.isArray(outlet.OutletPolicies)
            ? outlet.OutletPolicies.filter(
                (policy) =>
                    policy.SalesTypeID === -1 &&
                    (policy.OutletPolicyTypeID === 1 ||
                        policy.OutletPolicyTypeID === 2 ||
                        policy.OutletPolicyTypeID === 5),
            )
            : [];

    const socialTypeList =
        outlet &&
        outlet.OutletSocialTypes &&
        Array.isArray(outlet.OutletSocialTypes)
            ? outlet.OutletSocialTypes.filter(
                (social) => social.URL && social.SocialTypeID && social.Active,
            )
            : [];

    return (
        <Footer {...this.props}>
          <Contain hasPadding>
            {children ? (
                children
            ) : (
                <div style={{ display: 'flex' }}>
                  <LeftColumn>
                    {outlet.OutletName && (
                        <TitleContainer>
                          <Typography tag="h2">{outlet.OutletName}</Typography>
                        </TitleContainer>
                    )}
                    <LinkContainer>
                      {outlet.ContactUsEmail && (
                          <Typography
                              tag="a"
                              id="contact-us-footer-link"
                              onClick={() =>
                                  (window.location.href =
                                      'mailto:' + outlet.ContactUsEmail)
                              }
                          >
                            Contact Us {policies && policies.length ? '|' : null}{' '}
                          </Typography>
                      )}
                      {policies.map((policy, index) => {
                        return (
                            <Typography
                                tag="a"
                                id={'policy-footer-link-' + policy.OutletPolicyID}
                                className="policy-footer-link"
                                key={policy.OutletPolicyID}
                                onClick={() => this.handlePolicyClick(policy)}
                            >
                              {policy.Headline}
                              {index < policies.length - 1 && ' | '}{' '}
                            </Typography>
                        );
                      })}
                    </LinkContainer>
                    <Typography tag="div">
                      <Copyright>&copy; {new Date().getFullYear()}</Copyright>
                    </Typography>
                  </LeftColumn>
                  <RightColumn>
                    {socialTypeList.map((social) => (
                        <Typography
                            tag="a"
                            href={social.URL}
                            target="_blank"
                            rel="noreferrer"
                            key={social.SocialTypeID}
                            className={'social-footer-link'}
                            id={'social-footer-link-' + social.SocialTypeID}
                        >
                          <SocialIcon>
                            {social.SocialTypeID === 1 && <IconFacebook/>}
                            {social.SocialTypeID === 2 && <IconTwitter/>}
                            {social.SocialTypeID === 3 && <IconInstagram/>}
                          </SocialIcon>
                        </Typography>
                    ))}
                  </RightColumn>
                  <Modal
                      title={this.state.modalTitle}
                      open={this.state.showModal}
                      onClose={this.hideModal}
                  >
                    <div
                        dangerouslySetInnerHTML={{
                          __html: this.state.modalContent,
                        }}
                    />
                  </Modal>
                </div>
            )}
          </Contain>
        </Footer>
    );
  }
}

AppFooter.propTypes = {
  outlet: PropTypes.object,
};

AppFooter.defaultProps = {
  outlet: {},
};

function mapStateToProps(state) {
  const { outlet } = state;
  return {
    outlet,
  };
}

export default connect(mapStateToProps)(AppFooter);
