import React, { createContext, useContext, useState } from 'react';
import { Redirect, Route } from 'react-router-dom';

/********************************************************
 BELOW IS EXAMPLE USING CUSTOM PROVIDER INSTEAD OF REDUX
 CURRENTLY THIS IS NOT BEING USED
 ITS JUST A REFERENCE.... ENJOY
 *********************************************************/
/** For more details on
 * `authContext`, `ProvideAuth`, `useAuth` and `useProvideAuth`
 * refer to: https://usehooks.com/useAuth/
 */

const authContext = createContext();

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export function useAuth() {
  return useContext(authContext);
}

// Provider hook that creates auth object and handles state
export function useProvideAuth() {
  const [user, setUser] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(null);

  const signin = (cb) => {
    return setTimeout(() => {
      setUser('user');
      setIsAuthenticated(true);
      cb && cb();
    }, 1000);
  };

  const signout = (cb) => {
    return setTimeout(() => {
      setUser(null);
      setIsAuthenticated(false);
      cb && cb();
    }, 1000);
  };

  return {
    user,
    signin,
    signout,
    isAuthenticated,
  };
}

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
export function PrivateRoute({ children, authIsRequired = true, ...rest }) {
  //console.log("PrivateRoute authIsRequired", authIsRequired);

  let auth = useAuth();

  let userIsAuthenticated = auth && auth.user;

  return (
      <Route
          {...rest}
          render={({ location }) =>
              userIsAuthenticated || authIsRequired === false ? (
                  children
              ) : (
                  <Redirect
                      to={{
                        pathname: '/login',
                        state: { from: location },
                      }}
                  />
              )
          }
      />
  );
}
