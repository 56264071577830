import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import Attention from '../../utils/Attention';
import Contain from '../app/Contain';
import Modal from '../../utils/Modal';
import Typography from '../../utils/Typography';
import { IconClose, IconExternalLink, IconMaximize, IconMinus, IconPlus } from '../../utils/Icon';

const ContentTypeRoot = styled.div`
  //cursor: pointer;
`;

const IconRoot = styled.div`
padding:0 0 0 10px;
  cursor: pointer;
  svg,
  img {
    &:hover {
      opacity: 0.7;
    }
  },
`;

const ALERT_STATUS = {
  CONTENT_TYPE_ALERT_INFORMATION: 'info',
  CONTENT_TYPE_ALERT_WARNING: 'warning',
  CONTENT_TYPE_ALERT_CRITICAL: 'critical',
};

const BEHAVIORS = {
  CONTENT_BEHAVIOR_ALWAYS_POPUP: 'popup',
  CONTENT_BEHAVIOR_ALWAYS_CLOSEABLE: 'close',
  CONTENT_BEHAVIOR_ALWAYS_COLLAPSIBLE: 'collapse',
};

class ContentTypeAlert extends React.Component {
  state = {
    visible: true,
    collapse: false,
    showModal: false,
  };

  hideAlert = () => {
    this.setState({ visible: false });
  };

  showAlert = () => {
    this.setState({ visible: true });
  };

  toggleCollapse = () => {
    this.setState({ collapse: !this.state.collapse });
  };

  showModal = () => {
    this.setState({ showModal: true });
  };

  hideModal = () => {
    this.setState({ showModal: false });
  };

  render() {
    const {Body, ContentBehavior, ContentType, Title, URL} = this.props;
    const status = ALERT_STATUS[ContentType.ConstantValue];
    const behavior = BEHAVIORS[ContentBehavior.ConstantValue];

    let showBody = true;
    let onClick = null;
    let icon = null;
    let iconWidth = 20;
    let iconHeight = 20;

    if (behavior === 'close') {
      onClick = this.hideAlert;
      icon = <IconClose width={iconWidth} height={iconHeight}/>;
    }
    if (behavior === 'collapse') {
      onClick = this.toggleCollapse;
      icon = <IconMinus width={iconWidth} height={iconHeight}/>;
      if (this.state.collapse) {
        icon = <IconPlus width={iconWidth} height={iconHeight}/>;
      }
    }
    if (behavior === 'popup') {
      showBody = false;
      onClick = this.showModal;
      icon = <IconMaximize/>;
      icon = <IconExternalLink width={iconWidth - 2} height={iconHeight - 2}/>;
    }

    if (!this.state.visible) return null;

    return (
        <React.Fragment>
          <ContentTypeRoot>
            <Attention status={status}>
              <Contain>
                <div style={{display: 'flex', width: '100%' }}>
                  <div style={{flex: 1, textAlign: 'center', width: '100%' }}>
                    <Typography style={{margin: '0 0 1rem 0'}}>
                      <strong>{Title}</strong>
                    </Typography>
                    {showBody && !this.state.collapse && Body && (
                        <Typography>
                          {/* The Body value sent back from API contains HTML markup */}
                          <div style={{ marginBottom: '10px' }} dangerouslySetInnerHTML={{__html: Body}}/>
                        </Typography>
                    )}
                    {URL && <img src={URL} style={{ width: '100%' }}/>}
                  </div>
                  {icon && <IconRoot onClick={onClick}>{icon}</IconRoot>}
                </div>
              </Contain>
            </Attention>
          </ContentTypeRoot>
          <Modal
              title={Title}
              open={this.state.showModal}
              onClose={this.hideModal}
          >
            {URL && <img src={URL} style={{ width: '100%' }}/>}
            <div
                dangerouslySetInnerHTML={{
                  __html: Body,
                }}
            />
          </Modal>
        </React.Fragment>
    );
  }
}

ContentTypeAlert.defaultProps = {
  Body: null,
  Title: null,
  URL: null,
  ContentBehavior: {},
  ContentType: {},
};

ContentTypeAlert.propTypes = {
  Body: PropTypes.string,
  Title: PropTypes.string,
  URL: PropTypes.string,
  ContentBehavior: PropTypes.object,
  ContentType: PropTypes.object,
};

export default ContentTypeAlert;
