import PropTypes from 'prop-types';
import React from 'react';
import Button from './Button';
import Error from './Error';
import { SelectInput, TextAreaInput, TextInput } from './Form';
import { formatPhoneNumber, getCanadaProvinces, getCountryList, getUnitedStates } from './utilities';

// 'blankAddress' keys match what is returned from they 'UserType'=>'AddressType' GraphQL query
const blankAddress = {
  AddressID: null,
  First: '',
  Last: '',
  CompanyName: '',
  AddressLineOne: '',
  AddressLineTwo: '',
  City: '',
  State: '',
  Zip: '',
  CountryID: '',
  //Province: "",
  Phone: '',
  ConnectionToArtist: '',
  Note: '',
};
// Lottery address keys:
// LotteryRequestShippingAddressUUID: String
// createdAt: String
// FirstName: String
// LastName: String
// Company: String
// Address1: String
// Address2: String
// City: String
// State: String
// Country: String
// ZipCode: String
// Phone: String
// Guest List address keys:
// AddressID: null
// AddressLineOne: "5912 Danann Drive"
// AddressLineTwo: ""
// City: "Midlothian"
// CountryID: 1
// First: "Homer"
// Last: "Simpson"
// Phone: "(674) 987-1123"
// State: "VA"
// Zip: "23112"
// ConnectionToArtist: "Smithers"
// Note: "homer likes donuts"

export class AddressForm extends React.Component {
  state = {
    formErrors: {},
    showCreateAddress: this.props.showCreateAddress,
    address: this.props.defaultAddress || blankAddress,
  };

  componentWillMount() {
    let address = { ...blankAddress };
    if (this.props.defaultAddress) {
      address = { ...this.props.defaultAddress };
      if (address.CountryID === null || address.CountryID === undefined) {
        address.CountryID = '';
      }
    }
    this.setState({ address });
  }

  componentDidUpdate(prevProps) {
    if (
        this.props.defaultAddress !== prevProps.defaultAddress &&
        this.props.refreshDefaultAddress
    ) {
      this.setState({
        address: this.props.defaultAddress,
      });
    }
  }

  handleSelectAddress = (e) => {
    const AddressID = e.target.value;
    if (AddressID === 'new') {
      this.setState({
        address: Object.assign(blankAddress, { AddressID: 'new' }),
        showCreateAddress: true,
      });
    } else {
      const address =
          this.props.addresses &&
          this.props.addresses.find(
              (a) => Number(a.AddressID) === Number(AddressID),
          );
      if (address) {
        this.setState(
            {
              address,
            },
            () => {
              this.props.onSelect && this.props.onSelect(address);
            },
        );
      }
    }
  };

  handlePhoneInput = (event) => {
    const address = Object.assign({}, this.state.address);
    const value = event.target.value;
    const formattedInputValue = formatPhoneNumber(value);
    address['Phone'] = formattedInputValue;
    this.setState({ address, dirty: true });
  };

  handleInput = (name) => (event) => {
    const address = Object.assign({}, this.state.address);
    address[name] = event.target.value;

    if (name === 'CountryID') {
      address.State = '';
    }
    this.setState({ address, dirty: true });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { address } = this.state;
    const formErrors = {};
    if (!address.First) {
      formErrors.First = 'First Name Required';
    }
    if (!address.Last) {
      formErrors.Last = 'Last Name Required';
    }
    if (!address.CountryID) {
      formErrors.CountryID = 'Country Required';
    }
    if (!address.AddressLineOne) {
      formErrors.AddressLineOne = 'Street Address Required';
    } else {
      //RLMTS-1586 (check for PO box)
      const regExPattern = new RegExp(
          '^(((p[\\s\\.]?[o\\s][\\.]?)\\s?)|(post\\s?office\\s?))((box|bin|b\\.?)?\\s?(num|number|#)?\\s?\\d+)',
          'igm',
      );
      // Valid Examples
      // 695 CAPON ST NE, PALM BAY, FL, 32905
      // 713 BIGGIN POND RD, MIDLOTIAN, VA, 23114
      // 123 Poor Box Road
      // 123 Harpo Box Street
      // The Postal Road
      // Box Hill
      // 123 Some Street
      // Controller's Office
      // pollo St.
      // 123 box canyon rd
      // 777 Post Oak Blvd
      // PSC 477 Box 396
      // RR 1 Box 1020

      // In-Valid Examples
      // po 123
      // pob 555
      // p.o.b. 555
      // po box 555
      // pobox 555
      // p.o. box 663
      // P.O. Box #123
      // P.O. Box 3456
      // PO Box 1234
      // PO Box Num 1234
      // P O Box 4321
      // Post Office Box 9999

      if (regExPattern.test(address.AddressLineOne)) {
        formErrors.AddressLineOne =
            'Please enter a valid street name for shipping address';
      }
    }
    if (!address.City) {
      formErrors.City = 'City Required';
    }

    if (!address.State) {
      formErrors.State = 'State Required';
    }

    if (!address.Zip) {
      formErrors.Zip = 'Zip Code Required';
    }
    if (!address.Phone) {
      formErrors.Phone = 'Phone Number Required';
    }
    if (this.props.showConnectionToArtist && !address.ConnectionToArtist) {
      formErrors.ConnectionToArtist = 'Connection to Artist Required';
    }
    if (Object.keys(formErrors).length) {
      this.setState({ formErrors });
      return false;
    } else {
      //Because we are using 'reset' and 'new' as values for those options, we had to set them in state to allow
      // the drop down to work properly. But we need to null them out before we submit so the address is recognized
      // as being new
      let addressToSubmit = (this.state.address.AddressID == 'reset' || this.state.address.AddressID == 'new') ?
          Object.assign(this.state.address, { AddressID: null }) : this.state.address;
      this.props.onSubmit && this.props.onSubmit(addressToSubmit);
      this.setState({ addressToSubmit, dirty: false, formErrors: {}, showCreateAddress: false });
    }
  };

  render() {
    const {
      error,
      loading,
      showNote,
      addresses,
      showAddressSelector,
      showConnectionToArtist,
    } = this.props;
    const { address, formErrors, showCreateAddress } = this.state;
    const countryList = getCountryList();
    const countryOptionList = [
      { value: '', label: '-- Select Country (required) --' },
    ];
    countryList.forEach((c) =>
        countryOptionList.push({
          value: c.id,
          label: c.name,
        }),
    );

    const countryID =
        address && address.CountryID ? parseInt(address.CountryID, 10) : 1;

    const statesList = countryID === 2 ? getCanadaProvinces() : getUnitedStates();

    return (
        <div id="address-form">
          {showAddressSelector && (
              <div style={{ marginBottom: 15 }}>
                <SelectInput
                    id="select-existing-address-input"
                    name="address"
                    value={
                      address.AddressID
                          ? address.AddressID
                          : addresses.length
                              ? 'reset'
                              : 'new'
                    }
                    onChange={this.handleSelectAddress}
                    style={{ width: '100%' }}
                    optionList={[
                      {
                        value: 'reset',
                        disabled: !addresses.length,
                        label: '-- Saved Addresses --',
                      },
                    ].concat(
                        addresses.map((x) => ({
                          value: x.AddressID,
                          label: `${x.AddressLineOne}, ${x.City} ${x.State}, ${x.Zip}`,
                        })).concat([{ value: 'new', label: '-- Use a new address --' }]),
                    )}
                ></SelectInput>
              </div>
          )}
          {(showCreateAddress || this.props.showCreateAddress) && (
              <div>
                <TextInput
                    name="First"
                    label="*First Name "
                    error={formErrors.First}
                    fullWidth
                    required
                    value={address.First || ''}
                    onChange={this.handleInput('First')}
                />
                <TextInput
                    name="Last"
                    label="*Last Name"
                    error={formErrors.Last}
                    fullWidth
                    value={address.Last || ''}
                    onChange={this.handleInput('Last')}
                />
                <TextInput
                    name="CompanyName"
                    label="Company"
                    error={formErrors.CompanyName}
                    fullWidth
                    value={address.CompanyName || ''}
                    onChange={this.handleInput('CompanyName')}
                />
                <SelectInput
                    name="CountryID"
                    value={address.CountryID}
                    error={formErrors.CountryID}
                    onChange={this.handleInput('CountryID')}
                    optionList={countryOptionList}
                />
                <TextInput
                    name="AddressLineOne"
                    label="*Street Address"
                    error={formErrors.AddressLineOne}
                    fullWidth
                    value={address.AddressLineOne || ''}
                    onChange={this.handleInput('AddressLineOne')}
                />
                <TextInput
                    name="AddressLineTwo"
                    label="Apt / Suite / Other"
                    error={formErrors.AddressLineTwo}
                    fullWidth
                    value={address.AddressLineTwo || ''}
                    onChange={this.handleInput('AddressLineTwo')}
                />
                <TextInput
                    name="City"
                    label="*City"
                    error={formErrors.City}
                    fullWidth
                    value={address.City || ''}
                    onChange={this.handleInput('City')}
                />
                {countryID === 1 || countryID === 2 ? (
                    <SelectInput
                        name="State"
                        value={address.State}
                        onChange={this.handleInput('State')}
                        error={formErrors.State}
                        optionList={[
                          {
                            value: '',
                            label: '-- Select State (required) --',
                          },
                        ].concat(
                            statesList.map((c) => ({
                              value: c.abbreviation,
                              label: c.name,
                            })),
                        )}
                    />
                ) : (
                    <TextInput
                        name="State"
                        label="*State"
                        error={formErrors.State}
                        fullWidth
                        value={address.State || ''}
                        onChange={this.handleInput('State')}
                    />
                )}
                <TextInput
                    name="Zip"
                    label="*Zip/Postal Code"
                    error={formErrors.Zip}
                    fullWidth
                    value={address.Zip || ''}
                    onChange={this.handleInput('Zip')}
                />
                <TextInput
                    name="Phone"
                    label="*Phone"
                    error={formErrors.Phone}
                    fullWidth
                    value={address.Phone || ''}
                    onChange={this.handlePhoneInput}
                />
                {showConnectionToArtist && (
                    <TextInput
                        name="ConnectionToArtist"
                        label="*Connection To Artist (Name &amp; Email Address)"
                        error={formErrors.ConnectionToArtist}
                        fullWidth
                        value={address.ConnectionToArtist || ''}
                        onChange={this.handleInput('ConnectionToArtist')}
                        autoComplete="none"
                    />
                )}
                {showNote && (
                    <TextAreaInput
                        name="Note"
                        label="Note"
                        error={formErrors.Note}
                        fullWidth
                        value={address.Note || ''}
                        onChange={this.handleInput('Note')}
                    />
                )}
              </div>
          )}
          {error && <Error>error occurred</Error>}

          <Button
              name="btn-save-address"
              id="save-address-btn"
              fullWidth
              onClick={this.handleSubmit}
              loading={loading}
              style={{ marginTop: '1rem' }}
          >
            {this.props.buttonText}
          </Button>
        </div>
    );
  }
}

AddressForm.defaultProps = {
  showCreateAddress: true,
  showAddressSelector: true,
  defaultAddress: blankAddress,
  buttonText: 'Save',
  addresses: [],
  loading: false,
  error: null,
  refreshDefaultAddress: true,
  onCancel: () => false,
  onSubmit: () => false,
  onSelect: () => false,
};

AddressForm.propTypes = {
  showAddressSelector: PropTypes.bool,
  defaultAddress: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  buttonText: PropTypes.string,
  loading: PropTypes.bool,
  error: PropTypes.bool,
  addresses: PropTypes.array,
  refreshDefaultAddress: PropTypes.bool,
  onSubmit: PropTypes.func,
  onSelect: PropTypes.func,
  onCancel: PropTypes.func,
};

export default AddressForm;
