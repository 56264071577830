import store from '../../store';
import { createUUID } from '../../utils/utilities';

export const addToast = (toast) => {
  toast.id = createUUID();
  // title,
  // description,
  // icon,
  // id,
  // type,
  // autoDelete,
  // dismissTime,
  store.dispatch({ type: 'ADD_TOAST', toast });
};

export const deleteToast = (id) => {
  //console.log("deleteToast id = ", id);
  store.dispatch({ type: 'DELETE_TOAST', id });
};
