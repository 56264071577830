import store from '../../store';
import { api } from '../api/actions';

export function captureScheduledPayment(payload) {
  //const state = store.getState();

  const response = api({
    action: 'CAPTURE_SCHEDULED_PAYMENT',
    payload,
  });
  response.then((user) => {
    // store.dispatch({
    //   type: "SET_USER_PROFILE",
    //   user,
    // });
  }).catch((error) => {
  });
  return response;
}

export function updateUserOrderPaymentInfo(payload = {}) {
  const state = store.getState();
  const userOrder = state.userOrder;
  const userOrderList = state.userOrderList;
  const userIsAuthenticated = state.userIsAuthenticated;
  const { OrderID } = payload;

  const response = api({
    action: 'UPDATE_USER_ORDER_PAYMENT',
    payload,
    showToast: true,
  });
  response.then((CustomerOrderScheduledPayments) => {
    if (userOrder && userOrder.CustomerOrderID) {
      const updatedOrder = { ...userOrder, CustomerOrderScheduledPayments };
      store.dispatch({
        type: 'SET_USER_ORDER',
        data: updatedOrder,
      });
      if (Array.isArray(userOrderList)) {
        const index = userOrderList.findIndex(
            (x) =>
                Number(x.CustomerOrderID) === Number(userOrder.CustomerOrderID),
        );
        const updatedOrderList = [...userOrderList];
        if (index > -1) {
          updatedOrderList[index] = {
            ...updatedOrderList[index],
            ...updatedOrder,
          };
        }

        store.dispatch({
          type: 'SET_USER_ORDER_LIST',
          data: updatedOrderList,
        });
      }
    }
  }).catch((error) => {
  });
  return response;
}

export function updateUserOrderAddress(payload = {}) {
  const state = store.getState();
  const userOrder = state.userOrder;
  const userOrderList = state.userOrderList;
  const userIsAuthenticated = state.userIsAuthenticated;
  const { OrderID } = payload;

  const response = api({
    action: 'UPDATE_USER_ORDER_ADDRESS',
    payload,
    showToast: true,
  });
  response.then((CustomerOrderAddress) => {
    if (userOrder && userOrder.CustomerOrderID) {
      const updatedOrder = { ...userOrder, CustomerOrderAddress };
      //console.log("updateUserOrderAddress updatedOrder ", updatedOrder);
      store.dispatch({
        type: 'SET_USER_ORDER',
        data: updatedOrder,
      });
      if (Array.isArray(userOrderList)) {
        const index = userOrderList.findIndex(
            (x) => x.CustomerOrderID == userOrder.CustomerOrderID,
        );
        const updatedOrderList = [...userOrderList];
        if (index > -1) {
          updatedOrderList[index] = {
            ...updatedOrderList[index],
            ...updatedOrder,
          };
        }

        store.dispatch({
          type: 'SET_USER_ORDER_LIST',
          data: updatedOrderList,
        });
      }
    }
  }).catch((error) => {
  });
  return response;
}

export function getUserMobileTicketInfo(params) {
  const { CustomerOrderID } = params;
  const response = api({
    endpoint: 'mobiletickets/getmobiletickets?CustomerOrderID=' + CustomerOrderID,
  });
  response.then((res) => {
    store.dispatch({
      type: 'SET_USER_MOBILE_TICKET_LIST',
      data: Array.isArray(res) ? res : [],
    });
  });
  return response;
}

export function getUserOrder(payload = {}) {
  console.log('getUserOrder payload', payload);
  const state = store.getState();
  let userOrder;
  const userOrderList = state.userOrderList;
  const userOrderSummaryList = state.userOrderSummaryList;
  const userIsAuthenticated = state.userIsAuthenticated;
  const { OrderID } = payload;
  console.log('getUserOrder userOrderList', userOrderList);
  if (state.userOrder && state.userOrder.OrderID === OrderID) {
    userOrder = state.userOrder;
  }

  if (!userOrder && Array.isArray(userOrderList)) {
    userOrder = userOrderList.find((order) => order.OrderID === OrderID);
  }
  if (payload && payload.forceRefresh === true) {
    userOrder = null;
  }
  console.log('getUserOrder userOrder', userOrder);
  if (userOrder) {
    store.dispatch({
      type: 'SET_USER_ORDER',
      data: userOrder,
    });
    return new Promise((resolve, reject) => resolve(userOrder));
  } else {
    const response = api({
      action: 'GET_USER_ORDER',
      payload,
    });
    response.then((res) => {
      store.dispatch({
        type: 'SET_USER_ORDER',
        data: res,
      });
      let updatedUserOrderList = Array.isArray(userOrderList)
          ? [...userOrderList]
          : [];

      const orderIndex = updatedUserOrderList.findIndex(
          (order) =>
              order.OrderID === OrderID || order.CustomerOrderID === OrderID,
      );
      //RLMTS-2899 bug with status key being an object on the "get order" call but its a string on the "get orders array" call
      let Status = res && res.Status && res.Status.CustomerStatus;
      console.log('getUserOrder orderIndex', orderIndex);
      if (orderIndex > -1) {
        updatedUserOrderList[orderIndex] = { ...res, Status };
      } else {
        updatedUserOrderList.push({ ...res, Status });
      }

      let updateUserOrderSummaryList = Array.isArray(userOrderSummaryList)
          ? [...userOrderSummaryList]
          : [];

      const orderSummaryIndex = updateUserOrderSummaryList.findIndex(
          (order) =>
              order.OrderID === OrderID || order.CustomerOrderID === OrderID,
      );
      console.log('getUserOrder orderSummaryIndex', orderSummaryIndex);
      if (orderSummaryIndex > -1) {
        updateUserOrderSummaryList[orderSummaryIndex] = {
          ...updateUserOrderSummaryList[orderSummaryIndex],
          ...res,
          Status,
        };
      } else {
        updateUserOrderSummaryList.push({ ...res, Status });
      }

      store.dispatch({
        type: 'SET_USER_ORDER_SUMMARY_LIST',
        data: updateUserOrderSummaryList,
      });
      store.dispatch({
        type: 'SET_USER_ORDER_LIST',
        data: updatedUserOrderList,
      });
    }).catch((error) => {
    });
    return response;
  }
}

export function getUserOrderSummaryList(payload = {}) {
  const response = api({
    action: 'GET_USER_ORDER_SUMMARY_LIST',
    payload,
  });
  response.then((res) => {
    const data =
        res && res.orders && Array.isArray(res.orders) ? res.orders : [];
    store.dispatch({
      type: 'SET_USER_ORDER_SUMMARY_LIST',
      data,
    });
  }).catch((error) => {
  });
  return response;
}

export function getUserPaymentMethodList(payload = {}) {
  const state = store.getState();
  const UserID =
      (payload && payload.UserID) || (state.session && state.session.UserID);

  if (!UserID) {
    return new Promise((resolve, reject) => reject('No User ID found'));
  } else {
    const response = api({
      action: 'GET_USER_PAYMENT_METHOD_LIST',
      payload: { UserID: Number(UserID), remote: true },
    });
    response.then((res) => {
      store.dispatch({
        type: 'SET_USER_PAYMENT_METHOD_LIST',
        data: res,
      });
    }).catch((error) => {
    });
    return response;
  }
}

export function getUserAddressList(payload = {}) {
  const state = store.getState();
  const UserID =
      (payload && payload.UserID) || (state.session && state.session.UserID);

  if (!UserID) {
    return new Promise((resolve, reject) => reject('No User ID found'));
  } else {
    const response = api({
      action: 'GET_USER_ADDRESS_LIST',
      payload: { UserID: Number(UserID) },
    });
    response.then((res) => {
      store.dispatch({
        type: 'SET_USER_ADDRESS_LIST',
        data: res,
      });
    }).catch((error) => {
    });
    return response;
  }
}

export function createUser(payload) {
  const response = api({
    action: 'CREATE_USER',
    payload,
  });
  response.then((res) => {
    store.dispatch({
      type: 'CREATE_USER',
      error: null,
    });
  }).catch((error) => {
  });
  return response;
}

export function deleteUserAddress(payload) {
  const response = api({
    action: 'DELETE_USER_ADDRESS',
    payload,
  });
  response.then((address) => {
    const userAddressList = store.getState().userAddressList;

    if (
        payload &&
        payload.AddressUUID &&
        userAddressList &&
        Array.isArray(userAddressList)
    ) {
      const updatedList = userAddressList.filter(
          (a) => a.AddressUUID !== payload.AddressUUID,
      );
      store.dispatch({
        type: 'SET_USER_ADDRESS_LIST',
        data: updatedList,
      });
    }
  }).catch((error) => {
  });
  return response;
}

export function createUserAddress(payload) {
  const response = api({
    action: 'CREATE_USER_ADDRESS',
    payload,
  });
  response.then((address) => {
    const userAddressList = store.getState().userAddressList;

    if (
        address &&
        address.AddressUUID &&
        userAddressList &&
        Array.isArray(userAddressList)
    ) {
      const updatedList = [...userAddressList].concat(address);
      store.dispatch({
        type: 'SET_USER_ADDRESS_LIST',
        data: updatedList,
      });
    }
  }).catch((error) => {
  });
  return response;
}

export function editUserAddress(payload) {
  const response = api({
    action: 'UPDATE_USER_ADDRESS',
    payload,
  });
  response.then((address) => {
    const userAddressList = store.getState().userAddressList;

    const updatedList = [];
    if (
        address &&
        address.AddressUUID &&
        userAddressList &&
        Array.isArray(userAddressList)
    ) {
      userAddressList.map((a) => {
        if (a.AddressUUID === address.AddressUUID) {
          updatedList.push(address);
        } else {
          updatedList.push(a);
        }
        return null;
      });
      store.dispatch({
        type: 'SET_USER_ADDRESS_LIST',
        data: updatedList,
      });
    }
  }).catch((error) => {
  });
  return response;
}

export function editUserProfile(payload) {
  const response = api({
    action: 'UPDATE_USER_PROFILE',
    payload,
  });
  response.then((user) => {
    // store.dispatch({
    //   type: "SET_USER_PROFILE",
    //   user,
    // });
  }).catch((error) => {
  });
  return response;
}

export function editPassword(payload) {
  const response = api({
    action: 'UPDATE_USER_PASSWORD',
    payload,
  });
  response.then((user) => {
    // store.dispatch({
    //   type: "SET_USER_PROFILE",
    //   user,
    // });
  }).catch((error) => {
  });
  return response;
}

export function getUserFanRequests(UserID) {
  const state = store.getState();
  const session = state.session;
  const userIsAuthenticated = state.userIsAuthenticated;
  //console.log("getUserFanRequests ", session, userIsAuthenticated);
  if (
      session &&
      session.UserID &&
      Number.isInteger(session.UserID)
      //&& userIsAuthenticated
  ) {
    const action = 'GET_USER_FAN_REQUESTS';
    const payload = { UserID: UserID || session.UserID, complete: true };

    const response = api({
      action,
      payload,
    });

    response.then((data) => {
      //console.log("user fan requests = ", JSON.stringify(data));
      store.dispatch({
        type: 'SET_USER_FAN_REQUESTS',
        data,
      });
    });
    return response;
  }

  return new Promise((reject) => reject('Invalid Session'));
}

export function getUserFanRequestSeriesSummary(params = {}) {
  console.log('getUserFanRequestSeriesSummary params', params);
  const {
    Year,
    UserID,
    OutletEventGroupID,
    updateStore = true,
    LotteryOEGRequestStatus,
  } = params;
  const state = store.getState();
  const session = state.session;
  //const filterByYear = Year ? Number(Year) : new Date().getFullYear();

  const filterByStatus = LotteryOEGRequestStatus || [
    'STATUS_REQUEST_PENDING',
    'STATUS_REQUEST_PROCESSING',
  ];
  if (session && session.UserID && Number.isInteger(session.UserID)) {
    const action = 'GET_USER_FAN_REQUEST_SERIES_SUMMARY';

    const payload = {
      Year,
      OutletEventGroupID,
      //LotteryOEGRequestUUID: oegId,
      completeOnly: true, //query will pull records from the save queue and database //save queue are records waiting to go to database.
      UserID: UserID || session.UserID,
      LotteryOEGRequestStatus: filterByStatus,
    };

    const response = api({
      action,
      payload,
    });

    response.then((data) => {
      //console.log("user fan requests = ", JSON.stringify(data));
      if (updateStore) {
        store.dispatch({
          type: 'SET_USER_FAN_REQUEST_SERIES_SUMMARY',
          data,
        });
      }
    });
    return response;
  }

  return new Promise((reject) => reject('Invalid Session'));
}

export function deleteUserPaymentMethod(params = {}) {
  const response = api({
    action: 'DELETE_USER_PAYMENT_METHOD',
    payload: params,
    showToast: false,
  });
  response.then((res) => {
    getUserPaymentMethodList();
    store.dispatch({
      type: 'SET_USER_DELETE_PAYMENT_METHOD_REJECT_DETAILS',
      data: null,
    });
  }).catch((res) => {
    // let data = null;
    // if (res && res.response) {
    //   data = res.response;
    // }

    store.dispatch({
      type: 'SET_USER_DELETE_PAYMENT_METHOD_REJECT_DETAILS',
      data: res,
    });
  });
  return response;
}
