import styled from 'styled-components';
import React, { useState } from 'react';

import DisplayError from '../../utils/Error';

const RootStyled = styled.div`
  max-width: 300px;
  padding: 4rem 2rem;
  margin: 0 auto;
`;
const InputStyled = styled.input`
  font-size: 1.6rem;
  border: 1px solid #cccccc;
  padding: 1rem 1.5rem;
  display: block;
  width: 100%;
  border-radius: 5px;
  margin: 0 0 1.5rem 0;
`;

export const OutletPrivateLogin = ({ onSubmit, error, ...props }) => {
  const [password, setPassword] = useState('');
  const handleSubmit = (evt) => {
    evt.preventDefault();
    if (password) {
      onSubmit && onSubmit({ password });
    } else {
      alert(`Password required`);
    }
  };
  return (
      <RootStyled>
        <form onSubmit={handleSubmit}>
          <label>Outlet Password:</label>
          <InputStyled
              type="password"
              password="outletPassword"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
          />
          {error && <DisplayError>{error}</DisplayError>}
          <InputStyled
              type="submit"
              value="Submit"
              style={{ cursor: 'pointer' }}
          />
        </form>
      </RootStyled>
  );
};
export default OutletPrivateLogin;
