import React from 'react';
import styled from 'styled-components';

import Typography from './Typography';
import { IconMinus, IconPlus } from './Icon';

const RootStyled = styled.div``;

const HeadRoot = styled.div`
  padding: 1rem 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  color: ${(props) => props.theme.PRIMARY_LINK_COLOR};
  outline: none;
  user-select: none;
  cursor: ${(props) =>
    props.disabled
        ? 'inherit'
        : props.collapsible || props.linkTo
            ? 'pointer'
            : 'inherit'};

  cursor: ${(props) => (props.disabled ? 'inherit' : 'pointer')};

  @media (min-width: ${(props) => props.theme.BREAKPOINT_MOBILE_WIDTH}px) {
    padding: 1rem 0;
  }
`;
const IconContainer = styled.div`
  color: ${(props) => props.theme.PRIMARY_LINK_COLOR}!important;
  margin: 0 0 0 2rem;
  cursor: pointer;
`;

const HeadingContainer = styled.div`
  flex: 1;
`;

const Heading = styled.div`
  padding: 0 0 0 0;
`;

const SubHeading = styled.div`
  //opacity: 0.8;
  color: ${(props) => props.theme.PRIMARY_TEXT_COLOR};
`;
const BodyRoot = styled.div``;
const Collapse = (props) => {
  const {
    collapsed = true,
    onClickHeader,
    heading,
    subHeading,
    children,
    disabled = false,
    ...remainingProps
  } = props;
  const [isCollapsed, setIsCollapsed] = React.useState(collapsed);

  return (
      <RootStyled {...remainingProps}>
        <HeadRoot
            name={'expand-' + heading}
            disabled={disabled}
            onClick={(e) =>
                disabled
                    ? () => false
                    : onClickHeader
                        ? onClickHeader()
                        : setIsCollapsed(!isCollapsed)
            }
        >
          <HeadingContainer>
            {heading && (
                <Heading>
                  <Typography
                      tag="h2"
                      dangerouslySetInnerHTML={{
                        __html: heading,
                      }}
                  />
                </Heading>
            )}
            {subHeading && (
                <SubHeading
                    dangerouslySetInnerHTML={{
                      __html: subHeading,
                    }}
                />
            )}
          </HeadingContainer>
          <IconContainer>
            {disabled ? null : isCollapsed ? <IconPlus/> : <IconMinus/>}
          </IconContainer>
        </HeadRoot>
        {disabled ? null : isCollapsed ? null : (
            <BodyRoot aria-expanded={isCollapsed}>{children}</BodyRoot>
        )}
      </RootStyled>
  );
};

export default Collapse;
