export const REAL_TIME_OUTLET_EVENT_SALES_TYPE_ID = 1;
export const LOTTERY_OUTLET_EVENT_SALES_TYPE_ID = 2;
export const EXTERNAL_OUTLET_EVENT_SALES_TYPE_ID = 3;


export const DELIVERY_METHOD_TYPE_WILL_CALL_ID = 1;
export const DELIVERY_METHOD_TYPE_SHIPPABLE_DOMESTIC_ID = 2;
export const DELIVERY_METHOD_TYPE_SHIPPABLE_INTERNATIONAL_ID = 3;
export const DELIVERY_METHOD_TYPE_MOBILE_TICKETING_ID = 4;


export const GUEST_LIST_INVITATION_GUEST_CHOICE_DELIVERY_OPTION_ID = -1;
export const GUEST_LIST_INVITATION_SHIPPING_AND_MOBILE_DELIVERY_OPTION_ID = -2;
export const GUEST_LIST_INVITATION_WILL_CALL_ONLY_DELIVERY_OPTION_ID = -3;

export const OUTLET_TYPE_BAND_ID = 1;
export const OUTLET_TYPE_VENUE_ID = 2;
export const OUTLET_TYPE_GUEST_LIST_ID = 3;
export const OUTLET_TYPE_POINT_OF_SALE_ID = 4;
