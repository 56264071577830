import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const AppContainerStyled = styled.div`
  margin: 0 auto;
  height: ${(props) => (props.fillHeight ? '100%' : 'initial')};
  max-width: ${(props) =>
    props.maxWidthMobile
        ? props.maxWidthMobile
        : props.theme.MAXIMUM_CONTAINER_WIDTH}px;
  padding: ${(props) => (props.hasPaddingMobile ? '0 2rem' : '0')};
  //border: solid 1px pink;
  @media (min-width: ${(props) => props.theme.BREAKPOINT_MOBILE_WIDTH}px) {
    padding: ${(props) => (props.hasPadding ? '0 2rem' : '0')};
    max-width: ${(props) =>
    props.maxWidth ? props.maxWidth : props.theme.MAXIMUM_CONTAINER_WIDTH}px;
  }
`;
export const Contain = (props) => {
  return <AppContainerStyled {...props}>{props.children}</AppContainerStyled>;
};

Contain.propTypes = {
  maxWidth: PropTypes.number,
  maxWidthMobile: PropTypes.number,
  hasPadding: PropTypes.bool,
  hasPaddingMobile: PropTypes.bool,
  fillHeight: PropTypes.bool,
};
Contain.defaultProps = {
  maxWidth: null,
  maxWidthMobile: null,
  hasPadding: true,
  hasPaddingMobile: true,
  fillHeight: false,
};

export default Contain;
