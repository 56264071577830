import store from '../../store';
import { api } from '../api/actions';

export const getOutlet = (payload) => {
  const action = 'GET_OUTLET';
  const defaultPayload = {
    InfoTypes: [
      // "Statuses",
      // "SalesTypes",
      // "PaymentTypes",
      'DeliveryMethods',
      //"DistributionMethods",
      'Accounts',
      'OutletEventGroups',
      'OutletSocialTypes',
      'OutletPolicies',
      'OutletLoginType',
      'OutletTheme',
      'OutletContents',
    ],
    //OutletID: 207,
  };

  const response = api({
    action,
    payload: payload || defaultPayload,
  });

  response.then((data) => {
    if (data && data.Outlet) {
      store.dispatch({
        type: 'OUTLET',
        data: data.Outlet,
      });
    }
  });
  return response;
};

export const getOutletProtocols = (payload = {}) => {
  return new Promise((resolve, reject) =>
      resolve({ autheticationRequired: false }),
  );

  // const action = "GET_OUTLET_PROTOCOLS";
  // const response = api({
  //   action,
  //   payload,
  // });
  // response.then((data) => {
  //   if (data && data.Outlet) {
  //     store.dispatch({
  //       type: "OUTLET_PROTOCOLS",
  //       data: data.Outlet,
  //     });
  //   }
  // });
  // return response;
};

export const loginPrivateOutlet = (payload = {}) => {
  //return new Promise((resolve, reject) => resolve("success"));
  const action = 'LOGIN_PRIVATE_OUTLET';
  const response = api({
    action,
    payload,
  });
  response.then((data) => {
  });
  return response;
};
