import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import Typography from './Typography';

const ContainerStyled = styled.div`
  padding: ${(props) => props.padding}px;
`;
const MessageStyled = styled(Typography)`
  text-align: ${(props) => props.align || 'left'};
  padding: 1rem 0;
  font-size: 1.4rem;
  opacity: 0.8;
`;

const SpinnerStlyed = styled.div`
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;

  border: ${(props) => props.width / 10}px solid
    ${(props) => props.theme.PRIMARY_LINK_COLOR || '#333'};
  border-top: 4px solid white;
  border-radius: 50%;
  margin: 0 ${(props) => (props.align ? 'auto' : 'left')};

  -webkit-transition-property: -webkit-transform;
  -webkit-transition-duration: 1.2s;
  -webkit-animation-name: rotate;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;

  -moz-transition-property: -moz-transform;
  -moz-animation-name: rotate;
  -moz-animation-duration: 1.2s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;

  transition-property: transform;
  animation-name: rotate;
  animation-duration: 1.2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  @-webkit-keyframes rotate {
    from {
      -webkit-transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
    }
  }

  @-moz-keyframes rotate {
    from {
      -moz-transform: rotate(0deg);
    }
    to {
      -moz-transform: rotate(360deg);
    }
  }

  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

class Loading extends React.Component {
  render() {
    const { message, width, height, align, padding, ...props } = this.props;
    return (
        <ContainerStyled padding={padding}>
          <SpinnerStlyed align={align} width={width} height={height}/>

          {message && (
              <MessageStyled {...props} align={align}>
                {message}{' '}
              </MessageStyled>
          )}
        </ContainerStyled>
    );
  }
}

Loading.defaultProps = {
  width: 40,
  height: 40,
  padding: 20,
  align: 'left',
  message: 'Loading...',
};

Loading.propTypes = {
  align: PropTypes.string,
  message: PropTypes.string,
};
export default Loading;
