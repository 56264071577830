import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Button from '../../utils/Button';
import OutletEventSummary from '../outletEvent/OutletEventSummary';
import { getEventDataFromCustomerOrder, getTotalQtyByOutletEventItem } from './orderUtils';

const DeliveryDescriptionStyled = styled.div`
  padding: 1rem;
  margin: 0 0 20px 0;
  border: ${(props) => 'solid 1px ' + props.theme.PRIMARY_GRAY};
`;

const OutletEventItemStyled = styled.div`
  padding: 1rem;
  color: #fff;
  font-weight: ${(props) => (props['bold'] ? '700' : '400')};
  background-color: ${(props) => props.theme.PRIMARY_LINK_COLOR};
`;

const DeliveryActionContainerStyled = styled.div`
  padding: 1rem;
  font-weight: ${(props) => (props['bold'] ? '700' : '400')};
  background-color: ${(props) =>
          props.noBackground ? 'none' : props.theme.PRIMARY_GRAY}80;
`;

const deliveryDescriptionText = `Please check back at a later date if your mobile tickets are not yet available. 
  When your mobile tickets are available, you may claim your tickets by clicking the button below and going to your account. 
  If you do not have an account, you will be prompted to create one. 
  If you do not see your mobile tickets within one week before the event please contact customer service.`;

function ManageTickets(props = {}) {

  const {
    order,
    loading,
    OutletEventID,
    userMobileTicketList,
  } = props;

  const { eventData, outletEventItems } = getEventDataFromCustomerOrder({ order, OutletEventID });

  /**
   * Render a clickable button if ticket url exists, otherwise display default message.
   *
   * @param {Object} mobileTicket object returned from api endpoint - 'mobiletickets/getmobiletickets'.
   * @param {string} noLinkMessage custom message to display if no ticket url is available.
   * @return {string|Element} no ticket message or clickable html button element.
   */
  const renderMobileTicketLink = ({ mobileTicket, noLinkMessage }) => {
    const defaultNoLinkMessage = 'Not Yet Available';
    if (mobileTicket) {
      const { redemptionUrl } = mobileTicket;
      if (redemptionUrl) {
        return <Button size="small" onClick={() => window.open(redemptionUrl, '_blank')}>View Tickets</Button>;
      }
    }
    return noLinkMessage || defaultNoLinkMessage;
  };

  return (
      <div>
        {deliveryDescriptionText && (<DeliveryDescriptionStyled>{deliveryDescriptionText}</DeliveryDescriptionStyled>)}
        <OutletEventSummary outletEvent={eventData}/>
        {Array.isArray(outletEventItems) ? outletEventItems.map((OutletEventItem) => {
          const { OutletEventItemID, OutletEventItemName, Name, EventItemID } = OutletEventItem;
          const mobileTicket = Array.isArray(userMobileTicketList) ? userMobileTicketList.find(
              ticket => ticket['eventItemID'] === EventItemID) : null;
          const outletEventItemTotalQty = getTotalQtyByOutletEventItem({ OutletEventItem });

          return (
              <div key={OutletEventItemID}>
                <OutletEventItemStyled bold>
                  {OutletEventItemName || Name} x{outletEventItemTotalQty}
                </OutletEventItemStyled>
                <DeliveryActionContainerStyled>
                  {loading ? '...loading mobile tickets' : renderMobileTicketLink({ mobileTicket })}
                </DeliveryActionContainerStyled>
              </div>
          );
        }) : null}
      </div>
  );
}

ManageTickets.defaultProps = {
  loading: false,
  order: null,
  OutletEventID: null,
  userMobileTicketList: [],
};

ManageTickets.propTypes = {
  loading: PropTypes.bool,
  order: PropTypes.object,
  OutletEventID: PropTypes.number,
  userMobileTicketList: PropTypes.array,
};
export default ManageTickets;
