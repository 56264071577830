import OutletEventSummary from '../outletEvent/OutletEventSummary';
import LineItemSummary, { LineItemRow } from '../cart/LineItemSummary';
import Typography from '../../utils/Typography';
import React from 'react';
import { DELIVERY_METHOD_TYPE_MOBILE_TICKETING_ID } from '../../utils/constants';

const GuestListRequestItemsDetails = (params) => {
  const {
    priceLevels,
    expandDetails,
    deliveryMethod,
    guestListInvitation = {},
    guestListOutletEvents,
    guestListRequestGrandTotal,
  } = params;

  const groupItemsBySeries = (items) => {
    // RLMTS-3976 - group the requested outlet events by 'series' (and show the shipping method / price for each)
    const seriesList = [];
    if (Array.isArray(items)) {
      items.forEach((outletEvent) => {
        const OutletEventGroupID = outletEvent?.OutletEventGroup?.OutletEventGroupID;
        if (OutletEventGroupID) {
          const index = seriesList.findIndex((oeg) => oeg.OutletEventGroupID === OutletEventGroupID);
          if (index > -1) {
            seriesList[index]['outletEventList'].push(outletEvent);
          } else {
            seriesList.push({
              ...outletEvent.OutletEventGroup,
              outletEventList: [outletEvent],
            });
          }
        }
      });
    }
    return seriesList;
  };
  let shippingTotal = 0;
  let shippingLabel = 'Shipping';
  let shippingDescription;
  let shippingIsMobileDelivery = false;
  if (deliveryMethod) {
    const { OverridePrice, OverrideName, OverrideNotes, DeliveryMethodTypeID } = deliveryMethod;
    shippingTotal = OverridePrice;
    shippingDescription = OverrideNotes;
    if (OverrideName) {
      shippingLabel += ` -  ${OverrideName}`;
    }
    shippingIsMobileDelivery = DeliveryMethodTypeID === DELIVERY_METHOD_TYPE_MOBILE_TICKETING_ID;
  }
  const itemListGroupedBySeries = groupItemsBySeries(guestListOutletEvents);
  return (
      <>
        {itemListGroupedBySeries.map((series, seriesIndex) => {
          const { ShippingNote } = series;
          const isLastSeries = seriesIndex + 1 === itemListGroupedBySeries.length;
          if (shippingIsMobileDelivery && ShippingNote) {
            shippingDescription = ShippingNote;
          }
          return (
              <>
                {series.outletEventList.map((oe, oeIndex) => {
                  const isLastEvent =
                      oeIndex + 1 === series.outletEventList.length;
                  return (
                      <div key={oeIndex}>
                        <div style={{
                          margin: expandDetails ? '0' : '0 0 1rem 0',
                        }}
                        >
                          <OutletEventSummary outletEvent={oe}/>
                        </div>
                        {expandDetails && (
                            <div style={{ margin: '0 0 3rem 0' }}>
                              <LineItemSummary
                                  outletEvent={oe}
                                  shippingTotalIsVisible={false}
                                  grandTotalIsVisible={false}
                                  cart={{ lineItems: priceLevels }}
                              />

                              {isLastEvent && (
                                  <div>
                                    <LineItemRow
                                        bold
                                        total={shippingTotal}
                                        label={shippingLabel}
                                        labelDescription={<div
                                            dangerouslySetInnerHTML={{
                                              __html: shippingDescription,
                                            }}
                                        />}
                                        hasBottomerBorder
                                        hasLeftBorder
                                        hasRightBorder
                                    />
                                    {isLastSeries && (<LineItemRow
                                        bold
                                        label="Grand Total"
                                        total={guestListRequestGrandTotal}
                                        hasBottomerBorder
                                        hasLeftBorder
                                        hasRightBorder
                                    />)}
                                  </div>
                              )}
                            </div>
                        )}
                      </div>
                  );
                })}

              </>
          );
        })}

        {expandDetails &&
            guestListInvitation &&
            Boolean(guestListInvitation.CollectPayment) === true && (
                <Typography style={{ padding: '0 .5rem' }}>
                  This is the total maximum value of your request and does
                  not reflect the total amount you will be charged
                </Typography>
            )}
      </>
  );
};

export default GuestListRequestItemsDetails;
