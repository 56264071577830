import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import Typography from '../../utils/Typography';

const NewsItemContainer = styled.div`
  margin: 0 0 3rem 0;
  padding: 0 0 3rem 0;
  border-bottom: solid 1px #eee;
  display: flex;
`;
const NewsContent = styled.div`
  flex: 1;
`;
const ImgContainer = styled.div`
  width: 200px;
  margin: 0 2rem 0 0;
  display: none;
  @media (min-width: ${(props) => props.theme.BREAKPOINT_MOBILE_WIDTH}px) {
    display: block;
    img {
      width: 100%;
    }
  }
`;

export const ContentTypeNews = ({ Body, DatetimeStart, Title, URL }) => {
  return (
      <NewsItemContainer>
        {URL && (
            <ImgContainer>
              <img src={URL} alt={Title ? Title : 'New image'}/>
            </ImgContainer>
        )}
        <NewsContent>
          <Typography>{DatetimeStart}</Typography>
          <Typography tag="h4" style={{ marginBottom: '2rem' }}>
            {Title}
          </Typography>
          <Typography dangerouslySetInnerHTML={{ __html: Body }}/>
          {/* <Typography tag="a"> Read More</Typography> */}
        </NewsContent>
      </NewsItemContainer>
  );
};

ContentTypeNews.defaultProps = {
  Body: null,
  Title: null,
  URL: null,
  ContentBehavior: {},
  ContentType: {},
};

ContentTypeNews.propTypes = {
  Body: PropTypes.string,
  Title: PropTypes.string,
  URL: PropTypes.string,
  ContentBehavior: PropTypes.object,
  ContentType: PropTypes.object,
};

export default ContentTypeNews;
