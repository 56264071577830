import React from 'react';
import PropTypes from 'prop-types';
import Typography from '../../utils/Typography';

export const FanRequestCheckoutHeader = (props = {}) => {
  const {
    stage,
    stageList,
    isMobileDelivery,
  } = props;
  const stageDetails = stageList?.find((x) => x.stage === stage);
  let headerText = stageDetails?.header;
  if (isMobileDelivery && stage !== 'addons') { //See RLMTS-4006 for details
    headerText = 'Shipping Details';
  }
  if (headerText) {
    return (
        <Typography tag="h1" style={{ marginBottom: '2rem' }}>
          {headerText}
        </Typography>
    );
  }
  return null;
};
FanRequestCheckoutHeader.defaultProps = {
  stage: '',
  stageList: [],
  isMobileDelivery: false,
};

FanRequestCheckoutHeader.propTypes = {
  stage: PropTypes.string,
  stageList: PropTypes.array,
  isMobileDelivery: PropTypes.bool,
};