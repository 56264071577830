/*
* Returns the api URL using window object
*/
const getApiUrl = () => {
  // reminder: the colon (:) is included in window.location.protocol
  const PROTOCOL = window.location.protocol ?? 'https:';
  const HOSTNAME = window.location.hostname;
  const API_PREFIX = process.env.REACT_APP_API_PREFIX ?? 'api';
  const HOSTNAME_SPLIT = HOSTNAME.split('.');
  HOSTNAME_SPLIT.shift();
  const HOSTNAME_WITHOUT_PREFIX = HOSTNAME_SPLIT.join('.');
  return `${PROTOCOL}//${API_PREFIX}.${HOSTNAME_WITHOUT_PREFIX}`;
};

/*
* Returns url for v1 api (currently only used for validating email addresses)
*/
export const getV1ApiURL = () => {
  const PATHNAME = 'v1';
  const API_URL = getApiUrl();
  return `${API_URL}/${PATHNAME}`;
};

/*
* Returns aws lambda API url
*/
export const getLambdaURL = () => {
  const LAMBDA_PATHNAME = 'gen.v1';
  const API_URL = getApiUrl();
  return `${API_URL}/${LAMBDA_PATHNAME}`;
};

/**
 * Wrapper around fetch API for making requests to lambda APIs given an endpoint
 * @param {string} endpoint endpoint for lambda function ie. 'outet/getinfo'
 * @return {Promise}
 */
export async function client(endpoint, { body, ...customConfig } = {}) {
  // must manually set the headers to 'JSON' content-type for fetch requests
  const headers = { 'Content-Type': 'application/json' };

  // add OutletUUID to headers if it is set in localStorage
  // this is a method we use to 'cache' the last outlet accessed by a user
  if (window.localStorage.getItem('OutletUUID')) {
    headers.OutletUUID = window.localStorage.getItem('OutletUUID');
  }

  const config = {
    credentials: 'include',
    method: 'POST',
    ...customConfig,
    headers: {
      ...headers,
      ...customConfig.headers,
    },
  };

  if (body) {
    // must manually stringify the body object with fetch requests
    config.body = JSON.stringify(body);
  }

  const LAMBDA_URL = getLambdaURL();

  try {
    const response = await window.fetch(`${LAMBDA_URL}/${endpoint}`, config);
    const OutletUUID = response.headers.get('outletuuid');

    if (OutletUUID) {
      window.localStorage.setItem('OutletUUID', OutletUUID);
    }

    if (response.ok) {
      return response.json();
    }
    // must manually reject the promise as fetch only rejects on network errors
    return Promise.reject(response);
  } catch (error) {
    console.error(`Network error when trying to call 'fetch' on ${LAMBDA_URL}/${endpoint}: `, error);
  }
}
