import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const handleButtonHeight = size => {
  switch (size) {
    case 'xsmall':
      return '2rem';
    case 'small':
      return '2.75rem';
    default:
      return '4.5rem';
  }
};
const handleButtonPadding = size => {
  switch (size) {
    case 'xsmall':
      return '0 1.2rem';
    case 'small':
      return '0 2rem';
    default:
      return '0 3rem';
  }
};
const handleButtonFontSize = size => {
  switch (size) {
    case 'xsmall':
      return '1rem';
    case 'small':
      return '1.2rem';
    default:
      return '1.6rem';
  }
};

const ButtonStyled = styled.button`
  font-family: ${(props) => props.theme.BUTTON_TEXT_FONT_FAMILY};
  font-size: ${(props) => handleButtonFontSize(props.size)};
  height: ${(props) => handleButtonHeight(props.size)};
  width: ${(props) => (props.fullWidth ? '100%' : 'inherit')};
  margin: 0;
  padding: ${(props) => handleButtonPadding(props.size)};
  outline: none;
  user-select: none;
  border: none;
  cursor: pointer;
  text-transform: uppercase;
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  color: ${(props) => props.theme.PRIMARY_BUTTON_TEXT_COLOR};
  background-color: ${(props) => props.theme.PRIMARY_BUTTON_BACKGROUND};
  border-radius: ${(props) => props.theme.BUTTON_BORDER_RADIUS};
  &:hover:enabled {
    background-color: ${(props) => props.theme.PRIMARY_BUTTON_BACKGROUND};
    opacity: 0.9;
  }
  &:active:enabled {
    background-color: ${(props) => props.theme.PRIMARY_BUTTON_BACKGROUND};
    opacity: 1;
  }
`;

const SecondaryButton = styled(ButtonStyled)`
  color: ${(props) => props.theme.SECONDARY_BUTTON_TEXT_COLOR};
  background-color: ${(props) => props.theme.SECONDARY_BUTTON_BACKGROUND};
  &:hover:enabled {
    background-color: ${(props) => props.theme.SECONDARY_BUTTON_BACKGROUND};
    opacity: 0.9;
  }
  &:active:enabled {
    background-color: ${(props) => props.theme.SECONDARY_BUTTON_BACKGROUND};
  }
`;

//const SecondaryButtonAlt = props => <ButtonStyled {...props} children={props.children.split('').reverse()} />

const Button = ({ loading, secondary, children, ...props }) => {
  const variant = secondary ? SecondaryButton : null;
  return (
      <ButtonStyled disabled={loading} as={variant} fullWidth={true} {...props}>
        {loading ? '...loading' : children}
      </ButtonStyled>
  );
};

Button.propTypes = {
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  secondary: PropTypes.bool,
  fullWidth: PropTypes.bool,
};

Button.defaultProps = {
  loading: false,
  secondary: false,
  children: ' ',
  fullWidth: false,
};

export default Button;
