import PropTypes from 'prop-types';
import styled from 'styled-components';
import React, { Component } from 'react';
import Typography from '../../utils/Typography';
import { IconMinus, IconPlus } from '../../utils/Icon';
import { formatDate, formatTime } from '../../utils/utilities';
import { DisplayFees } from '../../utils/DisplayContent';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';

import { getPriceLevelLabel } from './PriceLevelSelectorDisplay.util';

const Root = styled.div`
  display: flex;
  padding: 1rem 0;
  margin: 0;
  -webkit-user-select: none; /* Chrome/Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+ */
  user-select: none;
  outline-style: none;
`;
const InfoContainer = styled.div`
  flex: 1;
`;
const SelectorContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0 0 0 1rem;
`;
const Selector = styled.div`
  color: ${(props) => props.theme.PRIMARY_LINK_COLOR};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  opacity: ${(props) => (props.disabled ? 0.3 : 1)};
`;
const QtyDisplay = styled.div`
  padding: 0.5rem 1rem;
  font-size: 2rem;
`;

const MaxQtyStyled = styled.span`
  font-style: italic;
  font-size: 1.2rem;
`;

const QtySelectorTextStyled = styled.div`
  font-size: 1.4rem;
  margin: 0;
  padding: 0.5rem 0rem;
  /* font-style: italic; */
  color: ${(props) => props.theme.ERROR_TEXT_COLOR};
`;



export class PriceLevelSelectorInstant extends Component {
  render() {
    const {
      displayFees,
      priceLevel,
      updatePriceLevelQty,
      priceLevelAlreadySelected,
    } = this.props;

    if (
        !priceLevel ||
        !Object.keys(priceLevel).length ||
        priceLevel.HideFromView
    ) {
      return null;
    }

    const qty = (priceLevelAlreadySelected && priceLevelAlreadySelected.Quantity)
        ? priceLevelAlreadySelected.Quantity
        : priceLevel.quantity ? priceLevel.quantity : 0;
    // const minQty = priceLevel.PriceLevelMinimum
    //   ? priceLevel.PriceLevelMinimum
    //   : 0;
    const maxQty = priceLevel.PriceLevelLimit ? priceLevel.PriceLevelLimit : 0;
    const qtyMultiplier = priceLevel.PriceLevelMultiplier
        ? priceLevel.PriceLevelMultiplier
        : 1;
    const qtyMaxedOut =
        qty === parseInt(priceLevel.PriceLevelLimit, 10) ? true : false;

    let qtySelectorIsVisible = true;
    let qtySelectorReplacementText = '';

    //priceLevel.SaleStatus has 3 options = ON_SALE, BEFORE_SALE, OFF_SALE
    //RLMTS-1955 - for display text reference
    if (priceLevel.SaleStatus === 'BEFORE_SALE') {
      qtySelectorIsVisible = false;
      //On Sale: [on-sale date/time MM/DD/YYYY @ HH:MM XM]
      qtySelectorReplacementText = 'Coming Soon!';
      if (priceLevel.StartTime) {
        const formatedShowDate = formatDate(priceLevel.StartTime, {
          month: 'numeric',
          day: 'numeric',
          year: 'numeric',
        });
        qtySelectorReplacementText = `On Sale: ${formatedShowDate}`;

        const formatedShowTime = formatTime(priceLevel.StartTime, {
          hour: 'numeric',
          minute: 'numeric',
        });
        qtySelectorReplacementText += ` @ ${formatedShowTime}`;
      }
    }

    if (priceLevel.SaleStatus === 'OFF_SALE') {
      qtySelectorIsVisible = false;
      qtySelectorReplacementText = 'Currently Unavailable';
    }

    if (!priceLevel.AvailableInventory) {
      //qtySelectorIsVisible = false;
      //qtySelectorReplacementText = "Currently Unavailable";
    }

    if (priceLevel.SoldOut) {
      qtySelectorIsVisible = false;
      qtySelectorReplacementText = 'SOLD OUT';
    }

    // Find the outlet event
    const { eventList } = this.props;
    const { EventItemLevelID } = priceLevel;
    const outletEvents = Array.isArray(eventList) ? eventList.filter((evt) => {
      const { OutletEventItems } = evt;
      let match;
      if (Array.isArray(OutletEventItems)) {
        OutletEventItems.forEach((oei) => {
          const { PriceLevels } = oei;
          if (Array.isArray(PriceLevels)) {
            PriceLevels.forEach((pl) => {
              if (pl.EventItemLevelID.toString() === EventItemLevelID.toString()) {
                match = evt;
              }
            });
          }
        });
      }
      return match !== undefined;
    }) : [];
    const outletEvent = outletEvents[0];
    
    return (
        <Root style={updatePriceLevelQty ? { opacity: 1 } : { opacity: 0.5 }}>
          <InfoContainer>
            <Typography>{priceLevel.PriceLevelName}</Typography>
            <Typography style={{ marginBottom: '1rem' }}>
              {getPriceLevelLabel(priceLevel, outletEvent?.OutletEventID || '', eventList)}
            </Typography>
            {displayFees && (outletEvent && outletEvent.ShowAllInPricing !== 1) && (<DisplayFees FeeGroups={priceLevel?.FeeGroups} layout="vertical"/>)}
            <Typography
                dangerouslySetInnerHTML={{ __html: priceLevel.Description }}
            />
            <Typography>
              {/* {minQty && (
              <span>
                Min: {minQty} {maxQty && <span>|</span>}{" "}
              </span>
            )} */}

              {priceLevel.AvailableInventory ? (
                  <MaxQtyStyled>Max: {maxQty}</MaxQtyStyled>
              ) : null}

              {/* {qtyMultiplier && <span>Sets: ({qtyMultiplier})</span>} */}
            </Typography>
          </InfoContainer>
          {qtySelectorIsVisible ? (
              <SelectorContainer>
                <Selector
                    aria-label="Remove Quantity"
                    name={'sub-qty-' + priceLevel.PriceLevelID}
                    className={'price-level-qty-minus-btn'}
                    id={'price-level-qty-minus-' + priceLevel.PriceLevelID}
                    disabled={qty < 1 || !updatePriceLevelQty ? true : false}
                    onClick={() => {
                      if (priceLevelAlreadySelected && qty === 1) {
                        updatePriceLevelQty(
                            priceLevel,
                            -qtyMultiplier,
                            priceLevelAlreadySelected,
                        );
                      } else {
                        if (qty < 1) return false;
                        if (updatePriceLevelQty) {
                          updatePriceLevelQty(priceLevel, -qtyMultiplier);
                        }
                        return false;
                        //return updatePriceLevelQty(priceLevel, -qtyMultiplier);
                      }
                    }}
                >
                  <IconMinus/>
                </Selector>

                <QtyDisplay>{qty}</QtyDisplay>

                <Selector
                    aria-label="Add Quantity"
                    name={'add-qty-' + priceLevel.PriceLevelID}
                    className={'price-level-qty-add-btn'}
                    id={'price-level-qty-add-' + priceLevel.PriceLevelID}
                    disabled={qtyMaxedOut || !updatePriceLevelQty ? true : false}
                    onClick={() => {
                      if (qtyMaxedOut) return false;
                      if (updatePriceLevelQty) {
                        updatePriceLevelQty(priceLevel, qtyMultiplier);
                      }
                      return false;
                      //return updatePriceLevelQty(priceLevel, qtyMultiplier);
                    }}
                >
                  <IconPlus/>
                </Selector>
              </SelectorContainer>
          ) : (
              <SelectorContainer>
                <QtySelectorTextStyled>
                  {qtySelectorReplacementText}
                </QtySelectorTextStyled>
              </SelectorContainer>
          )}
        </Root>
    );
  }
}

PriceLevelSelectorInstant.defaultProps = {
  displayFees: true,
  priceLevel: {},
  updatePriceLevelQty: null,
};

PriceLevelSelectorInstant.propTypes = {
  displayFees: PropTypes.bool,
  priceLevel: PropTypes.object,
  updatePriceLevelQty: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
};

const mapStateToProps = (state) => ({
  eventList: state.eventList,
});

export default connect(mapStateToProps)(withRouter(PriceLevelSelectorInstant));
