/* eslint-disable require-jsdoc */
import store from '../../store';
import { addToast } from '../toast/actions';
import { api } from '../api/actions';
import { getUserFanRequestSeriesSummary } from '../user/actions';

export function resetGuestListCart() {
  store.dispatch({
    type: 'RESET_GUEST_REQUEST_CART',
  });
}

export function setGuestListCart(cart) {
  console.log('setGuestListCart', cart);
  store.dispatch({
    type: 'SET_GUEST_REQUEST_CART',
    cart,
  });
}

export function updateGuestListRequestCart({ action, cart }) {
  // GUEST_REQUEST_CLEAR_CART
  store.dispatch({
    type: 'SET_GUEST_REQUEST_LOADING',
    loading: true,
  });

  store.dispatch({
    type: 'SET_GUEST_REQUEST_ERROR',
    error: null,
  });

  const state = store.getState();
  const guestRequest = state.guestRequest;
  const currentGuestRequestCart = guestRequest && guestRequest.cart || {};

  if (action==='GUEST_REQUEST_CLEAR_CART') {
    resetGuestListCart();
  } else {
    if (cart) {
      setGuestListCart({ ...currentGuestRequestCart, ...cart });
    }
  }

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      store.dispatch({
        type: 'SET_GUEST_REQUEST_LOADING',
        loading: false,
      });
      resolve('updated request');
    }, 0);
  });
}

export function createGuestListRequest(params) {
  store.dispatch({
    type: 'SET_GUEST_REQUEST_LOADING',
    loading: true,
  });

  store.dispatch({
    type: 'SET_GUEST_REQUEST_ERROR',
    error: null,
  });

  const state = store.getState();
  const session = state.session;

  const User = {
    ...session,
  };
  const InvitationToken = state.auth.guestListToken;

  const payload = {
    GuestListRequest: {
      InvitationToken,
      User,
      ...params,
    },
  };

  // console.log("createGuestListRequest payload", payload);

  const apiConfig = {
    action: 'CREATE_GUEST_LIST_REQUEST',
    payload,
    showToast: true,
  };
  const response = api(apiConfig);
  response.then((res) => {
    // console.log("createGuestListRequest res", res);
    // console.log("createGuestListRequest res stringify", JSON.stringify(res));

    store.dispatch({
      type: 'SET_GUEST_LIST_RECEIPT',
      data: res,
    });

    store.dispatch({
      type: 'SET_GUEST_REQUEST_LOADING',
      loading: false,
    });
  }).catch((error) => {
    store.dispatch({
      type: 'SET_GUEST_REQUEST_LOADING',
      loading: false,
    });
    store.dispatch({
      type: 'SET_GUEST_REQUEST_ERROR',
      error,
    });
  });

  return response;

  // setTimeout(() => {
  //   store.dispatch({
  //     type: "SET_GUEST_REQUEST_LOADING",
  //     loading: false,
  //   });
  //   resolve("created request");
  // }, 2000);
}

export function setInstantCart(cart) {
  store.dispatch({
    type: 'SET_INSTANT_REQUEST_CART',
    cart,
  });
}

export function deleteInstantRequest() {
  // Server side is now performing clean up on expired carts,
  // We can just clear local state
  store.dispatch({
    type: 'DELETE_INSTANT_REQUEST',
  });
}

export function updateInstantRequest({ action, requestBody, outletEvent }) {
  // console.log("updateInstantRequest req = ", action, requestBody, outletEvent);

  store.dispatch({
    type: 'SET_INSTANT_REQUEST_LOADING',
    loading: true,
  });

  store.dispatch({
    type: 'SET_INSTANT_REQUEST_ERROR',
    error: null,
  });
  if (outletEvent) {
    store.dispatch({
      type: 'SET_INSTANT_REQUEST_OUTLET_EVENT',
      outletEvent,
    });
  }

  const response = api({
    action,
    payload: requestBody,
  });

  response.then((cart) => {
    if (action === 'INSTANT_REQUEST_CONFIRM_ORDER') {
      // reset cart?
      setInstantCart(null);
    } else {
      setInstantCart(cart);
    }
    store.dispatch({
      type: 'SET_INSTANT_REQUEST_LOADING',
      loading: false,
    });
  }).catch((error) => {
    // console.log("updateInstantRequest error = ", action, error);
    store.dispatch({
      type: 'SET_INSTANT_REQUEST_LOADING',
      loading: false,
    });
    store.dispatch({
      type: 'SET_INSTANT_REQUEST_ERROR',
      error,
    });
    addToast({
      type: 'error',
      title: 'Attention: ',
      description: error,
    });
  });

  return response;
}

export function setFanRequestCart(config = {}) {
  store.dispatch({
    type: 'SET_FAN_REQUEST_CART',
    cart: config,
  });
}

export function resetFanRequestCart() {
  store.dispatch({
    type: 'RESET_FAN_REQUEST_CART',
  });
}

export function setFanRequestLoading(loading) {
  store.dispatch({
    type: 'SET_FAN_REQUEST_LOADING',
    loading: loading ? true : false,
  });
}

export function setFanRequestError(error) {
  store.dispatch({
    type: 'SET_FAN_REQUEST_ERROR',
    error: error ? error : null,
  });
}

export function setFanRequestOutletEvent(outletEvent) {
  store.dispatch({
    type: 'SET_FAN_REQUEST_OUTLET_EVENT',
    outletEvent: outletEvent ? outletEvent : null,
  });
}

export function setFanRequestSeriesSummary(seriesSummary) {
  store.dispatch({
    type: 'SET_FAN_REQUEST_SERIES_SUMMARY',
    seriesSummary: seriesSummary ? seriesSummary : null,
  });
}

export function getUserSeriesSummaryForCart({ action, cart, outletEvent }) {
}

export function deleteFanRequest(params = {}) {
  const state = store.getState();
  const session = state.session;
  const UserID = session && session.UserID ? Number(session.UserID) : null;

  const { request } = params;
  if (
    request &&
      request.LotteryOEIRequests &&
      request.LotteryOEIRequests.length &&
      request.LotteryOERequestUUID
  ) {
    const lotteryOEIRequestsNormalized = request.LotteryOEIRequests.map(
        (x) => ({ ...x, Quantity: 0 }),
    );

    const payload = {
      UserID,
      complete: true,
      LotteryOERequestUUID: request.LotteryOERequestUUID,
      LotteryOEIRequests: lotteryOEIRequestsNormalized,
    };
    // console.log("delete request payload", payload);
    // return new Promise((resolve, reject) => resolve(payload));
    setFanRequestLoading(true);
    setFanRequestError(null);
    const apiConfig = {
      action: 'DELETE_USER_FAN_SERIES_REQUEST',
      payload,
      showToast: true,
    };
    const response = api(apiConfig);
    response.then((res) => {
      // console.log("%%% DELETE_USER_FAN_SERIES_REQUEST res", res);
      if (Array.isArray(res)) {
        store.dispatch({
          type: 'SET_USER_FAN_REQUEST_SERIES_SUMMARY',
          data: res,
        });
      } else {
        getUserFanRequestSeriesSummary();
      }

      setFanRequestLoading(false);
    }).catch((error) => {
      setFanRequestLoading(false);
      setFanRequestError(error);
    });

    return response;
  } else {
    return new Promise((resolve, reject) => reject('No line items found'));
  }
}

export function editExistingFanRequest(params = {}) {
  const state = store.getState();
  const session = state.session;
  const UserID = session && session.UserID ? Number(session.UserID) : null;

  const { LotteryOEIRequests, LotteryOERequestUUID } = params;
  if (LotteryOEIRequests && LotteryOERequestUUID) {
    const payload = {
      UserID,
      complete: true,
      // LotteryOERequestUUID: request.LotteryOERequestUUID,
      // LotteryOEIRequests: lotteryOEIRequestsNormalized,
      ...params,
    };
    // console.log("edit existing request payload", payload);
    // return new Promise((resolve, reject) => resolve(payload));
    setFanRequestLoading(true);
    setFanRequestError(null);
    const apiConfig = {
      action: 'UPDATE_USER_FAN_REQUEST',
      payload,
      // showToast: true,
    };
    const response = api(apiConfig);
    response.then((res) => {
      setFanRequestLoading(false);
    }).catch((error) => {
      setFanRequestLoading(false);
      setFanRequestError(error);
    });

    return response;
  } else {
    return new Promise((resolve, reject) => reject('No line items or UUID'));
  }
}

export function updateFanSeriesRequest(params = {}) {
  // example of params accepted below:
  // const {
  //   UserID: number, - REQUIRED
  //   Shipping: object,
  //   OutletEventGroupID: number, - REQUIRED
  //   PaymentPlanEnrolled: bool,
  //   LotteryOEGRequestUUID: uuid, - REQUIRED
  //   PriorityOERequestUUID: uuid,
  //   PaymentInfo: array [{ Sort, PaymentMethodID, PaymentMethodUUID, LotteryOEGRequestPaymentMethodUUID}],
  // } = params;
  const state = store.getState();
  const session = state.session;
  const UserID = session && session.UserID ? Number(session.UserID) : null;
  // let userFanRequestSeriesSummaryList = state.userFanRequestSeriesSummaryList
  //   ? [...state.userFanRequestSeriesSummaryList]
  //   : [];
  setFanRequestLoading(true);
  setFanRequestError(null);
  const apiConfig = {
    action: 'UPDATE_USER_FAN_SERIES_REQUEST',
    payload: {
      UserID,
      complete: true,
      ...params,
    },
  };
  const response = api(apiConfig);
  return new Promise((resolve, reject) => {
    response.then((res) => {
      if (Array.isArray(res)) {
        store.dispatch({
          type: 'SET_USER_FAN_REQUEST_SERIES_SUMMARY',
          data: res,
        });
      }

      // getUserFanRequestSeriesSummary();
      // if (res && res.LotteryOEGRequestUUID) {
      //   userFanRequestSeriesSummaryList = userFanRequestSeriesSummaryList.map(
      //     (x) => {
      //       if (x.LotteryOEGRequestUUID === res.LotteryOEGRequestUUID) {
      //         return { ...x, ...res };
      //       }
      //       return x;
      //     }
      //   );
      //   store.dispatch({
      //     type: "SET_USER_FAN_REQUEST_SERIES_SUMMARY",
      //     data: userFanRequestSeriesSummaryList,
      //   });

      // }
      setFanRequestLoading(false);
      resolve(res);
    }).catch((error) => {
      setFanRequestLoading(false);
      setFanRequestError(error);
      addToast({
        type: 'error',
        title: 'Attention: ',
        description: error,
      });
      reject(error);
    });
  });
  // return response;
}

export function updateFanSeriesRequestPriority(params = {}) {
  // example of params accepted below:
  // const {
  //   UserID: number, - REQUIRED
  //   OutletEventGroupID: number, - REQUIRED
  //   LotteryOEGRequestUUID: uuid, - REQUIRED
  //   PriorityOERequestUUID: uuid,
  // } = params;
  const state = store.getState();
  const session = state.session;
  const UserID = session && session.UserID ? Number(session.UserID) : null;

  setFanRequestLoading(true);
  setFanRequestError(null);
  const apiConfig = {
    action: 'UPDATE_USER_FAN_SERIES_PRIORITY',
    payload: {
      UserID,
      complete: true,
      ...params,
    },
  };
  const response = api(apiConfig);
  return new Promise((resolve, reject) => {
    response.then((res) => {
      store.dispatch({
        type: 'SET_USER_FAN_REQUEST_SERIES_PRIORITY',
        data: {
          LotteryOEGRequestUUID: params.LotteryOEGRequestUUID,
          TourID: params.TourID,
          PriorityOERequestUUID: params.PriorityOERequestUUID,
        },
      });

      setFanRequestLoading(false);
      resolve(res);
    }).catch((error) => {
      setFanRequestLoading(false);
      setFanRequestError(error);
      addToast({
        type: 'error',
        title: 'Attention: ',
        description: error,
      });
      reject(error);
    });
  });
}

const mergeLineItemsWithAddons = (items = [], addons = []) => {
  const ids = new Set(addons.map((d) => d.PriceLevelID));
  let normalizedItems = [
    ...addons,
    ...items.filter((d) => !ids.has(d.PriceLevelID)),
  ];
  if (addons.length === 0) {
    normalizedItems = normalizedItems.map((i) => {
      if (i.isAddOn) {
        i.quantity = 0;
        i.Quantity = 0;
      }
      return i;
    });
  }
  return normalizedItems;
};

export function updateFanRequest(params = {}) {
  // console.log("updateFanRequest params = ", params);
  const { action, cart, outletEvent, addons, paymentMethod } = params;
  const state = store.getState();
  const session = state.session;
  const fanRequest = state.fanRequest;
  const UserID = session && session.UserID ? Number(session.UserID) : null;
  const mergedCart = { ...fanRequest.cart, ...cart };
  // const seriesSummary = fanRequest && fanRequest.seriesSummary;

  setFanRequestLoading(true);
  setFanRequestError(null);
  if (cart) {
    setFanRequestCart(mergedCart);
  }
  if (outletEvent) {
    setFanRequestOutletEvent(outletEvent);
  }
  const OE = outletEvent || fanRequest.outletEvent;
  // console.log("updateFanRequest UserID = ", UserID);
  // console.log("updateFanRequest OE = ", OE);
  // console.log("updateFanRequest action = ", action);
  // console.log("updateFanRequest mergedCart = ", mergedCart);
  // TODO: wrap below in try catch await blocks to make code DRY

  // LotteryOEIRequests: [{…}]
  // addons: (3) [{…}, {…}, {…}]
  // lineItems: [{…}]
  // paymentMethodUUID: "0b3d1386-8669-49b7-a6f9-6191a945686b"
  // shippingAddress

  if (
    OE &&
      UserID &&
      Number.isInteger(UserID) &&
      (action === 'CREATE_FAN_REQUEST_WITH_SERIES_SUMMARY' ||
          action === 'SAVE_FAN_REQUEST')
  ) {
    const { OutletEventID, OutletSeriesID } = OE;
    const {
      lineItems,
      paymentList,
      shippingAddress,
      enrollInPaymentPlan,
      LotteryOERequestUUID,
      PriorityOERequestUUID,
    } = mergedCart;
    let payload;

    if (action === 'CREATE_FAN_REQUEST_WITH_SERIES_SUMMARY') {
      if (OutletSeriesID && lineItems.length) {
        payload = {
          query: {
            UserID,
            OutletEventGroupID: OutletSeriesID,
          },
          oerequest: {
            UserID,
            OutletEventID,
            LotteryOEIRequests: Array.isArray(addons)
                ? mergeLineItemsWithAddons(lineItems, addons)
                : lineItems,
          },
        };
      }
    }
    if (action === 'SAVE_FAN_REQUEST') {
      // Normalize the OEI keys so the object exactly matches reqirements of API call, otherwise the mutation fails because of unknown/unwanted fields
      const lotteryOEIRequestsNormalized = lineItems.map((li) => {
        const obj = {
          LotteryOEIRequestUUID: li.LotteryOEIRequestUUID,
          PriceLevelID: li.PriceLevelID || li.priceLevelID,
          Quantity: li.Quantity || li.quantity,
          PriceLevelOptOut: li.PriceLevelOptOut,
        };
        return obj;
      });
      payload = {
        UserID,
        complete: true,
        LotteryOERequestUUID,
        LotteryOEIRequests: lotteryOEIRequestsNormalized,
      };
    }
    // console.log("updateFanRequest payload = ", payload);
    return new Promise((resolve, reject) => {
      if (payload) {
        const apiConfig = {
          action,
          payload,
        };

        const response = api(apiConfig);
        response.then((res) => {
          // console.log("@@@@ updateFanSeriesRequest res", res);
          const adjustedCart = {
            ...mergedCart,
            lineItems: Array.isArray(addons)
                ? mergeLineItemsWithAddons(lineItems, addons)
                : lineItems,
          };

          setFanRequestCart(adjustedCart);

          if (action === 'CREATE_FAN_REQUEST_WITH_SERIES_SUMMARY') {
            setFanRequestLoading(false);
            const seriesSummary =
                res && Array.isArray(res) && res.length ? res[0] : null;
            if (seriesSummary) {
              resolve(seriesSummary);
              setFanRequestSeriesSummary(seriesSummary);
            } else {
              const error = 'Unable to build series summary';
              setFanRequestError(error);
              reject(error);
              addToast({
                type: 'error',
                title: 'Attention: ',
                description: error,
              });
            }
          }

          if (action === 'SAVE_FAN_REQUEST') {
            const LotteryOEGRequestUUID = res && res.LotteryOEGRequestUUID;
            // Next we determine if a call to update the OEG request is required.
            // Required if user updated anything related to shipping address, payment, payment plan, or priority select;
            const seriesConfig = {};
            if (shippingAddress) {
              const shippingAddressNormalized = {
                LotteryRequestShippingAddressUUID:
                shippingAddress.LotteryRequestShippingAddressUUID,
                FirstName: shippingAddress.First,
                LastName: shippingAddress.Last,
                CompanyName: shippingAddress.CompanyName,
                Address1: shippingAddress.AddressLineOne,
                Address2: shippingAddress.AddressLineTwo,
                City: shippingAddress.City,
                State: shippingAddress.State,
                Country: shippingAddress.CountryID,
                ZipCode: shippingAddress.Zip,
                Phone: shippingAddress.Phone,
                AddressID: shippingAddress.AddressID,
                AddressUUID: shippingAddress.AddressUUID,
              };
              seriesConfig.Shipping = shippingAddressNormalized;
            }
            if (paymentList && paymentList.length) {
              seriesConfig.PaymentInfo = paymentList;
            }
            if (PriorityOERequestUUID) {
              seriesConfig.PriorityOERequestUUID = PriorityOERequestUUID;
            }
            if (enrollInPaymentPlan !== undefined) {
              seriesConfig.PaymentPlanEnrolled = enrollInPaymentPlan;
            }
            if (Object.keys(seriesConfig).length && LotteryOEGRequestUUID) {
              seriesConfig.LotteryOEGRequestUUID = LotteryOEGRequestUUID;
              seriesConfig.OutletEventGroupID = OutletSeriesID;
              seriesConfig.UserID = UserID;
              updateFanSeriesRequest(seriesConfig).then((seriesSummaryList) => {
                resetFanRequestCart();
                let seriesSum = {};
                if (Array.isArray(seriesSummaryList)) {
                  seriesSum = seriesSummaryList.find(
                      (x) =>
                          x.LotteryOEGRequestUUID == res.LotteryOEGRequestUUID,
                  );
                }
                resolve({ ...res, ...seriesSum });
              }).catch((error) => reject(error));
            } else {
              resolve(res);
              setFanRequestLoading(false);
            }
          }
        }).catch((error) => {
          setFanRequestLoading(false);
          setFanRequestError(error);
          reject(error);
          addToast({
            type: 'error',
            title: 'Attention: ',
            description: error,
          });
        });

        // return response;
      } else {
        reject('invalid payload');
      }
    });
  } else {
    // if (action === "FAN_REQUEST_UPDATE_ADDONS" && addons) {
    //   // let mergedCartWithAddons = {
    //   //   ...mergedCart,
    //   //   lineItems: [...mergedCart.lineItems, ...addons],
    //   // };
    //   // console.log(
    //   //   "updateFanRequest mergedCartWithAddons = ",
    //   //   mergedCartWithAddons
    //   // );
    //   setFanRequestCart({ ...mergedCart, addons });
    // }
    if (
        action === 'ENROLL_IN_PAYMENTPLAN' ||
        action === 'EDIT_REQUES_LINE_ITEMS'
    ) {
      setFanRequestCart(mergedCart);
    }
    if (action === 'FAN_REQUEST_UPDATE_PAYMENT_METHOD' && paymentMethod) {
      let paymentList = [];
      if (Array.isArray(mergedCart.paymentList)) {
        paymentList = [...mergedCart.paymentList];
        const paymentIndex = paymentList.findIndex(
            (p) => Number(p.Sort) === Number(paymentMethod.Sort),
        );
        if (paymentIndex > -1) {
          const LotteryOEGRequestPaymentMethodUUID =
              paymentList[paymentIndex].LotteryOEGRequestPaymentMethodUUID;
          const LotteryOEGRequestPaymentMethodID =
              paymentList[paymentIndex].LotteryOEGRequestPaymentMethodID;

          paymentList[paymentIndex] = {
            ...paymentMethod,
            LotteryOEGRequestPaymentMethodID,
            LotteryOEGRequestPaymentMethodUUID,
          };
        } else {
          paymentList.push(paymentMethod);
        }
        // paymentList = mergedCart.paymentList.map((p) => {
        //   if (Number(p.Sort) === Number(paymentMethod.Sort)) {
        //     return paymentMethod;
        //   }
        //   return p;
        // });
      } else {
        paymentList.push(paymentMethod);
      }

      setFanRequestCart({ ...mergedCart, paymentList });
    }

    setFanRequestLoading(false);
    return new Promise((resolve, reject) => resolve(mergedCart));
  }
}
