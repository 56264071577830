import PropTypes from 'prop-types';
import React from 'react';

import Alert from '../../utils/Alert';
import Button from '../../utils/Button';
import Typography from '../../utils/Typography';
import { TextInput } from '../../utils/Form';
import { createUser } from './actions';
import { goToUrl } from '../../utils/utilities';
import { loginUser } from '../auth/actions';
import { validateEmail } from '../../utils/Validation';

export class UserProfileCreate extends React.Component {
  state = {
    email: '',
    emailconfirm: '',
    firstName: '',
    lastName: '',
    password: '',
    passwordconfirm: '',
    formErrors: {},
    loading: false,
    error: null,
  };

  handleSubmit = () => {
    const {
      email,
      firstName,
      lastName,
      emailconfirm,
      password,
      passwordconfirm,
    } = this.state;
    const { view, onSuccess } = this.props;
    const formErrors = {};

    if (email.trim() !== emailconfirm.trim()) {
      formErrors['emailconfirm'] = 'Email addresses do not match';
    }

    if (password !== passwordconfirm) {
      formErrors['passwordconfirm'] = 'Passwords do not match';
    }

    if (!password) {
      formErrors['password'] = 'Password required';
    } else {
      if (password.length < 7) {
        formErrors['password'] = 'Password must contain at least 7 characters.';
      }
      if (password.match(/[a-z]/i) === null) {
        formErrors['password'] =
            'Password must contain at least 1 alpha character.';
      }
      if (password.match(/\d/i) === null) {
        formErrors['password'] =
            'Password must contain at least 1 numeric character.';
      }
    }
    if (!passwordconfirm) {
      formErrors['passwordconfirm'] = 'Confirm Password required';
    } else {
      if (passwordconfirm.length < 7) {
        formErrors['passwordconfirm'] =
            'Confirm  Password must contain at least 7 characters.';
      }
      if (passwordconfirm.match(/[a-z]/i) === null) {
        formErrors['passwordconfirm'] =
            'Confirm  Password must contain at least 1 alpha character.';
      }
      if (passwordconfirm.match(/\d/i) === null) {
        formErrors['passwordconfirm'] =
            'Confirm Password must contain at least 1 numeric character.';
      }
    }
    if (!firstName) {
      formErrors['firstName'] = 'First name required';
    }
    if (!lastName) {
      formErrors['lastName'] = 'Last name required';
    }
    if (!email) {
      formErrors['email'] = 'Email required';
    }

    if (Object.keys(formErrors).length) {
      this.setState({ formErrors });
      return false;
    } else {
      this.setState({ loading: true, error: false });

      validateEmail(email).then((res) => {
        let validEmail = res && res.valid;
        if (validEmail === false) {
          formErrors['email'] =
              'Please verify your email address and try again';
          this.setState({ formErrors, loading: false });
          return false;
        } else {
          const req = {
            emailAddress: email,
            password: password,
            firstName: firstName,
            lastName: lastName,
          };
          createUser(req).then((createUserResponse) => {
            //Automatically log user in after successfull user create
            const loginRequest = {
              id: email,
              password,
              view,
            };

            if (createUserResponse && createUserResponse.error) {
              this.setState({
                loading: false,
                error:
                    createUserResponse.message || 'error creating new user',
              });
            } else {
              loginUser(loginRequest).then((loginResponse) => {
                if (loginResponse && loginResponse.error) {
                  this.setState({
                    loading: false,
                    error:
                        loginRequest.message || 'error creating new user',
                  });
                } else {
                  this.setState({
                    loading: false,
                    error: null,
                  });

                  onSuccess ? onSuccess() : goToUrl('/');
                }
              }).catch((error) => {
                this.setState({
                  loading: false,
                  error: error || 'unable to auto login user at this time',
                });
                goToUrl('/');
              });
            }
          }).catch((createUserError) => {
            this.setState({
              loading: false,
              error: createUserError || 'system error creating new user',
            });
          });
        }
      });
    }
  };

  handleInput = (name) => (event) =>
      this.setState({ [name]: event.target.value });

  handleContinue = () => {
    if (this.props.onSuccess) {
      this.props.onSuccess();
    } else {
      goToUrl('/');
    }
  };

  render() {
    console.log('Profile Create state =>', this.state);
    console.log('Profile Create props =>', this.props);

    const { onCancel } = this.props;
    const {
      email,
      emailconfirm,
      firstName,
      lastName,
      password,
      passwordconfirm,
      formErrors,
      error,
      loading,
    } = this.state;

    return (
        <form
            autoComplete="off"
            onSubmit={(e) => {
              e.preventDefault();
            }}
        >
          <TextInput
              name="firstName"
              placeholder="First Name"
              error={formErrors['firstName']}
              fullWidth
              value={firstName}
              onChange={this.handleInput('firstName')}
          />

          <TextInput
              name="lastName"
              placeholder="Last Name"
              error={formErrors['lastName']}
              fullWidth
              value={lastName}
              onChange={this.handleInput('lastName')}
          />
          <TextInput
              name="email"
              placeholder="Email"
              error={formErrors['email']}
              fullWidth
              value={email}
              onChange={this.handleInput('email')}
              autoComplete="new-password"
          />
          <TextInput
              name="emailconfirm"
              placeholder="Confirm Email"
              error={formErrors['emailconfirm']}
              fullWidth
              value={emailconfirm}
              onChange={this.handleInput('emailconfirm')}
              autoComplete="new-password"
          />
          <TextInput
              name="password"
              type="password"
              placeholder="Password"
              error={formErrors['password']}
              helpText={
                'Password must contain a minimum of 7 characters with at least 1 numeric character'
              }
              fullWidth
              value={password}
              onChange={this.handleInput('password')}
              autoComplete="new-password"
          />
          <TextInput
              name="passwordconfirm"
              type="password"
              placeholder="Confirm Password"
              error={formErrors['passwordconfirm']}
              fullWidth
              value={passwordconfirm}
              onChange={this.handleInput('passwordconfirm')}
              autoComplete="new-password"
          />
          {error && (
              <div style={{ margin: '2rem 0' }}>
                <Alert type="error"> {error} </Alert>
              </div>
          )}

          <div style={{ margin: '2rem 0' }}>
            <Button
                id="create-new-account-btn"
                loading={loading}
                onClick={() => this.handleSubmit()}
                fullWidth
            >
              Create Account
            </Button>
          </div>
          <div style={{ margin: '2rem 0', textAlign: 'center' }}>
            {!loading && onCancel && (
                <Typography tag="a" onClick={onCancel} id="cancel-new-account-btn">
                  Cancel
                </Typography>
            )}
          </div>
        </form>
    );
  }
}

UserProfileCreate.propTypes = {
  onCancel: PropTypes.func,
  onSuccess: PropTypes.func,
  view: PropTypes.string,
};

UserProfileCreate.defaultProps = {
  onCancel: null,
  onSuccess: null,
  view: '',
};

export default UserProfileCreate;
