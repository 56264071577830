import PropTypes from 'prop-types';
import styled from 'styled-components';
import React from 'react';
import { useSelector } from 'react-redux';

import PriceLevelSelectorInstant from '../priceLevel/PriceLevelSelectorInstant';
import Typography from '../../utils/Typography';
import { updateGuestListRequestCart } from '../cart/actions';

//import { IconCalendar, IconClock, IconMapPin } from "../../utils/Icon";

const RootStyled = styled.div`
  margin: 0;
  padding: 0;
`;

const OutletEventItemRowStyled = styled.div`
  color: ${(props) => props.theme.PRIMARY_LINK_COLOR}!important;
  background-color: ${(props) => props.theme.PRIMARY_GRAY};
  padding: 1rem 2rem;
`;
const OutletEventItemDescription = styled.div`
  padding: 0.5rem 0 0 0;
`;

const PriceLevelsContainer = styled.div`
  padding: 1rem 2rem;
  background-color: ${(props) => props.theme.PRIMARY_GRAY}20;
`;
const PriceLevelDivider = styled.div`
  margin: 1rem 0;
  border-bottom: solid 1px ${(props) => props.theme.PRIMARY_GRAY};
`;

export const OutletEventItemListForGuestList = ({
                                                  OutletEventItems,
                                                  onClickPriceLevelQty,
                                                }) => {
  //const [selectedPriceLevels, setSelectedPriceLevels] = useState([]);
  const invitation = useSelector((state) => state.guestListInvitation);
  const guestListRequestCart = useSelector((state) => state.guestRequest.cart);

  const handleClickPriceLevelQty = (priceLevel, increment = 1) => {
    let adjustedArray =
        guestListRequestCart && Array.isArray(guestListRequestCart.priceLevels)
            ? [...guestListRequestCart.priceLevels]
            : [];
    //let adjustedArray = [...selectedPriceLevels];
    const index = adjustedArray.findIndex(
        (pl) => pl.PriceLevelID === priceLevel.PriceLevelID,
    );

    if (index === -1) {
      let selectedPriceLevel = { ...priceLevel };
      selectedPriceLevel.quantity = increment;
      adjustedArray.push(selectedPriceLevel);
    } else {
      let spl = adjustedArray[index];
      spl.quantity = spl.quantity + increment;
      if (spl.quantity < 1) {
        adjustedArray.splice(index, 1);
      }
    }
    //setSelectedPriceLevels(adjustedArray);
    if (updateGuestListRequestCart) {
      const config = {
        action: 'GUEST_REQUEST_UPDATE_PRICE_LEVELS',
        cart: { priceLevels: adjustedArray },
      };
      console.log('handleClickPriceLevelQty config', config);
      updateGuestListRequestCart(config).then((res) => {
      });
    }
  };

  const selectedPriceLevels =
      guestListRequestCart && Array.isArray(guestListRequestCart.priceLevels)
          ? [...guestListRequestCart.priceLevels]
          : [];

  return (
      <RootStyled>
        {Array.isArray(OutletEventItems) && OutletEventItems.length ? (
            OutletEventItems.map((oei) => (
                <div key={oei.OutletEventItemID}>
                  <OutletEventItemRowStyled>
                    <Typography tag="h4">
                      {oei.Name ? oei.Name : oei.EventItem.Name}
                    </Typography>
                    {oei.Description && (
                        <Typography>
                          <OutletEventItemDescription>
                            <div
                                dangerouslySetInnerHTML={{
                                  __html: oei.Description,
                                }}
                            />
                          </OutletEventItemDescription>
                        </Typography>
                    )}{' '}
                  </OutletEventItemRowStyled>
                  <PriceLevelsContainer>
                    {oei.PriceLevels.length ? (
                        oei.PriceLevels.sort((a, b) => Number(a.Sort) - Number(b.Sort)).map((priceLevel, plidx) => {
                          const selectedPriceLevel = selectedPriceLevels.find(
                              (spl) => spl.PriceLevelID === priceLevel.PriceLevelID,
                          );
                          return (
                              <div key={priceLevel.PriceLevelID}>
                                <PriceLevelSelectorInstant
                                    priceLevel={selectedPriceLevel || priceLevel}
                                    updatePriceLevelQty={handleClickPriceLevelQty}
                                    displayFees={Boolean(
                                        invitation && Boolean(invitation.CollectPayment),
                                    )}
                                />
                                {plidx + 1 < oei.PriceLevels.length && (
                                    <PriceLevelDivider/>
                                )}
                              </div>
                          );
                        })
                    ) : (
                        <Typography>
                          There are currently no price levels for sale
                        </Typography>
                    )}
                  </PriceLevelsContainer>
                </div>
            ))
        ) : (
            <Typography>There are currently no items for sale</Typography>
        )}
      </RootStyled>
  );
};

OutletEventItemListForGuestList.propTypes = {
  loading: PropTypes.bool,
  invitation: PropTypes.object,
  onClickPriceLevelQty: PropTypes.func,
};
OutletEventItemListForGuestList.defaultProps = {
  loading: false,
  invitation: null,
  onClickPriceLevelQty: null,
};

export default OutletEventItemListForGuestList;
