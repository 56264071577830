import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { FeeItemRow, LineItemRow, PriceLevelHeader } from './LineItemSummary';
import { formatCurrency, toOrdinal } from '../../utils/utilities';

const PreferenceListStyled = styled.div`
  border: solid ${(props) => (props.hasBorder ? '1px ' : '0px')}
    ${(props) => props.theme.PRIMARY_GRAY}80;
`;

const PreferenceStyled = styled.div`
  text-decoration: ${(props) =>
    props.optedOut ? 'line-through!important' : 'none'};
  display: ${(props) =>
    props.optedOut && props.onlyPreferenceIsVisible
        ? 'none!important'
        : 'block'};
  opacity: ${(props) => (props.optedOut || props.grayedOut ? '.6' : '1')};
  background-color: ${(props) =>
    props.oddRow ? props.theme.PRIMARY_GRAY + '80' : 'initial'};
`;

export class LineItemPreferenceList extends React.Component {
  render() {
    //console.log("LineItemPreferenceList props", this.props);
    const {
      outletEventItem,
      optOutIsSelected,
      selectedQty,
      selectedPriceLevelId,
      displayFees,
      onlyPreferenceIsVisible,
      showPreferenceTitleOnly,
      showBorder,
      status,
      showTotals,

      ConfirmedPriceLevelID,
      //ConfirmedQuantity,
    } = this.props;
    if (!outletEventItem) {
      return null;
    }
    // if(optOutIsSelected && showOptOutLevels==false){
    //   return null
    // }
    let prefCounter = 1;
    let qtyLimit = 0;
    let priceLevelIndexSelected = 0;
    const priceLevelsSorted =
        outletEventItem && Array.isArray(outletEventItem.PriceLevels)
            ? [...outletEventItem.PriceLevels].sort(
                (a, b) => Number(a.Sort) - Number(b.Sort),
            )
            : null;
    //console.log("2 priceLevelsSorted ", priceLevelsSorted);

    if (priceLevelsSorted) {
      priceLevelsSorted.map((pl, idx) => {
        if (qtyLimit < pl.PriceLevelLimit) {
          qtyLimit = pl.PriceLevelLimit;
        }
        if (Number(selectedPriceLevelId) === Number(pl.PriceLevelID)) {
          priceLevelIndexSelected = idx;
        }
        return null;
      });
    }

    if (priceLevelsSorted) {
      let grayOutOtherPriceLevelsNotMatchingThisIndex = 99999;
      return (
          <PreferenceListStyled hasBorder={showBorder}>
            {priceLevelsSorted.map((pl, idx) => {
              let adjustedQty =
                  pl.PriceLevelLimit > selectedQty
                      ? selectedQty
                      : pl.PriceLevelLimit;
              const qtyModulo =
                  parseInt(adjustedQty, 10) % parseInt(pl.PriceLevelMultiplier, 10);
              if (qtyModulo > 0) {
                adjustedQty = adjustedQty - qtyModulo;
              }

              let preferenceTitleText = pl.PriceLevelName + ' x ' + adjustedQty;
              let optedOut = false;
              //If this pl allows 'optout' AND the 'opt out' checkbox is selected
              if (pl.EnableOptOut && optOutIsSelected) {
                optedOut = true;
              }
              //If this pl index (sort value) comes before the drop down selected price level
              if (idx < priceLevelIndexSelected) {
                optedOut = true;
              }
              if (!optedOut && this.props.showPrefenceNumber) {
                preferenceTitleText =
                    toOrdinal(prefCounter++) +
                    ' Preference: ' +
                    preferenceTitleText;
              }
              if (optedOut && this.props.showOptOutLevels === false) {
                return null;
              }

              const priceLevelTitle =
                  pl.PriceLevelName +
                  ' ' +
                  formatCurrency(pl.PriceLevelValue) +
                  ' x ' +
                  adjustedQty;

              let totalFees = 0;
              let lastRow = idx == priceLevelsSorted.length - 1;
              let oddRow = idx % 2 == 0;
              let grayedOut = false;
              let showAwardedPriceLevel = false;
              let showDeclinePriceLevel = false;

              if (
                  ConfirmedPriceLevelID &&
                  Number(ConfirmedPriceLevelID) === Number(pl.PriceLevelID)
              ) {
                showAwardedPriceLevel = true;
                grayOutOtherPriceLevelsNotMatchingThisIndex = Number(idx);
              }

              if (
                  (status === 'STATUS_REQUEST_DENIED' ||
                      status === 'STATUS_REQUEST_DENIED_INVENTORY') &&
                  Number(prefCounter) > 1
              ) {
                showDeclinePriceLevel = true;
                grayOutOtherPriceLevelsNotMatchingThisIndex = Number(idx);
              }

              if (grayOutOtherPriceLevelsNotMatchingThisIndex != idx) {
                if (ConfirmedPriceLevelID) {
                  grayedOut = true;
                }
              }
              // console.log(
              //   "LineItemPreferenceList ConfirmedPriceLevelID",
              //   ConfirmedPriceLevelID
              // );
              // console.log(
              //   "LineItemPreferenceList Number(pl.PriceLevelID)",
              //   Number(pl.PriceLevelID)
              // );

              // console.log(
              //   "LineItemPreferenceList showAwardedPriceLevel",
              //   showAwardedPriceLevel
              // );
              // console.log(
              //   "LineItemPreferenceList showDeclinePriceLevel",
              //   showDeclinePriceLevel
              // );
              // console.log(
              //   "LineItemPreferenceList grayOutOtherPriceLevelsNotMatchingThisIndex",
              //   grayOutOtherPriceLevelsNotMatchingThisIndex
              // );
              // console.log("LineItemPreferenceList idx", idx);
              // console.log("LineItemPreferenceList grayedOut", grayedOut);

              if (showPreferenceTitleOnly) {
                return (
                    <PreferenceStyled
                        optedOut={optedOut}
                        grayedOut={grayedOut}
                        key={idx}
                    >
                      <div style={{ display: 'flex' }}>
                        <div style={{ flex: 1 }}>
                          {preferenceTitleText}
                          {showAwardedPriceLevel || showDeclinePriceLevel ? (
                              <span
                                  style={{
                                    color: showAwardedPriceLevel ? 'green' : '#ddc204',
                                  }}
                              >
                          {' - '}
                                {showAwardedPriceLevel ? 'CONFIRMED' : 'DECLINED'}
                        </span>
                          ) : null}
                          {status && (
                              <span
                                  style={{
                                    textTransform: 'uppercase',
                                    color: status === 'CONFIRMED' ? 'green' : 'red',
                                  }}
                              >
                          {' - '}
                                {status}
                        </span>
                          )}
                        </div>

                        {this.props.showPriceLevelTotal
                            ? formatCurrency(
                                adjustedQty * pl.PriceLevelValue + totalFees,
                            )
                            : null}
                      </div>
                    </PreferenceStyled>
                );
              }
              return (
                  <PreferenceStyled
                      grayedOut={grayedOut}
                      oddRow={oddRow}
                      lastRow={lastRow}
                      key={pl.PriceLevelID}
                      optedOut={optedOut}
                      onlyPreferenceIsVisible={onlyPreferenceIsVisible}
                  >
                    <PriceLevelHeader noBackground={true}>
                      {preferenceTitleText}
                      {showAwardedPriceLevel || showDeclinePriceLevel ? (
                          <span
                              style={{
                                color: showAwardedPriceLevel ? 'green' : '#ddc204',
                              }}
                          >
                      {' - '}
                            {showAwardedPriceLevel ? 'CONFIRMED' : 'DECLINED'}
                    </span>
                      ) : null}
                      {status && (
                          <span
                              style={{
                                textTransform: 'uppercase',
                                color: status === 'CONFIRMED' ? 'green' : 'red',
                              }}
                          >
                      {' - '}
                            {status}
                    </span>
                      )}
                    </PriceLevelHeader>

                    <LineItemRow
                        label={priceLevelTitle}
                        showTotal={this.props.showPriceLevelTotal}
                        total={adjustedQty * pl.PriceLevelValue}
                    />

                    {displayFees && pl.FeeGroups && Array.isArray(pl.FeeGroups)
                        ? pl.FeeGroups.sort((a, b) =>
                            a.Name.toUpperCase() > b.Name.toUpperCase() ? 1 : -1,
                        ).map((fee, idxw) => {
                          const feeGroupTotal = fee.Total
                              ? fee.Total
                              : fee.Value
                                  ? fee.Value
                                  : 0;
                          totalFees = totalFees + feeGroupTotal * adjustedQty;
                          return (
                              <FeeItemRow
                                  key={idxw}
                                  label={fee.Name}
                                  total={feeGroupTotal * adjustedQty}
                                  description={fee.Description}
                              />
                          );
                        })
                        : null}
                    {showTotals && (
                        <LineItemRow
                            bold
                            label="Total"
                            total={adjustedQty * pl.PriceLevelValue + totalFees}
                        />
                    )}
                  </PreferenceStyled>
              );
            })}
          </PreferenceListStyled>
      );
    }
    return null;
  }
}

LineItemPreferenceList.propTypes = {
  outletEventItem: PropTypes.object.isRequired,
  selectedQty: PropTypes.number.isRequired,
  optOutIsSelected: PropTypes.bool,
  displayFees: PropTypes.bool,
  showOptOutLevels: PropTypes.bool,
  showPrefenceNumber: PropTypes.bool,
  onlyPreferenceIsVisible: PropTypes.bool,
  showPreferenceTitleOnly: PropTypes.bool,
  showTotals: PropTypes.bool,
  showPriceLevelTotal: PropTypes.bool,
};

LineItemPreferenceList.defaultProps = {
  outletEventItem: null,
  optOutIsSelected: false,
  selectedQty: 0,
  displayFees: true,
  showOptOutLevels: true,
  showPrefenceNumber: true,
  onlyPreferenceIsVisible: false,
  showPreferenceTitleOnly: false,
  showBorder: true,
  showTotals: true,
  showPriceLevelTotal: true,
};

export default LineItemPreferenceList;
