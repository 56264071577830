import store from '../../store';
import { api } from '../api/actions';
import { getGuestListInvitation } from '../event/actions';
import {
  getUserAddressList,
  getUserFanRequests,
  getUserFanRequestSeriesSummary,
  getUserPaymentMethodList,
} from '../user/actions';
//import { connect, useSelector } from "react-redux";
import { goToUrl, isUserAuthenticated } from '../../utils/utilities';

export const setGuestListToken = (guestListToken) => {
  store.dispatch({
    type: 'SET_GUEST_LIST_TOKEN',
    guestListToken,
  });
};

export const setGuestListInvitation = (guestListInvitation) => {
  store.dispatch({
    type: 'SET_GUEST_LIST_INVITATION',
    guestListInvitation,
  });
};

export function forgotUserPassword(payload) {
  const response = api({
    action: 'FORGOT_USER_PASSWORD',
    payload,
  });
  response.then((res) => {
    store.dispatch({
      type: 'FORGOT_USER_PASSWORD',
      error: null,
    });
  }).catch((error) => {
  });
  return response;
}

export function resetUserPassword(payload) {
  const response = api({
    action: 'RESET_USER_PASSWORD',
    payload,
  });
  response.then((res) => {
    store.dispatch({
      type: 'RESET_USER_PASSWORD',
      error: null,
    });
  }).catch((error) => {
  });
  return response;
}

export function changeUserPassword(payload) {
  const response = api({
    action: 'CHANGE_USER_PASSWORD',
    payload,
  });
  response.then((res) => {
    store.dispatch({
      type: 'CHANGE_USER_PASSWORD',
      error: null,
    });
  }).catch((error) => {
  });
  return response;
}

export function loginUser(payload) {
  //payloadExample:
  //id: "lloyd+2@grok.works",
  //password: "password1!",

  const response = api({
    action: 'LOGIN_USER',
    payload,
  });
  response.then((session) => {
    //const session =  await checkSession({sessionID});
    const UserID = session && session.UserID;
    handleUserSession(session);
    // store.dispatch({
    //   type: "SET_USER_SESSION",
    //   data: session,
    // });
    // store.dispatch({
    //   type: "RESET_FAILED_LOGIN_ATTEMPTS",
    // });

    // store.dispatch({
    //   type: "USER_IS_AUTHENTICATED",
    //   userIsAuthenticated: UserID ? true : false,
    // });

    //console.log("init login UserID", UserID);
    if (UserID && Number.isInteger(UserID)) {
      const state = store.getState();
      const authData = state.auth;

      const cookieArray = document.cookie && document.cookie.split('; ');
      let cookieFound;
      if (Array.isArray(cookieArray)) {
        let cookieRecord = cookieArray.find((row) => row.startsWith('glInvite'));
        if (cookieRecord) {
          let cookieParts = cookieRecord.split('=');
          if (Array.isArray(cookieParts) && cookieParts.length === 2) {
            if (cookieParts[1].startsWith(window.location.hostname)) {
              cookieFound = true;
            }
          }
        }
      }

      if (authData && (authData.guestListToken || cookieFound)) {
        getGuestListInvitation({
          InvitationToken: authData.guestListToken,
        });
      }
      getUserFanRequestSeriesSummary();
      getUserFanRequests(UserID);
      getUserAddressList({ UserID });
      getUserPaymentMethodList({ UserID });
    }
  }).catch((error) => {
    store.dispatch({
      type: 'SET_USER_SESSION',
      data: null,
    });
    store.dispatch({
      type: 'INCREASE_FAILED_LOGIN_ATTEMPTS',
    });
  });
  return response;
}

export function resetUserState() {
  // document.cookie =
  //   process.env.REACT_APP_COOKIE +
  //   "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

  // const response = api({
  //   action: "CHECK_SESSION",
  //   endpoint: `user/checksession?destroy=1`,
  // });

  store.dispatch({
    type: 'SET_USER_ORDER',
    data: null,
  });
  store.dispatch({
    type: 'SET_USER_ORDER_LIST',
    data: [],
  });
  store.dispatch({
    type: 'SET_USER_ORDER_SUMMARY_LIST',
    data: [],
  });
  store.dispatch({
    type: 'SET_USER_PAYMENT_METHOD_LIST',
    data: [],
  });
  store.dispatch({
    type: 'SET_USER_ADDRESS_LIST',
    data: [],
  });
  store.dispatch({
    type: 'SET_USER_FAN_REQUESTS',
    data: [],
  });
  store.dispatch({
    type: 'SET_USER_FAN_REQUEST_SERIES_SUMMARY',
    data: [],
  });
  store.dispatch({
    type: 'SET_USER_FAN_REQUEST_SERIES_PRIORITY',
  });
  store.dispatch({
    type: 'SET_USER_PAYMENT_METHOD_LIST',
    data: [],
  });
  store.dispatch({
    type: 'DELETE_INSTANT_REQUEST',
  });
  store.dispatch({
    type: 'RESET_GUEST_REQUEST_CART',
  });
  store.dispatch({
    type: 'RESET_FAN_REQUEST_CART',
  });
  store.dispatch({
    type: 'SET_GUEST_LIST_INVITATION',
    guestListInvitation: null,
  });
  store.dispatch({
    type: 'SET_GUEST_LIST_TOKEN',
    guestListToken: null,
  });
}

export function logoutUser() {
  resetUserState();
  store.dispatch({
    type: 'USER_IS_AUTHENTICATED',
    userIsAuthenticated: false,
  });
  store.dispatch({
    type: 'SET_USER_SESSION',
    data: null,
  });
  checkSession({ destroy: 1 });
  goToUrl('/');

  // document.cookie =
  //   process.env.REACT_APP_COOKIE +
  //   "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  //document.cookie = "sid=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
}

export function handleUserSession(session) {
  //console.log("handleUserSession", session);
  // const {
  //   UserID, //"invalid", "guest", 683
  //   expires, //1618346720
  //   OutletID, // 207
  //   LastName,
  //   FirstName,
  //   sessionID, //"50e47f13-1928-440a-89c3-0fcdbe8721fb"
  //   EmailAddress,
  //   OrganizationID, //1
  //   outletPasswordRequired, boolean
  //   secondsToExpire, //3600
  // } = session;
  let userIsAuthenticated = isUserAuthenticated(session);
  let sessionTimeoutWarningIsVisible =
      userIsAuthenticated && session && session.secondsToExpire < 180;

  if (!session || !session.UserID || session.UserID === 'invalid') {
    resetUserState();
  }

  store.dispatch({
    type: 'SET_USER_SESSION',
    data: session,
  });

  store.dispatch({
    type: 'USER_IS_AUTHENTICATED',
    userIsAuthenticated,
  });
  store.dispatch({
    type: 'SESSION_TIMEOUT_WARNING_IS_VISIBLE',
    isVisible: sessionTimeoutWarningIsVisible,
  });
}

export function initSession() {
  // let endpoint = `user/initsession?sessionId=##sessioncookie##`;
  const response = api({
    action: 'INIT_SESSION',
  });
  response.then((session) => {
    //const session =  await checkSession({sessionID});
    handleUserSession(session);
  }).catch((error) => {
    handleUserSession(null);
  });
  return response;
}

export async function checkSession(payload = {}) {
  // pass 1  into url param 'extend' to have it extend the session by 15 minutes (will only add if the session has less than 10 minutes left)

  const destroy = payload && payload.destroy ? 1 : 0;
  const extendSession = payload && payload.extendSession ? 1 : 0;
  let endpoint = `user/checksession?extend=${extendSession}`;

  if (destroy) {
    endpoint = `user/checksession?destroy=1`;
  }
  const response = api({
    action: 'CHECK_SESSION',
    endpoint,
  });

  response.then((session) => {
    handleUserSession(session);
  }).catch((error) => {
    handleUserSession(null);
    resetUserState();
  });

  return response;
}

export function renewUserSession() {
  checkSession({ extendSession: 1 });
}
