import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const ContainerStyled = styled.div`
  display: none;
  @media (max-width: ${(props) =>
    props.breakpoint
        ? props.breakpoint
        : props.theme.BREAKPOINT_MOBILE_WIDTH}px) {
    display: block;
  }
`;

const HideOnDesktop = ({ breakpoint, children }) => {
  // //console.log("HideOnDesktop", breakpoint);
  // //console.log("HideOnDesktop children", children);
  return <ContainerStyled breakpoint={breakpoint}>{children}</ContainerStyled>;
};

HideOnDesktop.propTypes = {
  breakpoint: PropTypes.number,
};

export default HideOnDesktop;
