import PropTypes from 'prop-types';
import React from 'react';

import Error from '../../utils/Error';
import { FormActions, TextInput } from '../../utils/Form';
import { addToast } from '../toast/actions';
import { editUserProfile } from './actions';

export class UserProfileUpdate extends React.Component {
  state = {
    firstName: this.props.FirstName,
    lastName: this.props.LastName,
    //birthday: "",
    formErrors: {},
    editProfileSuccess: false,
  };

  handleSubmit = () => {
    const {
      firstName,
      lastName,
      //birthday
    } = this.state;
    const userId = this.props.UserID;
    const formErrors = {};

    if (!firstName) {
      formErrors['firstName'] = 'First name required';
    }
    if (!lastName) {
      formErrors['lastName'] = 'Last name required';
    }
    if (!userId) {
      formErrors['userId'] = 'Userid  required';
    }
    // if(!birthday){
    //   formErrors['birthday'] = 'Birthday required';
    // }

    if (Object.keys(formErrors).length) {
      //console.log("profile not changed");
      this.setState({ formErrors, loading: false, error: null });
      return false;
    } else {
      this.setState({ formErrors: {}, loading: true, error: null });

      const req = {
        UserID: userId,
        FirstName: firstName,
        LastName: lastName,
        //Birthday: birthday,
      };

      editUserProfile(req).then((res) => {
        this.setState({ editProfileSuccess: true, loading: false });

        addToast({
          title: 'Success',
          description: 'Your account profile has been updated',
          type: 'success',
        });
      }).catch((error) => this.setState({ loading: false, error }));
    }
  };

  handleInput = (name) => (event) => {
    this.setState({ [name]: event.target.value });
    const {
      //birthday
      firstName,
      lastName,
      formErrors,
    } = this.state;
    if (firstName) {
      formErrors['firstName'] = '';
    }
    if (lastName) {
      formErrors['lastName'] = '';
    }
    // if (birthday) {
    //   formErrors["birthday"] = "";
    // }
  };

  render() {
    //console.log("Profile Edit props =>", this.props);

    const { onCancel, UserID, EmailAddress, ExternalUserID } = this.props;
    const {
      formErrors,
      editProfileSuccess,
      firstName,
      lastName,
      loading,
      error,
    } = this.state;
    if (!UserID) return null;

    return (
        <form
            autoComplete="off"
            onSubmit={(e) => {
              e.preventDefault();
            }}
        >
          {EmailAddress && (
              <TextInput
                  name="EmailAddress"
                  label="Email Address"
                  disabled={true}
                  fullWidth
                  value={EmailAddress}
                  onChange={() => false}
              />
              // <div
              //   style={{ fontSize: "1.8rem", opacity: "0.8", margin: "0 0 2rem 0" }}
              // >
              //   {EmailAddress}
              // </div>
          )}
          <TextInput
              name="firstName"
              label="First Name"
              error={formErrors['firstName']}
              fullWidth
              value={firstName}
              disabled={ExternalUserID ? true : false}
              onChange={this.handleInput('firstName')}
          />

          <TextInput
              name="lastName"
              label="Last Name"
              error={formErrors['lastName']}
              fullWidth
              value={lastName}
              disabled={ExternalUserID ? true : false}
              onChange={this.handleInput('lastName')}
          />
          {/*
            <TextInput
              name="birthday"
              label="Birthday"
              error={formErrors["birthday"]}
              fullWidth
              value={birthday}
              onChange={this.handleInput("birthday")}
            /> */}

          {error && (
              <div style={{ margin: '20px 0 10px 0' }}>
                <Error> {error} </Error>
              </div>
          )}
          {!ExternalUserID && (
              <FormActions
                  submitText={loading ? 'Loading' : 'Save'}
                  handleSubmit={() => this.handleSubmit()}
                  handleCancel={onCancel}
                  loading={loading}
                  fullWidth
              />
          )}
        </form>
    );
  }
}

UserProfileUpdate.propTypes = {
  FirstName: PropTypes.string,
  LastName: PropTypes.string,
  onCancel: PropTypes.func,
  onSuccess: PropTypes.func,
};

UserProfileUpdate.defaultProps = {
  FirstName: '',
  LastName: '',
  onCancel: null,
  onSuccess: null,
  ExternalUserID: null,
};

export default UserProfileUpdate;
