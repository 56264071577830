import styled from 'styled-components';
import React, { useState } from 'react';
import { renderToString } from 'react-dom/server';
import { useSelector } from 'react-redux';

import Button from '../../utils/Button';
import Loading from '../../utils/Loading';
import Modal from '../../utils/Modal';
import OutletEventSummary from '../outletEvent/OutletEventSummary';
import SeatDetails from '../cart/SeatDetails';
import Typography from '../../utils/Typography';
import { LineItemRow, OutletEventItemHeader, PriceLevelHeader } from '../cart/LineItemSummary';
import { formatCurrency, formatDate } from '../../utils/utilities';
import { getUserOrderSummaryList } from '../user/actions';
import { setOEIRefundRequestFlag, setOrderItemUpgradeFlag } from './actions';

const LineItemContainer = styled.div`
  padding: 1rem;
  border: solid 1px ${(props) => props.theme.PRIMARY_GRAY}80;
`;

function OrderDetails(props) {
  console.log('Order Details props => ', props);
  const {
    order,
    eventID,
    userOrderSummaryList,
    upgradeItemIsVisible = true,
  } = props;
  const [upgradeIsLoading, setUpgradeIsLoading] = useState(false);
  const outlet = useSelector((state) => state.outlet);
  const [modal, setModal] = useState({
    title: '',
    content: '',
    constant: null,
    isVisible: false,
  });
  const handleClickConfirmUpgradeLineItem = (payload) => {
    setUpgradeIsLoading(true);

    console.log('handleClickConfirmUpgradeLineItem payload', payload);
    setOrderItemUpgradeFlag(payload).then((res) => {
      console.log('handleClickConfirmUpgradeLineItem res', res);
      getUserOrderSummaryList();
      setUpgradeIsLoading(false);
      setModal({ isVisible: false, request: null });
    }).catch((error) => {
      console.log('handleClickConfirmUpgradeLineItem error', error);
      setUpgradeIsLoading(false);
    });
  };

  const handleClickConfirmRequestRefund = (payload) => {
    setOEIRefundRequestFlag(payload).then(setModal({ isVisible: false, request: null }));
  };
  const onClickRequestRefund = (priceLevels, customerOrderID, value, oeiName, eventData) => {
    //Modal stuff
    const Event = eventData.Event;
    const venueName = Event.Venue && Event.Venue.VenueName;
    const city = Event.Venue && Event.Venue.City;
    const state = Event.Venue && Event.Venue.State;
    const showDate = Event.ShowDate;
    const date = formatDate(showDate, {
      weekday: 'long',
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    });
    let artistName = '';

    if (Event.ArtistTable && Event.ArtistTable.ArtistName) {
      artistName = Event.ArtistTable.ArtistName;
    }

    const request = {
      PriceLevelIDs: priceLevels.map(pl => pl.PriceLevelID),
      CustomerOrderID: customerOrderID,
      RequestValue: value,
    };

    setModal({
      content: value ?
          renderToString(<div>You are about to request a refund
            for <b>{`${artistName} - ${date} - ${venueName} – ${city} ${state} – ${oeiName}`}</b>, are you sure you want
            to?</div>) : 'You are indicating that you want to undo your refund request',
      title: 'Refund Request',
      constant: 'REFUND_REQUEST',
      isVisible: true,
      request,
    });

    let payload = {
      PriceLevelIDs: priceLevels.map(pl => pl.PriceLevelID),
      CustomerOrderID: customerOrderID,
      RequestValue: value,
    };

  };

  const handleClickUpgradeLineItem = ({
                                        order,
                                        OutletEventID,
                                        OutletEventItemID,
                                      }) => {
    let upgradeRequestAlreadyExistsOnTour = false;
    let tourID;
    console.log('handleClickUpgradeLineItem order', order);
    console.log(
        'handleClickUpgradeLineItem OutletEventItemID',
        OutletEventItemID,
    );
    console.log(
        'handleClickUpgradeLineItem userOrderSummaryList',
        userOrderSummaryList,
    );
    if (order && order.CustomerOrderID && OutletEventID) {
      if (OutletEventItemID && Array.isArray(userOrderSummaryList)) {
        const orderSummary = userOrderSummaryList.find(
            (o) => o.CustomerOrderID === order.CustomerOrderID,
        );
        tourID = orderSummary && orderSummary.TourID;

        if (tourID) {
          userOrderSummaryList.map((o) => {
            if (o.TourID && o.TourID === tourID && Array.isArray(o.Events)) {
              console.log(
                  'handleClickUpgradeLineItem order found in summary',
                  o,
              );

              const foundExisiting = o.Events.some(
                  (e) => e.UpgradeRequestOutletEventItemID,
              );
              if (foundExisiting) {
                upgradeRequestAlreadyExistsOnTour = true;
              }

              // o.Events.map((e) => {
              //   if (
              //     e.UpgradeRequestOutletEventItemID &&
              //     e.UpgradeRequestOutletEventItemID === OutletEventItemID
              //   ) {
              //     upgradeRequestAlreadyExistsOnTour = true;
              //   }
              // });
            }
          });
        }
      }
      const request = {
        OutletEventID,
        CustomerOrderID: order.CustomerOrderID,
        OutletEventItemID, // Set OutletEventItemId to null if you want to remove it
      };
      console.log('handleClickUpgradeLineItem tourID', tourID);
      console.log('handleClickUpgradeLineItem request', request);
      if (upgradeRequestAlreadyExistsOnTour) {
        setModal({
          content:
              'You have requested an upgrade on another event already, do you wish to change your selection?',
          title: 'Upgrade Request',
          constant: 'UPGRADE_REQUEST',
          isVisible: true,
          request,
        });
      } else {
        handleClickConfirmUpgradeLineItem(request);
      }
    }

  };

  if (!order || !order.LineItems || !order.CustomerOrderID) {
    return null;
  }
  const { TotalSummary, OutletDeliveryMethod, OutletEvent } = order;

  //const SuppressSeatInfo = Boolean(OutletEvent && OutletEvent.SuppressSeatInfo);

  // seatInfo:{
  //   manifest:[{
  //     RowName: "RB",
  //     SeatName: 47,
  //     SectionName: "RES1",
  //     TicketID: 386911,
  //     XRow: 1,
  //     XSeat: 47,
  //     XSection: 2,
  //   }],
  //   priceLevel:{
  //     itemName: "Concert Tickets"
  //     priceLevelName: "Reserved"
  //   }
  // }],

  const SAMPLE_DATA_CustomerOrderLineItems = [
    {
      Barcode: null,
      ChildHoldType: { ChildHoldTypeID: 4446, Name: '6-AVAIL' },
      ChildSellType: {
        ChildSellTypeID: 1775,
        ParentSellTypeID: 895,
        Character: 'L',
        CharacterAlias: null,
        Name: 'L-SOLD',
      },
      RowName: 'RB',
      SeatName: 39,
      SectionName: 'RES1',
      TicketID: 386903,
      XRow: 1,
      XSeat: 39,
      XSection: 2,
    },
    {
      Barcode: null,
      ChildHoldType: { ChildHoldTypeID: 4446, Name: '6-AVAIL' },
      ChildSellType: {
        ChildSellTypeID: 1775,
        ParentSellTypeID: 895,
        Character: 'L',
        CharacterAlias: null,
        Name: 'L-SOLD',
      },
      RowName: 'RB',
      SeatName: 40,
      SectionName: 'RES1',
      TicketID: 386904,
      XRow: 1,
      XSeat: 40,
      XSection: 2,
    },
    {
      Barcode: null,
      ChildHoldType: { ChildHoldTypeID: 4446, Name: '6-AVAIL' },
      ChildSellType: {
        ChildSellTypeID: 1775,
        ParentSellTypeID: 895,
        Character: 'L',
        CharacterAlias: null,
        Name: 'L-SOLD',
      },
      RowName: 'SB',
      SeatName: 20,
      SectionName: 'RES2',
      TicketID: 386905,
      XRow: 2,
      XSeat: 20,
      XSection: 3,
    },
  ];

  let eventData = order.LineItems.find(
      (x) => Number(eventID) === Number(x.OutletEventID),
  );

  const lineItems = eventData ? [eventData] : [...order.LineItems];

  return (
      <div>
        {eventData && <OutletEventSummary outletEvent={eventData}/>}

        {lineItems.map((li, idx) => {
          const { OutletEventID, UpgradeRequestOutletEventItemID } = li;
          return (
              <div key={idx}>
                {Array.isArray(li.OutletEventItems)
                    ? li.OutletEventItems.map((oei) => {
                      let outletEventItemUpgradeIsVisible =
                          OutletEventID &&
                          upgradeItemIsVisible &&
                          oei &&
                          oei.AllowUpgrade;
                      //Start RLMTS-2880
                      if (
                          outletEventItemUpgradeIsVisible === true &&
                          Array.isArray(oei.PriceLevels)
                      ) {
                        oei.PriceLevels.map((pl) => {
                          if (Number(pl.Sort) === 1) {
                            outletEventItemUpgradeIsVisible = false;
                          }
                        });
                      }
                      //End RLMTS-2880

                      const upgradeIsCurrentlyRequested = Boolean(
                          Number(UpgradeRequestOutletEventItemID) ===
                          Number(oei.OutletEventItemID),
                      );

                      // console.log("OrderDetails ", {
                      //   li,
                      //   oei,
                      //   upgradeItemIsVisible,
                      //   outletEventItemUpgradeIsVisible,
                      //   upgradeIsCurrentlyRequested,
                      // });

                      let relevantLineItems = [];

                      oei.PriceLevels.forEach(priceLevel => {
                        relevantLineItems = relevantLineItems.concat(priceLevel.CustomerOrderLineItems);
                      });
                      let refundRequested = relevantLineItems.every(rli => rli.RefundRequested == true);
                      return (
                          <div key={oei.OutletEventItemID}>
                            <OutletEventItemHeader oei={oei} allowRefund={oei.AllowRefund}
                                                   refundRequested={refundRequested}
                                                   refundWindowOpen={li.Event.RefundWindowOpen}
                                                   onClickRequestRefund={() => onClickRequestRefund(oei.PriceLevels,
                                                       order.CustomerOrderID, !refundRequested,
                                                       oei.OutletEventItemName || oei.Name, eventData)}/>

                            {outletEventItemUpgradeIsVisible && (
                                <LineItemContainer>
                                  {upgradeIsLoading ? (
                                      <Loading
                                          width={16}
                                          height={16}
                                          message={null}
                                          padding={0}
                                      />
                                  ) : (
                                      <Typography
                                          tag="a"
                                          style={{
                                            color: upgradeIsCurrentlyRequested
                                                ? 'red'
                                                : null,
                                          }}
                                          onClick={() =>
                                              handleClickUpgradeLineItem({
                                                order,
                                                OutletEventID,
                                                OutletEventItemID: upgradeIsCurrentlyRequested
                                                    ? null
                                                    : oei.OutletEventItemID,
                                              })
                                          }
                                      >
                                        {upgradeIsCurrentlyRequested
                                            ? 'Remove '
                                            : 'Request '}{' '}
                                        Upgrade
                                      </Typography>
                                  )}
                                </LineItemContainer>
                            )}
                            {Array.isArray(oei.PriceLevels)
                                ? oei.PriceLevels.map((pl) => {
                                  let manifest = [];

                                  //Start RLMTS-2921 new totals for orders that contain line items with different qty, unit pice
                                  const lineItemsByUnitPrice = [];
                                  if (Array.isArray(pl.CustomerOrderLineItems)) {
                                    pl.CustomerOrderLineItems.map((i) => {
                                      const {
                                        Canceled,
                                        Quantity,
                                        UnitPrice,
                                        TicketManifest,
                                      } = i;

                                      if (Quantity && Boolean(Canceled) === false) {
                                        const index = lineItemsByUnitPrice.findIndex(
                                            (j) => j.UnitPrice === UnitPrice,
                                        );

                                        if (index > -1) {
                                          const adjustedQty = (lineItemsByUnitPrice[
                                              index
                                              ].Quantity += Quantity);
                                          lineItemsByUnitPrice[index] = {
                                            UnitPrice,
                                            Quantity: adjustedQty,
                                          };
                                        } else {
                                          lineItemsByUnitPrice.push({
                                            UnitPrice,
                                            Quantity,
                                          });
                                        }
                                        if (TicketManifest) {
                                          manifest.push(TicketManifest);
                                        }
                                      }
                                    });
                                  }
                                  //End RLMTS-2921
                                  console.log(
                                      'lineItemsByUnitPrice ',
                                      lineItemsByUnitPrice,
                                  );
                                  //manifest = SAMPLE_DATA_CustomerOrderLineItems; //for testing
                                  return (
                                      <div key={pl.PriceLevelID}>
                                        <PriceLevelHeader>
                                          <div style={{ display: 'flex' }}>
                                            <div style={{ flex: 1 }}>
                                              {pl.PriceLevelName}
                                            </div>
                                          </div>
                                        </PriceLevelHeader>
                                        {lineItemsByUnitPrice.map((libup, libupidx) => (
                                            <LineItemRow
                                                key={libupidx}
                                                label={
                                                    pl.PriceLevelName +
                                                    ' x' +
                                                    libup.Quantity +
                                                    ' @ ' +
                                                    formatCurrency(libup.UnitPrice)
                                                }
                                                total={libup.Quantity * libup.UnitPrice}
                                            />
                                        ))}

                                        {manifest && manifest.length ? (
                                            <SeatDetails manifest={manifest}/>
                                        ) : null}
                                      </div>
                                  );
                                })
                                : null}
                          </div>
                      );
                    })
                    : 'no event items'}
              </div>
          );
        })}

        {/* <LineItemRow bold label="Fees Total" total={TotalSummary.fees || 0} />

      <LineItemRow
        bold
        total={TotalSummary.shipping || 0}
        label={
          OutletDeliveryMethod && OutletDeliveryMethod.OverrideName
            ? OutletDeliveryMethod.OverrideName
            : "Shipping"
        }
      />

      <LineItemRow bold label="Grand Total" total={TotalSummary.total || 0} /> */}

        <Modal
            title={modal.title}
            open={modal.isVisible}
            onClose={() => setModal({ isVisible: false, request: null })}
        >
          {modal.constant === 'UPGRADE_REQUEST' && (
              <>
                <div
                    dangerouslySetInnerHTML={{
                      __html: modal.content,
                    }}
                    style={{ margin: '0 0 2rem 0' }}
                />
                <div>
                  <Button
                      onClick={() => handleClickConfirmUpgradeLineItem(modal.request)}
                      loading={upgradeIsLoading}
                      style={{ margin: '0 2rem 0 0' }}
                  >
                    Yes, Continue
                  </Button>
                  {!upgradeIsLoading && (
                      <Button
                          loading={upgradeIsLoading}
                          onClick={() => {
                            setModal({ isVisible: false, request: null });
                          }}
                          secondary
                      >
                        Nevermind
                      </Button>
                  )}
                </div>
              </>
          )}
          {modal.constant === 'REFUND_REQUEST' && (
              <>
                <div
                    dangerouslySetInnerHTML={{
                      __html: modal.content,
                    }}
                    style={{ margin: '0 0 2rem 0' }}
                />
                <div>
                  <Button
                      onClick={() => handleClickConfirmRequestRefund(modal.request)}
                      loading={upgradeIsLoading}
                      style={{ margin: '0 2rem 0 0' }}
                  >
                    {modal.request.RequestValue ? 'Request Refund' : 'Yes undo Refund Request'}
                  </Button>
                  {!upgradeIsLoading && (
                      <Button
                          loading={upgradeIsLoading}
                          onClick={() => {
                            setModal({ isVisible: false, request: null });
                          }}
                          secondary
                      >
                        Nevermind
                      </Button>
                  )}
                </div>
              </>
          )}
        </Modal>
      </div>
  );
}

export default OrderDetails;
