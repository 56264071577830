import styled from 'styled-components';

// Predefined spacing values
const SPACE_SIZE_MAP = {
  'sm': 4,
  'md': 8,
  'lg': 12,
  'xl': 16,
  '2xl': 20,
  '3xl': 24,
};

// use 'space' prop or 'size' prop value for spacing
function getHeight({ axis, size, space }) {
  const height = SPACE_SIZE_MAP[space] ? SPACE_SIZE_MAP[space] : size;
  return axis === 'horizontal' ? 1 : height;
}

// use 'space' prop or 'size' prop value for spacing
function getWidth({ axis, size, space }) {
  const width = SPACE_SIZE_MAP[space] ? SPACE_SIZE_MAP[space] : size;
  return axis === 'vertical' ? 1 : width;
}

/**
 * wrapper around a span element used to add spacing around components.
 * more details on this implementation: https://www.joshwcomeau.com/react/modern-spacer-gif/#the-code
 * @component
 * @param {string} space a predefined string value for the amount of space. ie: sm, md, lg, xl, 2xl, 3xl.
 * @param {number} size a integer value in pixels. ie: 10 would translate to 10px.
 * @return {React.ReactElement}
 */
const Spacer = styled.span`
  display: block;
  width: ${getWidth}px;
  min-width: ${getWidth}px;
  height: ${getHeight}px;
  min-height: ${getHeight}px;
`;

export default Spacer;
