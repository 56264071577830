import React from 'react';
import styled from 'styled-components';

const RootStyled = styled.div`
  display: grid;
  grid-template-columns: none;
  gap: 2rem;
  @media (min-width: ${(props) => props.theme.BREAKPOINT_MOBILE_WIDTH}px) {
    grid-template-columns: repeat(${(props) => props.columns}, 1fr);
    gap: 2rem;
  }
`;
const ImgStyled = styled.img`
  width: 100%;
  display: block;
  cursor: pointer;
`;
export const PromoImageList = ({ loading, imageList, ...props }) => {
  if (loading || !imageList || !Array.isArray(imageList)) return null;
  return (
      <RootStyled columns={imageList.length} {...props}>
        {imageList.map((p, index) => {
          return (
              <ImgStyled
                  key={index}
                  src={p.url}
                  alt={p.title}
                  onClick={() => window.open(p.link, '_blank')}
              ></ImgStyled>
          );
        })}{' '}
      </RootStyled>
  );
};
export default PromoImageList;
