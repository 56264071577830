import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import Typography from '../../utils/Typography';

const RootContainer = styled.div``;
const ItemBody = styled.div`
  background-color: ${(props) => props.theme.PRIMARY_GRAY}30;
  display: flex;
`;
const ItemDetailContainer = styled.div`
  text-align: center;
  padding: 1.8rem;
  flex: 1;
  border-right: ${(props) => (props.border ? 'solid 1px #dbdbdb' : 'none')};
  //border-bottom: solid 1px ${(props) => props.theme.PRIMARY_GRAY}80;
`;
const ItemDetailHeader = styled.div`
  font-size: 1.6rem;
  padding: 0 0 0.5rem 0;
`;
const ItemDetail = styled.div`
  color: ${(props) => props.theme.PRIMARY_BUTTON_BACKGROUND};
  font-weight: 800;
`;

const SeatDetails = (props = {}) => {
  console.log('SeatDetails props = ', props);

  const { manifest } = props;

  if (manifest && Array.isArray(manifest) && manifest.length) {
    let sections = [];
    let rows = [];
    let seats = [];

    manifest.map((i) => {
      const sectionIndex = sections.findIndex((s) => s.XSection === i.XSection);
      const newSection = {
        XSection: i.XSection,
        SectionName: i.SectionName,
      };
      const newRow = {
        SectionName: i.SectionName,
        XSection: i.XSection,
        XRow: i.XRow,
        RowName: i.RowName,
      };
      const newSeat = {
        XSection: i.XSection,
        XRow: i.XRow,
        XSeat: i.XSeat,
        TicketID: i.TicketID,
        SeatName: i.SeatName,
      };

      // Add seat when visibility allows
      if (i.XSeat) {
        seats.push(newSeat);
      }

      if (sectionIndex === -1) {
        sections.push(newSection);
        rows.push(newRow);
      } else {
        const rowAlreadyExists = rows.some(
            (r) => r.XSection === i.XSection && r.XRow === i.XRow,
        );
        if (rowAlreadyExists === false) {
          rows.push(newRow);
        }
      }
      return null;
    });

    sections.sort((a, b) => a.XSection - b.XSection);
    rows.sort((a, b) => a.XSection - b.XSection);
    rows.sort((a, b) => a.XRow - b.XRow);
    seats.sort((a, b) => a.XSection - b.XSection);
    seats.sort((a, b) => a.XRow - b.XRow);
    seats.sort((a, b) => a.XSeat - b.XSeat);

    console.log('SeatDetails sections = ', sections);
    console.log('SeatDetails rows = ', rows);
    console.log('SeatDetails seats = ', seats);

    return (
        <div>
          <RootContainer>
            <ItemBody>
              <ItemDetailContainer border={true}>
                <ItemDetailHeader>
                  <Typography>SECTION</Typography>
                </ItemDetailHeader>
                {rows.map((s, sectionIdx) => {
                  return (
                      <ItemDetail key={sectionIdx}>
                        <Typography>{s.SectionName}</Typography>
                      </ItemDetail>
                  );
                })}
              </ItemDetailContainer>
              <ItemDetailContainer border={(seats.length > 0)}>
                <ItemDetailHeader>
                  <Typography>ROW</Typography>
                </ItemDetailHeader>
                {rows.map((r, rowIdx) => {
                  return (
                      <ItemDetail key={rowIdx}>
                        <Typography>{r.RowName}</Typography>
                      </ItemDetail>
                  );
                })}
              </ItemDetailContainer>
              {seats.length > 0 && <ItemDetailContainer>
                <ItemDetailHeader>
                  <Typography>SEATS</Typography>
                </ItemDetailHeader>
                {rows.map((r, rowIdx) => {
                  const seatsFilteredByRow = seats.filter(
                      (s) => s.XRow === r.XRow,
                  );
                  console.log(
                      'SeatDetails seatsFilteredByRow = ',
                      seatsFilteredByRow,
                  );
                  const totalSeatsInRow = seatsFilteredByRow.length;
                  return (
                      <ItemDetail key={rowIdx}>
                        <Typography>
                          {totalSeatsInRow > 1 ? (
                              <span>
                          {seatsFilteredByRow[0].SeatName} -{' '}
                                {
                                  seatsFilteredByRow[seatsFilteredByRow.length - 1].SeatName
                                }
                        </span>
                          ) : (
                              <span>{seatsFilteredByRow[0].SeatName}</span>
                          )}
                        </Typography>
                      </ItemDetail>
                  );
                })}
              </ItemDetailContainer>}
            </ItemBody>
          </RootContainer>
        </div>
    );
  } else {
    return null;
  }
};
SeatDetails.propTypes = {
  manifest: PropTypes.array,
};

SeatDetails.defaultProps = {
  manifest: null,
};

export default SeatDetails;
