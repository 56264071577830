
export const orderHeaderIconSize = 18;

/**
 * Create a friendly data structure for easy consumption of table displays
 * @param {object[]} order order data typically returned from the api - customerorder/getlist or get
 * @param {string|number} expandedOutletEventId outletEventId user clicks to expand for more info
 * @param {string|number} expandedOrderId orderId user clicks to expand for more info
 * @param {boolean} isMobileDelivery is the order using mobile delivery
 * @return {array} list of event data with only top level keys ready for table display
 */
export function buildEventListDataForTableDisplay({ order, isMobileDelivery, expandedOutletEventId, expandedOrderId }) {
  const { Status, Events, OrderID, OrderSummary, CustomerOrderEvents } = order;
  if (Array.isArray(Events)) {
    return Events.map((eventData) => {
      const {
        Venue,
        ShowDate,
        EventName,
        ArtistName,
        OutletEventID,
        RefundAlertMessage,
        UpgradeRequestOutletEventItemID,
      } = eventData;
      const statusText = UpgradeRequestOutletEventItemID ? `${Status}<br /><span>*upgrade requested</span>` : Status;
      const eventTableInfo = Array.isArray(CustomerOrderEvents) ? CustomerOrderEvents.find(
        (x) => x.OutletEventID === OutletEventID)?.OutletEvent?.EventTable : null;
      const isExpanded = OrderID === expandedOrderId && Number(OutletEventID) === Number(expandedOutletEventId);
      let eventTotal = 0;
      if (Array.isArray(OrderSummary?.Events)) {
        const eventSummary = OrderSummary.Events.find((x) => x.OutletEventID === OutletEventID);
        eventTotal += eventSummary?.total;
      }
      return {
        isMobileDelivery,
        OutletEventID,
        refundAlertMessage: RefundAlertMessage || eventTableInfo?.RefundAlertMessage,
        refundRequestWindowOpenText: eventTableInfo?.RefundRequestWindowOpenText,
        refundRequestWindowClosedText: eventTableInfo?.RefundRequestWindowClosedText,
        eventLabel: EventName || ArtistName,
        showDate: ShowDate,
        total: eventTotal,
        status: statusText,
        location: Venue?.City + ', ' + Venue?.State,
        isExpanded,
      };
    });
  } else {
    return [];
  }
}
/**
 * Sort the orders as well as their events based on event show dates - GT-4159 for more sorting details
 * Function also addes two new keys to the order object to help with filtering orders on the hisory view:
 *  1) 'futureEventShowDates' key is an array of all future show dates
 *  2) 'eventShowDateYears' key is an array of all unique event date years in string format. ie ['2023','2022', '2021']
 * @param {object[]} orderList - List of orders typically returned from the api - customerorder/getlist
 * @return {array} sorted array of orders
 */
export function sortOrdersByEventShowDates({ orderList }) {
  if (Array.isArray(orderList)) {
    return orderList.map((orderData) => {
      const { Events } = orderData;
      const futureEventShowDates = [];
      const allShowDateYears = [];
      if (Array.isArray(Events) && Events.length) {
        Events.forEach((eventInfo) => {
          const { ShowDate } = eventInfo;
          const todayDateObj = new Date();
          const eventShowDateObj = new Date(ShowDate);
          if (eventShowDateObj >= todayDateObj) {
            futureEventShowDates.push(ShowDate);
          }
          allShowDateYears.push(eventShowDateObj.getFullYear().toString());
        });
        // if the order has at least one future event sort all events ascending - see GT-4159
        if (futureEventShowDates.length) {
          Events.sort((a, b) => new Date(a.ShowDate) - new Date(b.ShowDate));
        } else {
          Events.sort((a, b) => new Date(b.ShowDate) - new Date(a.ShowDate));
        }
      }
      futureEventShowDates.sort((a, b) => new Date(a) - new Date(b));
      const eventShowDateYears = [...new Set(allShowDateYears)].sort((a, b) => b - a);// make list a unique set
      // sort orders by their most recent upcoming event - see GT-4159
      return {
        ...orderData,
        Events,
        eventShowDateYears,
        futureEventShowDates,
      };
    }).sort((a, b) => new Date(a?.futureEventShowDates[0]) - new Date(b?.futureEventShowDates[0]));
  }
  return [];
}

/**
 * Add up the quantities found on each line item that belong to the same Outlet Event Item.
 *
 * This helper function parses a customer order object returned from api endpoint - "customerorder/get".
 * Specifically the nested 'OutletEventItem' object found in order.LineItems.OutletEventItems.
 * @param {Object} OutletEventItem
 * @return {number} totalQty
 */
export function getTotalQtyByOutletEventItem({ OutletEventItem }) {
  let totalQty = 0;
  if (Array.isArray(OutletEventItem?.PriceLevels)) {
    OutletEventItem.PriceLevels.forEach((priceLevel) => {
      const { CustomerOrderLineItems } = priceLevel;
      if (Array.isArray(CustomerOrderLineItems)) {
        CustomerOrderLineItems.forEach((li) => {
          totalQty += Number(li.Quantity);
        });
      }
    });
  }
  return totalQty;
}

/**
 * Parse out smaller chunks of data from a customer order returned from api endpoint - "customerorder/get".
 *
 * @param {Object} order
 * @param {Number} OutletEventID
 * @return {Object} data related to the OutletEventID passed it.
 * @return {Object} data.eventData.
 * @return {Array} data.outletEventItems.
 */
export function getEventDataFromCustomerOrder({ order, OutletEventID }) {
  const eventData = Array.isArray(order?.LineItems) ? order.LineItems.find(
    (x) => Number(OutletEventID) === Number(x.OutletEventID),
  ) : null;
  const outletEventItems = Array.isArray(eventData?.OutletEventItems) ? [...eventData.OutletEventItems] : [];
  return { eventData, outletEventItems };
}


