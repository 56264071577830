import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import Button from '../../utils/Button';
import { IconInfo } from '../../utils/Icon';
import Loading from '../../utils/Loading';
import Typography from '../../utils/Typography';
import { DELIVERY_METHOD_TYPE_MOBILE_TICKETING_ID, OUTLET_TYPE_GUEST_LIST_ID } from '../../utils/constants';
import { goToUrl } from '../../utils/utilities';
import OrderDetails from './OrderDetails';
import OrderEventListTable from './OrderEventListTable';
import OrderTotalSummary from './OrderTotalSummary';
import { buildEventListDataForTableDisplay, orderHeaderIconSize } from './orderUtils';
import {
  OrderNumberStyled,
  StyledIconContainer,
  StyledNoOrdersContainer,
  StyledNoOrdersTitle,
  StyledOrderHeader,
} from './shared-styled-components';

const OrderList = (props) => {
  const {
    order,
    orderList,
    orderIsLoading,
    expandedOrderId,
    expandedOutletEventId,
    messageIfNoOrdersFound,
    handleClickManageTickets,
  } = props;

  const outlet = useSelector((state) => state.outlet);

  if (!Array.isArray(orderList) || !orderList.length) {
    return (
      <StyledNoOrdersContainer>
        <StyledNoOrdersTitle>{messageIfNoOrdersFound}</StyledNoOrdersTitle>
        {outlet?.OutletTypeID !== OUTLET_TYPE_GUEST_LIST_ID && (
          <Button onClick={() => goToUrl('/user/history/requests')}>
            Go to My Requests
          </Button>
        )}
      </StyledNoOrdersContainer>
    );
  }

  return (
    <div>
      {orderList.map((orderSummary) => {
        const {
          Events,
          OrderID,
          CustomerOrderID,
          AllowPaymentChange,
          OutletDeliveryMethod,
          AllowShippingAddressChanges,
        } = orderSummary;

        const seriesName = Array.isArray(Events) && Events.length ? Events[0].OutletEventGroup?.Name : null;

        const isMobileDelivery = OutletDeliveryMethod?.DeliveryMethodTypeID ===
          DELIVERY_METHOD_TYPE_MOBILE_TICKETING_ID;

        const eventList = buildEventListDataForTableDisplay({
          order: orderSummary, isMobileDelivery, expandedOutletEventId, expandedOrderId,
        });

        return (
          <div
            key={OrderID || CustomerOrderID}
            id={'container-' + OrderID || CustomerOrderID}
          >
            <StyledOrderHeader>
              <div>
                {seriesName && (<Typography tag="h2" style={{ margin: ' 2rem 0' }}>{seriesName}</Typography>)}
                <OrderNumberStyled
                  tag="h3"
                  onClick={() => goToUrl('/user/history/orders/' + OrderID + '/summary')}
                >
                  Order Number - {OrderID}{' '}
                  <StyledIconContainer>
                    <IconInfo
                      height={orderHeaderIconSize}
                      width={orderHeaderIconSize}
                      title="Order summary information"
                    />
                  </StyledIconContainer>
                </OrderNumberStyled>
              </div>
              <div></div>
              <div style={{ display: 'flex' }}>
                <div>
                  {AllowShippingAddressChanges && (
                    <Typography
                      tag="a"
                      onClick={() => goToUrl('/user/history/orders/' + OrderID + '/edit-shipping')}
                    >
                      Edit Shipping
                    </Typography>
                  )}
                </div>
                {AllowShippingAddressChanges &&
                  AllowPaymentChange && (
                    <div style={{ padding: '0 1rem' }}> | </div>
                  )}
                <div>
                  {AllowPaymentChange && (
                    <Typography
                      tag="a"
                      onClick={() => goToUrl('/user/history/orders/' + OrderID + '/edit-billing')}
                    >
                      Manage Payment
                    </Typography>
                  )}
                </div>
              </div>
            </StyledOrderHeader>
            <OrderEventListTable
              dataList={eventList}
              handleClickManageTickets={
                (OutletEventID) => handleClickManageTickets({ CustomerOrderID, OrderID, OutletEventID })
              }
              onClickExpandRow={(eventID) => {
                let url = '/user/history/orders/' + OrderID;
                if (eventID !== expandedOutletEventId || OrderID !== expandedOrderId) {
                  url = url + '/event/' + eventID;
                }
                if (eventID == expandedOutletEventId && OrderID === expandedOrderId) {
                  url = '/user/history/orders';
                }
                goToUrl(url);
              }}>
              {orderIsLoading ? (
                <Loading message={''} />
              ) : (
                <div style={{ maxWidth: 500, margin: '0 auto' }}>
                  <OrderDetails
                    order={order}
                    eventID={expandedOutletEventId}
                    userOrderSummaryList={orderList}
                  />
                </div>
              )}
            </OrderEventListTable>
            <OrderTotalSummary order={orderSummary} />
          </div >
        );
      })}
    </div >
  );
};

OrderList.propTypes = {
  order: PropTypes.object,
  orderList: PropTypes.array.isRequired,
  orderIsLoading: PropTypes.bool,
  expandedOrderId: PropTypes.string,
  expandedOutletEventId: PropTypes.string,
  handleClickManageTickets: PropTypes.func,
  messageIfNoOrdersFound: PropTypes.string,
};

OrderList.defaultProps = {
  order: null,
  orderList: [],
  orderIsLoading: false,
  expandedOrderId: '',
  expandedOutletEventId: '',
  handleClickManageTickets: null,
  messageIfNoOrdersFound: 'No orders to display',
};

export default OrderList;
