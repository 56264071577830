import PropTypes from 'prop-types';
import styled from 'styled-components';
import React from 'react';

import Button from '../../utils/Button';
import OutletEventItemListForGuestList from './OutletEventItemListForGuestList';
import Typography from '../../utils/Typography';
import { IconExternalLink, IconMinus, IconPlus } from '../../utils/Icon';
import { calculateSeriesStatuses, formatDate, formatTime } from '../../utils/utilities';

const ListRoot = styled.div``;

const ListItemStyled = styled.div`
  border-top: solid 1px ${(props) => props.theme.PRIMARY_GRAY};
  display: grid;
  padding: 2rem 0;
  align-items: start;
  cursor: pointer;
  grid-template-columns: auto 1fr;
  gap: 2rem;
  @media (min-width: ${(props) => props.theme.BREAKPOINT_MOBILE_WIDTH}px) {
    grid-template-columns: auto 1fr;
  }
`;

const InfoContainerStyled = styled.div`
  display: grid;

  margin: 0 0 1rem 0;
  grid-template-columns: 1fr;
  gap: 1rem;
  @media (min-width: ${(props) => props.theme.BREAKPOINT_MOBILE_WIDTH}px) {
    grid-template-columns: 1fr auto;
    align-items: center;
  }
`;
const IconContainer = styled.div`
  color: ${(props) => props.theme.PRIMARY_LINK_COLOR}!important;
  margin: 0 0 0 0;
  display: none;
  @media (min-width: ${(props) => props.theme.BREAKPOINT_MOBILE_WIDTH}px) {
    display: block;
  }
`;

const ButtonContainer = styled.div`
  width: 180px;
  button {
    width: 100%;
    text-transform: uppercase;
  }
`;

const DateContainer = styled.div`
  padding: 5px;
  color: ${(props) => props.theme.SECONDARY_BUTTON_TEXT_COLOR};
  min-width: 60px;
  min-height: 60px;
  font-weight: 700;
  border-radius: 10px;
  text-align: center;
  border-radius: 10px;
  background-color: ${(props) => props.theme.SECONDARY_BUTTON_BACKGROUND};
`;

const Month = styled.div`
  text-align: center;
  text-transform: uppercase;
`;

const DayOfMonth = styled.div`
  text-align: center;
  text-transform: uppercase;
  font-size: 2rem;
`;

const DayOfWeek = styled.div`
  text-align: center;
  text-transform: uppercase;
  opacity: 0.75;
`;

export function OutletEventList({
                                  outletEventList,
                                  onClickEventDetails,
                                  outlet,
                                }) {
  const [expandedOutletEvents, setExpandedOutletEvents] = React.useState([]);

  const handleClickExpandOutletEvent = (OutletEventID) => {
    const adjustedArray = [...expandedOutletEvents];
    const index = adjustedArray.indexOf(OutletEventID);
    if (index > -1) {
      adjustedArray.splice(index, 1);
    } else {
      adjustedArray.push(OutletEventID);
    }
    // console.log("adjustedArray index", index);
    // console.log("adjustedArray", adjustedArray);
    setExpandedOutletEvents(adjustedArray);
  };

  if (!Array.isArray(outletEventList) || outletEventList.length < 1) {
    return <Typography>No event data</Typography>;
  }

  return (
      <ListRoot>
        {outletEventList.map((outletEvent, index) => {
          const outletEventOverrideName = outletEvent.OutletEventOverrideName;
          const doorTimeIsVisible =
              outletEvent.OutletEventDoorTimeStatus ||
              (outletEvent.SalesTypeID == 3 && outletEvent.Event.DoorTime);
          const doorTime =
              outletEvent.SalesTypeID == 3 && outletEvent.Event.DoorTime
                  ? outletEvent.Event.DoorTime
                  : outletEvent.OutletEventDoorTime;
          const oeg = outletEvent.OutletEventGroup;
          const isExpanded = expandedOutletEvents.some(
              (id) => id === outletEvent.OutletEventID,
          );
          const { seriesIsOpen, seriesIsVisible, seriesIsClosed } =
              calculateSeriesStatuses(oeg);
          const testmsg = seriesIsClosed
              ? ' - Closed'
              : seriesIsOpen
                  ? ' - Open'
                  : seriesIsVisible
                      ? ' - visible'
                      :null;
          // console.log({
          //   oegName: oeg.Name,
          //   testmsg,
          //   seriesIsOpen,
          //   seriesIsVisible,
          //   seriesIsClosed,
          // });

          let artistName,
              eventName,
              venueName,
              city,
              state,
              showDate,
              displayEventShowTime;
          if (outletEvent.Event) {
            eventName = outletEvent.Event.Name;
            showDate = outletEvent.Event.ShowDate;
            if (outletEvent.Event.Artist) {
              artistName = outletEvent.Event.Artist.ArtistName;
            }
            if (outletEvent.Event.Venue) {
              venueName = outletEvent.Event.Venue.VenueName;
              city = outletEvent.Event.Venue.City;
              state = outletEvent.Event.Venue.State;
            }
            displayEventShowTime = Boolean(outletEvent.Event.DisplayShowTime);
          }

          //RLMTS-1738 new logic for headline below:
          //Artist Outlet (1): Use Outlet Event Override if set, otherwise Artisist Name(RLMTS-2565). It was previously - Venue Name
          //Venue Outlet (2): Use Outlet Event Override if set, otherwise Artist Name
          //Guest List (3): Use Outlet Event Override if set, otherwise Event Name, else nothing
          //Point of Sale (4): no rules defined (so im defaulting to option 1)
          let headlineName = outletEventOverrideName;
          if (!headlineName) {
            if (outlet.OutletTypeID === 1) {
              headlineName = artistName; // venueName;
            }
            if (outlet.OutletTypeID === 2) {
              headlineName = artistName;
            }
            if (outlet.OutletTypeID === 3) {
              //RLMTS-2814 reverses previous logic to now follow artist outlet type
              //headlineName = eventName;
              headlineName = artistName;
            }
            if (outlet.OutletTypeID === 4) {
              headlineName = venueName;
            }
          }

          let btnName = 'btn-st1-';
          let btnText = 'Buy Now';
          if (outletEvent.SalesTypeID === 2) {
            btnName = 'btn-st2-';
            btnText = 'Request';
          }
          //START RLMTS-3215 - the series is visible and not open yet or is visible and closed
          if (seriesIsVisible === true) {
            if (seriesIsOpen === false || seriesIsClosed === true) {
              btnText = 'Details';
            }
          }
          //Catching non visible series events that are still returned to UI for whatever business reasons
          if (seriesIsVisible === false) {
            btnText = 'Details';
          }
          //END RLMTS-3215
          if (outletEvent.SalesTypeID === 3) {
            btnName = 'btn-st3-';
            btnText = (
                <>
                  Get Tickets &nbsp; &nbsp;
                  <span>
                <IconExternalLink width="16" height="16"/>
              </span>
                </>
            );
          }
          btnName += outletEvent.OutletEventID;

          const showVenueCityState =
              outlet.OutletTypeID === 1 || outlet.OutletTypeID === 3;
          return (
              <div key={index}>
                <ListItemStyled
                    className="event-list-item"
                    id={'event-' + outletEvent.OutletEventID}
                    // onClick={() =>
                    //   handleClickExpandOutletEvent(outletEvent.OutletEventID)
                    // }
                    onClick={() =>
                        outlet.OutletTypeID === 3
                            ? handleClickExpandOutletEvent(outletEvent.OutletEventID)
                            : onClickEventDetails
                                ? onClickEventDetails(outletEvent)
                                : false
                    }
                >
                  <DateContainer>
                    <Month>{formatDate(showDate, { month: 'short' })}</Month>
                    <DayOfMonth>
                      {formatDate(showDate, { day: 'numeric' })}
                    </DayOfMonth>
                    <DayOfWeek>
                      {formatDate(showDate, { weekday: 'short' })}
                    </DayOfWeek>
                  </DateContainer>
                  <InfoContainerStyled>
                    <div>
                      {headlineName && (
                          <Typography tag="h4">{headlineName}</Typography>
                      )}
                      {showVenueCityState && (
                          <Typography>
                            <strong>{venueName}</strong> - {city}, {state}
                          </Typography>
                      )}
                      <Typography>
                        {displayEventShowTime && (
                            <>
                              {formatTime(showDate, {
                                hour: 'numeric',
                                minute: 'numeric',
                                timeZone: 'America/New_York',
                              })}{' '}
                            </>
                        )}
                        {doorTimeIsVisible && doorTime ? (
                            <span>(Doors Open {formatTime(doorTime)})</span>
                        ) : null}
                      </Typography>

                      {oeg && oeg.ShowSeriesName && oeg.Name ? (
                          <Typography strong>{oeg.Name}</Typography>
                      ) : null}
                    </div>

                    {outlet.OutletTypeID === 3 ? (
                        <IconContainer>
                          {isExpanded ? <IconMinus/> : <IconPlus/>}
                        </IconContainer>
                    ) : (
                        <ButtonContainer>
                          <Button
                              style={{ margin: 0 }}
                              className="event-btn"
                              name={btnName}
                          >
                            {btnText}
                          </Button>
                        </ButtonContainer>
                    )}
                  </InfoContainerStyled>
                </ListItemStyled>
                {isExpanded && (
                    <OutletEventItemListForGuestList
                        OutletEventItems={outletEvent.OutletEventItems}
                    />
                )}
              </div>
          );
        })}
      </ListRoot>
  );
}

OutletEventList.defaultProps = {
  outlet: {},
  outletEventList: [],
  onClickPriceLevel: null,
  onClickEventDetails: null,
};

OutletEventList.propTypes = {
  outlet: PropTypes.object,
  outletEventList: PropTypes.array,
  onClickPriceLevel: PropTypes.func,
  onClickEventDetails: PropTypes.func,
};

export default OutletEventList;
