import React from 'react';
import { useSelector } from 'react-redux';

export default function OutletImage() {
  const outlet = useSelector((state) => state.outlet);

  if (outlet.OutletTheme && outlet.OutletTheme.HeaderImageURL) {
    return (
        <img
            src={outlet.OutletTheme.HeaderImageURL}
            alt="Outlet Header"
            style={{ maxHeight: 'auto', width: '100%', display: 'block' }}
        />
    );
  }
  return null;
}
