import { combineReducers, createStore } from 'redux';

//import apiReducer from "./components/api/reducer";

function sessionReducer(
    state = {
      sessionID: null,
    },
    action,
) {
  switch (action.type) {
    case 'SET_USER_SESSION': {
      return action.data ? { ...action.data } : state;
    }

    default:
      return state;
  }
}

function authReducer(
    state = {
      failedLoginAttempts: 0,
      userIsAuthenticated: false,
      sessionTimeoutWarningIsVisible: false,
      userFailedLoginAttemptsCheckoutRealTime: 0,
      guestListToken: null,
      guestListInvitation: null,
      guestListInvitationError: null,
    },
    action,
) {
  switch (action.type) {
    case 'SET_GUEST_LIST_INVITATION': {
      return {
        ...state,
        guestListInvitation: action.guestListInvitation,
      };
    }
    case 'SET_GUEST_LIST_INVITATION_DELIVERY_METHOD_LIST': {
      return {
        ...state,
        guestListInvitationDeliveryMethodList: action.guestListInvitationDeliveryMethodList,
      };
    }
    case 'SET_GUEST_LIST_INVITATION_ERROR': {
      return {
        ...state,
        guestListInvitationError: action.guestListInvitationError,
      };
    }

    case 'SET_GUEST_LIST_TOKEN': {
      return {
        ...state,
        guestListToken: action.guestListToken,
      };
    }
    case 'SESSION_TIMEOUT_WARNING_IS_VISIBLE': {
      return {
        ...state,
        sessionTimeoutWarningIsVisible: action.isVisible,
      };
    }
    case 'USER_IS_AUTHENTICATED': {
      return {
        ...state,
        userIsAuthenticated: action.userIsAuthenticated ? true : false,
      };
    }
    case 'INCREASE_FAILED_LOGIN_ATTEMPTS': {
      return {
        ...state,
        failedLoginAttempts: state.failedLoginAttempts + 1,
      };
    }
    case 'RESET_FAILED_LOGIN_ATTEMPTS': {
      return {
        ...state,
        failedLoginAttempts: 0,
      };
    }
    default:
      return state;
  }
}

function appReducer(
    state = {
      appDrawerIsVisible: false,
      appDrawerIconIsVisible: true,
      attemptsToFetchUserRequests: 0,
      authnetIframeIsVisible: false,
    },
    action,
) {
  switch (action.type) {
    case 'TOGGLE_DRAWER_VISIBILITY': {
      return {
        ...state,
        appDrawerIsVisible: action.appDrawerIsVisible,
      };
    }
    case 'TOGGLE_DRAWER_ICON_VISIBILITY': {
      return {
        ...state,
        appDrawerIconIsVisible: action.appDrawerIconIsVisible,
      };
    }
    case 'TOGGLE_AUTHNET_IFRAME_VISIBILITY': {
      return {
        ...state,
        authnetIframeIsVisible: action.authnetIframeIsVisible,
      };
    }
    default:
      return state;
  }
}

function apiReducer(
    state = {
      error: [],
      loading: [],
      invalidSession: false,
      invalidSessionLastAction: null,
    },
    action,
) {
  switch (action.type) {
    case 'API_INIT': {
      return {
        ...state,
        invalidSession: false,
        invalidSessionLastAction: null,
        loading: [...state.loading, action.action],
        error: [...state.error].filter((e) => e !== action.action),
      };
    }
    case 'API_SUCCESS': {
      return {
        ...state,
        invalidSession: false,
        invalidSessionLastAction: null,
        loading: [...state.loading].filter((e) => e !== action.action),
        error: [...state.error].filter((e) => e !== action.action),
      };
    }
    case 'API_ERROR': {
      return {
        ...state,
        invalidSession: false,
        invalidSessionLastAction: null,
        loading: [...state.loading].filter((e) => e !== action.action),
        error: [...state.error, action.action],
      };
    }
    case 'INVALID_SESSION': {
      return {
        ...state,
        invalidSession: action.invalidSession,
        invalidSessionLastAction: action.action,
      };
    }
    default:
      return state;
  }
}

function userPaymentMethodListReducer(state = [], action) {
  switch (action.type) {
    case 'SET_USER_PAYMENT_METHOD_LIST': {
      return action.data;
    }
    default:
      return state;
  }
}

function userPaymentMethodDeleteRejectionDetailsReducer(state = [], action) {
  switch (action.type) {
    case 'SET_USER_DELETE_PAYMENT_METHOD_REJECT_DETAILS': {
      return action.data;
    }
    default:
      return state;
  }
}

function userOrderListReducer(state = [], action) {
  switch (action.type) {
    case 'SET_USER_ORDER_LIST': {
      return action.data;
    }
    default:
      return state;
  }
}

function userOrderSummaryListReducer(state = [], action) {
  switch (action.type) {
    case 'SET_USER_ORDER_SUMMARY_LIST': {
      return action.data;
    }
    default:
      return state;
  }
}

function userFanRequestListReducer(state = [], action) {
  switch (action.type) {
    case 'SET_USER_FAN_REQUESTS': {
      return action.data;
    }
    default:
      return state;
  }
}

function userFanRequestSeriesSummaryListReducer(state = [], action) {
  switch (action.type) {
    case 'SET_USER_FAN_REQUEST_SERIES_SUMMARY': {
      return action.data;
    }
      // update priority selection on the frontend to skip Series Summary call
    case 'SET_USER_FAN_REQUEST_SERIES_PRIORITY': {
      var newState = [...state];

      // set all other requests' Priority selections to null in the tour
      // set chosen request's priority selection to incoming value
      newState.forEach(x => {
        if (x.OutletEventGroup?.Tour?.TourID == action.data.TourID) {
          if (x.LotteryOEGRequestUUID == action.data.LotteryOEGRequestUUID) {
            x.PriorityOERequestUUID = action.data.PriorityOERequestUUID;
          } else {
            x.PriorityOERequestUUID = null;
          }
        }
      });

      return newState;
    }
    default:
      return state;
  }
}

function userAddressListReducer(state = [], action) {
  switch (action.type) {
    case 'SET_USER_ADDRESS_LIST': {
      return action.data;
    }
    default:
      return state;
  }
}

function eventListReducer(state = [], action) {
  switch (action.type) {
    case 'EVENT_LIST': {
      return action && action.data && Array.isArray(action.data)
          ? action.data
          : [];
    }
    default:
      return state;
  }
}

function toastListReducer(state = [], action) {
  switch (action.type) {
    case 'ADD_TOAST': {
      return [...state, action.toast];
    }
    case 'DELETE_TOAST': {
      return [...state].filter((t) => t.id !== action.id);
    }
    default:
      return state;
  }
}

function outletReducer(state = {}, action) {
  switch (action.type) {
    case 'OUTLET': {
      return action && action.data ? action.data : {};
    }
    default:
      return state;
  }
}

function outletProtocolsReducer(state = {}, action) {
  switch (action.type) {
    case 'OUTLET_PROTOCOLS': {
      return action && action.data ? action.data : {};
    }
    default:
      return state;
  }
}

function userOrderReducer(state = null, action) {
  switch (action.type) {
    case 'SET_USER_ORDER': {
      return action && action.data ? action.data : state;
    }
    default:
      return state;
  }
}

function userMobileTicketListReducer(state = [], action) {
  const { type, data } = action;
  switch (type) {
    case 'SET_USER_MOBILE_TICKET_LIST': {
      return data || state;
    }
    default:
      return state;
  }
}

function userRecieptReducer(state = null, action) {
  switch (action.type) {
    case 'SET_USER_RECIEPT': {
      return action && action.data ? action.data : state;
    }
    default:
      return state;
  }
}

// cartUUID: "5a5a3bb4-b423-43f0-9147-bd326a43d18d",
// sessionID: "94acba95-e813-4576-a919-cf631ecaed9f",
// clock: {secondsToExpire: 119, running: true},
// createdAt: 1618597887181,
// deliveryMethodUUID: "",
// expireAt: 1618598007 ,
// paymentMethodUUID: "",
// processing: 0,
// shippingAddress: "",
// userID: 0,
// lineItems: [
// held: true,
// priceLevelID: 906,
// quantity: 2
// seatInfo:{
//   manifest:[{
//     RowName: "RB",
//     SeatName: 47,
//     SectionName: "RES1",
//     TicketID: 386911,
//     XRow: 1,
//     XSeat: 47,
//     XSection: 2,
//   }],
//   priceLevel:{
//     itemName: "Concert Tickets"
//     priceLevelName: "Reserved"
//   }
// }],

function instantRequestReducer(
    state = { cart: null, outletEvent: null, loading: null, error: null },
    action,
) {
  switch (action.type) {
    case 'SET_INSTANT_REQUEST_CART': {
      return { ...state, cart: action.cart || null };
    }
    case 'DELETE_INSTANT_REQUEST': {
      return { cart: null, outletEvent: null, loading: null, error: null };
    }
    case 'SET_INSTANT_REQUEST_ERROR': {
      return { ...state, error: action.error || null };
    }
    case 'SET_INSTANT_REQUEST_LOADING': {
      return { ...state, loading: action.loading || false };
    }
    case 'SET_INSTANT_REQUEST_OUTLET_EVENT': {
      return { ...state, outletEvent: action.outletEvent || null };
    }
    default:
      return state;
  }
}

//     OutletEventGroupID: null, //91,
//     PriorityOERequestUUID: null,
//     PaymentPlanEnrolled: null,
//     //LotteryOEGRequestUUID: "71f23680-7a8e-4e3d-b1c7-fab2f2b153df",
//     Shipping: null,
//     PaymentInfo: null,
//     LotteryOEIRequests: [],
//     receipt: {
//       LotteryOEGRequest: null,
//       LotteryOERequest: null,
//       loading: null,
//       error: null,
//     },
//     outletEvent: null,
//     seriesSummary: {
//       loading: false,
//       error: null,
//       data: null,
//     },
function fanRequestReducer(
    state = {
      // cart: { lineItems: requestCartLineItems },
      // outletEvent: outletEvent883StubData,
      // seriesSummary: seriesSummaryForCartStubData,
      cart: null,
      outletEvent: null,
      seriesSummary: null,
      loading: null,
      error: null,
    },
    action,
) {
  switch (action.type) {
    case 'SET_FAN_REQUEST_CART': {
      return { ...state, cart: action.cart || null };
    }
    case 'RESET_FAN_REQUEST_CART': {
      return {
        ...state,
        cart: null,
        outletEvent: null,
        seriesSummary: null,
        loading: null,
        error: null,
      };
    }
    case 'SET_FAN_REQUEST_ERROR': {
      return { ...state, error: action.error || null };
    }
    case 'SET_FAN_REQUEST_LOADING': {
      return { ...state, loading: action.loading || false };
    }
    case 'SET_FAN_REQUEST_OUTLET_EVENT': {
      return { ...state, outletEvent: action.outletEvent || null };
    }
    case 'SET_FAN_REQUEST_SERIES_SUMMARY': {
      return { ...state, seriesSummary: action.seriesSummary || false };
    }

    default:
      return state;
  }
}

//   token: app.token,
//   deliveryMethod: app.cartDeliveryMethod,
//   paymentMethod: app.cartPaymentMethod,
//   priceLevels: app.cartPriceLevels,
//   shippingAddress: app.cartShippingAddress,
//   contactEmails: app.cartContactEmails,
//   outletID: app.user.OutletID,
//   user: {
//     UserID: app.user.UserID,
//     FirstName: app.user.FirstName,
//     LastName: app.user.LastName,
//     EmailAddress: app.user.EmailAddress,
//   },

function guestRequestReducer(
    state = { cart: null, outletEvent: null, loading: null, error: null },
    action,
) {
  switch (action.type) {
    case 'RESET_GUEST_REQUEST_CART': {
      return {
        cart: null,
        error: null,
        loading: null,
        outletEvent: null,
      };
    }

    case 'SET_GUEST_REQUEST_CART': {
      let adjustedCart = null;
      if (action.cart) {
        adjustedCart = state.cart
            ? { ...state.cart, ...action.cart }
            : { ...action.cart };
      }

      return { ...state, cart: adjustedCart };
    }
    case 'SET_GUEST_REQUEST_LOADING': {
      return { ...state, loading: action.loading || false };
    }
    case 'SET_GUEST_REQUEST_OUTLET_EVENT': {
      return { ...state, outletEvent: action.outletEvent || null };
    }
    default:
      return state;
  }
}

function guestListReceiptReducer(state = {}, action) {
  switch (action.type) {
    case 'SET_GUEST_LIST_RECEIPT': {
      return action.data;
    }

    default:
      return state;
  }
}

const rootReducer = combineReducers({
  api: apiReducer,
  app: appReducer,
  auth: authReducer,
  outlet: outletReducer,
  outletProtocols: outletProtocolsReducer,
  session: sessionReducer,
  eventList: eventListReducer,
  toastList: toastListReducer,

  fanRequest: fanRequestReducer,
  guestRequest: guestRequestReducer,
  instantRequest: instantRequestReducer,

  userReciept: userRecieptReducer,
  guestListReceipt: guestListReceiptReducer,

  userOrder: userOrderReducer,
  userOrderList: userOrderListReducer,
  userOrderSummaryList: userOrderSummaryListReducer,
  
  userMobileTicketList: userMobileTicketListReducer,

  userAddressList: userAddressListReducer,
  userPaymentMethodList: userPaymentMethodListReducer,

  userFanRequestList: userFanRequestListReducer,
  userFanRequestSeriesSummaryList: userFanRequestSeriesSummaryListReducer,

  userPaymentMethodDeleteRejectionDetails:
  userPaymentMethodDeleteRejectionDetailsReducer,
});

const store = createStore(rootReducer);

export default store;
