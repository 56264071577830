import store from '../../store';
import { addToast } from '../toast/actions';
import { client } from '../../utils/apiClient';

const actionLookup = {
  GET_OUTLET: {
    endpoint: 'outlet/getinfo',
  },
  GET_OUTLET_PROTOCOLS: {
    endpoint: 'outlet/getprotocols',
  },
  GET_OUTLET_LIST: {
    endpoint: 'outlet/getoutlets',
  },
  GET_EVENT_LIST: {
    endpoint: 'outlet/getoutletevents',
  },
  GET_OUTLET_EVENT_LIST: {
    endpoint: 'outlet/getoutletevents',
  },
  GET_AUTHNET_PROFILE_TOKEN: {
    endpoint: 'payment/gethostedprofilepage',
  },
  GET_USER_ADDRESS_LIST: {
    endpoint: 'user/getsavedaddresses',
  },
  GET_USER_PAYMENT_METHOD_LIST: {
    endpoint: 'payment/paymentmethodlist',
  },
  GET_USER_FAN_REQUESTS: {
    endpoint: 'tixrequests/getoerequest',
  },
  GET_USER_FAN_REQUEST_SERIES_SUMMARY: {
    endpoint: 'tixrequests/getoegrequestsummary',
  },
  CREATE_FAN_REQUEST_WITH_SERIES_SUMMARY: {
    endpoint: 'tixrequests/getoegrequestsummarywithcart',
  },
  GET_USER_FAN_REQUEST_SERIES_SUMMARY_FOR_CART: {
    endpoint: 'tixrequests/getoegrequestsummarywithcart',
  },
  DELETE_USER_FAN_SERIES_REQUEST: {
    endpoint: 'tixrequests/putoerequest',
  },
  UPDATE_USER_FAN_REQUEST: {
    endpoint: 'tixrequests/putoerequest',
  },
  SAVE_FAN_REQUEST: {
    endpoint: 'tixrequests/putoerequest',
  },
  UPDATE_USER_FAN_SERIES_REQUEST: {
    endpoint: 'tixrequests/putoegrequest',
  },
  UPDATE_USER_FAN_SERIES_PRIORITY: {
    endpoint: 'tixrequests/updateoegrpriority',
  },

  INIT_SESSION: {
    endpoint: 'user/sessioninit',
  },
  CHECK_SESSION: {
    endpoint: 'user/checksession',
  },

  LOGIN_USER: {
    endpoint: 'user/login',
  },
  CREATE_USER: {
    endpoint: 'user/create',
  },
  UPDATE_USER_PROFILE: {
    endpoint: 'user/updateProfile',
  },
  RESET_USER_PASSWORD: {
    endpoint: 'user/resetpassword',
  },
  CHANGE_USER_PASSWORD: {
    endpoint: 'user/changePassword',
  },
  FORGOT_USER_PASSWORD: {
    endpoint: 'user/forgotpassword',
  },

  GET_USER_ORDER: {
    endpoint: 'customerorder/get',
  },
  GET_USER_ORDER_SUMMARY_LIST: {
    endpoint: 'customerorder/getlist',
  },
  GET_USER_MOBILE_TICKET_LIST: {
    endpoint: 'customerorder/getmobileticketlist',
  },

  INSTANT_REQUEST_CREATE_CART: {
    endpoint: 'cart/createcart',
  },
  INSTANT_REQUEST_CONFIRM_SEATS: {
    endpoint: 'cart/begincheckout',
  },
  INSTANT_REQUEST_UPDATE_LINEITEMS: {
    endpoint: 'cart/updatelineitems',
  },
  INSTANT_REQUEST_UPDATE_DELIVERY_METHOD: {
    endpoint: 'cart/adddeliverymethod',
  },
  INSTANT_REQUEST_UPDATE_SHIPPING_ADDRESS: {
    endpoint: 'cart/addshippingaddress',
  },
  INSTANT_REQUEST_UPDATE_PAYMENT_METHOD: {
    endpoint: 'cart/addpaymentmethod',
  },
  INSTANT_REQUEST_CONFIRM_ORDER: {
    endpoint: 'cart/createorder',
  },

  UPDATE_USER_ADDRESS: {
    endpoint: 'user/updatesavedaddress',
  },
  CREATE_USER_ADDRESS: {
    endpoint: 'user/createsavedaddress',
  },
  DELETE_USER_ADDRESS: {
    endpoint: 'user/deletesavedaddress',
  },

  UPDATE_USER_ORDER_ADDRESS: {
    endpoint: 'customerorder/updateaddress',
  },
  UPDATE_USER_ORDER_PAYMENT: {
    endpoint: 'customerorder/updatescheduledpayments',
  },
  DELETE_USER_PAYMENT_METHOD: {
    endpoint: 'payment/disablepaymentmethod',
  },
  GET_GUEST_LIST_INVITATION: {
    endpoint: 'guestlist/getTokenInfo',
  },
  CREATE_GUEST_LIST_REQUEST: {
    endpoint: 'guestlist/createRequest',
  },
  UPDATE_OUTLET_EVENT_ITEM_UPGRADE: {
    endpoint: 'customerorder/updateOutletEventItemUpgrade',
  },
  CAPTURE_SCHEDULED_PAYMENT: {
    endpoint: 'customerorder/capturescheduledpayment',
  },
  LOGIN_PRIVATE_OUTLET: {
    endpoint: 'user/checkoutletpassword',
  },
  UPDATE_OUTLET_EVENT_ITEM_REFUND_REQUEST: {
    endpoint: 'customerorder/requestoeirefund',
  },
};

export function getUserCookie() {
  // RLMTS-2722
  // check for valid cookie before asking the API for info
  // After discussion with team, decision was made to have server check cookie info before returning any payload
  // Therefore alleviating the need for client side to check for valid cookie status
  // Leaving this function intact incase future needs change

  // if (document.cookie.split(';').some((item) => item.trim().startsWith(process.env.REACT_APP_COOKIE + "="))) {
  //   const output = document.getElementById('a-cookie-existence')
  //   output.textContent = '> The cookie "reader" exists'
  // }

  let cookieValue = null;
  let cookieFound = false;
  const allCookies = document.cookie;
  const cookieArray = allCookies && allCookies.split('; ');
  const environmentString = process.env.toString().toLocaleLowerCase();
  const cookieName = process.env.REACT_APP_COOKIE + environmentString + '=';

  if (Array.isArray(cookieArray)) {
    cookieFound = cookieArray.find((row) => row.startsWith(cookieName));
  }

  if (cookieFound) {
    cookieValue = cookieFound.split('=')[1];
  }

  return cookieValue;
}

export const api = ({ action, endpoint, payload, showToast }) => {
  store.dispatch({ type: 'API_INIT', action });
  const ep = endpoint || actionLookup[action].endpoint;

  return new Promise((resolve, reject) => {
    client(ep, { body: payload }).then((res) => {
      // TODO: Do we need to check for error here?
      // The same logic is also duplicated below in the catch block.
      if (res.error) {
        const message = res.message || 'api response error';

        if (message === 'Invalid Session') {
          store.dispatch({
            action,
            invalidSession: true,
            type: 'INVALID_SESSION',
          });
        } else {
          store.dispatch({
            type: 'API_ERROR',
            action,
          });
        }

        if (showToast) {
          addToast({
            title: 'API error',
            description: message,
            type: 'error',
          });
        }

        if (action === 'DELETE_USER_PAYMENT_METHOD') {
          let deleteRes = { message };
          if (res.response) {
            deleteRes = { ...deleteRes, ...res.response };
          }
          reject(deleteRes);
        }
        reject(message);
      } else {
        store.dispatch({
          type: 'API_SUCCESS',
          action,
        });
        if (res.response) {
          resolve(res.response);
        } else {
          resolve(res);
        }
      }
    }).catch(async (res) => {
      const { status } = res;
      const response = await res.json();
      const errorMessage = response.message || `api server error. status code: ${status}`;

      if (errorMessage === 'Invalid Session') {
        store.dispatch({
          action,
          invalidSession: true,
          type: 'INVALID_SESSION',
        });
      } else {
        store.dispatch({
          type: 'API_ERROR',
          action,
        });
      }

      if (showToast) {
        addToast({
          title: 'API server error',
          description: action + ' - ' + errorMessage,
          type: 'error',
        });
      }

      reject(errorMessage);
    });
  });
};
