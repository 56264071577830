import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const H1 = styled.h1`
  font-family: ${(props) => props.theme.HEADER_TEXT_FONT_FAMILY};
  font-weight: 400;
  font-size: 3rem;
  margin: 0;
  padding: 0;
  line-height: 1.2em;
  text-transform: uppercase;
`;

const H2 = styled.h2`
  font-family: ${(props) => props.theme.HEADER_TEXT_FONT_FAMILY};
  font-weight: 400;
  font-size: 2.2rem;
  margin: 0;
  padding: 0;
  line-height: 1.4em;
  text-transform: uppercase;
`;

const H3 = styled.h3`
  font-family: ${(props) => props.theme.HEADER_TEXT_FONT_FAMILY};
  font-weight: 400;
  font-size: 2rem;
  margin: 0;
  padding: 0;
  line-height: 1.4em;
  text-transform: uppercase;
`;

const H4 = styled.h4`
  font-family: ${(props) => props.theme.HEADER_TEXT_FONT_FAMILY};
  font-weight: 400;
  font-size: 1.6rem;
  margin: 0;
  padding: 0;
  line-height: 1.4em;
  text-transform: uppercase;
`;

const H5 = styled.h5`
  font-family: ${(props) => props.theme.HEADER_TEXT_FONT_FAMILY};
  font-weight: 400;
  font-size: 1.4rem;
  margin: 0;
  padding: 0;
  line-height: 1.4em;
  text-transform: uppercase;
`;

const P = styled.p`
  margin: 0 0 10px 0;
`;

const Div = styled.div``;

const A = styled.a`
  color: ${(props) =>
    props.secondary
        ? props.theme.SECONDARY_LINK_COLOR
        : props.theme.PRIMARY_LINK_COLOR};
  text-decoration: none;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
    opacity: 0.8;
  }
`;

// const ASecondary = styled(A)`
//   color: ${(props) => props.theme.SECONDARY_LINK_COLOR};
// `;
//const SecondaryButton2 = props => <ButtonStyled {...props} children={props.children.split('').reverse()} />

export const Typography = ({ children, tag = 'div', ...props }) => {
  //console.log("Typography props = ", tag, ...props);
  //const variant = (tag === "h1" ? Heading1 : Div)
  if (tag === 'h1') {
    return <H1 {...props}>{children}</H1>;
  }
  if (tag === 'h2') {
    return <H2 {...props}>{children}</H2>;
  }
  if (tag === 'h3') {
    return <H3 {...props}>{children}</H3>;
  }
  if (tag === 'h4') {
    return <H4 {...props}>{children}</H4>;
  }
  if (tag === 'h5') {
    return <H5 {...props}>{children}</H5>;
  }
  if (tag === 'p') {
    return <P {...props}>{children}</P>;
  }
  if (tag === 'a') {
    return <A {...props}>{children}</A>;
  }
  return <Div {...props}>{children}</Div>;
};

Typography.propTypes = {
  secondary: PropTypes.bool,
};

Typography.defaultProps = {
  secondary: false,
};

export default Typography;
