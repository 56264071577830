import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import Button from '../../utils/Button';
import PaymentMethodForm from '../../utils/PaymentMethodForm';
import PaymentPlanEnrollment from './PaymentPlanEnrollment';
import PaymentPlanForm from './PaymentPlanForm';
import { getUserPaymentMethodList } from '../user/actions';

function EditRequestBilling(props = {}) {
  console.log('EditRequestBilling props', props);
  const {
    loading = false,

    onClickSave,
    // onClickCancel,
    // onCreateNewPaymentMethodViaIFrame,
    paymentPlanIsAvailable,
    outletEvent,
    seriesTotal = 0,
    userIsEnrolledInPaymentPlan = false,
    userPaymentMethodList = [],
    defaultPaymentInfo = [],
  } = props;
  const outlet = useSelector((state) => state.outlet);
  const authnetIframeIsVisible = useSelector(
      (state) => state.app.authnetIframeIsVisible,
  );
  const [paymentList, setPaymentList] = useState(defaultPaymentInfo);

  const [paymentPlanEnrollmentIsSelected, setPaymentPlanEnrollmentIsSelected] =
      useState(userIsEnrolledInPaymentPlan);

  const handleOnCreateNewPaymentMethodViaIFrame = (params) => {
    console.log(
        'EditRequestBilling handleOnCreateNewPaymentMethodViaIFrame params',
        params,
    );
    console.log(
        'EditRequestBilling handleOnCreateNewPaymentMethodViaIFrame paymentList',
        paymentList,
    );
    if (params) {
      const { Sort } = params;
      console.log(
          'EditRequestBilling handleOnCreateNewPaymentMethodViaIFrame Sort',
          Sort,
      );
      getUserPaymentMethodList().then((userPaymentMethods) => {
        if (
            userPaymentMethods &&
            Array.isArray(userPaymentMethods) &&
            userPaymentMethods.length
        ) {
          if (userPaymentMethods[0]) {
            const newPaymentMethod = { ...userPaymentMethods[0] };

            newPaymentMethod['Sort'] = Sort || 1;

            console.log(
                'EditRequestBilling handleOnCreateNewPaymentMethodViaIFrame newPaymentMethod',
                newPaymentMethod,
            );

            const newPaymentList = [...paymentList];
            const index = newPaymentList.findIndex(
                (p) => Number(p.Sort) === Number(Sort),
            );
            console.log(
                'EditRequestBilling handleOnCreateNewPaymentMethodViaIFrame newPaymentList',
                newPaymentList,
            );
            console.log(
                'EditRequestBilling handleOnCreateNewPaymentMethodViaIFrame index',
                index,
            );

            if (index > -1) {
              newPaymentList[index] = newPaymentMethod;
            } else {
              newPaymentList.push(newPaymentMethod);
            }

            console.log(
                'EditRequestBilling handleOnCreateNewPaymentMethodViaIFrame newPaymentList 2',
                newPaymentList,
            );

            setPaymentList(newPaymentList);
          }
        }
      });
    }

    //payment plan form sends back
    // handleOnCreateNewPaymentMethodViaIFrame({
    //   ...x,
    //   seriesSummary: selectedSeriesRequest,
    // });
    // // payment method form sends back
    // handleOnCreateNewPaymentMethodViaIFrame({
    //   iframeResponse: x,
    //   Sort: 1,
    //   seriesSummary: selectedSeriesRequest,
    // });

    //setPaymentList({})
  };
  const handleOnSubmit = () => {
    onClickSave &&
    onClickSave({ paymentList, paymentPlanEnrollmentIsSelected });

    return false;
  };
  const handleOnChangePaymentPlanEnrollment = (e) => {
    const isChecked = e.target.checked;
    setPaymentPlanEnrollmentIsSelected(Boolean(isChecked));
  };

  const handleOnSelectPaymentMethod = (pm) => {
    console.log('handleOnSelectPaymentMethod paymentMethod', pm);

    if (pm && pm.PaymentMethodUUID) {
      if (!pm.Sort) {
        pm.Sort = 1;
      }

      let paymentListAdjusted = Array.isArray(paymentList)
          ? [...paymentList]
          : [];

      const paymentIndex = paymentListAdjusted.findIndex(
          (p) => Number(p.Sort) === Number(pm.Sort),
      );
      if (paymentIndex > -1) {
        paymentListAdjusted[paymentIndex] = {
          ...paymentListAdjusted[paymentIndex],
          ...pm,
        };
      } else {
        paymentListAdjusted.push(pm);
      }
      setPaymentList(paymentListAdjusted);
    } else {
      alert('error missing payment method details');
    }
  };

  let defaultPayment;

  if (Array.isArray(paymentList) && paymentList.length) {
    defaultPayment =
        paymentList.find((p) => Number(p.Sort) === 1) || paymentList[0];
  }

  console.log('EditRequestBilling paymentList', paymentList);
  console.log(
      'EditRequestBilling paymentPlanEnrollmentIsSelected',
      paymentPlanEnrollmentIsSelected,
  );
  console.log('EditRequestBilling defaultPayment', defaultPayment);

  let showPaymentPlanEnrollment = false;
  let paymentPlanRules;
  if (
      paymentPlanIsAvailable &&
      Array.isArray(outlet.OutletEventGroups) &&
      outletEvent.OutletEventGroup &&
      outletEvent.OutletEventGroup.OfferPaymentPlan
  ) {
    const oegFound = outlet.OutletEventGroups.find(
        (oeg) =>
            Number(oeg.OutletEventGroupID) ===
            Number(outletEvent.OutletEventGroup.OutletEventGroupID),
    );

    console.log('RLMTS-2894 oegFound', oegFound);
    if (oegFound && oegFound.PaymentPlanRules) {
      paymentPlanRules = oegFound.PaymentPlanRules;
      showPaymentPlanEnrollment =
          Number(seriesTotal) >=
          Number(oegFound.PaymentPlanRules.eligibilityMinimum);
    }
  }

  return (
      <>
        {showPaymentPlanEnrollment ? (
            <PaymentPlanEnrollment
                userIsEnrolledInPaymentPlan={paymentPlanEnrollmentIsSelected}
                onChangeCheckboxEnrollment={handleOnChangePaymentPlanEnrollment}
            />
        ) : null}

        {paymentPlanEnrollmentIsSelected ? (
            <>
              <PaymentPlanForm
                  paymentPlanRules={paymentPlanRules}
                  total={seriesTotal}
                  paymentList={paymentList}
                  userPaymentMethodList={userPaymentMethodList}
                  outletEventGroup={outletEvent && outletEvent.OutletEventGroup}
                  onChangePaymentMethod={(pm) => handleOnSelectPaymentMethod(pm)}
                  onCreateViaIFrame={(x = {}) =>
                      handleOnCreateNewPaymentMethodViaIFrame({
                        ...x,
                      })
                  }
              />
              {!authnetIframeIsVisible && (
                  <Button
                      fullWidth
                      loading={loading}
                      onClick={handleOnSubmit}
                      disabled={!paymentList || paymentList.length < 3 || loading}
                  >
                    Save
                  </Button>
              )}
            </>
        ) : (
            <>
              <PaymentMethodForm
                  defaultMethod={defaultPayment}
                  paymentMethods={userPaymentMethodList}
                  onSelect={(pm) => handleOnSelectPaymentMethod(pm)}
                  onCreateViaIFrame={(x) =>
                      handleOnCreateNewPaymentMethodViaIFrame({
                        iframeResponse: x,
                        Sort: 1,
                      })
                  }
              />
              {!authnetIframeIsVisible && (
                  <Button
                      fullWidth
                      loading={loading}
                      disabled={!defaultPayment || loading}
                      onClick={handleOnSubmit}
                  >
                    Save
                  </Button>
              )}
            </>
        )}
      </>
      // <PaymentPlanEnrollment
      //   userIsEnrolledInPaymentPlan={selectedSeriesRequest.PaymentPlanEnrolled}
      //   onChangeCheckboxEnrollment={(e) =>
      //     handleOnChangePaymentPlanEnrollment(e, selectedSeriesRequest)
      //   }
      // />
  );
}

export default EditRequestBilling;
