import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import Alert from '../../utils/Alert';
import Button from '../../utils/Button';
import { TextAreaInput, TextInput } from '../../utils/Form';
import { IconDelete, IconPlusCircle } from '../../utils/Icon';
import Typography from '../../utils/Typography';
import { validateEmail } from '../../utils/Validation';

const SaveButtonContainer = styled.div`
  margin: 1rem;
  cursor: pointer;
  //color: ${(props) => props.theme.PRIMARY_LINK_COLOR}!important;
  color: green;
`;
const DeleteButtonContainer = styled.div`
  margin: 1rem;
  cursor: pointer;
  color: red;
`;
const UpdateButtonContainer = styled.div`
  //margin: 0 1rem;
`;
const EmailListStyled = styled.ol`
  margin: 0 0 1rem 0;
  padding: 0 0 0 2rem;
  li {
    padding: 0 0 0 1rem;
  }
`;

export const CheckoutContactEmails = (props = {}) => {
  const {
    cart,
    onSubmit,
    showNote,
    showPhone,
    showConnectionToArtist,
  } = props;
  const [newEmail, setNewEmail] = useState('');
  const [formErrors, setFormErrors] = useState(null);
  const [GuestPhone, setGuestPhone] = useState(cart?.GuestPhone || '');
  const [verifyingEmailIsLoading, setVerifyingEmailIsLoading] = useState(false);
  const [RequesterNotes, setRequesterNotes] = useState(cart?.RequesterNotes || '');
  const [ConnectionEmail, setConnectionEmail] = useState(cart?.ConnectionEmail || '');
  const [ConnectionToArtist, setConnectionToArtist] = useState(cart?.ConnectionToArtist || '');
  const [emails, setEmails] = useState(Array.isArray(cart?.emails) ? [...cart.emails] : []);
  const session = useSelector((state) => state.session);

  const handleOnChangeExistingEmailInput = (e) => {
    const emailsAdjusted = [...emails];
    const value = e.target.value;
    const index = e.target.dataset.id;
    emailsAdjusted[index] = value;
    setEmails(emailsAdjusted);
  };

  const handleOnChangeNewEmailInput = (e) => setNewEmail(e.target.value);

  const handleOnClickAddNewEmail = () => {
    if (!newEmail || newEmail.trim() === '') {
      alert('email address input is empty');
    } else {
      const emailsAdjusted = [...emails, newEmail];
      setNewEmail('');
      setEmails(emailsAdjusted);
    }
  };

  const handleOnClickDeleteEmail = (id) => {
    const emailsAdjusted = [...emails];
    emailsAdjusted.splice(id, 1);
    setEmails(emailsAdjusted);
  };

  const handleOnSubmit = () => {
    const formErrorsNew = {};
    setFormErrors(formErrorsNew);
    const connectionEmailTrimmed = ConnectionEmail.trim(); // GT-3878
    if (showConnectionToArtist && !ConnectionToArtist) {
      formErrorsNew.ConnectionToArtist = 'Connection with Artist Name Required';
    }
    if (showConnectionToArtist && !connectionEmailTrimmed) {
      formErrorsNew.ConnectionEmail = 'Connection\'s Email Required';
    }
    if (showPhone && !GuestPhone) {
      formErrorsNew.GuestPhone = 'GuestPhone Number Required';
    }
    if (Object.keys(formErrorsNew).length) {
      setFormErrors(formErrorsNew);
      return false;
    } else {
      if (showConnectionToArtist) {
        setVerifyingEmailIsLoading(true);
        validateEmail(connectionEmailTrimmed).then((res) => {
          const validEmail = res && res.valid;
          if (validEmail !== true) {
            formErrorsNew.ConnectionEmail = 'Please verify email address and try again';
            setFormErrors(formErrorsNew);
            setVerifyingEmailIsLoading(false);
            return false;
          } else {
            if (onSubmit) {
              onSubmit({
                emails, ConnectionToArtist, RequesterNotes, GuestPhone,
                ConnectionEmail: connectionEmailTrimmed,
              });
            }
          }
        }).catch((err) => {
          formErrorsNew.ConnectionEmail = 'Error validating email address, please try again';
          setFormErrors(formErrorsNew);
          setVerifyingEmailIsLoading(false);
          return false;
        });
      } else {
        if (onSubmit) {
          onSubmit({
            emails, ConnectionToArtist, RequesterNotes, GuestPhone,
            ConnectionEmail: connectionEmailTrimmed,
          });
        }
      }
    }
  };

  return (
    <div>
      <div style={{ margin: '0 0 1rem 0' }}>
        All notifications will be sent to this address. Please add additional
        email addresses to receive a copy of these notifications.
      </div>
      <EmailListStyled>
        <li style={{ fontSize: '1.6rem', padding: '1rem 1.5rem' }}>
          {(session && session.EmailAddress) || 'N/A'}
        </li>
        {emails.map((val, idx) => {
          const emailId = `email-${idx}`;
          return (
            <li key={idx}>
              <div style={{ display: 'flex' }}>
                <div style={{ flex: 1 }}>
                  <TextInput
                    name={emailId}
                    data-id={idx}
                    id={emailId}
                    value={val}
                    fullWidth
                    onChange={handleOnChangeExistingEmailInput}
                  />
                </div>
                <DeleteButtonContainer
                  onClick={() => handleOnClickDeleteEmail(idx)}
                >
                  <IconDelete title="Delete email address" />
                </DeleteButtonContainer>
              </div>
            </li>
          );
        })}
        <li>
          <div style={{ display: 'flex' }}>
            <div style={{ flex: 1 }}>
              <TextInput
                value={newEmail}
                placeholder="Enter Additional Email Address"
                fullWidth
                onChange={handleOnChangeNewEmailInput}
              />
            </div>
            <SaveButtonContainer onClick={handleOnClickAddNewEmail}>
              <IconPlusCircle title="Add new email address" />
            </SaveButtonContainer>
          </div>
        </li>
      </EmailListStyled>

      {showConnectionToArtist && (
        <>
          <TextInput
            name="ConnectionToArtist"
            label="*Connection with artist.  Who referred you?"
            error={formErrors && formErrors.ConnectionToArtist}
            fullWidth
            value={ConnectionToArtist}
            onChange={(e) => setConnectionToArtist(e.target.value)}
          />
          <TextInput
            name="ConnectionEmail"
            label="*Connection’s email address. Who sent you the link?"
            error={formErrors && formErrors.ConnectionEmail}
            fullWidth
            value={ConnectionEmail}
            onChange={(e) => setConnectionEmail(e.target.value)}
          />
        </>
      )}
      {showPhone && (
        <TextInput
          name="GuestPhone"
          label="*Guest Mobile Phone Number"
          error={formErrors && formErrors.GuestPhone}
          fullWidth
          value={GuestPhone}
          onChange={(e) => setGuestPhone(e.target.value)}
        />
      )}
      {showNote && (
        <>
          <Typography tag="h1" style={{ margin: '0 0 1rem 0' }}>Notes</Typography>
          <TextAreaInput
            name="RequesterNotes"
            label="Notes concerning your request"
            fullWidth
            value={RequesterNotes}
            onChange={(e) => setRequesterNotes(e.target.value)}
          />
        </>
      )}
      {formErrors && Object.keys(formErrors).length ? (
        <Alert style={{ margin: '2rem 0' }}>
          {Object.keys(formErrors).map((key) => {
            return (
              <div key={key}>{formErrors[key]}</div>
            );
          })}
        </Alert>
      ) : null}
      {onSubmit && (
        <UpdateButtonContainer>
          <Button
            fullWidth
            onClick={handleOnSubmit}
            title="Submit contact information"
            loading={verifyingEmailIsLoading}
          >
            Continue
          </Button>
        </UpdateButtonContainer>
      )}
    </div>
  );
};

CheckoutContactEmails.defaultProps = {
  cart: null,
  onSubmit: null,
  showNote: false,
  showPhone: false,
  showConnectionToArtist: false,
};

CheckoutContactEmails.propTypes = {
  cart: PropTypes.object,
  onSubmit: PropTypes.func,
  showNote: PropTypes.bool,
  showPhone: PropTypes.bool,
  showConnectionToArtist: PropTypes.bool,
};

export default CheckoutContactEmails;
