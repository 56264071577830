import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Alert from '../utils/Alert';
import Attention from '../utils/Attention';
import AuthContainer from '../components/auth/AuthContainer';
import Button from '../utils/Button';
import CheckoutAddons from '../components/checkout/CheckoutAddons';
import Collapse from '../utils/Collapse';
import Columns from '../utils/Columns';
import Contain from '../components/app/Contain';
import DisplayError from '../utils/Error';
import Modal from '../utils/Modal';
import OutletEventDetails from '../components/outletEvent/OutletEventDetails';
import PriceLevelSelectorFanRequest from '../components/priceLevel/PriceLevelSelectorFanRequest';
import PriceLevelSelectorInstant from '../components/priceLevel/PriceLevelSelectorInstant';
import Typography from '../utils/Typography';
import { calculateSeriesStatuses, goToUrl, htmlHasValidContent } from '../utils/utilities';
import {
  deleteInstantRequest,
  resetFanRequestCart,
  resetGuestListCart,
  updateFanRequest,
  updateInstantRequest,
} from '../components/cart/actions';
import { getUserFanRequests } from '../components/user/actions';

const LeftColumStyled = styled.div`
  margin: 2rem 0 0 0;
`;
const RightColumStyled = styled.div`
  margin: 2rem 0 0 0;
  @media (min-width: ${(props) => props.theme.BREAKPOINT_MOBILE_WIDTH}px) {
  }
`;
const ImportantInfoBox = styled.div`
  border: solid 1px ${(props) => props.theme.PRIMARY_GRAY};
  background-color: ${(props) => props.theme.PRIMARY_GRAY}70;
  padding: 1rem;

  margin: 0 0 2rem 0;
`;
const ImportantInfoBoxHeader = styled.div`
  margin: 0 0 1rem 0;
  text-align: center;
  font-weight: 700;
  color: ${(props) => props.theme.PRIMARY_LINK_COLOR};
`;
const ImportantInfoBoxContent = styled.div`
  //padding: 1rem;
`;
const ErrorTextStyled = styled.div`
  font-size: 1.4rem;
  margin: 0;
  padding: 0.5rem 0rem;
  font-style: italic;
  color: ${(props) => props.theme.ERROR_TEXT_COLOR};
`;
const PriceLevelContainerStyled = styled.div`
  padding: 0 1rem;
  border-bottom: solid 1px ${(props) => props.theme.PRIMARY_GRAY};
`;

function EventView() {
  const [modal, setModal] = useState({
    title: '',
    content: '',
    constant: null,
    isVisible: false,
  });
  const [outletEvent, setOutletEvent] = useState(null);
  const [selectedPriceLevels, setSelectedPriceLevels] = useState([]);
  const [selectedLotteryItems, setSelectedLotteryItems] = useState([]);
  const userIsAuthenticated = useSelector(
      (state) => state.auth.userIsAuthenticated,
  );
  const api = useSelector((state) => state.api);
  const outlet = useSelector((state) => state.outlet);
  const outletEventList = useSelector((state) => state.eventList);
  const userFanRequestList = useSelector((state) => state.userFanRequestList); //userFanRequestListStubData; //
  const instantRequest = useSelector((state) => state.instantRequest);
  const fanRequest = useSelector((state) => state.fanRequest);
  const userFanRequestSeriesSummaryList = useSelector(
      (state) => state.userFanRequestSeriesSummaryList,
  );

  const { OutletEventID } = useParams();

  const [selectedRequestAddons, setSelectedRequestAddons] = useState([]);

  useEffect(() => {
    //clear cart method here????
    //setOutletEvent(outletEvent883StubData);
    resetGuestListCart();
    resetFanRequestCart();
    deleteInstantRequest();

    const outletEventFound =
        OutletEventID &&
        outletEventList &&
        outletEventList.find(
            (oe) => Number(oe.OutletEventID) === Number(OutletEventID),
        );

    if (outletEventFound) {
      setOutletEvent(outletEventFound);
      setSelectedPriceLevels([]);
      const eventData = outletEventFound.Event;
      const eventdate =
          eventData && eventData.ShowDate ? new Date(eventData.ShowDate) : '';
      const venueName =
          eventData && eventData.Venue ? eventData.Venue.VenueName : '';

      document.title =
          (outletEventFound.SalesTypeID === 2 ? 'Request - ' : 'Buy - ') +
          venueName +
          ' - ' +
          (eventdate.getMonth() + 1) +
          '/' +
          eventdate.getDate() +
          ' | ' +
          outlet.OutletName;
    }

    return function cleanup() {
      document.title = outlet.OutletName;
    };
  }, [outletEvent]);

  const handleClickPolicy = (policy) => {
    setModal({
      content: policy.PolicyText,
      title: policy.Headline,
      constant: 'POLICY',
      isVisible: true,
    });
  };

  const handleOnChangeLotteryRequest = (
      formdata = {},
      outletEventItem,
      existingLotteryOEIRequest,
  ) => {

    const OutletEventItemID =
        outletEventItem && parseInt(outletEventItem.OutletEventItemID, 10);
    const LotteryOEIRequestUUID =
        existingLotteryOEIRequest &&
        existingLotteryOEIRequest.LotteryOEIRequestUUID;

    if (OutletEventItemID) {
      const requestedItem = {
        LotteryOEIRequestUUID,
        PriceLevelID: parseInt(Number(formdata.selectedPriceLevelId), 10),
        Quantity: parseInt(formdata.selectedQty, 10),
        OutletEventItemID,
        isAddOn: false,
        PriceLevelOptOut: formdata.optOutIsSelected,
      };

      const selectedLotteryItemsUpdated = [...selectedLotteryItems];
      const index = selectedLotteryItems.findIndex(
          (item) => item.OutletEventItemID === OutletEventItemID,
      );

      if (index > -1) {
        selectedLotteryItemsUpdated[index] = requestedItem;
      } else {
        selectedLotteryItemsUpdated.push(requestedItem);
      }
      setSelectedLotteryItems(selectedLotteryItemsUpdated);
    }
  };

  const handleOnChangePriceLevel = (priceLevel, increment = 1) => {
    let selectedPriceLevelsUpdated = [...selectedPriceLevels];
    const index = selectedPriceLevels.findIndex(
        (pl) => pl.PriceLevelID === priceLevel.PriceLevelID,
    );

    if (index === -1) {
      let selectedPriceLevel = { ...priceLevel };
      selectedPriceLevel.quantity = increment;
      selectedPriceLevelsUpdated.push(selectedPriceLevel);
    } else {
      let spl = selectedPriceLevelsUpdated[index];
      spl.quantity = spl.quantity + increment;
      if (spl.quantity < 1) {
        selectedPriceLevelsUpdated.splice(index);
      }
    }
    setSelectedPriceLevels(selectedPriceLevelsUpdated);
  };

  const handleOnSubmitInstant = () => {
    //clean up any existing (old) cart data before creating new
    deleteInstantRequest();

    const lineItems = selectedPriceLevels.map((li) => {
      const formatted = {
        priceLevelID: li.PriceLevelID,
        quantity: li.quantity,
      };
      return formatted;
    });

    const config = {
      action: 'INSTANT_REQUEST_CREATE_CART',
      requestBody: { lineItems },
      outletEvent,
    };

    updateInstantRequest(config).then((res) => {
      //setInstantCart({ selectedPriceLevels: lineItems });
      goToUrl('/checkout/i/review', { replace: false });
      //goToUrl("/checkout/" + res.cartUUID, { replace: true });
    }).catch((error) => {
      //alert("error");
      // if(error=="Not enough inventory to fulfill request.")
      // if (error == "Insufficient Inventory") {
      // setModalTitle("Unable to complete request");
      // setModalContent(error);
      // setModalConstant("QTY_REQUESTED");
      // setModalIsVisible(true);
      //}
    });
  };

  const handleRequestAddOnQtyChange = (selectedPriceLevels) => {
    const selectedAddonsFormattted = selectedPriceLevels
        //.filter((p) => p.isAddOn)
        .map((spl) => {
          const quantity = parseInt(Number(spl.quantity || spl.Quantity, 10));
          const priceLevelID = parseInt(Number(spl['PriceLevelID'], 10));
          let outletEventItemID =
              spl.OutletEventItemID || (spl.oei && spl.oei.OutletEventItemID);
          outletEventItemID = parseInt(Number(outletEventItemID, 10));
          return {
            ...spl,
            quantity,
            Quantity: quantity,
            priceLevelID,
            isAddOn: true,
            outletEventItemID,
          };
        });
    setSelectedRequestAddons(selectedAddonsFormattted);
  };

  const showAddonsModal = (outletEvent) => {
    const outletEventItemsWithAddons =
        outletEvent && Array.isArray(outletEvent.OutletEventItems)
            ? outletEvent.OutletEventItems.filter(
                (oei) => oei?.EventItem?.SuppressOnEDP,
            ).filter((oei) => (oei.PriceLevels || []).length !== 0)
            : [];

    let priceLevelsAreAvailable = false;

    if (outletEventItemsWithAddons.length) {
      outletEventItemsWithAddons.map((oei) => {
        if (oei.PriceLevels && oei.PriceLevels.length) {
          oei.PriceLevels.map((priceLevel, plidx) => {
            if (priceLevel.SaleStatus !== 'OFF_SALE') {
              priceLevelsAreAvailable = true;
            }
            return null;
          });
        }
      });
    }

    if (priceLevelsAreAvailable) {
      setModal({
        content: outletEventItemsWithAddons,
        title: 'Addons',
        constant: 'FAN_REQUEST_ADDONS',
        isVisible: true,
      });
    }
  };

  const handleOnSubmitFanRequest = () => {
    const lineItems = Array.isArray(selectedLotteryItems)
        ? selectedLotteryItems.filter((li) => li.PriceLevelID)
        : null;
    if (lineItems && lineItems.length && outletEvent) {
      //RLMTS-2694 does this event request belong to an existing series request
      //if so, skip remaining checkout stage and send user to My requests page
      let existingSeriesRequest;
      const OutletEventGroupID =
          outletEvent.OutletEventGroup &&
          outletEvent.OutletEventGroup.OutletEventGroupID;

      if (
          OutletEventGroupID &&
          Array.isArray(userFanRequestSeriesSummaryList)
      ) {
        existingSeriesRequest = userFanRequestSeriesSummaryList.find(
            (x) => Number(x.OutletEventGroupID) === OutletEventGroupID,
        );
      }

      const addonsAvailable =
          outletEvent &&
          outletEvent.OutletEventItems &&
          outletEvent.OutletEventItems.some(
              (oei) => oei?.EventItem?.SuppressOnEDP,
          );

      if (existingSeriesRequest) {
        const lineItemsMerged = [...lineItems];
        if (
            Array.isArray(selectedRequestAddons) &&
            selectedRequestAddons.length
        ) {
          selectedRequestAddons.map((addon) => {
            const { OutletEventItemID, PriceLevelID } = addon;
            lineItemsMerged.push({
              OutletEventItemID,
              PriceLevelID,
              isAddOn: true,
              PriceLevelOptOut: false,
              Quantity: addon.quantity || addon.qty,
            });
          });
        }
        const config = {
          action: 'SAVE_FAN_REQUEST',
          cart: { lineItems: lineItemsMerged },
          outletEvent,
        };

        //Start RLMTS-2871 show user message if they are newly eligible for payment plan
        const paymentPlanIsAvailable = Boolean(
            existingSeriesRequest &&
            existingSeriesRequest.OutletEventGroup &&
            existingSeriesRequest.OutletEventGroup.OfferPaymentPlan,
        );
        const userIsEnrolledInPaymentPlan =
            existingSeriesRequest && existingSeriesRequest.PaymentPlanEnrolled;

        const existingSeriesTotal =
            (existingSeriesRequest.Totals &&
                existingSeriesRequest.Totals.total) ||
            0;

        let adjustedSeriesTotal = existingSeriesTotal;

        if (Array.isArray(lineItemsMerged)) {
          lineItemsMerged.map((li) => {
            const { OutletEventItemID, PriceLevelID, Quantity } = li;

            if (outletEvent && outletEvent.OutletEventItems) {
              const oeiFound = outletEvent.OutletEventItems.find(
                  (oei) =>
                      Number(oei.OutletEventItemID) === Number(OutletEventItemID),
              );
              if (oeiFound && oeiFound.PriceLevels) {
                const plFound = oeiFound.PriceLevels.find(
                    (pl) => Number(pl.PriceLevelID) === Number(PriceLevelID),
                );
                if (plFound && plFound.PriceLevelValue && Quantity) {
                  adjustedSeriesTotal += plFound.PriceLevelValue * Quantity;
                }
              }
            }
          });
        }
        let userIsNewlyEligibleForPaymentPlan = false;

        if (outlet && Array.isArray(outlet.OutletEventGroups)) {
          const oegFound = outlet.OutletEventGroups.find(
              (oeg) =>
                  Number(oeg.OutletEventGroupID) ===
                  Number(outletEvent.OutletEventGroup.OutletEventGroupID),
          );
          if (oegFound && oegFound.PaymentPlanRules) {
            userIsNewlyEligibleForPaymentPlan =
                !userIsEnrolledInPaymentPlan &&
                paymentPlanIsAvailable &&
                adjustedSeriesTotal >=
                Number(oegFound.PaymentPlanRules.eligibilityMinimum);
          }
        }
        //End RLMTS-2871

        updateFanRequest(config).then((res) => {
          // 2715 use modal instead of toast
          // addToast({
          //   title: "Success",
          //   description: "Your request was updated",
          //   type: "success",
          // });
          setSelectedRequestAddons([]);
          getUserFanRequests();
          let myRequestUrl = '/user/history/requests';
          if (existingSeriesRequest && existingSeriesRequest.RequestID) {
            myRequestUrl += '/' + existingSeriesRequest.RequestID;
          }
          //goToUrl(myRequestUrl);
          setModal({
            content: myRequestUrl,
            title: 'Successfully updated request',
            constant: 'FAN_REQUEST_UPDATE',
            isVisible: true,
            userIsNewlyEligibleForPaymentPlan,
          });
        });
      } else {
        const stage = addonsAvailable ? 'addons' : 'shipping-address';
        const checkoutUrl = '/checkout/f/' + stage;

        // Fan request example
        // LotteryOEIRequestUUID: undefined
        // OutletEventItemID: 578
        // PriceLevelID: 957
        // PriceLevelOptOut: true
        // Quantity: 4
        // isAddOn: false

        // Instant cart example
        // lineItems: [
        //   {
        //     priceLevelID: 955,
        //     quantity: 4,
        //     seatInfo: {
        //       priceLevel: {
        //         priceLevelName: "Gold reserve seats",
        //         itemName: "Tickets",
        //       },
        //     },
        //     held: true,
        //   }

        updateFanRequest({
          outletEvent,
          action: 'CREATE_FAN_REQUEST_WITH_SERIES_SUMMARY',
          cart: { lineItems },
        }).then((res) => {
          setSelectedRequestAddons([]);
          goToUrl(checkoutUrl);
        });
      }
    } else {
      alert('invalid form');
      return false;
    }
  };

  const handleClickRefreshPriceLevels = (refetch) => {
  };

  const renderSeriesMessage = (outletEvent) => {
    if (!outletEvent || !outletEvent.OutletEventGroup) return null;

    const { seriesIsVisible, seriesIsOpen, seriesIsClosed } =
        calculateSeriesStatuses(outletEvent.OutletEventGroup);
    const { SeriesVisibleMessage, SeriesClosedMessage } =
        outletEvent.OutletEventGroup;
    let customText = '';

    if (seriesIsVisible && seriesIsOpen == false && !seriesIsClosed) {
      customText = SeriesVisibleMessage ? SeriesVisibleMessage : '';
    } else if (seriesIsClosed) {
      customText = SeriesClosedMessage ? SeriesClosedMessage : '';
    }

    if (seriesIsClosed || (seriesIsVisible && seriesIsOpen == false)) {
      let hyperlinkText = 'On Sale Now';
      let ev = outletEvent.Event;

      if (ev.PublicOnsaleURL) {
        // if ev.PublicOnsaleDate == null
        // add "On Sale Now" link that links to ev.PublicOnsaleURL
        if (ev.PublicOnsaleDate == null) {
          customText =
              customText +
              `<a href=${ev.PublicOnsaleURL}>${ev.PublicOnSaleTextOverride
                  ? ev.PublicOnSaleTextOverrideText
                  : hyperlinkText
              }</a>`;
        } else {
          // if outlet.Event.PublicOnsaleDate != null
          let saleDate = new Date(ev.PublicOnsaleDate);
          if (saleDate > new Date()) {
            let dateStr = saleDate.toLocaleString('en-US', {
              day: 'numeric',
              year: 'numeric',
              month: 'long',
              timeZone: 'America/New_York',
            });
            let timeStr = saleDate.toLocaleString('en-US', {
              timeStyle: 'short',
              timeZone: 'America/New_York',
            });

            // if date is in the future
            //�Public Onsale: [POS month] [POS date], 2023 at [POS time (if time is set)] local time through Event Date�
            hyperlinkText = `Public Onsale: ${dateStr} at ${timeStr} local time through Event Date`;
            customText =
                customText +
                `<a href=${ev.PublicOnsaleURL}>${ev.PublicOnSaleTextOverride
                    ? ev.PublicOnSaleTextOverrideText
                    : hyperlinkText
                }</a>`;
          } else {
            // if date is reached or in the past
            // add "On Sale Now" link without the time info
            customText =
                customText +
                `<a href=${ev.PublicOnsaleURL}>${ev.PublicOnSaleTextOverride
                    ? ev.PublicOnSaleTextOverrideText
                    : hyperlinkText
                }</a>`;
          }
        }
      }
    }
    const textIsValid = htmlHasValidContent(customText);

    if (customText && textIsValid) {
      return (
          <ImportantInfoBox>
            <ImportantInfoBoxHeader>Important Information</ImportantInfoBoxHeader>
            <ImportantInfoBoxContent>
              <div
                  dangerouslySetInnerHTML={{
                    __html: customText,
                  }}
              />
            </ImportantInfoBoxContent>
          </ImportantInfoBox>
      );
    }

    return null;
  };

  const handleClickLoginButton = () => {
    if (outlet && outlet.LoginURL) {
      goToUrl(outlet.LoginURL, { outside: true });
    } else {
      setModal({
        isVisible: true,
        title: 'Login to Proceed',
        constant: 'LOGIN',
      });
    }
  };

  const handleOnLogin = () => {
    setModal({ isVisible: false });
  };

  if (outletEvent) {
    //  salesTypeId 1 = Instant real time tix
    //  salesTypeId 2 = Lottery
    //  salesTypeId 3 = External sales url
    const { SalesTypeID, OutletEventGroup } = outletEvent;
    const loading = instantRequest.loading || fanRequest.loading;
    let addonsAvailable =
        outletEvent &&
        outletEvent.OutletEventItems &&
        outletEvent.OutletEventItems.some(
            (oei) => oei?.EventItem?.SuppressOnEDP,
        );

    let existingSeriesSummary;
    const existingFanRequest =
        userFanRequestList &&
        userFanRequestList.find(
            (lr) => lr.OutletEventID === outletEvent.OutletEventID,
        );

    if (existingFanRequest) {
      existingSeriesSummary = userFanRequestSeriesSummaryList.find(
          (x) => x.LotteryOEGRequestID === existingFanRequest.LotteryOEGRequestID,
      );
    }

    const ticketGuidlines = outlet.OutletPolicies.filter(
        (policy) =>
            (policy.SalesTypeID === SalesTypeID || policy.SalesTypeID === -1) &&
            policy.OutletPolicyTypeID === 4,
    )[0];
    //RLMTS-1381 Remove OEI's that are "suppressed" on event details page (EDP)
    //RLMTS-605 Sort OEI's by Sort value
    const outletEventItemList = outletEvent.OutletEventItems.filter(
        (oei) => !oei?.EventItem?.SuppressOnEDP,
    );
    const { seriesIsVisible, seriesIsOpen, seriesIsClosed } =
        calculateSeriesStatuses(OutletEventGroup);
    const eventIsReadyToSell =
        seriesIsOpen && outletEventItemList && outletEventItemList.length;

    const userFanRequestListIsLoading =
        api && api.loading.includes('GET_USER_FAN_REQUESTS');
    const userFanRequestSeriesSummaryIsLoading =
        api && api.loading.includes('GET_USER_FAN_REQUEST_SERIES_SUMMARY');

    let continueButtonLabel = 'Buy Now';
    let continueButtonIsDisabled = true;
    let continueButtonIsVisible = true;
    let loginButtonIsVisible = false;
    let errorMessages = [];

    if (SalesTypeID === 2) {
      continueButtonLabel = 'Continue';

      if (selectedLotteryItems.length) {
        const selectedLotteryItemsFiltered = selectedLotteryItems.filter(
            (x) => x.PriceLevelID || x.Quantity,
        );

        selectedLotteryItemsFiltered.map((x) => {
          if (x.PriceLevelID || x.Quantity) {
            let oei, errorMessage;
            if (Array.isArray(outletEventItemList)) {
              oei = outletEventItemList.find(
                  (y) =>
                      Number(y.OutletEventItemID) === Number(x.OutletEventItemID),
              );
            }
            if (!x.PriceLevelID) {
              errorMessage = oei.Name + ': Price level required';
            }
            if (!x.Quantity || Number(x.Quantity) < 1) {
              errorMessage = oei.Name + ': Quantity required';
            }
            if (oei && errorMessage) {
              errorMessages.push({
                OutletEventItemID: x.OutletEventItemID,
                message: errorMessage,
                oei,
              });
            }
          }
        });

        const allItemsValid =
            selectedLotteryItemsFiltered &&
            selectedLotteryItemsFiltered.length &&
            selectedLotteryItemsFiltered.every(
                (l) => l.PriceLevelID && l.Quantity && Number(l.Quantity) > 0,
            );
        if (allItemsValid) {
          continueButtonIsDisabled = false;
        }
      }

      if (!userIsAuthenticated) {
        loginButtonIsVisible = true;
        continueButtonIsVisible = false;
      }

      if (seriesIsClosed || !seriesIsOpen) {
        continueButtonIsVisible = false;
      }

      if (existingFanRequest) {
        continueButtonLabel = 'Go to my requests';
        continueButtonIsDisabled = false;
      }
    } else {
      if (selectedPriceLevels.length) {
        continueButtonIsDisabled = false;
      }

      if (seriesIsClosed || !seriesIsOpen) {
        continueButtonIsVisible = false;
      }
    }

    return (
        <div style={{ height: '100%' }}>
          {ticketGuidlines && (
              <Attention onClick={() => handleClickPolicy(ticketGuidlines)}>
                <Typography
                    style={{
                      fontWeight: '700',
                      textAlign: 'center',
                      cursor: 'pointer',
                    }}
                >
                  {ticketGuidlines.Headline}
                </Typography>
              </Attention>
          )}

          <Contain hasPaddingMobile={true}>
            <Columns qty={2}>
              <LeftColumStyled>
                {/* //RLMTS-2695
              <Typography tag="h1" style={{ margin: "0 0 2rem 0" }}>
                Event Details
              </Typography> */}
                <OutletEventDetails outletEvent={outletEvent}/>
              </LeftColumStyled>
              <RightColumStyled>
                <>
                  <Typography tag="h1" style={{ margin: '0 0 2rem 0' }}>
                    Select Items
                  </Typography>

                  {renderSeriesMessage(outletEvent)}

                  {Array.isArray(outletEventItemList) &&
                      outletEventItemList.map((oei, idx) => {
                        const EventItemTypeID =
                            oei.EventItem && oei.EventItem.EventItemTypeID;

                        const outletEventItemIsATicket =
                            EventItemTypeID && EventItemTypeID === 1;

                        const lotteryOEIRequest =
                            existingFanRequest &&
                            existingFanRequest.LotteryOEIRequests &&
                            existingFanRequest.LotteryOEIRequests.find(
                                (r) => r.OutletEventItemID === oei.OutletEventItemID,
                            );
                        //RLMTS-1955
                        //OEIs will display in 2 possible states:
                        //1. Available - available OEIs have visible price levels below it.  PL's are either yet to go on-sale, currently on-sale or marked as sold out (see Price Level notes below)
                        //2. Unavailable - if there are no Price Levels under the OEI (due to all PL's either being hidden or Off Sale) then the OEI is unavailable and should show the message "Currently unavailable."
                        let priceLevelsAreAvailable = false;
                        if (oei.PriceLevels && oei.PriceLevels.length) {
                          oei.PriceLevels.map((priceLevel, plidx) => {
                            if (priceLevel.SaleStatus !== 'OFF_SALE') {
                              priceLevelsAreAvailable = true;
                            }
                            return null;
                          });
                        }

                        return (
                            <div key={'oei-' + idx} style={{ margin: '0 0 3rem 0' }}>
                              <Collapse
                                  heading={oei.Name}
                                  subHeading={oei.Description}
                                  disabled={seriesIsClosed || !seriesIsOpen}
                              >
                                {priceLevelsAreAvailable ? (
                                    <div>
                                      {SalesTypeID === 2 ? (
                                          <div>
                                            {userIsAuthenticated ? (
                                                <div>
                                                  {userFanRequestListIsLoading ||
                                                  userFanRequestSeriesSummaryIsLoading ? (
                                                      <ImportantInfoBox>
                                                        ...loading your requests
                                                      </ImportantInfoBox>
                                                  ) : existingFanRequest ? (
                                                      <ImportantInfoBox>
                                                        You have an existing request for this
                                                        event. If you wish to make changes,
                                                        please visit the
                                                        <Typography
                                                            tag="a"
                                                            onClick={() => {
                                                              let url =
                                                                  '/user/history/requests';
                                                              if (existingSeriesSummary) {
                                                                url =
                                                                    url +
                                                                    '/' +
                                                                    existingSeriesSummary.RequestID +
                                                                    '/item-details/' +
                                                                    existingFanRequest.LotteryOERequestUUID;
                                                              }
                                                              goToUrl(url);
                                                            }}
                                                        >
                                                          {' '}
                                                          my requests page
                                                        </Typography>
                                                      </ImportantInfoBox>
                                                  ) : (
                                                      <PriceLevelSelectorFanRequest
                                                          lotteryOEIRequest={lotteryOEIRequest}
                                                          outletEventItem={oei}
                                                          onChangeCallback={(formData) =>
                                                              handleOnChangeLotteryRequest(
                                                                  formData,
                                                                  oei,
                                                                  lotteryOEIRequest,
                                                              )
                                                          }
                                                      />
                                                  )}
                                                </div>
                                            ) : (
                                                <Button
                                                    fullWidth
                                                    name="btn-login"
                                                    loading={loading}
                                                    onClick={handleClickLoginButton}
                                                >
                                                  Login to proceed
                                                </Button>
                                            )}
                                          </div>
                                      ) : (
                                          <div>
                                            {oei.PriceLevels.map((priceLevel, plidx) => {
                                              const selectedPriceLevel =
                                                  selectedPriceLevels.find(
                                                      (spl) =>
                                                          spl.PriceLevelID ===
                                                          priceLevel.PriceLevelID,
                                                  );
                                              // RLMTS-1927 Price Level Selector is disabled when:
                                              // 1) a price level has already been choosen from an OEI of type === "Ticket"
                                              let priceLevelSelectorIsEnabled = false;
                                              if (
                                                  selectedPriceLevel ||
                                                  selectedPriceLevels.length < 1 ||
                                                  outletEventItemIsATicket === false
                                              ) {
                                                priceLevelSelectorIsEnabled = true;
                                              }

                                              return (
                                                  <PriceLevelContainerStyled key={plidx}>
                                                    <PriceLevelSelectorInstant
                                                        priceLevel={
                                                            selectedPriceLevel || priceLevel
                                                        }
                                                        updatePriceLevelQty={
                                                          priceLevelSelectorIsEnabled
                                                              ? (pl, qty) =>
                                                                  handleOnChangePriceLevel(
                                                                      pl,
                                                                      qty,
                                                                  )
                                                              : false
                                                        }
                                                    />
                                                  </PriceLevelContainerStyled>
                                              );
                                            })}
                                          </div>
                                      )}
                                    </div>
                                ) : (
                                    <ErrorTextStyled>
                                      Currently unavailable
                                    </ErrorTextStyled>
                                )}
                              </Collapse>
                            </div>
                        );
                      })}

                  <div style={{ margin: '3rem 0' }}>
                    {continueButtonIsVisible && (
                        <>
                          {errorMessages && errorMessages.length
                              ? errorMessages.map((e) => (
                                  <DisplayError>{e.message}</DisplayError>
                              ))
                              : null}
                          <Button
                              fullWidth
                              name="btn-continue"
                              loading={loading || userFanRequestListIsLoading}
                              disabled={
                                  loading ||
                                  continueButtonIsDisabled ||
                                  userFanRequestListIsLoading
                              }
                              onClick={() => {
                                if (SalesTypeID === 2) {
                                  if (existingFanRequest) {
                                    //if lottery request
                                    goToUrl(
                                        '/user/history/requests/' +
                                        existingFanRequest.LotteryOERequestUUID,
                                    );
                                  } else {
                                    let existingSeriesRequest;
                                    const OutletEventGroupID =
                                        outletEvent.OutletEventGroup &&
                                        outletEvent.OutletEventGroup.OutletEventGroupID;

                                    if (
                                        OutletEventGroupID &&
                                        Array.isArray(userFanRequestSeriesSummaryList)
                                    ) {
                                      existingSeriesRequest =
                                          userFanRequestSeriesSummaryList.find(
                                              (x) =>
                                                  Number(x.OutletEventGroupID) ===
                                                  OutletEventGroupID,
                                          );
                                    }
                                    if (existingSeriesRequest && addonsAvailable) {
                                      showAddonsModal(outletEvent);
                                    } else {
                                      handleOnSubmitFanRequest();
                                    }
                                  }
                                } else {
                                  handleOnSubmitInstant();
                                }
                              }}
                          >
                            {continueButtonLabel}
                          </Button>{' '}
                        </>
                    )}
                  </div>
                </>
              </RightColumStyled>
            </Columns>
            <Modal
                title={modal.title}
                open={modal.isVisible}
                onClose={() => {
                  if (modal.constant === 'QTY_REQUESTED') {
                    handleClickRefreshPriceLevels();
                  }
                  setModal({ isVisible: false });
                }}
            >
              {modal.constant === 'FAN_REQUEST_ADDONS' && (
                  <div>
                    <CheckoutAddons
                        //expandedOutletEventItems={expandedOutletEventItems}
                        // selectedPriceLevels={cart.lineItems.filter(
                        //   (li) => li.isAddOn
                        // )}
                        outletEventItems={modal.content}
                        handleAddOnQtyChange={handleRequestAddOnQtyChange}
                    />
                    <Button
                        fullWidth
                        name="btn-continue"
                        loading={loading || userFanRequestListIsLoading}
                        disabled={
                            loading ||
                            continueButtonIsDisabled ||
                            userFanRequestListIsLoading
                        }
                        onClick={() => handleOnSubmitFanRequest()}
                    >
                      Continue
                    </Button>
                  </div>
              )}
              {modal.constant === 'QTY_REQUESTED' && (
                  <div>
                    Please reduce the quantity requested or choose a different price
                    level
                    <div style={{ margin: '3rem 0' }}>
                      <Button onClick={() => handleClickRefreshPriceLevels()}>
                        Go back
                      </Button>
                    </div>
                  </div>
              )}
              {modal.constant === 'POLICY' && (
                  <div
                      dangerouslySetInnerHTML={{
                        __html: modal.content,
                      }}
                  />
              )}
              {modal.constant === 'LOGIN' && (
                  <AuthContainer
                      onLogin={() => handleOnLogin()}
                      onCreateAccount={() => handleOnLogin()}
                  />
              )}
              {modal.constant === 'FAN_REQUEST_UPDATE' && (
                  <div>
                    <div
                        style={{
                          fontWeight: 'bold',
                          color: 'green',
                          textAlign: 'center',
                        }}
                    >
                      <p>You successfully added an event to your request</p>
                      {modal.userIsNewlyEligibleForPaymentPlan && (
                          <p>
                            {' '}
                            Your request is eligible for you to enroll in the payment
                            plan. View your request and manage your billing
                            information to enroll.{' '}
                          </p>
                      )}
                    </div>

                    <div
                        style={{
                          margin: '3rem 0',
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                    >
                      <div style={{ margin: '0 2rem 0 0' }}>
                        <Button onClick={() => goToUrl('/')}>
                          Add To Your Request
                        </Button>
                      </div>
                      <div>
                        <Button onClick={() => goToUrl(modal.content)}>
                          View Your Request
                        </Button>
                      </div>
                    </div>
                  </div>
              )}
            </Modal>
          </Contain>
        </div>
    );
  }

  return <Alert type="warning">No event information available</Alert>;
}

export default EventView;
