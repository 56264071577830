import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import Contain from './Contain';
import { IconMenu } from '../../utils/Icon';
import { Typography } from '../../utils/Typography';
//import { goToUrl } from "../../utils/utilities";
import { toggleAppDrawerVisibility } from './actions';

const Header = styled.div`
  margin: 0;
  padding: 1rem 0;
  color: ${(props) => props.theme.HEADER_TEXT_COLOR};
  background-color: ${(props) => props.theme.HEADER_BACKGROUND};
`;
const HeaderContent = styled.div`
  display: flex;
  align-items: center;
  height: 6rem;
`;
const TitleContainer = styled.div`
  flex: 1;
`;
const LogoContainer = styled.div`
  background-image: url("${(props) => props.LogoImageURL}");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left center;
  height: 6rem;
`;
const MenuIconContainer = styled.div`
  cursor: pointer;
  padding: 0 0 0 15px;
  color: ${(props) => props.theme.HEADER_TEXT_COLOR};
`;

const AppHeader = ({ children, showMenu }) => {
  //console.log("AppHeader props = ", children, showMenu);
  const outlet = useSelector((state) => state.outlet);
  const appDrawerIconIsVisible = useSelector(
      (state) => state.app.appDrawerIconIsVisible,
  );
  const LogoImageURL =
      outlet && outlet.OutletTheme && outlet.OutletTheme.LogoImageURL;

  return (
      <Header id="appHeader">
        <Contain hasPadding>
          <HeaderContent>
            <TitleContainer>
              {children ? (
                  <>{children}</>
              ) : (
                  <>
                    {LogoImageURL ? (
                        <LogoContainer
                            role="img"
                            LogoImageURL={LogoImageURL}
                            aria-label={outlet.OutletName}
                        />
                    ) : (
                        <Typography tag="h1">{outlet.OutletName}</Typography>
                    )}
                  </>
              )}
            </TitleContainer>
            {appDrawerIconIsVisible && showMenu && (
                <MenuIconContainer
                    name="menutoggle"
                    id="menutoggle"
                    role="img"
                    aria-label="main menu toggle"
                    onClick={() => toggleAppDrawerVisibility(true)}
                >
                  <IconMenu/>
                </MenuIconContainer>
            )}
          </HeaderContent>
        </Contain>
      </Header>
  );
};

AppHeader.propTypes = {
  showMenu: PropTypes.bool,
};

AppHeader.defaultProps = {
  showMenu: true,
};

export default AppHeader;
