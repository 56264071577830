import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import { deleteToast } from './actions';

//TODO move css rules from index.html to in component styled components
const Toast = (props = {}) => {
  const { toastList = [], position } = props;
  return (
      <div id="toast-container">
        <div className={`notification-container ${position}`}>
          {toastList.map((toast, i) => (
              <div key={i} className={`notification toast ${position}`}>
                <ToastDetails {...toast} />
              </div>
          ))}
        </div>
      </div>
  );
};

Toast.propTypes = {
  toastList: PropTypes.array.isRequired,
  position: PropTypes.string,
  autoDelete: PropTypes.bool,
  dismissTime: PropTypes.number,
};

export default Toast;

export const ToastDetails = ({
                               title,
                               description,
                               icon,
                               id,
                               type,
                               autoDelete,
                               dismissTime,
                             }) => {
  useEffect(() => {
    if (autoDelete && id) {
      const interval = setInterval(() => {
        if (autoDelete && id) {
          deleteToast(id);
        }
      }, dismissTime || 3000);

      return () => {
        clearInterval(interval);
      };
    }
    // eslint-disable-next-line
  }, [id]);

  return (
      <div
          style={{
            padding: '15px',
            backgroundColor: type === 'error' ? '#bd2c2c' : '#307834',
          }}
      >
        <button onClick={() => deleteToast(id)}>X</button>
        {icon && (
            <div className="notification-image">
              <img src={icon} alt=""/>
            </div>
        )}
        <div>
          <p className="notification-title">{title}</p>
          <p className="notification-message">{description}</p>
        </div>
      </div>
  );
};
