import React from 'react';
import { useParams } from 'react-router-dom';

import Alert from '../utils/Alert';
import Contain from '../components/app/Contain';
import ResetPassword from '../components/auth/ResetPassword';
import { goToUrl } from '../utils/utilities';

function ResetPasswordView() {
  const { token } = useParams();

  return (
      <Contain maxWidth={600} fillHeight>
        <div style={{ margin: '4rem 0' }}>
          {!token ? (
              <Alert>
                Incorrect reset link, please refer to your email for instructions.
              </Alert>
          ) : (
              <ResetPassword
                  token={token}
                  onSuccess={() => goToUrl('/')}
                  onCancel={() => goToUrl('/login')}
              />
          )}
        </div>
      </Contain>
  );
}

export default ResetPasswordView;
