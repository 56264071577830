import PropTypes from 'prop-types';
import React from 'react';
import Button from '../../utils/Button';
import { IconMinus, IconPlus } from '../../utils/Icon';
import Spacer from '../../utils/Spacer';
import { formatCurrency, formatDate } from '../../utils/utilities';
import {
  StyledEventListMobileContainer,
  StyledEventListMobileGrid,
  StyledEventListTable,
  StyledExpandedRow,
  StyledIconContainer,
  StyledLabelColumn,
  StyledRowSpan,
  StyledToggleIconColumn
} from './shared-styled-components';

const OrderEventListTable = (props = {}) => {
  const {
    children,
    dataList,
    onClickExpandRow,
    handleClickManageTickets,
  } = props;

  const tableHeaders = ['STATUS', 'DELIVERY', 'SHOW DATE', 'EVENT', 'LOCATION', 'TOTAL'];
  const gridTemplateColumns = '0.8fr 120px 120px 1fr 1fr 100px 50px';

  if (dataList && Array.isArray(dataList) && dataList.length) {
    return (
      <div>
        {dataList.map((row, rowIndex) => {
          return (
            <StyledEventListMobileContainer
              key={'mobileview-' + rowIndex}
              isExpanded={row.isExpanded}
              id={'expandedDetailsRow-' + row?.OutletEventID}
            >
              <StyledEventListMobileGrid>
                <StyledLabelColumn>
                  Status:{' '}
                  {row.isMobileDelivery && (<><Spacer space="sm" />Delivery: </>)}
                  {!row.isExpanded && (
                    <>
                      <Spacer space="sm" />
                      Show Date:
                      <Spacer space="sm" />
                      Artist:
                      <Spacer space="sm" />
                      Location:
                      <Spacer space="sm" /> Total:
                    </>
                  )}
                </StyledLabelColumn>
                <div>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: row.status,
                    }}
                  />
                  {row.isMobileDelivery && (<><Spacer space="sm" />
                    <Button
                      size="xsmall"
                      onClick={() => handleClickManageTickets(row?.OutletEventID)}>Mobile Tickets
                    </Button>
                  </>)}
                  {!row.isExpanded && (
                    <>
                      <Spacer space="sm" />
                      {formatDate(row.showDate)}
                      <Spacer space="sm" />
                      {row.eventLabel}
                      <Spacer space="sm" />
                      {row.location}
                      <Spacer space="sm" />
                      {formatCurrency(row.total)}
                    </>
                  )}
                </div>
                <StyledToggleIconColumn>
                  <StyledIconContainer onClick={() => onClickExpandRow(row?.OutletEventID)}>
                    {row.isExpanded ? <IconMinus /> : <IconPlus />}
                  </StyledIconContainer>{' '}
                </StyledToggleIconColumn>
              </StyledEventListMobileGrid>
              {row.isExpanded && (
                <StyledExpandedRow>{children}</StyledExpandedRow>
              )}
            </StyledEventListMobileContainer>
          );
        })}

        <StyledEventListTable
          gridTemplateColumns={gridTemplateColumns}
        >
          <thead>
            <tr>
              <th>Status</th>
              {tableHeaders.includes('DELIVERY') ? <th>Delivery</th> : null}
              <th>Show Date</th>
              <th>Event</th>
              <th>Location</th>
              {tableHeaders.includes('TOTAL') && (
                <th style={{ textAlign: 'right' }}>Total</th>
              )}
              <th />
            </tr>
          </thead>
          <tbody>
            {dataList.map((row, rowIndex) => {
              return [
                <tr
                  id={'expandedDetailsRow-' + row?.OutletEventID}
                  key={rowIndex}
                  style={{ fontWeight: row.isExpanded ? 700 : 500 }}
                >
                  <td className="allowOverflow">
                    <span
                      dangerouslySetInnerHTML={{
                        __html: row.status,
                      }}
                    />
                  </td>

                  {tableHeaders.includes('DELIVERY') && (<td>
                    {row.isMobileDelivery && (
                      <Button
                        size="xsmall"
                        onClick={() => handleClickManageTickets(row?.OutletEventID)}>Mobile Tickets
                      </Button>
                    )}
                  </td>)}

                  <td>{formatDate(row.showDate)}</td>

                  <td className="allowOverflow">{row.eventLabel} {row.refundAlertMessage
                    ? <><Spacer space="sm" /><span style={{ color: 'red' }}>{row.refundAlertMessage}</span></>
                    : null}</td>
                  <td>{row.location}</td>
                  {tableHeaders.includes('TOTAL') && (
                    <td style={{ textAlign: 'right' }}>
                      {formatCurrency(row.total)}
                    </td>
                  )}
                  <td style={{ textAlign: 'right' }}>
                    <StyledIconContainer
                      onClick={() => {
                        onClickExpandRow(row?.OutletEventID);
                      }}
                    >
                      {row.isExpanded ? <IconMinus /> : <IconPlus />}
                    </StyledIconContainer>
                  </td>
                </tr>,
                <StyledRowSpan
                  key={rowIndex + '-expanded'}
                  span={tableHeaders.length + 1}
                  visible={row.isExpanded}
                >
                  {row.isExpanded && <>{children}</>}
                </StyledRowSpan>,
              ];
            })}
          </tbody>
        </StyledEventListTable>
      </div>
    );
  }
  return null;
};

OrderEventListTable.defaultProps = {
  children: null,
  dataList: [],
  onClickExpandRow: null,
  handleClickManageTickets: null,
};

OrderEventListTable.propTypes = {
  children: PropTypes.node,
  dataList: PropTypes.array,
  onClickExpandRow: PropTypes.func,
  handleClickManageTickets: PropTypes.func,
};
export default OrderEventListTable;
