
import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import Contain from '../components/app/Contain';
import LineItemPreferenceList from '../components/cart/LineItemPreferenceList';
import LineItemSummary, {
  LineItemRow,
  OutletEventItemHeader,
  PriceLevelHeader,
} from '../components/cart/LineItemSummary';
import {
  deleteFanRequest,
  editExistingFanRequest,
  setFanRequestError,
  updateFanSeriesRequest,
  updateFanSeriesRequestPriority,
} from '../components/cart/actions';
import CheckoutTotalsSummaryFanRequest from '../components/checkout/CheckoutTotalsSummaryFanRequest';
import EditRequestBilling from '../components/fanrequest/EditRequestBilling';
import EditRequestLineItems from '../components/fanrequest/EditRequestLineItems';
import PaymentPlanForm from '../components/fanrequest/PaymentPlanForm';
import RequestReceipt from '../components/fanrequest/RequestReceipt';
import ManageTickets from '../components/order/ManageTickets';
import OrderList from '../components/order/OrderList';
import OrderReceipt from '../components/order/OrderReceipt';
import { sortOrdersByEventShowDates } from '../components/order/orderUtils';
import OutletImage from '../components/outlet/OutletImage';
import OutletEventSummary from '../components/outletEvent/OutletEventSummary';
import { addToast } from '../components/toast/actions';
import {
  getUserFanRequestSeriesSummary,
  getUserFanRequests,
  getUserMobileTicketInfo,
  getUserOrder,
  getUserOrderSummaryList,
  getUserPaymentMethodList,
  updateUserOrderAddress,
  updateUserOrderPaymentInfo,
} from '../components/user/actions';
import AddressForm from '../utils/AddressForm';
import Button from '../utils/Button';
import Columns from '../utils/Columns';
import { DisplayPaymentMethod, DisplayShippingAddress } from '../utils/DisplayContent';
import DisplayError from '../utils/Error';
import { CheckboxInput, SelectInput } from '../utils/Form';
import { IconCheck, IconInfo, IconMinus, IconPlus } from '../utils/Icon';
import Loading from '../utils/Loading';
import Modal from '../utils/Modal';
import Typography from '../utils/Typography';
import { OUTLET_TYPE_GUEST_LIST_ID } from '../utils/constants';
import {
  calculateSeriesStatuses,
  formatCurrency,
  formatDate,
  formatTime,
  getYearsArray,
  goToUrl,
  lineItemsNormalizedByOutletEventItem,
  normalizeAddress,
} from '../utils/utilities';

import { getTracking } from 'ts-tracking-number';

const iconSize = 18;

const ButtonContainerStyled = styled.div`
  margin: 2rem 0;
  display: flex;
  justify-content: space-evenly;
  color: ${(props) => props.theme.PRIMARY_GRAY};
  font-size: 2rem;
`;
const LeftColumStyled = styled.div`
  margin: 0 0 0 0;
`;

const RightColumStyled = styled.div`
  margin: 0 0 0 0;
`;
const TourRibbonStyled = styled.div`
  font-size: 1.6rem;
  padding: 2rem 0;
  background-color: ${(props) => props.theme.PRIMARY_GRAY}80;
  p {
    margin: 0.5rem 0 1rem 0 !important;
  }
`;

const TourMessageHeadlineStyled = styled.div`
  color: ${(props) => props.theme.PRIMARY_LINK_COLOR};
  font-weight: 700;
  font-size: 1.8rem;
`;

const TabRootSyled = styled.div`
  margin: 2rem 0;
  border-bottom: ${(props) => `solid 2px ${props.theme.PRIMARY_GRAY}`};
`;
const TabContainerSyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TabStyled = styled.div`
  color: ${(props) =>
    props.active
      ? props.theme.PRIMARY_LINK_COLOR
      : `${props.theme.PRIMARY_TEXT_COLOR}80`};
  border-top: ${(props) =>
    props.active ? `solid 2px ${props.theme.PRIMARY_GRAY}` : 'none'};
  border-right: ${(props) =>
    props.active ? `solid 2px ${props.theme.PRIMARY_GRAY}` : 'none'};
  border-left: ${(props) =>
    props.active ? `solid 2px ${props.theme.PRIMARY_GRAY}` : 'none'};
  cursor: ${(props) => (props.active ? 'not-allowed' : 'pointer')};
  border-bottom: ${(props) => (props.active ? `solid 2px #ffffff` : 'none')};
  margin: 0 0 -2px 0;
  padding: 1rem 2rem;
  background: ${(props) =>
    props.active ? '#fff' : props.theme.PRIMARY_LINK_COLOR}15;
  &:hover {
    background-color: ${(props) =>
    props.active ? 'inherit' : props.theme.PRIMARY_LINK_COLOR}30;
  }
`;

const PillBtnStyled = styled.div`
  font-size: 1.8rem;
  color: ${(props) =>
    props.active
      ? props.theme.PRIMARY_LINK_COLOR
      : `${props.theme.PRIMARY_TEXT_COLOR}80`};


  cursor: ${(props) => (props.active ? 'not-allowed' : 'pointer')};

  margin: 0 0 0 0;
  padding: 1rem 2rem;
  background: ${(props) =>
    props.active ? '#fff' : props.theme.PRIMARY_LINK_COLOR}15;

  &:hover {
    background-color: ${(props) =>
    props.active ? 'inherit' : props.theme.PRIMARY_LINK_COLOR}30;
  }
`;

const RequestHeaderStyled = styled.div`
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr;
  margin: 2rem 0 0 0;
  font-size: 1.8rem;
  align-items: center;
  justify-content: space-between;
  @media (min-width: ${(props) => props.theme.BREAKPOINT_MOBILE_WIDTH}px) {
    grid-template-columns: auto 220px auto;
  }
`;

const StyledPastEventsTitleAndSelector = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 0 1rem 0;
  gap: 20px;
  align-items: baseline;
`;

const modalDetailsLookup = {
  'edit-shipping': {
    title: 'Edit Shipping Address',
    constant: 'EDIT_SHIPPING_ADDRESS',
  },
  'edit-billing': {
    title: 'Manage Payments',
    constant: 'EDIT_BILLING',
  },
  'summary': {
    title: 'Summary Details',
    constant: 'SUMMARY_DETAILS',
  },
};

// TODO: Break this component into smaller subcomponents as this file is too large
// making any changes to this file unviewable in MRs on gitlab.
function UserHistoryView(props) {
  const { type, ID, itemID, view } = useParams();
  const [addressUpdateIsLoading, setAddressUpdateIsLoading] = useState(false);
  const [paymentInfoUpdateIsLoading, setPaymentInfoUpdateIsLoading] =
    useState(false);
  const [modal, setModal] = useState({
    title: '',
    content: '',
    constant: null,
    isVisible: false,
    payload: null,
  });
  const [requestLineItemErrorMessages, setRequestLineItemErrorMessages] =
    useState([]);
  const [requestViewFilter, setRequestViewFilter] = useState('current');
  const [orderListYearFilter, setOrderListYearFilter] = useState('');
  const [requestYearFilter, setRequestYearFilter] = useState(false);
  const [fetchedRequestsAt, setFetchedRequestsAt] = useState(null);
  const [fetchedOrdersAt, setFetchedOrdersAt] = useState(null);
  const [selectedSeriesRequest, setSelectedSeriesRequest] = useState(null);
  const [pastRequestSeriesList, setPastRequestSeriesList] = useState([]);
  const api = useSelector((state) => state.api);
  const order = useSelector((state) => state.userOrder);
  const outlet = useSelector((state) => state.outlet);
  const userOrderSummaryList = useSelector(
    (state) => state.userOrderSummaryList,
  );
  const userFanRequestSeriesSummaryList = useSelector(
    (state) => state.userFanRequestSeriesSummaryList,
  );
  const fanRequest = useSelector((state) => state.fanRequest);
  const userAddressList = useSelector((state) => state.userAddressList);
  const userPaymentMethodList = useSelector(
    (state) => state.userPaymentMethodList,
  );
  const userMobileTicketList = useSelector((state) => state.userMobileTicketList);
  const requestSeriesList =
    requestViewFilter === 'current'
      ? userFanRequestSeriesSummaryList
      : pastRequestSeriesList;

  /**
   * Function to handle preparing all data and UI needs for managing mobile tickets
   *
   * Fetch order and mobile ticketing data from api, then fire off modal to display manage tickets
   *
   * @param {string} OrderID - example: "Z3UH36DA"
   * @param {number} OutletEventID
   * @param {number} CustomerOrderID
   */
  const handleClickManageTickets = ({ OrderID, OutletEventID, CustomerOrderID }) => {
    getUserOrder({ OrderID });
    getUserMobileTicketInfo({ CustomerOrderID, OutletEventID });
    setModal({
      title: 'Mobile Tickets',
      constant: 'MANAGE_TICKETS',
      isVisible: true,
      content: { OrderID, OutletEventID },
    });
  };

  const setRequest = (id) => {
    const requestFound = requestSeriesList.find((x) => x.RequestID === id);

    if (requestFound) {
      setSelectedSeriesRequest(requestFound);
      const element = document.getElementById(
        'container-' + requestFound.LotteryOEGRequestUUID,
      );
      if (element) {
        element.scrollIntoView({
          behavior: 'smooth',
        });
      }
    }
  };

  const setOrder = (id) => {
    const element = document.getElementById('container-' + id);
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    if (type === 'requests') {
      if (!fetchedRequestsAt) {
        getUserFanRequestSeriesSummary({}).then((res) => {
          setFetchedRequestsAt(new Date());
          setRequest(ID);
        });
      }
      if (ID) {
        setRequest(ID);
      }
      if (!requestYearFilter) {
        // setRequestYearFilter( new Date().getUTCFullYear());
      }
    }

    if (type === 'orders') {
      if (!fetchedOrdersAt) {
        getUserOrderSummaryList({}).then((res) => {
          setFetchedOrdersAt(new Date());
          setOrder(ID);
        });
      }

      if (ID) {
        getUserOrder({ OrderID: ID }).then((res) => {
          setOrder(ID);
        });
      }
    }

    if (view) {
      const modalDetails = modalDetailsLookup[view];
      if (modalDetails) {
        setModal({
          ...modalDetails,
          isVisible: true,
        });
      }
    }

    return () => {
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, ID, view, fetchedOrdersAt, fetchedRequestsAt]);

  const handleCloseModal = () => {
    let url = '/user/history/';
    if (type === 'orders') {
      url += 'orders';
    } else {
      url += 'requests';
    }

    if (ID) {
      url += '/' + ID;
    }
    resetModal();
    goToUrl(url);
  };

  const resetModal = () => {
    setModal({
      title: '',
      content: '',
      constant: '',
      isVisible: false,
      payload: null,
    });
    setAddressUpdateIsLoading(false);
  };

  const handleOnCreateNewPaymentMethodViaIFrame = (params) => {
    if (params) {
      const {
        Sort,
        seriesSummary,
      } = params;
      setPaymentInfoUpdateIsLoading(true);
      getUserPaymentMethodList().then((userPaymentMethods) => {
        setPaymentInfoUpdateIsLoading(false);
        if (
          userPaymentMethods &&
          Array.isArray(userPaymentMethods) &&
          userPaymentMethods.length
        ) {
          if (userPaymentMethods[0]) {
            const newPaymentMethod = {
              ...userPaymentMethods[0],
              Sort: Sort || 1,
            };

            if (type === 'orders') {
              handleOnChangePaymentMethod(newPaymentMethod);
            }
            if (type === 'requests') {
              handleOnSelectPaymentMethodForFanRequest(
                { ...newPaymentMethod },
                seriesSummary,
              );
            }
          }
        }
      }).catch((error) => {
        setPaymentInfoUpdateIsLoading(false);
      });
    }
  };

  const handleOnSelectPaymentMethodForFanRequest = (
    payload,
    seriesSummary,
    callback,
  ) => {
    if (payload && seriesSummary) {
      const { paymentList, paymentPlanEnrollmentIsSelected } = payload;
      const {
        OutletEventGroupID,
        LotteryOEGRequestID,
        LotteryOEGRequestUUID,
        PaymentInfo,
        RequestID,
      } = seriesSummary;

      const paymentListAdjusted = Array.isArray(paymentList)
        ? paymentList.map((p) => {
          let LotteryOEGRequestPaymentMethodID = null;
          let LotteryOEGRequestPaymentMethodUUID = null;
          if (Array.isArray(PaymentInfo)) {
            const existingPaymentFound = PaymentInfo.find(
              (pi) => Number(p.Sort) === Number(pi.Sort),
            );
            if (
              existingPaymentFound &&
              existingPaymentFound.LotteryOEGRequestPaymentMethodID
            ) {
              LotteryOEGRequestPaymentMethodID =
                existingPaymentFound.LotteryOEGRequestPaymentMethodID;
            }
            if (
              existingPaymentFound &&
              existingPaymentFound.LotteryOEGRequestPaymentMethodUUID
            ) {
              LotteryOEGRequestPaymentMethodUUID =
                existingPaymentFound.LotteryOEGRequestPaymentMethodUUID;
            }
          }
          return {
            ...p,
            LotteryOEGRequestID,
            LotteryOEGRequestUUID,
            LotteryOEGRequestPaymentMethodID,
            LotteryOEGRequestPaymentMethodUUID,
          };
        })
        : [];

      const config = {
        OutletEventGroupID,
        LotteryOEGRequestUUID,
        PaymentInfo: paymentListAdjusted,
        PaymentPlanEnrolled: paymentPlanEnrollmentIsSelected,
      };

      setPaymentInfoUpdateIsLoading(true);
      updateFanSeriesRequest(config).then((res) => {
        const requestFound =
          Array.isArray(res) && res.find((x) => x.RequestID === RequestID);
        if (requestFound) {
          setSelectedSeriesRequest(requestFound);
        }
        setPaymentInfoUpdateIsLoading(false);
        callback && callback(res);
      }).catch((error) => {
        setPaymentInfoUpdateIsLoading(false);
        console.error('handleOnSelectPaymentMethodForFanRequest error ', error);
      });
    } else {
      addToast({
        title: 'Error',
        description: 'Incomplete payment method details',
        type: 'error',
      });
    }
  };

  const handleOnChangePaymentMethod = (paymentMethod) => {
    if (paymentMethod && order) {
      const { Sort } = paymentMethod;

      const { CustomerOrderScheduledPayments } = order;

      const adjustedPayments = Array.isArray(CustomerOrderScheduledPayments)
        ? [...CustomerOrderScheduledPayments]
        : [];

      const index = adjustedPayments.findIndex(
        (x) => Number(x.Sort) === Number(Sort),
      );

      if (index > -1) {
        adjustedPayments[index] = {
          ...adjustedPayments[index],
          Sort: Number(Sort),
          PaymentMethod: paymentMethod,
          PaymentMethodID: paymentMethod.PaymentMethodID,
        };

        const payload = {
          CustomerOrderID: order.CustomerOrderID,
          CustomerOrderScheduledPayments: adjustedPayments,
        };

        setPaymentInfoUpdateIsLoading(true);
        updateUserOrderPaymentInfo(payload).then((res) => {
          setPaymentInfoUpdateIsLoading(false);
          //  handleCloseModal();
        }).catch((error) => {
          alert(error);
          setPaymentInfoUpdateIsLoading(false);
        });
      } else {
        addToast({
          title: 'Error',
          description: 'Cant find scheduled payment that matches sort value',
          type: 'error',
        });
      }
    }
  };

  const handleOnSubmitShippingAddressForOrder = (shippingAddress, order) => {
    setAddressUpdateIsLoading(true);

    if (shippingAddress && order && order.CustomerOrderID) {
      const formattedAddress = normalizeAddress(shippingAddress, 'fanRequest');
      formattedAddress.Country = formattedAddress.CountryID;
      delete formattedAddress.CountryID;
      delete formattedAddress.AddressID;
      delete formattedAddress.AddressUUID;
      const payload = {
        CustomerOrderID: order.CustomerOrderID,
        address: formattedAddress,
      };

      updateUserOrderAddress(payload).then((res) => {
        setAddressUpdateIsLoading(false);
        handleCloseModal();
      }).catch((error) => setAddressUpdateIsLoading(false));
    }
  };

  const handleOnSubmitShippingAddressForFanRequest = (
    shippingAddress,
    seriesSummary,
  ) => {
    if (shippingAddress && seriesSummary) {
      const { OutletEventGroupID, LotteryOEGRequestUUID, Shipping, RequestID } =
        seriesSummary;
      const shippingAddressNormalized = {
        ...normalizeAddress(shippingAddress, 'fanRequest'),
        LotteryRequestShippingAddressUUID:
          Shipping && Shipping.LotteryRequestShippingAddressUUID,
      };
      const config = {
        OutletEventGroupID,
        LotteryOEGRequestUUID,
        Shipping: shippingAddressNormalized,
      };
      updateFanSeriesRequest(config).then((res) => {
        const requestFound =
          Array.isArray(res) && res.find((x) => x.RequestID === RequestID);

        if (requestFound) {
          setSelectedSeriesRequest(requestFound);
        }
        handleCloseModal();
      });
    } else {
      alert('error missing shipping address details');
    }
  };

  // handle priority checkbox
  const handleClickPrioritySelection = (params = {}) => {
    const { PriorityOERequestUUID, LotteryOEGRequestUUID, TourID } = params;

    if (LotteryOEGRequestUUID) {
      const seriesFound = requestSeriesList.find(
        (x) => x.LotteryOEGRequestUUID === LotteryOEGRequestUUID,
      );

      if (seriesFound) {
        const { OutletEventGroup, RequestID } = seriesFound;
        const payload = {
          LotteryOEGRequestUUID,
          PriorityOERequestUUID,
          OutletEventGroupID: OutletEventGroup.OutletEventGroupID,
          TourID,
        };

        updateFanSeriesRequestPriority(payload).then((res) => {
          if (!res.error) {
            const requestSelected = requestSeriesList.find(
              (x) => x.LotteryOEGRequestUUID == LotteryOEGRequestUUID,
            );
            requestSelected.PriorityOERequestUUID = PriorityOERequestUUID;
            setSelectedSeriesRequest(requestSelected);
            const x = { LotteryOEGRequestUUID, PriorityOERequestUUID };
          }
        });
      }
    }
  };

  const handleClickProcessedRequestsTab = () => {
    const currentYear = new Date().getFullYear();
    handleSelectYearFilter(currentYear);
    setRequestViewFilter('past');
  };

  const handleSelectYearFilter = (year) => {
    setRequestYearFilter(year);
    if (year) {
      setPastRequestSeriesList([]);
      getUserFanRequestSeriesSummary({
        updateStore: false,
        Year: Number(year),
        LotteryOEGRequestStatus: [
          'STATUS_REQUEST_CONFIRMED',
          'STATUS_REQUEST_CONFIRMED_PARTIAL',
          'STATUS_REQUEST_CONFIRMED_ALTERNATE',
          'STATUS_REQUEST_DENIED',
          'STATUS_REQUEST_DENIED_INVENTORY',
        ],
      }).then((res) => {
        if (res && Array.isArray(res)) {
          setPastRequestSeriesList(res);
        }
      });
    }
  };

  const handleClickSaveRequestLineItems = (lineItems) => {
    // RLMTS-2714 need error messaging for invalid form data when editing line tiems
    const errorMessages = [];

    if (Array.isArray(lineItems)) {
      const selectedLotteryItemsFiltered = lineItems.filter(
        (x) => x.PriceLevelID || x.Quantity,
      );

      let expandedRequestDetails;
      requestSeriesList.map((summary) => {
        if (Array.isArray(summary.LotteryOERequests)) {
          summary.LotteryOERequests.map((r) => {
            if (r.LotteryOERequestUUID === itemID) {
              expandedRequestDetails = r;
            }
            return null;
          });
        }
        return null;
      });

      selectedLotteryItemsFiltered.map((x) => {
        if (x.PriceLevelID || x.Quantity) {
          let oei; let errorMessage;
          const outletEventItemList =
            expandedRequestDetails &&
            expandedRequestDetails.OutletEvent &&
            expandedRequestDetails.OutletEvent.OutletEventItems.filter(
              (oei) => oei.OutletEventID,
            );

          if (Array.isArray(outletEventItemList)) {
            oei = outletEventItemList.find(
              (y) => Number(y.OutletEventItemID) === Number(x.OutletEventItemID),
            );
          }
          if (!x.PriceLevelID) {
            errorMessage = oei.Name + ': Price level required';
          }
          if (oei && errorMessage) {
            errorMessages.push({
              OutletEventItemID: x.OutletEventItemID,
              message: errorMessage,
              oei,
            });
          }
        }
      });
    }

    setRequestLineItemErrorMessages(errorMessages);

    if (errorMessages.length) {

    } else {
      const lineItemsFiltered = lineItems.filter((x) => x.PriceLevelID);
      editExistingFanRequest({
        LotteryOEIRequests: lineItemsFiltered,
        LotteryOERequestUUID:
          modal.content &&
          modal.content.LotteryOERequest &&
          modal.content.LotteryOERequest.LotteryOERequestUUID,
      }).then((res) => {
        handleCloseModal();
        getUserFanRequests();
        getUserFanRequestSeriesSummary().then((requestList) => {
          let existingSeriesRequest;

          if (
            expandedRequestDetails &&
            expandedRequestDetails.LotteryOEGRequestUUID
          ) {
            existingSeriesRequest = requestList.find(
              (x) =>
                x.LotteryOEGRequestUUID ===
                expandedRequestDetails.LotteryOEGRequestUUID,
            );
          }

          // RLMTS-2871 show meessage for payment plan
          // Start RLMTS-2871 show user message if they are newly eligible for payment plan
          const paymentPlanIsAvailable = Boolean(
            existingSeriesRequest &&
            existingSeriesRequest.OutletEventGroup &&
            existingSeriesRequest.OutletEventGroup.OfferPaymentPlan,
          );
          const userIsEnrolledInPaymentPlan =
            existingSeriesRequest && existingSeriesRequest.PaymentPlanEnrolled;

          const existingSeriesTotal =
            (existingSeriesRequest &&
              existingSeriesRequest.Totals &&
              existingSeriesRequest.Totals.total) ||
            0;
          let userIsNewlyEligibleForPaymentPlan = false;
          if (
            existingSeriesRequest &&
            outlet &&
            Array.isArray(outlet.OutletEventGroups)
          ) {
            const oegFound = outlet.OutletEventGroups.find(
              (oeg) =>
                Number(oeg.OutletEventGroupID) ===
                Number(
                  existingSeriesRequest.OutletEventGroup.OutletEventGroupID,
                ),
            );

            if (oegFound && oegFound.PaymentPlanRules) {
              userIsNewlyEligibleForPaymentPlan =
                paymentPlanIsAvailable &&
                !userIsEnrolledInPaymentPlan &&
                existingSeriesTotal >=
                oegFound.PaymentPlanRules.eligibilityMinimum;
            }
          }

          // End RLMTS-2871

          if (userIsNewlyEligibleForPaymentPlan) {
            let managePaymentUrl = '/user/history/requests';
            if (existingSeriesRequest && existingSeriesRequest.RequestID) {
              managePaymentUrl +=
                '/' + existingSeriesRequest.RequestID + '/edit-billing';
            }

            setModal({
              title: 'Successfully updated request',
              constant: 'ELIGIBLE_FOR_PAYMENT_PLAN',
              isVisible: true,
              managePaymentUrl,
            });
          }
        });
      });
    }
  };

  let expandedSummaryDetails;
  let expandedRequestDetails;
  let loadingMessage;
  let tourList = [];
  const seriesListPulledFromRequests = [];

  const prioritySelectPolicy =
    outlet.OutletPolicies &&
    outlet.OutletPolicies.find(
      (policy) =>
        (policy.SalesTypeID === -1 || policy.SalesTypeID === 2) &&
        policy.OutletPolicyTypeID === 8,
    );
  const orderIsLoading = api.loading.includes('GET_USER_ORDER');
  const mobileTicketListIsLoading = api.loading.includes('GET_USER_MOBILE_TICKET_LIST');
  const orderListIsLoading = api.loading.includes(
    'GET_USER_ORDER_SUMMARY_LIST',
  );
  const requestSeriesListIsLoading = api.loading.includes(
    'GET_USER_FAN_REQUEST_SERIES_SUMMARY',
  );

  const userPaymentMethodListIsLoading = api.loading.includes(
    'GET_USER_PAYMENT_METHOD_LIST',
  );
  if (orderListIsLoading) {
    loadingMessage = 'Loading your order history';
  }
  if (requestSeriesListIsLoading) {
    loadingMessage = 'Loading your request history';
  }

  requestSeriesList.map((summary) => {
    if (Array.isArray(summary.LotteryOERequests)) {
      summary.LotteryOERequests.map((r) => {
        if (r.LotteryOERequestUUID === itemID) {
          expandedRequestDetails = r;
          expandedSummaryDetails = summary;
        }
        return null;
      });
    }
    return null;
  });

  // Loop all series and create a list of tours
  // RLMTS-2754 - stop getting info from the outlet object because the admin user can turn off/on series
  // RLMTS-2754 - instead loop requests and group it from there
  if (Array.isArray(requestSeriesList)) {
    requestSeriesList.map((r) => {
      const { OutletEventGroup } = r;
      if (OutletEventGroup.Tour && OutletEventGroup.Tour.TourID) {
        const index = tourList.findIndex(
          (x) => Number(x.TourID) === Number(OutletEventGroup.Tour.TourID),
        );
        if (index === -1) {
          tourList.push(OutletEventGroup.Tour);
        }
      }
      seriesListPulledFromRequests.push(OutletEventGroup);
      return null;
    });

    // RLMTS-2205 after team discussion 2/23/21, "AllowPrioritySelection" requires the following:
    // 1) the series (oeg) belongs to a tour with 'AllowPrioritySelection' === true (database store '1',
    // but api in most cases returns boolean)
    // 2) all series in that tour must be open 'OutletEventGroup.SeriesOpenDate'<= new Date()
    // 3) Important - if for whatever reason an admin closes a series before the other series in that tour
    // we remove ability to make a priority select on the entire tour.

    // RLMTS-3275: Update logic to still display priority selection after series is closed, but not allow change.
    // Adding DisablePrioritySelection status to track additional state.
    // Loop all series and if any are closed change tour.DisablePrioritySelection to TRUE
    seriesListPulledFromRequests.map((oeg) => {
      const { seriesIsOpen } = calculateSeriesStatuses(oeg);
      if (!seriesIsOpen) {
        const tourID = oeg.Tour && oeg.Tour.TourID;
        const tourIndex = tourList.findIndex(
          (x) => Number(x.TourID) === Number(tourID),
        );
        if (tourIndex > -1) {
          tourList[tourIndex].DisablePrioritySelection = true;
        }
      }
      return null;
    });
  }

  // Insert user's series request inside our tourlist array
  // Filter out any tours that have no series requests
  if (Array.isArray(requestSeriesList) && Array.isArray(tourList)) {
    tourList = tourList.map((t) => {
      t.requestSeriesList = [];
      requestSeriesList.map((x) => {
        const tourId = x && x.OutletEventGroup && x.OutletEventGroup.TourID;
        if (Number(tourId) === Number(t.TourID)) {
          t.requestSeriesList.push(x);
        }
        return null;
      });
      return t;
    });

    tourList = tourList.filter((t) => t.requestSeriesList.length > 0);
  }

  // RLMTS-2804 - sort the tour list chronologicaly by the last event it contains
  if (Array.isArray(tourList)) {
    tourList = tourList.map((t) => {
      const eventDatesInTour = [];
      if (Array.isArray(t.requestSeriesList)) {
        t.requestSeriesList.map((s) => {
          let eventDatesInSeries = [];
          if (Array.isArray(s.LotteryOERequests)) {
            s.LotteryOERequests = s.LotteryOERequests.map((r) => {
              if (
                r.OutletEvent &&
                r.OutletEvent.Event &&
                r.OutletEvent.Event.ShowDate
              ) {
                const dateObj = {
                  eventShowDate: r.OutletEvent.Event.ShowDate,
                  LotteryOEGRequestUUID: s.LotteryOEGRequestUUID,
                  LotteryOERequestUUID: r.LotteryOERequestUUID,
                };
                r.eventShowDate = r.OutletEvent.Event.ShowDate;
                eventDatesInTour.push(dateObj);
                eventDatesInSeries.push(dateObj);
                return r;
              }
            });
            const LotteryOERequestsSorted = s.LotteryOERequests.sort((a, b) => {
              if (a.eventShowDate && b.eventShowDate) {
                return new Date(b.eventShowDate) - new Date(a.eventShowDate);
              }
              return 0;
            });

            s.LotteryOERequests = LotteryOERequestsSorted;
          }
          eventDatesInSeries = eventDatesInSeries.sort((a, b) => {
            if (a.eventShowDate && b.eventShowDate) {
              return new Date(b.eventShowDate) - new Date(a.eventShowDate);
            }
            return 0;
          });
          const lastEventInSeries =
            eventDatesInSeries[eventDatesInSeries.length - 1];
          s.lastEventInSeries = lastEventInSeries;
        });
      }
      eventDatesInTour.sort((a, b) => {
        if (a.eventShowDate && b.eventShowDate) {
          return new Date(b.eventShowDate) - new Date(a.eventShowDate);
        }
        return 0;
      });
      const lastEventInTour = eventDatesInTour[eventDatesInTour.length - 1];
      t.lastEventInTour = lastEventInTour;
      t.eventDatesInTour = eventDatesInTour;

      const requestSeriesListSorted = t.requestSeriesList.sort((a, b) => {
        if (
          a.lastEventInSeries &&
          a.lastEventInSeries.eventShowDate &&
          b.lastEventInSeries &&
          b.lastEventInSeries.eventShowDate
        ) {
          return (
            new Date(a.lastEventInSeries.eventShowDate) -
            new Date(b.lastEventInSeries.eventShowDate)
          );
        }
        return 0;
      });
      t.requestSeriesList = requestSeriesListSorted;
      return t;
    });
  }

  // Sort Tours by the last event
  tourList = tourList.sort((a, b) => {
    if (
      a.lastEventInTour &&
      a.lastEventInTour.eventShowDate &&
      b.lastEventInTour &&
      b.lastEventInTour.eventShowDate
    ) {
      return (
        new Date(a.lastEventInTour.eventShowDate) -
        new Date(b.lastEventInTour.eventShowDate)
      );
    }
    return 0;
  });


  /**
   * Function to handle any operations after updating the years drop down that filters display of orders
   *
   * @param {string} year - example: "2023"
   */
  const handleSelectOrderListYearFilter = (year) => {
    setOrderListYearFilter(year);
  };

  /**
   * Create an array of unique years gathered from all events within a list of orders
   * @param {object[]} orderList - List of orders typically returned from the api - customerorder/getlist
   * @return {array} unique set of years example ['2021','2022']
   */
  const getListOfShowDateYearsFromOrders = (orderList) => {
    const showDateYears = [];
    if (Array.isArray(orderList)) {
      orderList.forEach((order) => {
        const { eventShowDateYears } = order;
        // do we have dates avialble already from using sortOrdersByEventShowDates
        if (Array.isArray(eventShowDateYears)) {
          showDateYears.push(...eventShowDateYears);
        }
      });
    }
    return [...new Set(showDateYears)].sort((a, b) => b - a);
  };


  if (view === 'receipt' && type === 'orders') {
    if (orderIsLoading) {
      return (
        <Contain>
          <Loading message={'loading order'} align="center" />
        </Contain>
      );
    }
    return <OrderReceipt order={order} />;
  }

  if (view === 'receipt' && type === 'requests') {
    if (requestSeriesListIsLoading) {
      return (
        <Contain>
          <Loading message={'loading request'} align="center" />
        </Contain>
      );
    }

    return (
      <RequestReceipt
        request={expandedRequestDetails}
        seriesSummary={expandedSummaryDetails}
      />
    );
  }

  // RLMTS-2829 custom modal title for edit request
  let editRequestModalTitle = 'Edit Request';
  if (
    expandedRequestDetails &&
    expandedRequestDetails.OutletEvent &&
    expandedRequestDetails.OutletEvent.Event
  ) {
    const customEventName =
      expandedRequestDetails.OutletEvent.OutletEventOverrideName ||
      expandedRequestDetails.OutletEvent.Event.Name;
    const customEventShowDate = formatDate(
      expandedRequestDetails.OutletEvent.Event.ShowDate,
    );
    if (customEventName) {
      editRequestModalTitle += ' - ' + customEventName;
    }
    if (customEventShowDate) {
      editRequestModalTitle += ' - ' + customEventShowDate;
    }
  }

  // RLMTS-2894 define payment plan rules depending on order selected
  // Marshall said to use any event in CustomerOrderEvents because they all have the same OutletSeriesID
  let paymentPlanRules;
  if (
    order &&
    Array.isArray(order.CustomerOrderEvents) &&
    order.CustomerOrderEvents.length &&
    order.CustomerOrderEvents[0].OutletEvent &&
    order.CustomerOrderEvents[0].OutletEvent.OutletSeriesID &&
    Array.isArray(outlet.OutletEventGroups)
  ) {
    const oegFound = outlet.OutletEventGroups.find(
      (oeg) =>
        Number(oeg.OutletEventGroupID) ===
        Number(order.CustomerOrderEvents[0].OutletEvent.OutletSeriesID),
    );

    if (oegFound && oegFound.PaymentPlanRules) {
      paymentPlanRules = oegFound.PaymentPlanRules;
      // Start RLMTS-3280
      if (type === 'orders' && order && order.PaymentPlanEnrolled) {
        if (paymentPlanRules) {
          paymentPlanRules['fee'] = order.PaymentPlanFee;
        } else {
          paymentPlanRules = { fee: order.PaymentPlanFee };
        }
      }
      // End RLMTS-3280
    }
  }

  let trackingUrl;
  if (order && order.TrackingNumber) {
    const tracking = getTracking(order.TrackingNumber);
    trackingUrl = tracking && tracking.trackingUrl && tracking.trackingNumber ? tracking.trackingUrl.replace(/%s/g,
      tracking.trackingNumber) : null;
  }
  // GT-4159 split viewing orders by their event dates (orders with upcoming events vs orders will only past events)
  const orderListSorted = sortOrdersByEventShowDates({ orderList: userOrderSummaryList });
  const orderListWithUpcomingEvents = orderListSorted.filter((order) => order?.futureEventShowDates?.length);
  const orderListWithOnlyPastEvents = orderListSorted.filter((order) => !order?.futureEventShowDates?.length);
  return (
    <div style={{ height: '100%' }}>
      <OutletImage />

      <TabRootSyled>
        <Contain>
          <TabContainerSyled>
            {outlet?.OutletTypeID !== OUTLET_TYPE_GUEST_LIST_ID && (
              <TabStyled
                onClick={() =>
                  type === 'requests' ? false : goToUrl('/user/history/requests')
                }
                active={type === 'requests'}
              >
                <Typography tag="h1">My Requests</Typography>
              </TabStyled>
            )}
            <TabStyled
              onClick={() =>
                type === 'orders' ? false : goToUrl('/user/history/orders')
              }
              active={type === 'orders'}
            >
              <Typography tag="h1">My Orders</Typography>
            </TabStyled>
          </TabContainerSyled>
        </Contain>
      </TabRootSyled>
      {loadingMessage ? (
        <div style={{ height: '100%' }}>
          <Contain>
            <Loading message={loadingMessage} align="center" />
          </Contain>
        </div>
      ) : (
        <div>
          {type === 'requests' && outlet?.OutletTypeID !== OUTLET_TYPE_GUEST_LIST_ID && (
            <div>
              <Contain>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    margin: '0 0 2rem 0',
                  }}
                >
                  <PillBtnStyled
                    active={requestViewFilter === 'current'}
                    onClick={() => setRequestViewFilter('current')}
                  >
                    Pending Requests
                  </PillBtnStyled>

                  <PillBtnStyled
                    active={requestViewFilter === 'past'}
                    onClick={() => handleClickProcessedRequestsTab()}
                  >
                    Processed Requests
                  </PillBtnStyled>
                </div>

                {requestViewFilter === 'past' && (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      margin: '0 0 2rem 0',
                    }}
                  >
                    <select
                      name="requestYearFilter"
                      value={requestYearFilter}
                      onChange={(e) => handleSelectYearFilter(e.target.value)}
                    >
                      <option value={false}>Select Year</option>
                      {getYearsArray(35).map((year) => (
                        <option key={year} value={year}>{year}</option>
                      ))}
                    </select>
                  </div>
                )}
              </Contain>
              {tourList.length ? (
                tourList.map((tour) => {
                  const prioritySelectTextIsVisible = Boolean(
                    requestViewFilter === 'current' &&
                    tour.AllowPrioritySelection &&
                    !tour.DisablePrioritySelection &&
                    prioritySelectPolicy,
                  );

                  return (
                    <div key={tour.Name}>
                      <TourRibbon
                        title={tour.Name}
                        messageHeadline={
                          prioritySelectTextIsVisible
                            ? prioritySelectPolicy.Headline
                            : null
                        }
                        messageBody={
                          prioritySelectTextIsVisible
                            ? prioritySelectPolicy.PolicyText
                            : null
                        }
                      />
                      {tour.requestSeriesList.map((seriesSummary) => {
                        const {
                          Totals,
                          OutletEventGroup,
                          LotteryOERequests,
                          PaymentPlanEnrolled,
                          LotteryOEGRequestUUID,
                          PriorityOERequestUUID,
                        } = seriesSummary;

                        const { seriesIsOpen } =
                          calculateSeriesStatuses(OutletEventGroup);

                        const paymentPlanIsAvailable = Boolean(
                          OutletEventGroup && OutletEventGroup.OfferPaymentPlan,
                        );
                        const userIsEnrolledInPaymentPlan =
                          Boolean(PaymentPlanEnrolled);

                        const seriesTotal = (Totals && Totals.total) || 0;

                        let userIsNewlyEligibleForPaymentPlan = false;
                        if (outlet && Array.isArray(outlet.OutletEventGroups)) {
                          const oegFound = outlet.OutletEventGroups.find(
                            (oeg) =>
                              Number(oeg.OutletEventGroupID) ===
                              Number(OutletEventGroup.OutletEventGroupID),
                          );

                          if (oegFound && oegFound.PaymentPlanRules) {
                            userIsNewlyEligibleForPaymentPlan =
                              seriesIsOpen &&
                              paymentPlanIsAvailable &&
                              !userIsEnrolledInPaymentPlan &&
                              seriesTotal >=
                              oegFound.PaymentPlanRules.eligibilityMinimum;
                          }
                        }

                        const allowPaymentChange =
                          requestViewFilter !== 'past' && seriesIsOpen;
                        const allowShippingAddressChanges =
                          requestViewFilter !== 'past' &&
                          (seriesIsOpen ||
                            (OutletEventGroup &&
                              OutletEventGroup.AllowShippingAddressChanges));
                        let eventRequestList = [];
                        let lineItemsNormalizedByOEI = [];
                        let addonItems;
                        if (Array.isArray(LotteryOERequests)) {
                          LotteryOERequests.map((eventRequest) => {
                            const { OutletEvent, LotteryOERequestUUID } =
                              eventRequest;
                            const requestIsPriority =
                              LotteryOERequestUUID === PriorityOERequestUUID;
                            const eventData = OutletEvent && OutletEvent.Event;
                            const total =
                              eventRequest.Totals && eventRequest.Totals.total;
                            let showDate; let artist; let city; let state; let eventName;
                            const eventOverrideName =
                              OutletEvent &&
                              OutletEvent.OutletEventOverrideName;
                            if (eventData) {
                              showDate = eventData.ShowDate;
                              artist =
                                eventData.Artist && eventData.Artist.ArtistName;
                              city = eventData.Venue && eventData.Venue.City;
                              state = eventData.Venue && eventData.Venue.State;
                              eventName = eventData.Name;
                            }
                            const eventLabel =
                              eventOverrideName || eventName || artist;
                            const thisSeriesIsCurrentlySelected =
                              LotteryOERequestUUID === itemID;

                            const obj = {
                              eventLabel,
                              showDate,
                              total,
                              requestIsPriority,
                              status:
                                eventRequest.StatusInfo &&
                                eventRequest.StatusInfo.CustomerStatus,
                              location: city + ', ' + state,
                              ID: LotteryOERequestUUID,
                              isExpanded: thisSeriesIsCurrentlySelected,
                            };

                            return eventRequestList.push(obj);
                          });
                        }

                        eventRequestList = eventRequestList.sort(
                          (a, b) => new Date(a.showDate) - new Date(b.showDate),
                        );

                        if (expandedRequestDetails) {
                          lineItemsNormalizedByOEI =
                            lineItemsNormalizedByOutletEventItem({
                              OutletEventItems:
                                expandedRequestDetails.OutletEvent.OutletEventItems,
                              cart: {
                                lineItems:
                                  expandedRequestDetails.LotteryOEIRequests,
                              },
                            });
                        }

                        const tableHeaders = [
                          'STATUS',
                          'SHOW DATE',
                          'EVENT',
                          'LOCATION',
                        ];
                        if (requestViewFilter !== 'past') {
                          tableHeaders.push('TOTAL');
                          if (tour.AllowPrioritySelection) {
                            tableHeaders.push('PRIORITY SELECT');
                          }
                        }

                        return (
                          <div
                            key={LotteryOEGRequestUUID}
                            id={'container-' + LotteryOEGRequestUUID}
                          >
                            <Contain>
                              {OutletEventGroup &&
                                OutletEventGroup.Name &&
                                OutletEventGroup.ShowSeriesName && (
                                  <Typography
                                    tag="h2"
                                    style={{ margin: ' 2rem 0' }}
                                  >
                                    {OutletEventGroup.Name}
                                  </Typography>
                                )}
                              <RequestHeaderStyled>
                                <div>
                                  <OrderNumberStyled
                                    tag="h3"
                                    onClick={() =>
                                      goToUrl(
                                        '/user/history/requests/' +
                                        seriesSummary.RequestID +
                                        '/summary',
                                      )
                                    }
                                  >
                                    Request Number -{' '}
                                    {seriesSummary && seriesSummary.RequestID}{' '}
                                    <IconContainerStyled>
                                      <IconInfo
                                        height={iconSize}
                                        width={iconSize}
                                        title="Series summary information"
                                      />
                                    </IconContainerStyled>
                                  </OrderNumberStyled>

                                  {seriesSummary &&
                                    seriesSummary.StatusInfo &&
                                    seriesSummary.StatusInfo.ConstantValue.startsWith(
                                      'STATUS_REQUEST_CONFIRMED',
                                    ) &&
                                    seriesSummary.UniqueID && (
                                      <div style={{ margin: '1rem 0 0 0' }}>
                                        <Typography
                                          tag="a"
                                          onClick={() =>
                                            goToUrl(
                                              '/user/history/orders/Z' +
                                              seriesSummary.UniqueID,
                                            )
                                          }
                                        >
                                          View Order #Z{seriesSummary.UniqueID}
                                        </Typography>
                                      </div>
                                    )}
                                </div>
                                <div>
                                  {requestViewFilter !== 'past' &&
                                    seriesIsOpen && (
                                      <Button
                                        style={{ height: '2.2em' }}
                                        onClick={() => goToUrl('/events')}
                                      >
                                        + Add to Your Request +{' '}
                                      </Button>
                                    )}
                                </div>
                                <div
                                  style={{
                                    display: 'flex',
                                  }}
                                >
                                  <div>
                                    {allowShippingAddressChanges &&
                                      seriesSummary.StatusInfo.ConstantValue.startsWith(
                                        'STATUS_REQUEST_PENDING',
                                      ) && (
                                        <Typography
                                          tag="a"
                                          onClick={() =>
                                            goToUrl(
                                              '/user/history/requests/' +
                                              seriesSummary.RequestID +
                                              '/edit-shipping',
                                            )
                                          }
                                        >
                                          Edit Shipping
                                        </Typography>
                                      )}
                                  </div>
                                  {allowShippingAddressChanges &&
                                    allowPaymentChange && (
                                      <div style={{ padding: '0 .5rem' }}>
                                        {' '}
                                        |{' '}
                                      </div>
                                    )}
                                  <div>
                                    {allowPaymentChange && (
                                      <Typography
                                        tag="a"
                                        onClick={() =>
                                          goToUrl(
                                            '/user/history/requests/' +
                                            seriesSummary.RequestID +
                                            '/edit-billing',
                                          )
                                        }
                                      >
                                        Manage Payment
                                      </Typography>
                                    )}
                                  </div>
                                  {(allowShippingAddressChanges ||
                                    allowPaymentChange) &&
                                    userIsNewlyEligibleForPaymentPlan && (
                                      <div style={{ padding: '0 .5rem' }}>
                                        {' '}
                                        |{' '}
                                      </div>
                                    )}
                                  {userIsNewlyEligibleForPaymentPlan && (
                                    <div>
                                      {' '}
                                      <Typography
                                        tag="a"
                                        onClick={() =>
                                          goToUrl(
                                            '/user/history/requests/' +
                                            seriesSummary.RequestID +
                                            '/edit-billing',
                                          )
                                        }
                                      >
                                        Enroll in Payment Plan
                                      </Typography>
                                    </div>
                                  )}
                                </div>
                              </RequestHeaderStyled>
                              {seriesSummary.CustomerOrderID && (
                                <div style={{ margin: '1rem 0' }}>
                                  <Typography
                                    tag="h3"
                                    style={{ opacity: 0.5, cursor: 'pointer' }}
                                    onClick={() =>
                                      goToUrl(
                                        '/user/history/orders/' +
                                        seriesSummary.CustomerOrderID,
                                      )
                                    }
                                  >
                                    Order ID{seriesSummary.CustomerOrderID}
                                  </Typography>
                                </div>
                              )}
                              <div
                                key={LotteryOEGRequestUUID}
                                id={'seriesSummary-' + LotteryOEGRequestUUID}
                              >
                                <UserHistoryList
                                  noMargin={true}
                                  tableHeaders={tableHeaders}
                                  type={type}
                                  expandedRowID={ID}
                                  fanRequest={fanRequest}
                                  dataList={eventRequestList}
                                  disablePrioritySelection={
                                    tour.DisablePrioritySelection
                                  }
                                  onClickExpandRow={(id) => {
                                    if (id === itemID) {
                                      goToUrl('/user/history/requests/');
                                    } else {
                                      goToUrl(
                                        '/user/history/requests/' +
                                        seriesSummary.RequestID +
                                        '/item-details/' +
                                        id,
                                      );
                                    }
                                  }}
                                  onClickPrioritySelection={(id) =>
                                    handleClickPrioritySelection({
                                      LotteryOEGRequestUUID,
                                      PriorityOERequestUUID: id,
                                      TourID: tour.TourID,
                                    })
                                  }
                                >
                                  {expandedRequestDetails &&
                                    expandedRequestDetails.OutletEvent && (
                                      <Columns qty={2}>
                                        <LeftColumStyled>
                                          <OutletEventSummary
                                            outletEvent={
                                              expandedRequestDetails.OutletEvent
                                            }
                                          />
                                          {requestViewFilter !== 'past' &&
                                            seriesIsOpen && (
                                              <ButtonContainerStyled>
                                                <div>
                                                  <Typography
                                                    tag="a"
                                                    onClick={() => {
                                                      setRequestLineItemErrorMessages(
                                                        [],
                                                      );
                                                      setModal({
                                                        constant:
                                                          'EDIT_LINE_ITEMS',
                                                        title:
                                                          editRequestModalTitle,
                                                        isVisible: true,
                                                        content: {
                                                          // outletEventItems,
                                                          LotteryOERequest:
                                                            expandedRequestDetails,
                                                        },
                                                      });
                                                    }}
                                                    secondary
                                                  >
                                                    Edit Request
                                                  </Typography>
                                                </div>
                                                <div
                                                  style={{ margin: '0 2rem' }}
                                                >
                                                  {' '}
                                                  |{' '}
                                                </div>
                                                {' '}
                                                <div>
                                                  <Typography
                                                    tag="a"
                                                    onClick={() =>
                                                      setModal({
                                                        constant:
                                                          'DELETE_REQUEST',
                                                        title: 'Delete Request',
                                                        isVisible: true,
                                                        content: {
                                                          // outletEventItems,
                                                          LotteryOERequest:
                                                            expandedRequestDetails,
                                                        },
                                                      })
                                                    }
                                                    style={{ color: 'red' }}
                                                  >
                                                    Delete Request
                                                  </Typography>
                                                </div>
                                              </ButtonContainerStyled>
                                            )}
                                        </LeftColumStyled>
                                        <RightColumStyled>
                                          {Array.isArray(
                                            lineItemsNormalizedByOEI,
                                          ) && (
                                              <>
                                                {lineItemsNormalizedByOEI.map(
                                                  (oei, idx) => {
                                                    return (
                                                      <div key={idx}>
                                                        <OutletEventItemHeader
                                                          oei={oei}
                                                        />

                                                        {oei.lineItems &&
                                                          Array.isArray(
                                                            oei.lineItems,
                                                          )
                                                          ? oei.lineItems.map(
                                                            (
                                                              lineItem,
                                                              idx2,
                                                            ) => {
                                                              const lineItemStatus = lineItem.status;
                                                              if (
                                                                lineItem.isAddOn
                                                              ) {
                                                                return (
                                                                  <div>
                                                                    <LineItemSummary
                                                                      showOutletEventItemHeader={false}
                                                                      showConfirmedPriceLevel={true}
                                                                      outletEvent={expandedRequestDetails.OutletEvent}
                                                                      cart={{
                                                                        lineItems: [lineItem],
                                                                      }}
                                                                      itemTotalIsVisible={
                                                                        requestViewFilter === 'past' ? false : true}
                                                                      feeInfoIsVisible={requestViewFilter === 'past' ? false : true}
                                                                      priceLevelTotalIsVisible={requestViewFilter === 'past' ? false : true}
                                                                      shippingTotalIsVisible={false}
                                                                      grandTotalIsVisible={false}
                                                                    />
                                                                  </div>
                                                                );
                                                              }

                                                              return (
                                                                <LineItemPreferenceList
                                                                  displayFees={
                                                                    requestViewFilter ===
                                                                      'past'
                                                                      ? false
                                                                      : true
                                                                  }
                                                                  showTotals={
                                                                    requestViewFilter ===
                                                                      'past'
                                                                      ? false
                                                                      : true
                                                                  }
                                                                  showPriceLevelTotal={
                                                                    requestViewFilter ===
                                                                      'past'
                                                                      ? false
                                                                      : true
                                                                  }
                                                                  priceLevelTotalIsVisible={
                                                                    requestViewFilter ===
                                                                      'past'
                                                                      ? false
                                                                      : true
                                                                  }
                                                                  ConfirmedPriceLevelID={
                                                                    lineItem.ConfirmedPriceLevelID
                                                                  }
                                                                  ConfirmedQuantity={
                                                                    lineItem.ConfirmedQuantity
                                                                  }
                                                                  key={idx2}
                                                                  status={
                                                                    lineItemStatus
                                                                  }
                                                                  selectedQty={
                                                                    lineItem.quantity ||
                                                                    lineItem.Quantity
                                                                  }
                                                                  outletEventItem={
                                                                    oei
                                                                  }
                                                                  selectedPriceLevelId={
                                                                    lineItem.PriceLevelID
                                                                  }
                                                                  optOutIsSelected={Boolean(
                                                                    lineItem.PriceLevelOptOut,
                                                                  )}
                                                                  onlyPreferenceIsVisible={
                                                                    false
                                                                  }
                                                                />
                                                              );
                                                            },
                                                          )
                                                          : null}
                                                      </div>
                                                    );
                                                  },
                                                )}
                                              </>
                                            )}
                                          {addonItems && (
                                            <LineItemSummary
                                              showConfirmedPriceLevel={true}
                                              outletEvent={
                                                expandedRequestDetails.OutletEvent
                                              }
                                              cart={{
                                                lineItems: addonItems,
                                              }}
                                              itemTotalIsVisible={
                                                requestViewFilter === 'past'
                                                  ? false
                                                  : true
                                              }
                                              feeInfoIsVisible={
                                                requestViewFilter === 'past'
                                                  ? false
                                                  : true
                                              }
                                              priceLevelTotalIsVisible={
                                                requestViewFilter === 'past'
                                                  ? false
                                                  : true
                                              }
                                              shippingTotalIsVisible={false}
                                              grandTotalIsVisible={false}
                                            />
                                          )}
                                          <CheckoutTotalsSummaryFanRequest
                                            seriesTotalIsVisible={
                                              requestViewFilter !== 'past'
                                            }
                                            seriesShippingTotalIsVisible={
                                              requestViewFilter !== 'past'
                                            }
                                            outletEvent={
                                              expandedRequestDetails.OutletEvent
                                            }
                                            seriesSummary={seriesSummary}
                                            allowShowAllRequest={false}
                                          />
                                        </RightColumStyled>
                                      </Columns>
                                    )}
                                </UserHistoryList>

                                <RequestTotalsSummaryStyled>
                                  <div style={{ width: 225 }}>
                                    <LineItemRow
                                      bold
                                      label="Fees:"
                                      total={
                                        (seriesSummary &&
                                          seriesSummary.Totals &&
                                          seriesSummary.Totals.fees) ||
                                        0
                                      }
                                    />

                                    {seriesSummary &&
                                      seriesSummary.Totals &&
                                      seriesSummary.Totals.ppfee &&
                                      seriesSummary.PaymentPlanEnrolled && (
                                        <LineItemRow
                                          bold
                                          total={
                                            seriesSummary.Totals.ppfee || 0
                                          }
                                          label={'Payment Plan Fee:'}
                                        />
                                      )}

                                    <LineItemRow
                                      bold
                                      total={
                                        (seriesSummary &&
                                          seriesSummary.Totals &&
                                          seriesSummary.Totals.shipping) ||
                                        0
                                      }
                                      label={'Shipping:'}
                                    />

                                    <LineItemRow
                                      bold
                                      label="Max Series Total:"
                                      total={
                                        (seriesSummary &&
                                          seriesSummary.Totals &&
                                          seriesSummary.Totals.total) ||
                                        0
                                      }
                                    />
                                  </div>
                                </RequestTotalsSummaryStyled>
                              </div>
                              {' '}
                            </Contain>
                          </div>
                        );
                      })}
                    </div>
                  );
                })
              ) : (
                <div style={{ textAlign: 'center', padding: '4rem 0' }}>
                  <Typography tag="h3" style={{ margin: '0 0 4rem 0' }}>
                    You have no{' '}
                    {requestViewFilter === 'current'
                      ? 'current'
                      : requestYearFilter
                        ? requestYearFilter
                        : new Date().getFullYear()}{' '}
                    requests
                  </Typography>
                  {requestViewFilter === 'current' ? (
                    <Button onClick={() => goToUrl('/events')}>
                      Go to Event List
                    </Button>
                  ) : null}
                </div>
              )}
            </div>
          )}
          {type === 'orders' && (
            <Contain>
              <Typography tag="h2" style={{ textAlign: 'center' }}>Upcoming Events</Typography>
              <OrderList
                order={order}
                expandedOrderId={ID}
                expandedOutletEventId={itemID}
                orderIsLoading={orderIsLoading}
                handleClickManageTickets={handleClickManageTickets}
                orderList={orderListWithUpcomingEvents}
                messageIfNoOrdersFound='No upcoming events' />
              <StyledPastEventsTitleAndSelector>
                <Typography tag="h2" style={{ textAlign: 'center' }}>Past Events</Typography>
                <SelectInput
                  noMargin
                  name="orderListYearFilter"
                  value={orderListYearFilter}
                  onChange={(e) => handleSelectOrderListYearFilter(e.target.value)}
                  optionList={
                    [{ value: '', label: 'Select Year' }]
                      .concat(getListOfShowDateYearsFromOrders(orderListWithOnlyPastEvents)
                        .map((year) => ({ value: year, label: year })))
                  }
                />
              </StyledPastEventsTitleAndSelector>
              <OrderList
                order={order}
                expandedOrderId={ID}
                expandedOutletEventId={itemID}
                orderIsLoading={orderIsLoading}
                orderList={orderListWithOnlyPastEvents
                  .filter((order) => order?.eventShowDateYears?.includes(orderListYearFilter))}
                messageIfNoOrdersFound={orderListYearFilter ? 'No orders to display' : 'Select Year to Display'} />
            </Contain>
          )}
        </div>
      )}

      <Modal
        title={modal.title}
        open={modal.isVisible}
        onClose={() => handleCloseModal()}
      >
        {(type === 'orders' && orderIsLoading) ||
          (type === 'requests' && requestSeriesListIsLoading) ? (
          'Loading details'
        ) : (
          <div>
            {modal.constant === 'MANAGE_TICKETS' && (
              <ManageTickets
                {...modal.content}
                order={order}
                loading={mobileTicketListIsLoading}
                userMobileTicketList={userMobileTicketList}
                userOrderSummaryList={userOrderSummaryList}
              />
            )}
            {modal.constant === 'ELIGIBLE_FOR_PAYMENT_PLAN' && (
              <div>
                <div
                  style={{
                    fontWeight: 'bold',
                    color: 'green',
                    textAlign: 'center',
                  }}
                >
                  <p>You successfully updated your request</p>
                  <p>
                    Your request is eligible for you to enroll in the payment
                    plan.
                  </p>
                </div>
                <div
                  style={{
                    margin: '3rem 0',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <div style={{ margin: '0 2rem 0 0' }}>
                    <Button onClick={() => goToUrl(modal.managePaymentUrl)}>
                      Manage Payment
                    </Button>
                  </div>
                  <div>
                    <Button onClick={() => handleCloseModal()}>Close</Button>
                  </div>
                </div>
              </div>
            )}

            {modal.constant === 'DELETE_REQUEST' && (
              <div>
                <Typography tag="p">
                  Are you sure you wish to delete your request?{' '}
                </Typography>
                <Typography tag="p">
                  By deleting your Ticket Request, you may no longer qualify for
                  the payment plan
                </Typography>
                <Typography tag="p">
                  If you no longer have any tickets requested for this event,
                  your requests for add ons for this event will also be deleted.
                </Typography>
                <div style={{ margin: '2rem 0', textAlign: 'center' }}>
                  {fanRequest && fanRequest.loading ? (
                    <Loading align="center" message="Deleting request" />
                  ) : (
                    <>
                      <Button
                        fullWidth
                        onClick={() => setModal({ ...modal, isVisible: false })}
                        style={{ margin: '2rem 0' }}
                        loading={fanRequest && fanRequest.loading}
                      >
                        No, Keep My Ticket Request
                      </Button>
                      <Typography
                        style={{
                          display: 'block',
                          textAlign: 'center',
                          margin: '0 0 3rem 0',
                          color: 'red',
                        }}
                        tag="a"
                        onClick={() => {
                          if (modal.content && modal.content.LotteryOERequest) {
                            deleteFanRequest({
                              request: modal.content.LotteryOERequest,
                            }).then((res) => {
                              getUserFanRequestSeriesSummary();
                              getUserFanRequests();
                              handleCloseModal();
                            });
                          } else {
                            alert('missing request id');
                          }
                        }}
                        loading={fanRequest && fanRequest.loading}
                      >
                        Yes, Delete My Request
                      </Typography>
                    </>
                  )}
                </div>
              </div>
            )}
            {modal.constant === 'EDIT_LINE_ITEMS' && (
              <>
                {type === 'requests' ? (
                  <div>
                    {modal.content &&
                      modal.content.LotteryOERequest &&
                      modal.content.LotteryOERequest.OutletEvent && (
                        <OutletEventSummary
                          outletEvent={
                            modal.content.LotteryOERequest.OutletEvent
                          }
                        />
                      )}

                    <EditRequestLineItems
                      LotteryOERequest={
                        modal.content && modal.content.LotteryOERequest
                      }
                      loading={fanRequest.loading}
                      outletEventItems={
                        modal.content &&
                        modal.content.LotteryOERequest &&
                        modal.content.LotteryOERequest.OutletEvent &&
                        modal.content.LotteryOERequest.OutletEvent.OutletEventItems
                      }
                      defaultUserSelectedPriceLevels={
                        modal.content &&
                        modal.content.LotteryOERequest &&
                        modal.content.LotteryOERequest.LotteryOEIRequests
                      }
                      onClickCancel={() => {
                        setFanRequestError(null);
                        setRequestLineItemErrorMessages([]);
                        setModal({ ...modal, isVisible: false });
                      }}
                      onClickSave={(lineItems) => {
                        setFanRequestError(null);
                        handleClickSaveRequestLineItems(lineItems);
                      }}
                    >
                      {requestLineItemErrorMessages &&
                        requestLineItemErrorMessages.length
                        ? requestLineItemErrorMessages.map((e) => (
                          <DisplayError>{e.message}</DisplayError>
                        ))
                        : null}
                      {fanRequest && fanRequest.error && (
                        <DisplayError>{fanRequest.error}</DisplayError>
                      )}
                    </EditRequestLineItems>
                  </div>
                ) : null}
              </>
            )}

            {modal.constant === 'EDIT_SHIPPING_ADDRESS' && (
              <>
                {(type === 'orders' && !order) ||
                  (type === 'requests' && !selectedSeriesRequest) ? (
                  'Loading Data'
                ) : (
                  <AddressForm
                    loading={
                      addressUpdateIsLoading ||
                      orderIsLoading ||
                      fanRequest.loading
                    }
                    // error={cartError}
                    buttonText="Update"
                    defaultAddress={
                      type === 'orders'
                        ? normalizeAddress(order && order.CustomerOrderAddress)
                        : normalizeAddress(
                          selectedSeriesRequest &&
                          selectedSeriesRequest.Shipping,
                        )
                    }
                    addresses={userAddressList}
                    onSubmit={(formData) =>
                      type === 'requests'
                        ? handleOnSubmitShippingAddressForFanRequest(
                          formData,
                          selectedSeriesRequest,
                        )
                        : handleOnSubmitShippingAddressForOrder(formData, order)
                    }
                    refreshDefaultAddress={false}
                  />
                )}
              </>
            )}
            {modal.constant === 'EDIT_BILLING' && (
              <>
                {type === 'requests' ? (
                  <>
                    {selectedSeriesRequest && (
                      <EditRequestBilling
                        selectedSeriesRequest={selectedSeriesRequest}
                        loading={
                          fanRequest.loading || userPaymentMethodListIsLoading
                        }
                        onClickSave={(payload) =>
                          handleOnSelectPaymentMethodForFanRequest(
                            payload,
                            selectedSeriesRequest,
                            () => handleCloseModal(),
                          )
                        }
                        outletEvent={{
                          OutletEventGroup:
                            selectedSeriesRequest.OutletEventGroup,
                        }}
                        seriesTotal={
                          (selectedSeriesRequest.Totals &&
                            selectedSeriesRequest.Totals.total) ||
                          0
                        }
                        paymentPlanIsAvailable={
                          selectedSeriesRequest.OutletEventGroup.OfferPaymentPlan
                        }
                        userIsEnrolledInPaymentPlan={
                          selectedSeriesRequest.PaymentPlanEnrolled
                        }
                        defaultPaymentInfo={selectedSeriesRequest.PaymentInfo}
                        userPaymentMethodList={userPaymentMethodList}
                      />
                    )}
                  </>
                ) : (
                  <div>
                    {order && order.PaymentPlanEnrolled ? (
                      <PaymentPlanForm
                        paymentPlanRules={paymentPlanRules}
                        payNowIsAvailable={true}
                        loading={paymentInfoUpdateIsLoading}
                        paymentList={
                          order && order.CustomerOrderScheduledPayments
                        }
                        userPaymentMethodList={userPaymentMethodList}
                        total={(order && order.TotalSummary.total) || 0}
                        onChangePaymentMethod={(pm) => {
                          handleOnChangePaymentMethod(pm);
                        }}
                        onCreateViaIFrame={
                          handleOnCreateNewPaymentMethodViaIFrame
                        }
                      />
                    ) : (
                      <DisplayPaymentMethod
                        paymentMethod={order && order.PaymentMethod}
                      />
                    )}
                  </div>
                )}
              </>
            )}

            {modal.constant === 'SUMMARY_DETAILS' && (
              <div>
                <div
                  style={{
                    display: 'flex',
                    gap: '30px',
                    justifyContent: 'start',
                  }}
                >
                  <div style={{ flex: 1 }}>
                    {type === 'orders' && order && (
                      <>
                        <Typography tag="h3">
                          Order #: <span style={{ opacity: 0.5 }}>{ID}</span>
                        </Typography>

                        {order &&
                          order.Status &&
                          order.Status.CustomerStatus && (
                            <Typography tag="h3">
                              Status:{' '}
                              <span style={{ opacity: 0.5 }}>
                                {order.Status.CustomerStatus}
                              </span>
                            </Typography>
                          )}
                        {order && order.TrackingNumber ? (
                          <React.Fragment>
                            <Typography tag="h3">
                              Tracking #:{' '}
                              <a
                                href={
                                  trackingUrl
                                }
                                target="_blank" rel="noreferrer"
                              >
                                {order.TrackingNumber}
                              </a>
                            </Typography>
                          </React.Fragment>
                        ) : (
                          <>
                            {order && order.EstimatedShipDate && (
                              <Typography tag="h3">
                                Estimated Ship Date:{' '}
                                <span style={{ opacity: 0.5 }}>
                                  {formatDate(order.EstimatedShipDate)}
                                </span>
                              </Typography>
                            )}
                          </>
                        )}
                        <>
                          {order && order.ShipLabelShipDate && (
                            <Typography tag="h3">
                              Ship Date:{' '}
                              <span style={{ opacity: 0.5 }}>
                                {formatDate(order.ShipLabelShipDate)}
                              </span>
                            </Typography>
                          )}
                        </>
                      </>
                    )}
                    {type === 'requests' && selectedSeriesRequest && (
                      <>
                        {selectedSeriesRequest.StatusInfo && (
                          <Typography tag="h3">
                            Status:{' '}
                            <span style={{ opacity: 0.5 }}>
                              {selectedSeriesRequest.StatusInfo.CustomerStatus}
                            </span>
                          </Typography>
                        )}
                        <Typography tag="h3">
                          Request #: <span style={{ opacity: 0.5 }}>{ID}</span>
                        </Typography>
                        {selectedSeriesRequest.OutletEventGroup &&
                          selectedSeriesRequest.OutletEventGroup.EstimatedShipDate && (
                            <Typography tag="h3">
                              Est. Ship Date:{' '}
                              <span style={{ opacity: 0.5 }}>
                                {formatDate(
                                  selectedSeriesRequest.OutletEventGroup.EstimatedShipDate,
                                )}
                              </span>
                            </Typography>
                          )}
                        <Typography tag="h3">
                          Created Date:{' '}
                          <span style={{ opacity: 0.5 }}>
                            {formatDate(selectedSeriesRequest.createdAt)} @{' '}
                            {formatTime(selectedSeriesRequest.createdAt)}
                          </span>
                        </Typography>
                        <Typography tag="h3">
                          Last Modified Date:{' '}
                          <span style={{ opacity: 0.5 }}>
                            {formatDate(selectedSeriesRequest.updatedAt)} @{' '}
                            {formatTime(selectedSeriesRequest.updatedAt)}
                          </span>
                        </Typography>
                      </>
                    )}
                    <Typography tag="h3">Shipping Address:</Typography>
                    <div style={{ margin: '0 0 5px 0' }}>
                      <DisplayShippingAddress
                        address={normalizeAddress(
                          type === 'requests'
                            ? selectedSeriesRequest &&
                            selectedSeriesRequest.Shipping
                            : order && order.CustomerOrderAddress,
                        )}
                      />
                    </div>
                    <Typography tag="h3">
                      {(type === 'requests' &&
                        selectedSeriesRequest &&
                        selectedSeriesRequest.PaymentPlanEnrolled &&
                        Array.isArray(selectedSeriesRequest.PaymentInfo)) ||
                        (order && order.PaymentPlanEnrolled)
                        ? 'Payment Plan Information:'
                        : 'Payment Information:'}
                    </Typography>
                    {type === 'requests' ? (
                      <>
                        {selectedSeriesRequest &&
                          selectedSeriesRequest.PaymentPlanEnrolled &&
                          Array.isArray(selectedSeriesRequest.PaymentInfo) ? (
                          selectedSeriesRequest.PaymentInfo.map((p, pidx) => {
                            let paymentDueDate =
                              selectedSeriesRequest.OutletEventGroup &&
                              selectedSeriesRequest.OutletEventGroup.PaymentPlanInstallment1Date;
                            if (pidx === 1) {
                              paymentDueDate =
                                selectedSeriesRequest.OutletEventGroup &&
                                selectedSeriesRequest.OutletEventGroup.PaymentPlanInstallment2Date;
                            }
                            if (pidx === 2) {
                              paymentDueDate =
                                selectedSeriesRequest.OutletEventGroup &&
                                selectedSeriesRequest.OutletEventGroup.PaymentPlanInstallment3Date;
                            }
                            if (paymentDueDate) {
                              paymentDueDate = formatDate(paymentDueDate, {
                                timeZone: 'UTC',
                              });
                            }

                            const installmentText =
                              'Installment ' +
                              (pidx + 1) +
                              ' - ' +
                              formatCurrency(p.Amount);
                            let processText;
                            if (paymentDueDate) {
                              if (pidx === 0) {
                                processText =
                                  'Process on or before ' + paymentDueDate;
                              } else {
                                processText = 'Process on ' + paymentDueDate;
                              }
                            }
                            return (
                              <div style={{ margin: '0 0 1.5rem 0' }}>
                                <div style={{ fontWeight: 700 }}>
                                  {installmentText}
                                </div>
                                {processText && <div>{processText}</div>}
                                <div
                                  style={{
                                    border: 'solid 1px #ddd',
                                    padding: '5px',
                                    borderRadius: '5px',
                                  }}
                                >
                                  <DisplayPaymentMethod paymentMethod={p} />
                                </div>
                              </div>
                            );
                          })
                        ) : (
                          <DisplayPaymentMethod
                            paymentMethod={
                              (selectedSeriesRequest &&
                                selectedSeriesRequest.PaymentInfo &&
                                selectedSeriesRequest.PaymentInfo[0]) ||
                              null
                            }
                          />
                        )}
                      </>
                    ) : (
                      <div>
                        {order && order.PaymentPlanEnrolled ? (
                          <PaymentPlanForm
                            paymentPlanRules={paymentPlanRules}
                            readOnly={true}
                            paymentList={
                              order &&
                              order.CustomerOrderScheduledPayments.map((x) => ({
                                ...x,
                                ...x.PaymentMethod,
                              }))
                            }
                            userPaymentMethodList={userPaymentMethodList}
                            total={(order && order.TotalSummary.total) || 0}
                          />
                        ) : (
                          <DisplayPaymentMethod
                            paymentMethod={order && order.PaymentMethod}
                          />
                        )}
                      </div>
                    )}
                    {type === 'orders' && order && (
                      <>
                        <Typography tag="h3">Order Totals:</Typography>
                        <LineItemRow
                          bold
                          label="Fees Total"
                          total={order.TotalSummary.fees || 0}
                        />
                        {order?.TotalSummary?.ppFee && (
                          <>
                            <LineItemRow
                              bold
                              label="Payment Plan Fee"
                              total={order.TotalSummary.ppFee || 0}
                            />
                          </>
                        )}
                        {order.TotalSummary.taxes &&
                          order.TotalSummary.taxes > 0 ? (
                          <>
                            <LineItemRow
                              bold
                              label="Taxes"
                              total={order.TotalSummary.taxes || 0}
                            />
                          </>
                        ) : null}
                        <LineItemRow
                          bold
                          total={order.TotalSummary.shipping || 0}
                          label={
                            order.OutletDeliveryMethod &&
                              order.OutletDeliveryMethod.OverrideName
                              ? order.OutletDeliveryMethod.OverrideName
                              : 'Shipping'
                          }
                        />
                        <LineItemRow
                          bold
                          label="Grand Total"
                          total={order.TotalSummary.total || 0}
                        />
                      </>
                    )}
                  </div>
                  {type === 'orders' && order && (
                    <div style={{ flex: 1 }}>
                      {order.LineItems.map((li, idx) => {
                        return (
                          <div key={idx}>
                            <div style={{ margin: '0 0 2rem 0' }}>
                              <OutletEventSummary outletEvent={li} />
                              {Array.isArray(li.OutletEventItems)
                                ? li.OutletEventItems.map((oei) => {
                                  return (
                                    <div key={oei.OutletEventItemID}>
                                      <OutletEventItemHeader oei={oei} />
                                      {Array.isArray(oei.PriceLevels)
                                        ? oei.PriceLevels.map((pl) => {
                                          // Start RLMTS-2921 new totals for orders that contain line items with different qty, unit pice
                                          const lineItemsByUnitPrice = [];
                                          if (
                                            Array.isArray(
                                              pl.CustomerOrderLineItems,
                                            )
                                          ) {
                                            pl.CustomerOrderLineItems.map(
                                              (i) => {
                                                const {
                                                  Canceled,
                                                  Quantity,
                                                  UnitPrice,
                                                  TicketManifest,
                                                } = i;

                                                if (
                                                  Quantity &&
                                                  Boolean(Canceled) ===
                                                  false
                                                ) {
                                                  const index =
                                                    lineItemsByUnitPrice.findIndex(
                                                      (j) =>
                                                        j.UnitPrice ===
                                                        UnitPrice,
                                                    );

                                                  if (index > -1) {
                                                    const adjustedQty =
                                                      (lineItemsByUnitPrice[
                                                        index
                                                      ].Quantity +=
                                                        Quantity);
                                                    lineItemsByUnitPrice[
                                                      index
                                                    ] = {
                                                      UnitPrice,
                                                      Quantity: adjustedQty,
                                                    };
                                                  } else {
                                                    lineItemsByUnitPrice.push(
                                                      {
                                                        UnitPrice,
                                                        Quantity,
                                                      },
                                                    );
                                                  }
                                                }
                                              },
                                            );
                                          }
                                          // End RLMTS-2921

                                          return (
                                            <div key={pl.PriceLevelID}>
                                              <PriceLevelHeader>
                                                <div
                                                  style={{
                                                    display: 'flex',
                                                  }}
                                                >
                                                  <div style={{ flex: 1 }}>
                                                    {pl.PriceLevelName}
                                                  </div>
                                                </div>
                                              </PriceLevelHeader>
                                              {lineItemsByUnitPrice.map(
                                                (libup) => (
                                                  <LineItemRow
                                                    label={
                                                      pl.PriceLevelName +
                                                      ' x' +
                                                      libup.Quantity +
                                                      ' @ ' +
                                                      formatCurrency(
                                                        libup.UnitPrice,
                                                      )
                                                    }
                                                    total={
                                                      libup.Quantity *
                                                      libup.UnitPrice
                                                    }
                                                  />
                                                ),
                                              )}

                                              {oei.manifest && (
                                                <div>
                                                  Section:
                                                  {oei.manifest.SectionName}
                                                  <br />
                                                  Row:{oei.manifest.RowName}
                                                  <br />
                                                  Seat:
                                                  {oei.manifest.SeatName}
                                                </div>
                                              )}
                                            </div>
                                          );
                                        })
                                        : null}
                                    </div>
                                  );
                                })
                                : 'no event items'}{' '}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                  {type === 'requests' && selectedSeriesRequest && (
                    <div style={{ flex: 1 }}>
                      <Typography tag="h3">Event Details:</Typography>
                      {selectedSeriesRequest &&
                        Array.isArray(selectedSeriesRequest.LotteryOERequests) &&
                        selectedSeriesRequest.LotteryOERequests.length ? (
                        <>
                          {selectedSeriesRequest.LotteryOERequests.sort(
                            (a, b) =>
                              new Date(a.eventShowDate) -
                              new Date(b.eventShowDate),
                          ).map((eventRequest) => {
                            const borderColor = '#9e9e9e';
                            const {
                              OutletEvent,
                              Totals,
                              LotteryOERequestUUID,
                              LotteryOEIRequests,
                            } = eventRequest;
                            const eventData = OutletEvent && OutletEvent.Event;
                            const total = Totals && Totals.total;
                            let showDate; let artist; let city; let state; let venueName;
                            if (eventData) {
                              showDate = eventData.ShowDate;
                              artist =
                                eventData.Artist && eventData.Artist.ArtistName;
                              city = eventData.Venue && eventData.Venue.City;
                              state = eventData.Venue && eventData.Venue.State;
                              venueName =
                                eventData.Venue && eventData.Venue.VenueName;
                            }

                            const lineItemsNormalizedByOEI =
                              lineItemsNormalizedByOutletEventItem({
                                OutletEventItems: OutletEvent.OutletEventItems,
                                cart: {
                                  lineItems: LotteryOEIRequests,
                                },
                              });

                            const addonItems = Array.isArray(LotteryOEIRequests)
                              ? LotteryOEIRequests.filter((li) => li.isAddOn)
                              : [];
                            const eventRequestURL =
                              '/user/history/requests/' +
                              selectedSeriesRequest.RequestID +
                              '/item-details/' +
                              LotteryOERequestUUID;

                            return (
                              <div
                                style={{
                                  border: `solid 1px ${borderColor}`,
                                  margin: '0 0 2rem 0',
                                }}
                              >
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    borderBottom: `solid 1px ${borderColor}`,
                                  }}
                                >
                                  <div style={{ flex: 1, padding: '1rem' }}>
                                    <Typography
                                      tag="a"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        handleCloseModal();
                                        goToUrl(eventRequestURL);
                                      }}
                                    >
                                      {formatDate(showDate)}
                                    </Typography>
                                    <br />
                                    {artist}
                                  </div>
                                  <div
                                    style={{
                                      textAlign: 'right',
                                      padding: '1rem',
                                    }}
                                  >
                                    {venueName}
                                    <br />
                                    {city}, {state}
                                  </div>
                                </div>
                                <div>
                                  {lineItemsNormalizedByOEI.map((li, index) => {
                                    const isOddRow = index % 2;
                                    return (
                                      <div
                                        style={{
                                          backgroundColor: isOddRow
                                            ? 'inherit'
                                            : '#f2efef',
                                        }}
                                      >
                                        <div
                                          style={{
                                            padding: '1rem 1rem 0 1rem',
                                            fontWeight: 600,
                                          }}
                                        >
                                          {li.Name}
                                        </div>
                                        <div style={{ padding: '1rem' }}>
                                          {li.lineItems &&
                                            Array.isArray(li.lineItems)
                                            ? li.lineItems.map(
                                              (lineItem, idx2) => {
                                                const lineItemStatus =
                                                  lineItem.status;
                                                if (lineItem.isAddOn) {
                                                  const quantity =
                                                    lineItem.quantity ||
                                                    lineItem.Quantity ||
                                                    0;

                                                  const label =
                                                    lineItem.PriceLevelName +
                                                    ' ' +
                                                    formatCurrency(
                                                      lineItem.PriceLevelValue,
                                                    ) +
                                                    ' x ' +
                                                    quantity;
                                                  return (
                                                    <div key={idx2}>
                                                      <LineItemRow
                                                        style={{
                                                          padding: '0px',
                                                        }}
                                                        label={label}
                                                        showTotal={true}
                                                        total={
                                                          lineItem.PriceLevelValue *
                                                          quantity
                                                        }
                                                      />
                                                    </div>
                                                  );
                                                }

                                                return (
                                                  <div
                                                    style={{
                                                      padding: '0 0 0 0',
                                                    }}
                                                    key={idx2}
                                                  >
                                                    <LineItemPreferenceList
                                                      status={lineItemStatus}
                                                      displayFees={
                                                        requestViewFilter ===
                                                          'past'
                                                          ? false
                                                          : true
                                                      }
                                                      showTotals={
                                                        requestViewFilter ===
                                                          'past'
                                                          ? false
                                                          : true
                                                      }
                                                      showPriceLevelTotal={
                                                        requestViewFilter ===
                                                          'past'
                                                          ? false
                                                          : true
                                                      }
                                                      ConfirmedPriceLevelID={
                                                        lineItem.ConfirmedPriceLevelID
                                                      }
                                                      ConfirmedQuantity={
                                                        lineItem.ConfirmedQuantity
                                                      }
                                                      showBorder={false}
                                                      key={idx2}
                                                      showPreferenceTitleOnly={
                                                        true
                                                      }
                                                      selectedQty={
                                                        lineItem.quantity ||
                                                        lineItem.Quantity
                                                      }
                                                      outletEventItem={
                                                        lineItem.OutletEventItem
                                                      }
                                                      selectedPriceLevelId={
                                                        lineItem.PriceLevelID
                                                      }
                                                      optOutIsSelected={Boolean(
                                                        lineItem.PriceLevelOptOut,
                                                      )}
                                                      onlyPreferenceIsVisible={
                                                        false
                                                      }
                                                    />
                                                  </div>
                                                );
                                              },
                                            )
                                            : null}
                                        </div>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            );
                          })}
                        </>
                      ) : (
                        'no event request data found'
                      )}
                      {requestViewFilter !== 'past' && (
                        <>
                          <div
                            style={{
                              display: 'flex',
                              padding: ' 0 0 1rem 0',
                              alignItems: 'center',
                            }}
                          >
                            <div style={{ flex: 1 }}>
                              <Typography tag="h3">Fees</Typography>
                            </div>
                            <div>
                              <strong>
                                <Typography tag="h3">
                                  {formatCurrency(
                                    selectedSeriesRequest.Totals.fees,
                                  )}
                                </Typography>
                              </strong>
                            </div>
                          </div>

                          <div
                            style={{
                              display: 'flex',
                              padding: ' 0 0 1rem 0',
                              alignItems: 'center',
                            }}
                          >
                            <div style={{ flex: 1 }}>
                              <Typography tag="h3">Taxes</Typography>
                            </div>
                            <div>
                              <strong>
                                <Typography tag="h3">
                                  {formatCurrency(
                                    selectedSeriesRequest.Totals.taxes,
                                  )}
                                </Typography>
                              </strong>
                            </div>
                          </div>

                          <div
                            style={{
                              display: 'flex',
                              padding: ' 0 0 1rem 0',
                              alignItems: 'center',
                            }}
                          >
                            <div style={{ flex: 1 }}>
                              <Typography tag="h3">Shipping</Typography>
                            </div>
                            <div>
                              <strong>
                                <Typography tag="h3">
                                  {formatCurrency(
                                    selectedSeriesRequest.Totals.shipping,
                                  )}
                                </Typography>
                              </strong>
                            </div>
                          </div>
                          {selectedSeriesRequest &&
                            selectedSeriesRequest.PaymentPlanEnrolled &&
                            Array.isArray(
                              selectedSeriesRequest.PaymentInfo,
                            ) && (
                              <div
                                style={{
                                  display: 'flex',
                                  padding: ' 0 0 1rem 0',
                                  alignItems: 'center',
                                }}
                              >
                                <div style={{ flex: 1 }}>
                                  <Typography tag="h3">
                                    Payment Plan Fee
                                  </Typography>
                                </div>
                                <div>
                                  <strong>
                                    <Typography tag="h3">
                                      {formatCurrency(
                                        selectedSeriesRequest.Totals.ppfee,
                                      )}
                                    </Typography>
                                  </strong>
                                </div>
                              </div>
                            )}

                          <div
                            style={{
                              display: 'flex',
                              padding: ' 0 0 1rem 0',
                              alignItems: 'center',
                            }}
                          >
                            <div style={{ flex: 1 }}>
                              <Typography tag="h3">Max Series Total</Typography>
                            </div>
                            <div>
                              <strong>
                                <Typography tag="h3">
                                  {formatCurrency(
                                    selectedSeriesRequest.Totals.total,
                                  )}
                                </Typography>
                              </strong>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        )}
      </Modal>
    </div>
  );
}

const RequestTotalsSummaryStyled = styled.div`
  border-bottom: solid 0px ${(props) => props.theme.PRIMARY_GRAY}80;
  display: flex;
  padding: 1rem 0 1rem 0;
  margin: 0 0 6rem 0;
  justify-content: flex-end;
`;

const OrderTotalsSummaryStyled = styled.div`
  border-bottom: solid 1px ${(props) => props.theme.PRIMARY_GRAY}80;
  display: flex;
  padding: 1rem 0 1rem 0;
  margin: 0 0 6rem 0;
  justify-content: flex-end;
`;

const TableStyled = styled.table`
  display: grid;
  border-collapse: collapse;
  min-width: 100%;
  grid-template-columns: ${(props) => props.gridTemplateColumns};
  @media (max-width: ${(props) => props.theme.BREAKPOINT_MOBILE_WIDTH}px) {
    display: none;
  }
  border-bottom: solid 1px ${(props) => props.theme.PRIMARY_GRAY}80;
  thead,
  tbody,
  tr {
    display: contents;
  }
  th,
  td {
    padding: 2rem 1rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-top: solid 1px ${(props) => props.theme.PRIMARY_GRAY}80;
  }
  td.allowOverflow {
    overflow: visible;
    text-overflow: initial;
    white-space: normal;
  }
  th {
    border: none;
  }
  th:last-child {
    border: 0;
  }

  tr:nth-child(even) td {
    background: ${(props) => props.theme.PRIMARY_GRAY}40;
  }
  th {
    text-align: left;
    font-weight: 700;
    text-transform: uppercase;
  }
`;

const RowSpanStyled = styled.div`
  display: ${(props) => (props.visible ? 'initial' : 'none')};
  padding: ${(props) => (props.visible ? '2rem 0rem' : 'none')};
  grid-column: span ${(props) => props.span};
`;

const OrderNumberStyled = styled(Typography)`
  cursor: pointer;
  color: ${(props) => props.theme.PRIMARY_LINK_COLOR}!important;
`;
const IconContainerStyled = styled.span`
  display: inline-block;
  vertical-align: text-bottom;
  cursor: pointer;
  color: ${(props) => props.theme.PRIMARY_LINK_COLOR}!important;
`;

const TableStyledMobile = styled.div`
  margin: 0 0 2rem 0;
  border: solid 1px #d6d6d6;
  box-shadow: ${(props) =>
    props.isExpanded ? '0 0  10px 1px #d6d6d6' : '0 0 0 0'};
  display: block;
  grid-template-columns: 50px 90px auto 30px 1fr;
  @media (min-width: ${(props) => props.theme.BREAKPOINT_MOBILE_WIDTH}px) {
    display: none;
  }
`;

const GridStyledMobile = styled.div`
  display: grid;
  grid-template-columns: ${(props) =>
    props.prioritySelectIsVisible ? '40px 100px auto 30px' : '100px auto 30px'};
  grid-gap: 10px;
  padding: 1rem;
`;
const LabelColumnStyled = styled.div`
  color: #4a4a4a;
  text-transform: uppercase;
  font-weight: 600;
`;
const InfoColumnStyled = styled.div``;
const ToggleIconColumnStyled = styled.div`
  display: flex;
  align-items: center;
`;

const ExpandedRowStyled = styled.div`
  border-top: solid 1px #d6d6d6;
`;

export function UserHistoryList(props = {}) {
  const {
    noMargin = false,
    children,
    dataList,
    fanRequest,
    type = 'orders',
    onClickExpandRow,
    onClickPrioritySelection,
    disablePrioritySelection,
    tableHeaders = ['STATUS', 'SHOW DATE', 'EVENT', 'LOCATION', 'TOTAL'],
    onClickManageTickets = () => null,
  } = props;

  const prioritySelectIsVisible = tableHeaders.includes('PRIORITY SELECT');

  let gridTemplateColumns = '';
  if (prioritySelectIsVisible) {
    gridTemplateColumns += '100px';
  }
  if (tableHeaders.includes('STATUS')) {
    gridTemplateColumns += ' 0.8fr';
  }
  if (tableHeaders.includes('DELIVERY')) {
    gridTemplateColumns += ' 120px';
  }
  if (tableHeaders.includes('SHOW DATE')) {
    gridTemplateColumns += ' 120px';
  }
  if (tableHeaders.includes('EVENT')) {
    gridTemplateColumns += ' 1fr';
  }
  if (tableHeaders.includes('LOCATION')) {
    gridTemplateColumns += ' 1fr';
  }
  if (tableHeaders.includes('TOTAL')) {
    gridTemplateColumns += ' 100px';
  }
  gridTemplateColumns += ' 50px';

  if (dataList && Array.isArray(dataList) && dataList.length) {
    return (
      <div style={{ margin: noMargin ? '0' : '2rem 0 4rem 0' }}>
        {dataList.map((row, rowIndex) => {
          return (
            <TableStyledMobile
              type={type}
              key={'mobileview-' + rowIndex}
              isExpanded={row.isExpanded}
              id={'expandedDetailsRow-' + row.ID}
            >
              <GridStyledMobile
                prioritySelectIsVisible={prioritySelectIsVisible}
              >
                {prioritySelectIsVisible &&
                  !disablePrioritySelection &&
                  fanRequest ? (
                  <div>
                    {fanRequest.loading ? (
                      <Loading
                        width={14}
                        height={14}
                        padding={5}
                        align="center"
                        message=""
                      />
                    ) : (
                      <CheckboxInput
                        aria-label="Make Request First Priority"
                        checked={row.requestIsPriority}
                        onChange={() =>
                          onClickPrioritySelection(
                            row.requestIsPriority ? null : row.ID,
                          )
                        }
                        value={row.requestIsPriority}
                      />
                    )}
                  </div>
                ) : prioritySelectIsVisible && row.requestIsPriority ? (
                  <div>
                    <IconCheck />
                  </div>
                ) : prioritySelectIsVisible ? (
                  <div></div>
                ) : (
                  <></>
                )}

                <LabelColumnStyled>
                  Status:{' '}
                  {row.isMobileDelivery && (<><br />Delivery: </>)}
                  {!row.isExpanded && (
                    <>
                      <br />
                      Show Date:
                      <br />
                      Artist:
                      <br />
                      Location:
                      <br /> Total:
                    </>
                  )}
                </LabelColumnStyled>
                <InfoColumnStyled>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: row.status,
                    }}
                  />
                  {row.isMobileDelivery && (<><br />
                    <Button
                      size="xsmall"
                      onClick={() => onClickManageTickets(row?.eventData?.OutletEventID)}>MobileTickets
                    </Button>
                  </>)}
                  {!row.isExpanded && (
                    <>
                      <br />
                      {formatDate(row.showDate)}
                      <br />
                      {row.eventLabel}
                      <br />
                      {row.location}
                      <br />
                      {formatCurrency(row.total)}
                    </>
                  )}
                </InfoColumnStyled>
                <ToggleIconColumnStyled>
                  <IconContainerStyled onClick={() => onClickExpandRow(row.ID)}>
                    {row.isExpanded ? <IconMinus /> : <IconPlus />}
                  </IconContainerStyled>{' '}
                </ToggleIconColumnStyled>
              </GridStyledMobile>
              {row.isExpanded && (
                <ExpandedRowStyled>{children}</ExpandedRowStyled>
              )}
            </TableStyledMobile>
          );
        })}

        <TableStyled
          type={type}
          gridTemplateColumns={gridTemplateColumns}
          prioritySelectIsVisible={prioritySelectIsVisible}
        >
          <thead>
            <tr>
              {prioritySelectIsVisible && <th>Priority</th>}
              <th>Status</th>
              {tableHeaders.includes('DELIVERY') ? <th>Delivery</th> : null}
              <th>Show Date</th>
              <th>Event</th>
              <th>Location</th>
              {tableHeaders.includes('TOTAL') && (
                <th style={{ textAlign: 'right' }}>Total</th>
              )}
              <th style={{ textAlign: 'right' }}></th>
            </tr>
          </thead>
          <tbody>
            {dataList.map((row, rowIndex) => {
              return [
                <tr
                  id={'expandedDetailsRow-' + row.ID}
                  key={rowIndex}
                  style={{ fontWeight: row.isExpanded ? 700 : 500 }}
                >
                  {prioritySelectIsVisible && !disablePrioritySelection ? (
                    <td>
                      {fanRequest && fanRequest.loading ? (
                        <Loading
                          width={14}
                          height={14}
                          padding={5}
                          align="center"
                          message=""
                        />
                      ) : (
                        <CheckboxInput
                          aria-label="Make Request First Priority"
                          checked={row.requestIsPriority}
                          onChange={() =>
                            onClickPrioritySelection(
                              row.requestIsPriority ? null : row.ID,
                            )
                          }
                          value={row.requestIsPriority}
                        // disabled={row.requestIsPriority}
                        />
                      )}
                    </td>
                  ) : prioritySelectIsVisible && row.requestIsPriority ? (
                    <td>
                      <IconCheck />
                    </td>
                  ) : prioritySelectIsVisible ? (
                    <td></td>
                  ) : null}

                  <td className="allowOverflow">
                    <span
                      dangerouslySetInnerHTML={{
                        __html: row.status,
                      }}
                    />
                  </td>

                  {tableHeaders.includes('DELIVERY') && (<td>
                    {row.isMobileDelivery && (
                      <Button
                        size="xsmall"
                        onClick={() => onClickManageTickets(row?.eventData?.OutletEventID)}>Mobile Tickets
                      </Button>
                    )}
                  </td>)}

                  <td>{formatDate(row.showDate)}</td>

                  <td className="allowOverflow">{row.eventLabel} {row.refundAlertMessage
                    ? <Fragment><br /><span style={{ color: 'red' }}>{row.refundAlertMessage}</span></Fragment>
                    : null}</td>
                  <td>{row.location}</td>
                  {tableHeaders.includes('TOTAL') && (
                    <td style={{ textAlign: 'right' }}>
                      {formatCurrency(row.total)}
                    </td>
                  )}
                  <td style={{ textAlign: 'right' }}>
                    <IconContainerStyled
                      onClick={() => {
                        onClickExpandRow(row.ID);
                      }}
                    >
                      {row.isExpanded ? <IconMinus /> : <IconPlus />}
                    </IconContainerStyled>
                  </td>
                </tr>,
                <RowSpanStyled
                  key={rowIndex + '-expanded'}
                  span={tableHeaders.length + 1}
                  visible={row.isExpanded}
                >
                  {row.isExpanded && <>{children}</>}
                </RowSpanStyled>,
              ];
            })}
          </tbody>
        </TableStyled>
      </div>
    );
  }
  return null;
}

export function TourRibbon(props) {
  const { messageHeadline, messageBody, title } = props;
  return (
    <TourRibbonStyled>
      <Contain>
        {title && <Typography tag="h2">{title}</Typography>}
        {(messageHeadline || messageBody) && (
          <div style={{ display: 'flex', margin: '1.5rem 0 0 0' }}>
            <div style={{ flex: 1 }}>
              <TourMessageHeadlineStyled
                dangerouslySetInnerHTML={{
                  __html: messageHeadline,
                }}
              />
              <div
                dangerouslySetInnerHTML={{
                  __html: messageBody,
                }}
              />
            </div>
          </div>
        )}
      </Contain>
    </TourRibbonStyled>
  );
}

export default UserHistoryView;
