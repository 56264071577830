import PropTypes from 'prop-types';
import styled from 'styled-components';
import React, { useState } from 'react';

import AddressForm from '../../utils/AddressForm';
import Button from '../../utils/Button';
import Error from '../../utils/Error';
import Modal from '../../utils/Modal';
import Typography from '../../utils/Typography';
import { IconStar } from '../../utils/Icon';
import { createUserAddress, deleteUserAddress, editUserAddress } from './actions';
import { addToast } from '../toast/actions';
//import { useSelector } from "react-redux";

const DefaultMessageStyled = styled.div`
  display: flex;
  padding: 1rem 2rem;
  background-color: #eee;
  margin: 0 0 2rem 0;
`;

const AddressContainerStyled = styled.div`
  display: flex;
`;

const AddressContentStyled = styled.div`
  flex: 1;
  padding: 0 1rem 0 0;
`;

const DeleteStyled = styled.div`
  padding: 0 0 0 1rem;
`;
const EditStyled = styled.div`
  padding: 0 1rem 0 0;
`;

const StarContainerStyled = styled.div`
  padding: 0 1rem 0 0;
`;

const DividerStyled = styled.div`
  margin: 2rem 0;
  border-bottom: solid 1px ${(props) => props.theme.PRIMARY_TEXT_COLOR};
  opacity: 0.2;
`;

function UserAddressList(props = {}) {
  const {
    addressList,
    error,
    loading,
    canEdit,
    canDelete,
    canCreate,
    canSetDefault,
  } = props;
  const [address, setAddress] = useState(null);
  const [modal, setModal] = useState({
    title: '',
    content: '',
    constant: null,
    isVisible: false,
  });

  const handleClickAddAddress = () => {
    setAddress(null);
    setModal({ title: 'Create Address', constant: 'CREATE', isVisible: true });
  };

  const handleClickUpdateAddress = (address) => {
    setAddress(address);
    setModal({ title: 'Edit Address', constant: 'UPDATE', isVisible: true });
  };
  const handleClickDeleteAddress = (address) => {
    setAddress(address);
    setModal({ title: 'Delete Address', constant: 'DELETE', isVisible: true });
  };
  const handleClickConfirmDelete = () => {
    deleteUserAddress(address).then((res) => {
          setModal({ ...modal, isVisible: false });
          addToast({
            title: 'Success - Address removed',
          });
        },
    );
  };
  const handleOnClickSaveAddress = (address) => {
    //console.log("handleOnClickSaveAddress address", address);
    editUserAddress(address).then((res) => {
          setModal({ ...modal, isVisible: false });
          addToast({
            title: 'Success - Address updated!',
          });
        },
    );
  };

  const handleOnClickCreateAddress = (address) => {
    //console.log("handleOnClickCreateAddress address", address);
    createUserAddress(address).then((res) => {
          setModal({ ...modal, isVisible: false });
          addToast({
            title: 'Success - New address added!',
          });
        },
    );
  };

  //console.log("Addresses Edit props =>", props);

  return (
      <div>
        {canSetDefault ? (
            <DefaultMessageStyled>
              <StarContainerStyled>
                <IconStar fill="gold" color="gold"/>
              </StarContainerStyled>
              <Typography style={{ flex: 1 }}>Set as default</Typography>
            </DefaultMessageStyled>
        ) : (
            <DividerStyled style={{ margin: '0 0 2rem 0' }}/>
        )}

        {Array.isArray(addressList) &&
            addressList.map((address, idx) => {
              return (
                  <React.Fragment key={idx}>
                    <AddressContainerStyled>
                      {canSetDefault && (
                          <StarContainerStyled
                              style={{ cursor: 'pointer' }}
                              onClick={() => alert('set default function')}
                          >
                            <IconStar
                                fill={address.default ? 'gold' : '#ddd'}
                                color="#ddd"
                            />
                          </StarContainerStyled>
                      )}
                      <AddressContentStyled>
                        <Typography tag="h5">
                          {address.First + ' ' + address.Last}
                        </Typography>
                        {/* <Typography>{address.First + ' ' + address.Last}</Typography>  */}
                        <Typography>{address.AddressLineOne}</Typography>
                        <Typography>{address.AddressLineTwo}</Typography>
                        <Typography>
                          {address.City + ', ' + address.State + ' ' + address.Zip}
                        </Typography>
                      </AddressContentStyled>

                      {canEdit && (
                          <EditStyled>
                            <Typography
                                tag="a"
                                id="update-address"
                                onClick={() => handleClickUpdateAddress(address)}
                            >
                              Edit
                            </Typography>
                          </EditStyled>
                      )}
                      {canEdit && canDelete && <div> | </div>}
                      {canDelete && (
                          <DeleteStyled>
                            <Typography
                                tag="a"
                                id="delete-address"
                                style={{ color: 'red' }}
                                onClick={() => handleClickDeleteAddress(address)}
                            >
                              Delete
                            </Typography>
                          </DeleteStyled>
                      )}
                    </AddressContainerStyled>
                    <DividerStyled/>
                  </React.Fragment>
              );
            })}
        {canCreate && (
            <div style={{ margin: '1rem 2rem 4rem' }}>
              <Button fullWidth onClick={handleClickAddAddress}>
                Add New Address
              </Button>
            </div>
        )}

        <Modal
            title={modal.title}
            open={modal.isVisible}
            onClose={() => setModal({ ...modal, isVisible: false })}
        >
          <div style={{ padding: '1rem 2rem' }}>
            {modal.constant === 'CREATE' && (
                <AddressForm
                    error={error}
                    loading={loading}
                    showCreateAddress={true}
                    showAddressSelector={false}
                    onSubmit={handleOnClickCreateAddress}
                />
            )}

            {modal.constant === 'UPDATE' && (
                <AddressForm
                    error={error}
                    loading={loading}
                    defaultAddress={address}
                    showCreateAddress={true}
                    showAddressSelector={false}
                    onSubmit={handleOnClickSaveAddress}
                />
            )}

            {modal.constant === 'DELETE' && (
                <div>
                  <Typography tag="h4">The following will be deleted:</Typography>
                  <AddressContentStyled style={{ margin: '2rem 0' }}>
                    <Typography tag="h5">
                      {address.First + ' ' + address.Last}
                    </Typography>
                    {/* <Typography>{address.First + ' ' + address.Last}</Typography>  */}
                    <Typography>{address.AddressLineOne}</Typography>
                    <Typography>{address.AddressLineTwo}</Typography>
                    <Typography>
                      {address.City + ', ' + address.State + ' ' + address.Zip}
                    </Typography>
                  </AddressContentStyled>
                  {error && <Error>error occurred</Error>}
                  <Button
                      fullWidth
                      onClick={handleClickConfirmDelete}
                      loading={loading}
                  >
                    Delete
                  </Button>
                </div>
            )}

            <div style={{ margin: '2rem 0', textAlign: 'center' }}>
              <Typography
                  tag="a"
                  onClick={() =>
                      loading ? false : setModal({ ...modal, isVisible: false })
                  }
                  disabled={loading}
              >
                Cancel
              </Typography>
            </div>
          </div>
        </Modal>
      </div>
  );
}

UserAddressList.propTypes = {
  canEdit: PropTypes.bool,
  canCreate: PropTypes.bool,
  canDelete: PropTypes.bool,
  canSetDefault: PropTypes.bool,
  onCancel: PropTypes.func,
  onSuccess: PropTypes.func,
};

UserAddressList.defaultProps = {
  loading: false,
  error: null,
  canEdit: false,
  canCreate: false,
  canDelete: false,
  canSetDefault: false,
  onCancel: null,
  onSuccess: null,
};

export default UserAddressList;
