import styled from 'styled-components';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Columns from '../utils/Columns';
import Contain from '../components/app/Contain';
import HideOnDesktop from '../utils/HideOnDesktop';
import HideOnMobile from '../utils/HideOnMobile';
import Typography from '../utils/Typography';
import { normalizeAddress } from '../utils/utilities';
import GuestListRequestItemsDetails from '../components/guestList/GuestListRequestItemsDetails';
import GuestListRequestShippingDetails from '../components/guestList/GuestListRequestShippingDetails';

const LeftColumStyled = styled.div`
  margin: 2rem 0 0 0;
`;

const RightColumStyled = styled.div`
  margin: 2rem 0 0 0;
  @media (min-width: ${(props) => props.theme.BREAKPOINT_MOBILE_WIDTH}px) {
  }
`;

const RequestNumberStyled = styled.span`
  color: red;
  font-weight: bold;
  text-transform: uppercase;
`;

const RightColumnTitleContainer = styled.div`
  padding: 0 0 2rem 0;
  display: flex;
  align-items: center;
  @media (min-width: ${(props) => props.theme.BREAKPOINT_MOBILE_WIDTH}px) {
  }
`;

const DividerStyled = styled.div`
  margin: 2rem 0;
  border-bottom: solid 2px ${(props) => props.theme.PRIMARY_GRAY};
  opacity: 0.4;
`;

const ContactEmailsContainer = styled.div`
  div {
    margin: 0 0 0.5rem 0;
    opacity: 0.8;
  }
`;

export function GuestListReceiptView() {
  const session = useSelector((state) => state.session);
  const receipt = useSelector((state) => state.guestListReceipt);
  const [outletEventDetailsVisible, setOutletEventDetailsVisible] = useState(false);

  if (Array.isArray(receipt) && receipt.length > 0) {

    let RequestID = [];
    let GuestListRequestEvents = [];

    const {
      // AdminNotes,
      // BillingInfoID, // "1517699754"
      // Connection, //"string "
      // DistributionID,
      GuestAdditionalEmails, // "hello@grok.works,another@test.com"
      GuestEmail,
      // GuestFirstName, // first name entered in the shipping details section of request form
      // GuestLastName, // last name entered in the shipping details section of request form
      // GuestListInvitationToken, // "HE5DVF5PFF36J6DZEKWD"
      // GuestListRequestHistories, // []
      // GuestListRequestID, // 2305
      // GuestPhone, // "540-797-6277"
      // Outlet, // {OutletName: 'Ph!sh Guest List', LinkedSiteName: null, LinkedSiteURL: null, OutletType: {…}}
      OutletDeliveryMethod, // {OutletDeliveryID: 195, DeliveryMethodID: 2, OutletID: 210, OverrideName: 'Fedex Next Day', OverridePrice: 25, …}
      // OutletID, //210
      PaymentMethod,
      // {AuthorizePaymentMethodID: "507420819"
      // CardHolderName: "Homer Simpson"
      // DefaultFlag: false
      // Description: ""
      // DisabledAt: null
      // Expiration: "2023-04"
      // LastFour: "1111"
      // PaymentMethodID: 34399
      // PaymentMethodUUID: "4a92a407-a2f9-4589-ac29-a314e6ec9ce7"
      // Type: "Visa"
      // UserID: 38398}
      // RequesterNotes,
      // RequestorEmail,
      // RequestorFirstName, //  first name setup in the account profile
      // RequestorLastName, // last name setup in the account profile
      // ShippingAccountID,
      // ShippingAccountPaid,
      // ShippingCompanyName,
      // ShippingAddressLineOne,
      // ShippingAddressLineTwo,
      // ShippingCity,
      // ShippingCountry, //1
      // ShippingState,
      // ShippingZip,
      // status, // "STATUS_GUESTLIST_REQUEST_PENDING"
      // UniqueID, // "CMBT6H5"
      // UserID,
      // paymentDeclinedTransactionID, // null
      // shippingMethod, //"Fedex Next Day"
      // updatedAt,
    } = receipt[0];

    receipt.forEach(r => {
      RequestID.push(r.RequestID);
      GuestListRequestEvents.push(...r.GuestListRequestEvents);
    });

    let GuestAdditionalEmailsArray = [GuestEmail];
    if (GuestAdditionalEmails) {
      const emailsArray = GuestAdditionalEmails.split(',');
      if (emailsArray) {
        GuestAdditionalEmailsArray =
            GuestAdditionalEmailsArray.concat(emailsArray);
      }
    }
    let grandTotal =  (OutletDeliveryMethod && OutletDeliveryMethod.OverridePrice) || 0;
    let priceLevels = [];

    const normalizedEventList = [];
    if (Array.isArray(GuestListRequestEvents)) {
      GuestListRequestEvents.forEach((glre) => {
        const outletEventItems = [];
        if (Array.isArray(glre.GuestListRequestEventItems)) {
          glre.GuestListRequestEventItems.map((glri) => {
            if (glri.PriceLevel && glri.PriceLevel.OutletEventItem) {
              const oeiFound = outletEventItems.find(
                  (oei) =>
                      oei.OutletEventItemID ===
                      glri.PriceLevel.OutletEventItem.OutletEventItemID,
              );
              const formattedPL = {
                ...glri.PriceLevel,
                quantity: glri.TotalTickets,
              };
              const formattedOEI = {
                ...glri.PriceLevel.OutletEventItem,
                PriceLevels: [formattedPL],
              };
              if (oeiFound) {
                oeiFound.PriceLevels = oeiFound.PriceLevels.concat(formattedPL);
              } else {
                outletEventItems.push(formattedOEI);
              }
            }
          });

          normalizedEventList.push({
            ...glre,
            ...glre.OutletEvent,
            OutletEvent: {
              ...glre.OutletEvent,
              OutletEventItems: outletEventItems,
            },
            OutletEventItems: outletEventItems,
          });
        }
      });
    }

    normalizedEventList.forEach((e) => {
      if (Array.isArray(e.OutletEventItems)) {
        e.OutletEventItems.forEach((oei) => {
          if (Array.isArray(oei.PriceLevels)) {
            oei.PriceLevels.forEach((pl) => {
              const quantity = pl.quantity || pl.Quantity || 0;
              grandTotal += pl.PriceLevelValue * quantity;
              if (pl.FeeGroups && Array.isArray(pl.FeeGroups)) {
                pl.FeeGroups.forEach((fg) => {
                  const feeGroupTotal = fg.Total
                      ? fg.Total
                      : fg.Value
                          ? fg.Value
                          : 0;
                  grandTotal += feeGroupTotal * quantity;
                });
              }
              priceLevels.push({ ...pl });
            });
          }
        });
      }
    });

    return (
        <Contain hasPaddingMobile={true}>
          <Columns qty={2}>
            <LeftColumStyled>
              <HideOnDesktop>
                <GuestListReceiptHeader
                    PaymentMethod={PaymentMethod}
                    RequestID={RequestID}
                />
              </HideOnDesktop>
              <Typography tag="h1" style={{ marginBottom: '2rem' }}>
                Requested Events
              </Typography>
              <div style={{ margin: '0 0 1rem 0' }}>
                <Typography
                    tag="a"
                    onClick={() =>
                        setOutletEventDetailsVisible(!outletEventDetailsVisible)
                    }
                >
                  {outletEventDetailsVisible
                      ? '- Less Details'
                      : '+ More Details'}
                </Typography>
              </div>

              <GuestListRequestItemsDetails
                  priceLevels={priceLevels}
                  deliveryMethod={OutletDeliveryMethod}
                  expandDetails={outletEventDetailsVisible}
                  guestListOutletEvents={normalizedEventList}
                  guestListRequestGrandTotal={grandTotal}
              />

            </LeftColumStyled>
            <RightColumStyled>
              <HideOnMobile>
                <GuestListReceiptHeader
                    PaymentMethod={PaymentMethod}
                    RequestID={RequestID}
                />
              </HideOnMobile>
              <RightColumnTitleContainer>
                <Typography tag="h1" style={{ flex: 1 }}>
                  CONTACT INFO
                </Typography>
              </RightColumnTitleContainer>

              <ContactEmailsContainer>
                {GuestAdditionalEmailsArray.map((email, idx) => {
                  return <div key={idx}>{email}</div>;
                })}
              </ContactEmailsContainer>

              <DividerStyled/>

              <GuestListRequestShippingDetails
                  request={receipt[0]}
                  userSession={session}
                  shippingAddress={normalizeAddress(receipt[0])}
              />


              {PaymentMethod ? (
                  <React.Fragment>
                    <DividerStyled/>
                    <RightColumnTitleContainer>
                      <Typography tag="h1" style={{ flex: 1 }}>
                        PAYMENT INFORMATION
                      </Typography>
                    </RightColumnTitleContainer>

                    <Typography tag="h3">CARD INFO:</Typography>
                    <Typography style={{ marginBottom: '2rem' }}>
                      {PaymentMethod.Type} ***{' '}
                      {PaymentMethod && PaymentMethod.LastFour}
                      <br/>
                      Exp: {PaymentMethod && PaymentMethod.Expiration}
                    </Typography>
                  </React.Fragment>
              ) : null}
            </RightColumStyled>
          </Columns>
        </Contain>
    );
  }
  return (
      <Contain hasPaddingMobile={true}>
        <h1 style={{ textAlign: 'center' }}>No Receipt Found</h1>
      </Contain>
  );
}

function GuestListReceiptHeader(props) {
  const { RequestID, PaymentMethod } = props;
  return (
      <>
        <Typography tag="h1" style={{ marginBottom: '1rem' }}>
          Thank you! Your ticket request has been submitted
        </Typography>
        {RequestID && (
            <Typography style={{ marginBottom: '2rem' }}>
              Your request number(s):{' '}
              <RequestNumberStyled>{RequestID.join(', ')}</RequestNumberStyled>
            </Typography>
        )}

        <Typography style={{ marginBottom: '2rem' }}>
          Your request is pending approval. Please note that the request does not
          guarantee that you will receive tickets.
          <p>
            You will receive your tickets via the delivery method listed for each
            show. You will be billed according to the information on this page.
            (Shipping and handling fees may vary for each show). Please direct any
            queries to the person with whom you initially made the request.
          </p>
        </Typography>
        {PaymentMethod && (
            <Typography style={{ marginBottom: '2rem' }}>
              You will not be charged until you have been approved for tickets.
            </Typography>
        )}
      </>
  );
}

export default GuestListReceiptView;
