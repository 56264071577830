import React from 'react';
import { DisplayHeader, DisplayShippingAddress } from '../../utils/DisplayContent';
import { DELIVERY_METHOD_TYPE_MOBILE_TICKETING_ID, DELIVERY_METHOD_TYPE_WILL_CALL_ID } from '../../utils/constants';

const getHeaderAndBodyInfo = ({
                                deliveryOption,
                                DeliveryMethodTypeID,
                                GuestFirstName,
                                GuestLastName,
                                EmailAddress,
                                shippingAddress,
                              }) => {
  let headerTitle;
  let bodyInfo;
  let deliveryConstant = 'SHIPPABLE';
  if (Number(deliveryOption) === 1 || DeliveryMethodTypeID === DELIVERY_METHOD_TYPE_WILL_CALL_ID) {
    deliveryConstant = 'WILL_CALL';
  }
  if (Number(deliveryOption) === 3 || DeliveryMethodTypeID === DELIVERY_METHOD_TYPE_MOBILE_TICKETING_ID) {
    deliveryConstant = 'MOBILE';
  }
  switch (deliveryConstant) {
    case 'WILL_CALL':
      headerTitle = 'Will Call Info';
      bodyInfo = GuestFirstName + ' ' + GuestLastName;
      break;
    case 'MOBILE':
      headerTitle = 'Mobile Delivery Info';
      bodyInfo = EmailAddress;
      break;
    default:
      headerTitle = 'Shipping Address';
      bodyInfo = <DisplayShippingAddress address={shippingAddress}/>;
  }
  return { headerTitle, bodyInfo };
};

const GuestListRequestShippingDetails = (params) => {
  const {
    request,
    userSession,
    showHeader = true,
    onClickHeader = null,
    shippingAddress,
  } = params;
  const { EmailAddress } = userSession;
  const { GuestFirstName, GuestLastName, deliveryOption, OutletDeliveryMethod } = request;
  const DeliveryMethodTypeID = OutletDeliveryMethod && OutletDeliveryMethod.DeliveryMethodTypeID;
  const { headerTitle, bodyInfo } = getHeaderAndBodyInfo(
      { deliveryOption, DeliveryMethodTypeID, GuestFirstName, GuestLastName, EmailAddress, shippingAddress });

  return (
      <>
        {showHeader && (
            <DisplayHeader
                btnText="Edit"
                btnName="btn-shipping"
                title={headerTitle}
                onClickBtn={onClickHeader}
            />
        )}
        <div>{bodyInfo}</div>
      </>
  );
};

export default GuestListRequestShippingDetails;
