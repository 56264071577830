import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const ColumnsStyled = styled.div`
  height: 100%;
  display: grid;
  grid-template-columns: none;
  @media (min-width: ${(props) => props.theme.BREAKPOINT_MOBILE_WIDTH}px) {
    //grid-template-columns:  1fr 2fr ;
    grid-template-columns: repeat(${(props) => props.qty}, 1fr);
    gap: 2rem;
  }
`;

export const Columns = ({ loading, children, ...props }) => {
  return <ColumnsStyled {...props}>{children}</ColumnsStyled>;
};

Columns.propTypes = {
  qty: PropTypes.number,
  loading: PropTypes.bool,
};
Columns.defaultProps = {
  qty: 1,
  loading: false,
};

export default Columns;
