import React, { useEffect, useState } from 'react';

import Typography from './Typography';

function formatRemaingTime(remainingTime = 0) {
  let seconds = remainingTime % 60;
  let hours = Math.floor(remainingTime / 60 / 60);
  let minutes = Math.floor(remainingTime / 60) - hours * 60;
  let formattedTime =
      minutes.toString().padStart(2, '0') +
      ':' +
      seconds.toString().padStart(2, '0');
  return formattedTime;
}

export function Timer(props = {}) {
  const [remainingTime, setRemainingTime] = useState(props.remainingTime);
  const [maximumTime, setMaximumTime] = useState(props.remainingTime);
  const [percentageTime, setPercentageTime] = useState(props.percentageTime);
  const timerWorker = new Worker('/timer-worker.js');

  const progress = (params = {}) => {
    if (params.remainingTime && params.remainingTime >= 1) {
      //if (differenceInSeconds && differenceInSeconds >= 1) {
      setRemainingTime(params.remainingTime);
      setMaximumTime(params.maximumTime || 0);
      setPercentageTime(params.percentageTime || 0);
    } else {
      //console.log("**************** timer is expired *******************");
      if (timerWorker) {
        timerWorker.terminate();
      }
      if (props.onTimerEnd) {
        props.onTimerEnd();
      }
    }
  };

  useEffect(() => {
    //console.log("timer useEffect outside");

    setRemainingTime(props.remainingTime || 0);
    setMaximumTime(props.maximumTime || 0);
    setPercentageTime(props.percentageTime || 0);

    timerWorker.postMessage({
      remainingTime: props.remainingTime,
      maximumTime: props.maximumTime,
    });
    timerWorker.onmessage = (e) => {
      //console.log("timer outside => timerWorker onmessage useEffect res", e);
      const payload =
          e && e.data
              ? e.data
              : { remainingTime: 0, maximumTime: 0, percentageTime: 0 };

      progress(payload);
    };
    return () => {
      //console.log("timer useEffect inside");
      if (timerWorker) {
        timerWorker.terminate();
      }
    };
  }, [props.maximumTime]);

  const { progressColor, timeColor } = props;

  const svgStrok = percentageTime + ', 100';
  const styles = {
    svgCircleRoot: {
      display: 'block',
      width: '70px',
      height: '70px',
      //stroke: countDownColor ? countDownColor : "#145a64"
    },
    svgCircleBG: {
      fill: 'none',
      stroke: progressColor ? progressColor : '#000',
      strokeWidth: 3.8,
      strokeOpacity: '0.1',
    },
    svgCircle: {
      stroke: progressColor ? progressColor : '#000',
      fill: 'none',
      strokeWidth: 2.8,
      strokeLinecap: 'round',
    },
    svgText: {
      fill: timeColor ? timeColor : '#000',
      fillOpacity: '1',
      fontFamily: 'sans-serif',
      fontSize: '1rem',
      textAnchor: 'middle',
    },
  };

  return (
      <Typography component="div">
        <svg viewBox="0 0 36 36" style={styles.svgCircleRoot}>
          <path
              style={styles.svgCircleBG}
              d="M18 2.0845
                  a 15.9155 15.9155 0 0 1 0 31.831
                  a 15.9155 15.9155 0 0 1 0 -31.831"
          />
          <path
              style={styles.svgCircle}
              strokeDasharray={svgStrok}
              d="M18 2.0845
                  a 15.9155 15.9155 0 0 1 0 31.831
                  a 15.9155 15.9155 0 0 1 0 -31.831"
          />
          <text x="18" y="21.15" style={styles.svgText}>
            {formatRemaingTime(remainingTime)}
          </text>
        </svg>
      </Typography>
  );
}

// export class Timer_OLD extends Component {
//   static propTypes = {
//     percentageTime: PropTypes.number,
//     remainingTime: PropTypes.number,
//     maximumTime: PropTypes.number,
//     onTimerEnd: PropTypes.func,
//   };

//   static defaultProps = {
//     percentageTime: 100,
//     remainingTime: null,
//     maximumTime: null,
//     countDownColor: null,
//     onTimerEnd: () => false,
//   };

//   state = {
//     percentageTime: 100,
//     remainingTime: null,
//     maximumTime: null,
//     startDateTime: null,
//   };

//   progress = (params = {}) => {
//     const { remainingTime, maximumTime, percentageTime } = params;

//     if (remainingTime && remainingTime >= 1) {
//       //if (differenceInSeconds && differenceInSeconds >= 1) {
//       this.setState({
//         remainingTime,
//         maximumTime,
//         percentageTime,
//       });
//     } else {
//       console.log("**************** Timers Up *******************");
//       if (timerWorker) {
//         timerWorker.terminate();
//       }
//       this.props.onTimerEnd();
//     }
//   };

//   componentDidMount() {
//     const maximumTime = this.props.maximumTime;
//     const remainingTime = this.props.remainingTime;
//     const _this = this;

//     console.log("Timer componentDidMount = ", this.props);
//     if (maximumTime && remainingTime) {
//       this.setState({ remainingTime, maximumTime }, () => {
//         console.log("Timer => timerWorker", timerWorker);
//         timerWorker.postMessage({ remainingTime, maximumTime });
//         timerWorker.onmessage = function (e) {
//           console.log(
//             "Timer => timerWorker onmessage componentDidMount res",
//             e
//           );
//           if (e && e.data) {
//             _this.progress(e.data);
//           }
//         };
//       });
//     }
//   }

//   componentWillReceiveProps(nextProps) {
//     if (
//       nextProps.remainingTime !== this.props.remainingTime ||
//       nextProps.maximumTime !== this.props.maximumTime
//     ) {
//       console.log("Timer componentWillReceiveProps = ", nextProps, this.props);
//       const _this = this;
//       if (timerWorker) {
//         timerWorker.terminate();
//       }
//       this.setState(
//         {
//           remainingTime: nextProps.remainingTime,
//           maximumTime: nextProps.maximumTime,
//         },
//         () => {
//           timerWorker = new Worker("/timer-worker.js");
//           timerWorker.postMessage({
//             remainingTime: nextProps.remainingTime,
//             maximumTime: nextProps.maximumTime,
//           });
//           timerWorker.onmessage = function (e) {
//             //console.log("timer tick remainingTime", _this.state.remainingTime);
//             // console.log(
//             //   "Timer => timerWorker onmessage componentWillReceivePropsres",
//             //   e
//             // );
//             if (e && e.data) {
//               _this.progress(e.data);
//             }
//           };
//         }
//       );
//     }
//   }

//   componentWillUnmount() {
//     if (timerWorker) {
//       timerWorker.terminate();
//     }
//   }

//   formatRemaingTime = (remainingTime = 0) => {
//     let hours = Math.floor(remainingTime / 60 / 60);

//     let minutes = Math.floor(remainingTime / 60) - hours * 60;

//     let seconds = remainingTime % 60;

//     let formattedTime =
//       minutes.toString().padStart(2, "0") +
//       ":" +
//       seconds.toString().padStart(2, "0");
//     return formattedTime;
//   };

//   render() {
//     // //console.log("Timer props = ", this.props);
//     // //console.log("Timer state = ", this.state);

//     const { progressColor, timeColor } = this.props;
//     const { percentageTime, remainingTime } = this.state;
//     const svgStrok = percentageTime + ", 100";
//     const styles = {
//       svgCircleRoot: {
//         display: "block",
//         width: "70px",
//         height: "70px",
//         //stroke: countDownColor ? countDownColor : "#145a64"
//       },
//       svgCircleBG: {
//         fill: "none",
//         stroke: progressColor ? progressColor : "#000",
//         strokeWidth: 3.8,
//         strokeOpacity: "0.1",
//       },
//       svgCircle: {
//         stroke: progressColor ? progressColor : "#000",
//         fill: "none",
//         strokeWidth: 2.8,
//         strokeLinecap: "round",
//       },
//       svgText: {
//         fill: timeColor ? timeColor : "#000",
//         fillOpacity: "1",
//         fontFamily: "sans-serif",
//         fontSize: "1rem",
//         textAnchor: "middle",
//       },
//     };

//     return (
//       <Typography component="div">
//         <svg viewBox="0 0 36 36" style={styles.svgCircleRoot}>
//           <path
//             style={styles.svgCircleBG}
//             d="M18 2.0845
//                   a 15.9155 15.9155 0 0 1 0 31.831
//                   a 15.9155 15.9155 0 0 1 0 -31.831"
//           />
//           <path
//             style={styles.svgCircle}
//             strokeDasharray={svgStrok}
//             d="M18 2.0845
//                   a 15.9155 15.9155 0 0 1 0 31.831
//                   a 15.9155 15.9155 0 0 1 0 -31.831"
//           />
//           <text x="18" y="21.15" style={styles.svgText}>
//             {this.formatRemaingTime(remainingTime)}
//           </text>
//         </svg>
//       </Typography>
//     );
//   }
// }

export default Timer;
