import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import LogRocket from 'logrocket';
import App from './App';
import history from './utils/browserHistory';
import store from './store';
import { ProvideAuth } from './components/auth/use-auth.js';

const isProduction = process.env.REACT_APP_ENV === 'prod';


// GT-4224 disabling LogRocket in prod until we get approval from LN third party risk management team
if (!isProduction) {
  // initialize logrocket frontend monitoring
  LogRocket.init('zfruno/samurai-client');
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ProvideAuth>
        <Router history={history}>
          <App />
        </Router>
      </ProvideAuth>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);
