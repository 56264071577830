import PropTypes from 'prop-types';
import React from 'react';

import Alert from './Alert';
import AuthnetPaymentMethodCreate from './AuthnetPaymentMethodCreate';
import Loading from './Loading';
import { SelectInput } from './Form';
import { api } from '../components/api/actions';
import { getCreditCartExpirationDateMessage, isCreditCardExpired } from '../utils/utilities';
import { toggleAuthnetIframeVisibility } from '../components/app/actions';

class PaymentMethodForm extends React.Component {
  state = {
    authnetProfileToken: null,
    authnetEndPoint: null,
    authnetProfileTokenIsLoading: false,
    authnetProfileTokenError: null,
    paymentMethod: this.props.defaultMethod || null,
    showSelector: this.props.showSelector,
    showCreateNewPayment: this.props.showCreateNewPayment,
  };

  componentWillMount() {
    if (this.props.showCreateNewPayment) {
      toggleAuthnetIframeVisibility(true);
      this.getAuthnetProfileToken();
    } else {
      toggleAuthnetIframeVisibility(false);
    }
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.defaultMethod !== prevProps.defaultMethod) {
      this.setState({
        paymentMethod: this.props.defaultMethod,
      });
    }
  }

  onCreatePaymentMethodViaAuthnetIFrame = (authnetIFrameResponse) => {
    toggleAuthnetIframeVisibility(false);
    this.setState({
      paymentMethod: authnetIFrameResponse,
      showCreateNewPayment: false,
    });
    console.log('authnetIFrameResponse', authnetIFrameResponse);
    this.props.onCreateViaIFrame &&
    this.props.onCreateViaIFrame(authnetIFrameResponse);

    setTimeout(() => {
      toggleAuthnetIframeVisibility(false);
      this.setState({
        paymentMethod: authnetIFrameResponse,
        showCreateNewPayment: false,
      });
    }, 300);
  };

  onCancelPaymentMethodViaAuthnetIFrame = () => {
    this.props.onCancel && this.props.onCancel();
    this.setState({ paymentMethod: null, showCreateNewPayment: false });
    toggleAuthnetIframeVisibility(false);
  };

  getAuthnetProfileToken = () => {
    this.setState({ authnetProfileTokenIsLoading: true });

    // this.props.client
    //   .mutate({
    //     mutation: MUTATION_GET_AUTHNET_PROFILE_TOKEN,
    //     variables: {
    //       ReturnUrl: `https://${window.location.hostname}`,
    //       IFrameCommunicatorUrl: `https://${window.location.hostname}/ifc.html`,
    //     },
    //   })
    api({
      action: 'GET_AUTHNET_PROFILE_TOKEN',
      payload: {
        ReturnUrl: `https://${window.location.hostname}`,
        IFrameCommunicatorUrl: `https://${window.location.hostname}/ifc.html`,
        //    options:{
        //     BorderVisible: true,
        //     ValidationMode: "liveMode",
        //     BillingAddressRequired: true,
        //     CardCodeRequired: true,
        //     ManageOptions: "showAll",
        //     SaveButtonText: "Save",
        //     HeadingBgColor: "blue"
        // }
      },
    }).then((response) => {
      //console.log("authnetProfileToken response", response);
      toggleAuthnetIframeVisibility(true);
      this.setState({
        authnetProfileToken:
            response && response.token ? response.token : null,
        authnetEndPoint:
            response && response.endpoint ? response.endpoint : null,
        authnetProfileTokenError: null,
        authnetProfileTokenIsLoading: false,
        showCreateNewPayment: true,
      });
    }).catch((error) => {
      toggleAuthnetIframeVisibility(false);
      this.setState({
        authnetProfileToken: null,
        authnetEndPoint: null,
        authnetProfileTokenError: 'Error retrieving authnet profile token',
        authnetProfileTokenIsLoading: false,
        showCreateNewPayment: false,
      });
    });
  };

  handleSelectPaymentMethod = (e) => {
    const paymentMethodId = e.target.value;
    if (paymentMethodId === 'new') {
      toggleAuthnetIframeVisibility(true);
      this.setState(
          {
            paymentMethod: null,
            showCreateNewPayment: true,
          },
          () => {
            this.getAuthnetProfileToken();
          },
      );
    } else if (paymentMethodId === 'reset') {
      toggleAuthnetIframeVisibility(false);
      this.setState({
        paymentMethod: null,
        showCreateNewPayment: false,
      });
    } else {
      const paymentMethod =
          this.props.paymentMethods &&
          this.props.paymentMethods.find(
              (p) => Number(p.PaymentMethodID) === Number(paymentMethodId),
          );
      if (paymentMethod) {
        toggleAuthnetIframeVisibility(false);
        this.setState(
            {
              paymentMethod,
              showCreateNewPayment: false,
            },
            () => {
              this.props.onSelect && this.props.onSelect(paymentMethod);
            },
        );
      }
    }
  };

  render() {
    console.log('PaymentMethodForm state ', this.state);
    console.log('PaymentMethodForm props ', this.props);
    const {
      paymentMethod,
      showCreateNewPayment,
      authnetProfileToken,
      authnetEndPoint,
      authnetProfileTokenIsLoading,
      authnetProfileTokenError,
    } = this.state;
    const { disabled } = this.props;

    if (authnetProfileTokenIsLoading) {
      return <Loading message="" align="center"/>;
    }

    if (showCreateNewPayment && authnetProfileToken && authnetEndPoint) {
      return (
          <AuthnetPaymentMethodCreate
              authnetProfileToken={authnetProfileToken}
              authnetEndPoint={authnetEndPoint}
              onSuccess={(iFrameResponse) =>
                  this.onCreatePaymentMethodViaAuthnetIFrame(iFrameResponse)
              }
              onCancel={this.onCancelPaymentMethodViaAuthnetIFrame}
          />
      );
    }

    const paymentMethods = [];
    if (Array.isArray(this.props.paymentMethods)) {
      this.props.paymentMethods.map((method, idx) => {
        const value = method.PaymentMethodID;
        let label = `${method.CardHolderName} ${method.LastFour} (${method.Expiration})`;
        const expirationMsg = getCreditCartExpirationDateMessage(
            method.Expiration,
        );
        if (expirationMsg) {
          label += ' - ' + expirationMsg;
        }
        paymentMethods.push({
          value,
          label,
          disabled: isCreditCardExpired(method.Expiration),
        });
      });
    }
    console.log('paymentMethods', paymentMethods);
    return (
        <div id="select-payment-method-form">
          {this.props.showSelector && (
              <SelectInput
                  disabled={disabled}
                  name="select-payment-method"
                  className="select-payment-method-input"
                  value={(paymentMethod && paymentMethod.PaymentMethodID) || ''}
                  onChange={this.handleSelectPaymentMethod}
                  style={{ width: '100%' }}
                  optionList={[
                    {
                      value: 'reset',
                      disabled: paymentMethod && paymentMethod.PaymentMethodID,
                      label: '-- Select Payment --',
                    },
                  ].concat(paymentMethods).concat([{ value: 'new', label: 'Use a new payment' }])}
              ></SelectInput>
          )}

          {authnetProfileTokenError && (
              <Alert type="warning">{authnetProfileTokenError}</Alert>
          )}
        </div>
    );
  }
}

PaymentMethodForm.defaultProps = {
  defaultMethod: null,
  paymentMethods: [],
  showSelector: true,
  showCreateNewPayment: false,
  disabled: false,
  onCancel: () => false,
  onCreateViaIFrame: () => false,
  onSelect: () => false,
};

PaymentMethodForm.propTypes = {
  disabled: PropTypes.bool,
  //defaultMethod: PropTypes.object,
  paymentMethods: PropTypes.array,
  onCreateViaIFrame: PropTypes.func,
  onSelect: PropTypes.func,
  onCancel: PropTypes.func,
  showSelector: PropTypes.bool,
  showCreateNewPayment: PropTypes.bool,
};

export default PaymentMethodForm;
