import React from 'react';

import Contain from '../components/app/Contain';
import Typography from '../utils/Typography';

const PageNotFoundView = () => {
  return (
      <Contain maxWidth={600} fillHeight>
        <div style={{ margin: '4rem 0' }}>
          <Typography tag="h1">Page not found</Typography>
          <Typography tag="h4">
            We are sorry but the page you are looking for does not exist or you
            are not authorized to view at the moment.
          </Typography>
        </div>
      </Contain>
  );
};

export default PageNotFoundView;
