import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';

import Typography from './Typography';
import { IconClose } from './Icon';

const ModalRoot = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
`;

const ModalContainer = styled.div`
  position: fixed;
  background-color: ${(props) => props.theme.APP_BACKGROUND};
  width: 90%;
  height: auto;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -20%);
  border-radius: 0.5rem;
  @media (min-width: ${(props) => props.theme.BREAKPOINT_MOBILE_WIDTH}px) {
    max-width: 900px;
  }
`;

const ModalHeader = styled.div`
  border-bottom: solid 1px #eeeeee;
  padding: 2rem 2rem 1.6rem 2rem;
  display: flex;
`;

const ModalContent = styled.div`
  padding: 2rem;
  overflow-y: auto;
  max-height: 80vh;
`;

const ModalTitle = styled.div`
  flex: 1;
  // font-family: ${(props) => props.theme.HEADER_TEXT_FONT_FAMILY};
`;

const IconRoot = styled.div`
  padding: 0 0 0 10px;
  cursor: pointer;
  svg,
  img {
    &:hover {
      opacity: 0.7;
    }
  }
`;

const modalRoot = document.getElementById('modal-root');

class Modal extends React.Component {
  render() {
    const { onClose, open, title, children, closeIconIsVisible } = this.props;
    if (open) {
      return ReactDOM.createPortal(
          <ModalRoot>
            <ModalContainer>
              <ModalHeader>
                <ModalTitle>
                  <Typography tag="h2">{title}</Typography>
                </ModalTitle>
                {closeIconIsVisible && (
                    <IconRoot onClick={onClose}>
                      <IconClose/>
                    </IconRoot>
                )}
              </ModalHeader>
              <ModalContent>
                <Typography tag="div">{children}</Typography>
              </ModalContent>
            </ModalContainer>
          </ModalRoot>,
          modalRoot,
      );
    }
    return null;
  }
}

Modal.propTypes = {
  loading: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string,
  open: PropTypes.bool,
  closeIconIsVisible: PropTypes.bool,
};

Modal.defaultProps = {
  loading: false,
  open: false,
  title: '',
  closeIconIsVisible: true,
};

export default Modal;
