import React from 'react';
import styled from 'styled-components';

import Contain from '../app/Contain';
import OrderDetails from './OrderDetails';
import Typography from '../../utils/Typography';
import { IconChevronLeft, IconChevronRight } from '../../utils/Icon';
import { goToUrl } from '../../utils/utilities';

const NavigateRoot = styled.div`
  font-size: 2rem;
  padding: 2rem 0;
  margin: 2rem 0;
  background-color: ${(props) => props.theme.PRIMARY_GRAY}80;
  border-top: solid 1px ${(props) => props.theme.PRIMARY_TEXT_COLOR};
  border-bottom: solid 1px ${(props) => props.theme.PRIMARY_TEXT_COLOR};
`;
const NavigateContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const NavigateItem = styled.div`
  flex: 1;
  padding: 0 1rem;
  cursor: pointer;
  color: ${(props) => props.theme.PRIMARY_LINK_COLOR};
`;

function OrderReceipt(props = {}) {
  console.log('OrderReceipt props', props);
  const { order } = props;

  //const api = useSelector((state) => state.api));
  //const orderIsLoading = api && api.loading.includes("GET_USER_ORDER");
  if (!order)
    return (
        <Contain>
          <Typography tag="h1" style={{ padding: '2rem  0 ' }}>
            No order found
          </Typography>
        </Contain>
    );
  return (
      <div style={{ height: '100%' }}>
        <div style={{ margin: '2rem 0' }}>
          <Contain>
            <Typography tag="h1" style={{ padding: '2rem  0 ' }}>
              Thank you!
            </Typography>
          </Contain>

          <Contain style={{ fontSize: '1.6rem' }}>
            <div>
              Your order confirmation number is: #<strong>{order.OrderID}</strong>
            </div>
            {order && order.Status && order.Status.CustomerStatus && (
                <div style={{ margin: '1rem  0 ' }}>
                  Status - <strong>{order.Status.CustomerStatus}</strong>
                </div>
            )}
          </Contain>
          <NavigateRoot>
            <Contain>
              <NavigateContainer>
                <NavigateItem
                    name="nav-events"
                    id="orders-nav-goto-events-list-btn"
                    onClick={() => goToUrl('/')}
                >
                  <IconChevronLeft/> Back to Events
                </NavigateItem>
                <NavigateItem
                    name="nav-myrequests"
                    id="orders-nav-goto-myrequests-btn"
                    onClick={() => goToUrl('/user/history/orders')}
                    style={{ textAlign: 'right' }}
                >
                  {' '}
                  View My Orders <IconChevronRight/>
                </NavigateItem>
              </NavigateContainer>{' '}
            </Contain>
          </NavigateRoot>
          <Contain>
            <OrderDetails order={order} upgradeItemIsVisible={false}/>
          </Contain>
        </div>
      </div>
  );
}

export default OrderReceipt;
