import PropTypes from 'prop-types';
import React from 'react';

import Alert from '../../utils/Alert';
import Button from '../../utils/Button';
import Typography from '../../utils/Typography';
import { TextInput } from '../../utils/Form';
import { forgotUserPassword } from './actions';
import { validateEmail } from '../../utils/Validation';

export class ForgotPassword extends React.Component {
  state = {
    emailAddress: '',
    emailSent: false,
    formErrors: {},
    loading: false,
    error: null,
  };

  handleSubmit = () => {
    const { emailAddress } = this.state;
    const { onSuccess } = this.props;
    const formErrors = {};
    if (!emailAddress) {
      formErrors['emailAddress'] = 'Email address required';
    }
    if (Object.keys(formErrors).length) {
      this.setState({ formErrors });
      return false;
    } else {
      this.setState({ loading: true, error: null });
      validateEmail(emailAddress).then((res) => {
        if (res && res.valid) {
          forgotUserPassword({ emailAddress }).then((data) => {
            this.setState({
              emailSent: true,
              emailAddress: '',
              formErrors: {},
              loading: false,
            });
            onSuccess && onSuccess();
          }).catch((error) => {
            this.setState({ loading: false, error: error || 'server error' });
          });
        } else {
          formErrors['emailAddress'] =
              'Please verify your email address and try again';
          this.setState({ formErrors, loading: false });
          return false;
        }
      });
    }
  };

  handleInput = (name) => (event) =>
      this.setState({ [name]: event.target.value, formErrors: {} });

  render() {
    //console.log("Forgot Password props =>", this.props);

    const { onCancel } = this.props;
    const { emailSent, emailAddress, formErrors, loading, error } = this.state;

    if (emailSent) {
      return (
          <div>
            <Typography tag="h1">Success!</Typography>
            <Typography tag="p">
              Please check your email for the password reset link
            </Typography>
            {onCancel && (
                <Button onClick={onCancel} fullWidth id="forgot-password-back-btn">
                  Back
                </Button>
            )}
          </div>
      );
    }

    return (
        <form
            autoComplete="off"
            onSubmit={(e) => {
              e.preventDefault();
            }}
        >
          <TextInput
              required
              fullWidth
              name="emailAddress"
              placeholder="Email Address"
              value={emailAddress}
              onChange={this.handleInput('emailAddress')}
              error={formErrors['emailAddress']}
          />

          {error && (
              <div style={{ margin: '2rem 0 ' }}>
                <Alert type="error"> {error} </Alert>
              </div>
          )}

          <div style={{ margin: '2rem 0 ' }}>
            <Button
                fullWidth
                id="send-reset-link-btn"
                disabled={loading}
                loading={loading}
                onClick={() => this.handleSubmit()}
            >
              Send reset link
            </Button>
          </div>
          <div style={{ margin: '2rem 0 ', textAlign: 'center' }}>
            {!loading && onCancel && (
                <Typography
                    tag="a"
                    onClick={onCancel}
                    id="cancel-send-reset-link-btn"
                >
                  Cancel
                </Typography>
            )}
          </div>
        </form>
    );
  }
}

ForgotPassword.propTypes = {
  onCancel: PropTypes.func,
  onSuccess: PropTypes.func,
};

ForgotPassword.defaultProps = {
  onCancel: null,
  onSuccess: null,
};

export default ForgotPassword;
