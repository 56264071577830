import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import Button from './Button';
import Typography from './Typography';
import { outletThemeDefault } from './themes';

const TextInputContainer = styled.div`
  margin: 0 0 1.5rem 0;
`;

const TextInputStyled = styled.input`
  font-size: 1.6rem;
  border: 1px solid ${(props) =>
          props.error
                  ? props.theme.ERROR_TEXT_COLOR
                  : props.theme.PRIMARY_GRAY}; //cccccc
  padding: 1rem 1.5rem;
  display: ${(props) => (props.fullWidth ? 'block' : 'inline-block')};
  width: ${(props) => (props.fullWidth ? '100%' : 'inherit')};
  border-radius: 5px;
`;

const ErrorContainer = styled.div`
  color: ${(props) => props.theme.ERROR_TEXT_COLOR};
  opacity: 0.8;
  font-size: 1.2rem;
  padding: 0.5rem;
`;
const HelpTextContainer = styled.div`
  opacity: 0.8;
  font-size: 1.2rem;
  padding: 0.5rem 0 0 0;
`;
const CheckboxInputContainer = styled.div`
  margin: 0 0 1.5rem 0;
`;

const CheckboxLabel = styled.label`
  display: flex;
  cursor: pointer;
  align-items: center;
`;

const CheckboxContainer = styled.div`
  /* display: ${(props) => (props.fullWidth ? 'block' : 'inline-block')};
  width: ${(props) => (props.fullWidth ? '100%' : 'inherit')};
  margin: ${(props) => (props.fullWidth ? '0 0 14px 0' : '0')}; */
`;

const CheckboxChildren = styled.div`
  flex: 1;
  margin: 0 0 0 1rem;
`;

const CheckboxIcon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 3px;
`;
// Hide checkbox visually but remain accessible to screen readers.
// Source: https://polished.js.org/docs/#hidevisually
const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const StyledCheckbox = styled.div`
  display: inline-block;
  width: 18px;
  height: 18px;
  background-color: ${(props) =>
          props.checked ? props.theme.PRIMARY_LINK_COLOR : 'white'};
  border-radius: 3px;
  transition: all 150ms;
  border: 1px solid ${(props) =>
          props.checked
                  ? props.theme.PRIMARY_LINK_COLOR
                  : props.theme.PRIMARY_GRAY};
  ${CheckboxIcon} {
    visibility: ${(props) => (props.checked ? 'visible' : 'hidden')};
  }
`;

const SelectInputContainer = styled.div`
  margin: 0 0 1.5rem 0;
`;

const SelectInputStyled = styled.select`
  border-radius: 5px;
  font-size: 1.6rem;
  border: 1px solid
    ${(props) =>
    props.error ? props.theme.ERROR_TEXT_COLOR : props.theme.PRIMARY_GRAY};
  padding: 1rem 1.5rem;
  display: ${(props) => (props.fullWidth ? 'block' : 'inline-block')};
  width: ${(props) => (props.fullWidth ? '100%' : 'inherit')};
`;

const TextAreaInputContainer = styled.div`
  margin: 0 0 1.5rem 0;
`;

const TextAreaInputStyled = styled.textarea`
  font-size: 1.6rem;
  border: 1px solid ${(props) =>
          props.error
                  ? props.theme.ERROR_TEXT_COLOR
                  : props.theme.PRIMARY_GRAY}; //cccccc
  padding: 1rem 1.5rem;
  display: ${(props) => (props.fullWidth ? 'block' : 'inline-block')};
  width: ${(props) => (props.fullWidth ? '100%' : 'inherit')};
  min-height: 8rem;
  border-radius: 5px;
  font-family: "Roboto", sans-serif;
`;

export const TextAreaInput = ({
                                error = null,
                                label = '',
                                placeholder,
                                ...props
                              }) => {
  const placeholderText = placeholder || label;
  return (
      <TextAreaInputContainer>
        <TextAreaInputStyled
            error={error}
            placeholder={placeholderText}
            {...props}
        />
        {error && <ErrorContainer>{error}</ErrorContainer>}
      </TextAreaInputContainer>
  );
};

export const TextInput = ({
                            error = null,
                            helpText,
                            label = '',
                            placeholder,
                            ...props
                          }) => {
  const placeholderText = placeholder || label;
  return (
      <TextInputContainer>
        <TextInputStyled
            type="text"
            error={error}
            placeholder={placeholderText}
            {...props}
        />
        {helpText && <HelpTextContainer>{helpText}</HelpTextContainer>}
        {error && <ErrorContainer>{error}</ErrorContainer>}
      </TextInputContainer>
  );
};

export const CheckboxInput = ({
                                className,
                                checked,
                                children,
                                error,
                                ...props
                              }) => {
  // TODO: Remove this once we set default theme values on outlet creation (from backend)
  const theme = props?.theme || outletThemeDefault;
  return (
      <CheckboxInputContainer>
        <CheckboxLabel>
          <CheckboxContainer className={className}>
            <HiddenCheckbox checked={checked} {...props} />
            <StyledCheckbox checked={checked} theme={theme}>
              <CheckboxIcon viewBox="0 0 24 24">
                <polyline points="20 6 9 17 4 12"/>
              </CheckboxIcon>
            </StyledCheckbox>
          </CheckboxContainer>
          <CheckboxChildren>{children}</CheckboxChildren>
        </CheckboxLabel>
        {error && <ErrorContainer>{error}</ErrorContainer>}
      </CheckboxInputContainer>
  );
}

export const SelectInput = (props) => {
  const { optionList, error } = props;
  return (
      <SelectInputContainer>
        <SelectInputStyled error={error} {...props}>
          {optionList.map((option, index) => (
              <option
                  key={index}
                  value={option.value}
                  disabled={option.disabled ? true : false}
              >
                {option.label}
              </option>
          ))}
        </SelectInputStyled>
        {error && <ErrorContainer>{error}</ErrorContainer>}
      </SelectInputContainer>
  );
};
SelectInput.defaultProps = {
  optionList: [],
};

SelectInput.propTypes = {
  optionList: PropTypes.array,
};

export const FormActions = (props) => {
  const {
    handleSubmit,
    handleCancel,
    loading,
    submitText,
    cancelText,
    disabled,
    fullWidth,
    //...props
  } = props;
  const style = {
    container: {
      display: 'flex',
      justifyContent: 'center',
      margin: '15px 0 0 0',
    },
  };
  return (
      <React.Fragment>
        <div style={style.container}>
          <Button
              disabled={loading || disabled || !handleSubmit}
              onClick={!loading && handleSubmit ? handleSubmit : () => false}
              fullWidth={fullWidth}
          >
            {submitText}
          </Button>
        </div>
        <div style={style.container}>
          {!loading && handleCancel && (
              <Typography tag="a" onClick={handleCancel}>
                {cancelText}
              </Typography>
          )}
        </div>
      </React.Fragment>
  );
};
FormActions.defaultProps = {
  loading: false,
  error: false,
  submitText: 'Save',
  cancelText: 'Cancel',
  fullWidth: false,
};

FormActions.propTypes = {
  handleSubmit: PropTypes.func,
  handleCancel: PropTypes.func,
  loading: PropTypes.bool,
  submitText: PropTypes.string,
  cancelText: PropTypes.string,
  fullWidth: PropTypes.bool,
};
