import styled from 'styled-components';
import React, { useEffect, useRef, useState } from 'react';

import Arrow from './Arrow';
import Dots from './Dots';
import { outletThemeDefault } from '../../utils/themes';

//import Slide from "./Slide";
//import SliderContent from "./SliderContent";

const SliderStyled = styled.div`
  position: relative;
  height: 280px;
  width: 100vw;
  margin: 0 auto;
  overflow: hidden;
  white-space: nowrap;
  @media (min-width: ${(props) => props.theme.MAXIMUM_CONTAINER_WIDTH}px) {
    height: 380px;
    width: ${(props) => props.theme.MAXIMUM_CONTAINER_WIDTH - 40}px;
  }
`;
const SliderContent = styled.div`
  transform: translateX(-${(props) => props.translate}px);
  transition: transform ease-out ${(props) => props.transition}s;
  height: 100%;
  width: ${(props) => props.width}px;
  display: flex;
`;
const SlideStyled = styled.div`
  cursor: ${(props) => (props.link ? 'pointer' : 'initial')};
  height: 100%;
  width: ${(props) => props.width}px;
  background-image: url("${(props) => props.imageURL}");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;
const maxWidth =
    outletThemeDefault && outletThemeDefault.MAXIMUM_CONTAINER_WIDTH;
const getWidth = () =>
    maxWidth && maxWidth - 40 < window.innerWidth
        ? maxWidth - 40
        : window.innerWidth;
//const getWidth = () => window.innerWidth;
const Slider = (props) => {
  const { slides } = props;

  //const outlet = useSelector((state) => state.outlet);

  const firstSlide = slides && slides[0];
  const secondSlide = slides && slides[1];
  const lastSlide = slides && slides[slides.length - 1];

  const [state, setState] = useState({
    activeSlide: 0,
    translate: getWidth(),
    transition: 0.45,
    _slides: [lastSlide, firstSlide, secondSlide],
  });

  const { activeSlide, translate, _slides, transition } = state;

  const autoPlayRef = useRef();
  const transitionRef = useRef();
  const resizeRef = useRef();
  const sliderRef = useRef();

  useEffect(() => {
    autoPlayRef.current = nextSlide;
    transitionRef.current = smoothTransition;
    resizeRef.current = handleResize;
  });

  useEffect(() => {
    const slider = sliderRef.current;
    let interval = null;
    const play = () => {
      autoPlayRef.current();
    };

    const smooth = (e) => {
      if (e.target.className.includes('SliderContent')) {
        transitionRef.current();
      }
    };

    const resize = () => {
      resizeRef.current();
    };

    const handleMouseOver = () => {
      //console.log("handleMouseOver");

      if (props.autoPlay) {
        clearInterval(interval);
      }
    };
    const handleMouseOut = () => {
      //console.log("handleMouseOut");
      if (props.autoPlay) {
        interval = setInterval(play, props.autoPlay * 1000);
      }
    };

    const transitionEnd = slider.addEventListener('transitionend', smooth);
    const onResize = window.addEventListener('resize', resize);
    const onMouseOverSlide = slider.addEventListener(
        'mouseover',
        handleMouseOver,
    );
    const onMouseOutSlide = slider.addEventListener('mouseout', handleMouseOut);

    if (props.autoPlay) {
      interval = setInterval(play, props.autoPlay * 1000);
    }

    return () => {
      slider.removeEventListener('transitionend', transitionEnd);
      window.removeEventListener('resize', onResize);
      slider.removeEventListener('mouseover', onMouseOverSlide);
      slider.removeEventListener('mouseout', onMouseOutSlide);

      if (props.autoPlay) {
        clearInterval(interval);
      }
    };
  }, [props.autoPlay]);

  useEffect(() => {
    if (transition === 0) setState({ ...state, transition: 0.45 });
  }, [transition]);

  const handleResize = () => {
    setState({ ...state, translate: getWidth(), transition: 0 });
  };

  const smoothTransition = () => {
    let _slides = [];

    // We're at the last slide.
    if (activeSlide === slides.length - 1)
      _slides = [slides[slides.length - 2], lastSlide, firstSlide];
    // We're back at the first slide. Just reset to how it was on initial render
    else if (activeSlide === 0) _slides = [lastSlide, firstSlide, secondSlide];
    // Create an array of the previous last slide, and the next two slides that follow it.
    else _slides = slides.slice(activeSlide - 1, activeSlide + 2);

    setState({
      ...state,
      _slides,
      transition: 0,
      translate: getWidth(),
    });
  };

  const nextSlide = () =>
      setState({
        ...state,
        translate: translate + getWidth(),
        activeSlide: activeSlide === slides.length - 1 ? 0 : activeSlide + 1,
      });

  const prevSlide = () =>
      setState({
        ...state,
        translate: 0,
        activeSlide: activeSlide === 0 ? slides.length - 1 : activeSlide - 1,
      });

  if (!Array.isArray(slides) || slides.length <= 0) {
    return null;
  }
  return (
      <SliderStyled ref={sliderRef}>
        <SliderContent
            className="SliderContent"
            translate={translate}
            transition={transition}
            width={getWidth() * _slides.length}
        >
          {_slides.map((_slide, i) => {
            if (_slide && _slide.url) {
              return (
                  <SlideStyled
                      width={getWidth()}
                      key={i}
                      imageURL={_slide.url}
                      link={_slide.link}
                      onClick={() =>
                          _slide.link ? window.open(_slide.link, '_blank') : false
                      }
                      //title={_slide.title}
                  />
              );
            } else {
              return null;
            }
          })}
        </SliderContent>

        <Arrow direction="left" handleClick={prevSlide}/>
        <Arrow direction="right" handleClick={nextSlide}/>

        <Dots slides={slides} activeSlide={activeSlide}/>
      </SliderStyled>
  );
};

Slider.defaultProps = {
  slides: [],
  autoPlay: null,
};

export default Slider;
