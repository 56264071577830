import PropTypes from 'prop-types';
import styled from 'styled-components';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

//import HideOnMobile from "../../utils/HideOnMobile";
import Modal from '../../utils/Modal';
import OutletEventSummary from './OutletEventSummary';
import Typography from '../../utils/Typography';
//import { IconCalendar, IconClock, IconMapPin } from "../../utils/Icon";
import { htmlHasValidContent } from '../../utils/utilities';

const Root = styled.div`
  padding: 0 0 2rem 0;
`;

const ContentRow = styled.div`
  padding: 0 0 4rem 0;
`;
const SeatMapImg = styled.img`
  width: 100%;
  display: block;
  margin: 1rem 0 0 0;
  cursor: pointer;
`;

const MapZoomButton = styled.div`
  color: ${(props) => props.theme.PRIMARY_LINK_COLOR};
  cursor: pointer;
  text-align: right;
`;

const EventImageStyled = styled.img`
  width: 100%;
  margin: 0 0 1rem 0;
  display: ${(props) => (props.mobileOnly ? 'block' : 'none')};
  @media (min-width: ${(props) => props.theme.BREAKPOINT_MOBILE_WIDTH}px) {
    display: ${(props) => (props.mobileOnly ? 'none' : 'block')};
  }
`;

// const DetailRowStyled = styled(Typography)`
//   display: flex;
//   margin: 0 0 1rem 0;
// `;

// const IconContainerStyled = styled.div`
//   color: ${(props) => props.theme.PRIMARY_LINK_COLOR};
//   svg {
//     color: ${(props) => props.theme.PRIMARY_LINK_COLOR};
//     fill: none;
//     width: 10px;
//     height: 10px;
//   }
// `;

// const DetailContainerStyled = styled.div`
//   flex: 1;
//   padding: 0 1rem;
// `;

function OutletEventDetails(props) {
  //console.log("Outlet Event Details props => ", props);
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const outlet = useSelector((state) => state.outlet);

  const { outletEvent, showEventImage, showImageOnly } = props;

  if (!outletEvent) {
    return null;
  }
  const {
    Event = {},
    OutletEventContents,
    EventImageOverride,
    //OutletEventDoorTime,
    OutletEventDescription,
    OutletEventOverrideName,
    //OutletEventDoorTimeStatus,
  } = outletEvent;
  const eventName = Event.Name;
  const eventImgMobile = Event.EventImageMobile;
  const eventImg = EventImageOverride || Event.EventImage;
  const venueName = Event.Venue && Event.Venue.VenueName;
  // const city = Event.Venue && Event.Venue.City;
  // const state = Event.Venue && Event.Venue.State;
  // const googleMapLink = Event.Venue && Event.Venue.GoogleMapLink;
  // const showDate = Event.ShowDate;
  // const seatMap = Event.SeatMapImage;
  let artistName = '';
  if (Event.Artist && Event.Artist.ArtistName) {
    artistName = Event.Artist.ArtistName;
  }

  if (showImageOnly) {
    return (
        <Root>
          {showEventImage && (
              <React.Fragment>
                {eventImg && <EventImageStyled src={eventImg} alt={eventName}/>}
                {eventImgMobile && (
                    <EventImageStyled
                        src={eventImgMobile}
                        alt={eventName}
                        mobileOnly
                    />
                )}
              </React.Fragment>
          )}
        </Root>
    );
  }

  return (
      <Root>
        <ContentRow>
          <OutletEventSummary outletEvent={outletEvent}/>
        </ContentRow>

        {showEventImage && EventImageOverride && (
            <EventImageStyled src={EventImageOverride} alt={eventName}/>
        )}

        {showEventImage && eventImgMobile && (
            <EventImageStyled src={eventImgMobile} alt={eventName} mobileOnly/>
        )}

        {OutletEventDescription && htmlHasValidContent(OutletEventDescription) && (
            <ContentRow>
              {/* <Typography tag="h4">Event Description</Typography> */}
              <div dangerouslySetInnerHTML={{ __html: OutletEventDescription }}/>
            </ContentRow>
        )}

        {Event.SeatMapImage && (
            <ContentRow>
              {/* <Typography tag="h4">Seat Map</Typography> */}
              <SeatMapImg
                  src={Event.SeatMapImage}
                  alt="Seat Map"
                  onClick={() => setModalIsVisible(true)}
              />
              <MapZoomButton onClick={() => setModalIsVisible(true)}>
                + Zoom
              </MapZoomButton>
              <Modal
                  title="Seat Map"
                  open={modalIsVisible}
                  onClose={() => setModalIsVisible(false)}
              >
                <img
                    src={Event.SeatMapImage}
                    alt="Seat Map"
                    style={{ width: '100%', display: 'block' }}
                />
              </Modal>
            </ContentRow>
        )}

        {OutletEventContents &&
        Array.isArray(OutletEventContents) &&
        OutletEventContents.length ? (
            <ContentRow>
              {OutletEventContents.map((c, index) => (
                  <div key={index}>
                    <Typography tag="h4">{c.Content.Title}</Typography>
                    <div dangerouslySetInnerHTML={{ __html: c.Content.Body }}/>
                  </div>
              ))}
            </ContentRow>
        ) : null}
      </Root>
  );
}

OutletEventDetails.propTypes = {
  outletEvent: PropTypes.object,
  showImageOnly: PropTypes.bool,
  showEventImage: PropTypes.bool,
};

OutletEventDetails.defaultProps = {
  outletEvent: null,
  outletEventGroup: null,
  showEventImage: true,
  showImageOnly: false,
};

export default OutletEventDetails;
