import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const ErrorContainerStyled = styled.div`
  padding: 1rem;
  background: #fff0f0;
  margin: 2rem 0;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-left: 5px solid red;
`;
const ErrorMessageStyled = styled.p`
  margin: 0 0 1rem 0;
`;

const DisplayError = ({ children, error }) => {
  //console.log("Error error", error);
  //console.log("Error children", children);
  if (error || children) {
    return (
        <ErrorContainerStyled>
          {error && <ErrorMessageStyled>{error}</ErrorMessageStyled>}
          <div>{children}</div>
        </ErrorContainerStyled>
    );
  }
  return null;
};

DisplayError.propTypes = {
  error: PropTypes.string,
};

export default DisplayError;
