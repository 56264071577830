import React from 'react';
import styled from 'styled-components';

import Button from '../../utils/Button';
import CheckoutTotalsSummaryFanRequest from '../checkout/CheckoutTotalsSummaryFanRequest';
import Columns from '../../utils/Columns';
import Contain from '../app/Contain';
import LineItemPreferenceList from '../cart/LineItemPreferenceList';
import OutletEventSummary from '../outletEvent/OutletEventSummary';
import Typography from '../../utils/Typography';
import { LineItemRow, OutletEventItemHeader } from '../cart/LineItemSummary';
import { DisplayHeader, DisplayPaymentMethod, DisplayShippingAddress, DisplayFees  } from '../../utils/DisplayContent';
import { IconChevronLeft, IconChevronRight } from '../../utils/Icon';
import {
  formatCurrency,
  formatDate, getMobileDeliveryMethodFromOutlet,
  goToUrl, isFanRequestUsingMobileDelivery,
  lineItemsNormalizedByOutletEventItem,
  normalizeAddress,
} from '../../utils/utilities';
import { useSelector } from 'react-redux';
import { FanRequestMobileDeliveryDetails } from './FanRequestMobileDeliveryDetails';

const NavigateRoot = styled.div`
  font-size: 2rem;
  padding: 2rem 0;
  margin: 4rem 0;
  background-color: ${(props) => props.theme.PRIMARY_GRAY}80;
  border-top: solid 1px ${(props) => props.theme.PRIMARY_TEXT_COLOR};
  border-bottom: solid 1px ${(props) => props.theme.PRIMARY_TEXT_COLOR};
`;
const NavigateContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const NavigateItem = styled.div`
  flex: 1;
  padding: 0 1rem;
  cursor: pointer;
  color: ${(props) => props.theme.PRIMARY_LINK_COLOR};
`;

const LeftColumStyled = styled.div`
  margin: 0 0 2rem 0;
`;

const RightColumStyled = styled.div`
  margin: 0 0 0 0;
`;

const EditRequestRiboon = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 1.8rem;
  padding: 1rem;
  color: #fff;
  font-weight: 700;
  background-color: ${(props) => props.theme.PRIMARY_LINK_COLOR};
`;

const FeeWrapperStyled = styled.div`
  border-bottom: ${(props) =>
          props.hasBottomBorder
                  ? 'solid 1px' + props.theme.PRIMARY_GRAY + '80'
                  : 'none'};
`;

function RequestReceipt(props = {}) {
  console.log('RequestReceipt props', props);
  const { request, seriesSummary } = props;
   const outlet = useSelector((state) => state.outlet);
  //const orderIsLoading = api && api.loading.includes("GET_USER_ORDER");
  if (!request || !seriesSummary) return <div>No request found</div>;
  const { OutletEvent, LotteryOEIRequests } = request;
  const {
    //Totals,
    RequestID,
    Shipping,
    PaymentInfo,
    OutletEventGroup,
    //LotteryOERequests,
    PaymentPlanEnrolled,
    //PriorityOERequestUUID,
  } = seriesSummary;
  const shippingAddress = normalizeAddress(Shipping);
  const addonItems = LotteryOEIRequests
      ? LotteryOEIRequests.filter((li) => li.isAddOn)
      : [];

  const PaymentMethod = (Array.isArray(PaymentInfo) && PaymentInfo[0]) || null;

  const lineItemsNormalizedByOEI = lineItemsNormalizedByOutletEventItem({
    OutletEventItems: OutletEvent ? OutletEvent.OutletEventItems : [],
    cart: {
      lineItems: LotteryOEIRequests ? LotteryOEIRequests : [],
      // lineItems: LotteryOEIRequests
      // ? LotteryOEIRequests.filter((li) => !li.isAddOn)
      // : [],
    },
  });
  const mobileDeliveryMethod = getMobileDeliveryMethodFromOutlet(outlet);
  const fanRequestIsMobileDelivery = Boolean(mobileDeliveryMethod?.Active && isFanRequestUsingMobileDelivery({ seriesSummary }));
  return (
      <div style={{ height: '100%' }}>
        <div style={{ margin: '2rem 0' }}>
          <Contain>
            <Typography tag="h1" style={{ padding: '2rem  0rem' }}>
              Success! Thank you for completing your request
            </Typography>
          </Contain>

          <Contain style={{ fontSize: '1.6rem' }}>
            <div>
              Your request confirmation number is: #<strong>{RequestID}</strong>
            </div>
            <div style={{ margin: '1rem  0 ' }}>
              <strong>*Please Note:</strong> Your request has not yet been
              awarded, and your payment has not yet been processed. See the
              details of your request below.
            </div>
            <div style={{ margin: '2rem  0 ', textAlign: 'center' }}>
              <Button onClick={() => goToUrl('/events')}>
                Request More Tickets
              </Button>
            </div>
          </Contain>
          <NavigateRoot>
            <Contain>
              <NavigateContainer>
                <NavigateItem
                    name="nav-events"
                    id="orders-nav-goto-events-list-btn"
                    onClick={() => goToUrl('/')}
                >
                  <IconChevronLeft/> Back to Events
                </NavigateItem>
                <NavigateItem
                    name="nav-myrequests"
                    id="requests-nav-goto-myrequests-btn"
                    onClick={() => goToUrl('/user/history/requests')}
                    style={{ textAlign: 'right' }}
                >
                  {' '}
                  View My Requests <IconChevronRight/>
                </NavigateItem>
              </NavigateContainer>{' '}
            </Contain>
          </NavigateRoot>
          <Contain>
            <Columns qty={2}>
              <LeftColumStyled>
                <OutletEventSummary outletEvent={OutletEvent}/>
                <EditRequestRiboon>
                  <div>Your Request</div>
                  <div>
                    <Typography
                        tag="a"
                        style={{ color: 'white' }}
                        href={
                            '/user/history/requests/' +
                            seriesSummary.RequestID +
                            '/item-details/' +
                            request.LotteryOERequestUUID
                        }
                        onClick={(e) => {
                          e.preventDefault();
                          goToUrl(
                              '/user/history/requests/' +
                              seriesSummary.RequestID +
                              '/item-details/' +
                              request.LotteryOERequestUUID,
                          );
                        }}
                    >
                      Edit
                    </Typography>
                  </div>
                </EditRequestRiboon>
                {Array.isArray(lineItemsNormalizedByOEI) && (
                    <>
                      {lineItemsNormalizedByOEI.map((oei, idx) => {
                        return (
                            <div key={idx}>
                              <OutletEventItemHeader oei={oei}/>

                              {oei.lineItems && Array.isArray(oei.lineItems)
                                  ? oei.lineItems.map((lineItem, idx2) => {
                                    if (lineItem.isAddOn) {

                                      const quantity =
                                          lineItem.quantity || lineItem.Quantity || 0;

                                      const label =
                                          lineItem.PriceLevelName +
                                          ' ' +
                                          formatCurrency(lineItem.PriceLevelValue) +
                                          ' x ' +
                                          quantity;
                                      return (
                                          <div key={idx2}>
                                            <LineItemRow
                                                hasLeftBorder
                                                hasRightBorder
                                                hasBottomerBorder
                                                label={label}
                                                showTotal={true}
                                                total={
                                                    lineItem.PriceLevelValue * quantity
                                                }
                                            />
                                            <FeeWrapperStyled hasBottomBorder>
                                              <DisplayFees
                                                  FeeGroups={lineItem?.FeeGroups}
                                                  quantity={quantity}
                                                  hasLeftBorder={true}
                                                  hasRightBorder={true} />
                                            </FeeWrapperStyled>

                                          </div>
                                      );
                                    }
                                    return (
                                        <LineItemPreferenceList
                                            key={idx2}
                                            selectedQty={
                                                lineItem.quantity || lineItem.Quantity
                                            }
                                            outletEventItem={oei}
                                            selectedPriceLevelId={lineItem.PriceLevelID}
                                            optOutIsSelected={Boolean(
                                                lineItem.PriceLevelOptOut,
                                            )}
                                            onlyPreferenceIsVisible={false}
                                        />
                                    );
                                  })
                                  : null}
                            </div>
                        );
                      })}
                    </>
                )}

                {/* <LineItemSummary
                outletEvent={OutletEvent}
                cart={{
                  lineItems: addonItems,
                }}
                shippingTotalIsVisible={false}
                grandTotalIsVisible={false}
              /> */}
                <CheckoutTotalsSummaryFanRequest
                    outletEvent={OutletEvent}
                    seriesSummary={seriesSummary}
                    allowShowAllRequest={false}
                />
              </LeftColumStyled>
              <RightColumStyled>
                {fanRequestIsMobileDelivery && (
                    <>
                      <div style={{ margin: '0 0 2rem 0' }}>
                        <DisplayHeader title="Shipping Details" />
                      </div>
                      <FanRequestMobileDeliveryDetails
                          fanRequest={{...request, seriesSummary}}
                          mobileDeliveryMethod={mobileDeliveryMethod}/>
                    </>
                )}
                <div style={{ margin: '0 0 2rem 0' }}>
                  <DisplayHeader
                      title="Shipping Address"
                      btnName="btn-shipping"
                  />
                  <DisplayShippingAddress address={shippingAddress}/>
                </div>
                <div style={{ margin: '2rem 0' }}>
                  <DisplayHeader title="Payment Information"/>
                  {PaymentPlanEnrolled && Array.isArray(PaymentInfo) ? (
                      PaymentInfo.map((p, pidx) => {
                        let processDate;
                        if (OutletEventGroup) {
                          if (pidx === 0) {
                            processDate =
                                OutletEventGroup.PaymentPlanInstallment1Date;
                          }
                          if (pidx === 1) {
                            processDate =
                                OutletEventGroup.PaymentPlanInstallment2Date;
                          }
                          if (pidx === 2) {
                            processDate =
                                OutletEventGroup.PaymentPlanInstallment3Date;
                          }
                        }
                        let processText =
                            pidx === 0 ? 'Process on or around ' : 'Processes on ';
                        if (processDate) {
                          processText =
                              processText +
                              formatDate(processDate, {
                                timeZone: 'UTC',
                              });
                        }
                        return (
                            <div style={{ margin: '0 0 1.5rem 0' }}>
                              <div style={{ fontWeight: 700 }}>
                                Installment {pidx + 1} - {processDate && processText}
                              </div>
                              <DisplayPaymentMethod paymentMethod={p}/>
                            </div>
                        );
                      })
                  ) : (
                      <DisplayPaymentMethod paymentMethod={PaymentMethod}/>
                  )}
                </div>
                <Button
                    onClick={() => goToUrl('/user/history/requests')}
                    style={{ margin: '3rem 0' }}
                    fullWidth
                >
                  View My Requests
                </Button>
              </RightColumStyled>
            </Columns>
          </Contain>
        </div>
      </div>
  );
}

export default RequestReceipt;
