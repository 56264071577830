import PropTypes from 'prop-types';
import styled from 'styled-components';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

//import HideOnMobile from "../../utils/HideOnMobile";
import Modal from '../../utils/Modal';
import Typography from '../../utils/Typography';
import { IconInfo } from '../../utils/Icon';
import { convertHexToRgb, formatCurrency, lineItemsNormalizedByOutletEventItem } from '../../utils/utilities';
import Button from '../../utils/Button';

const OEIHeaderRow = styled(Typography)`
  padding: 1rem;
  color: #fff;
  font-weight: ${(props) => (props.bold ? '700' : '400')};
  background-color: ${(props) => props.theme.PRIMARY_LINK_COLOR};
`;

const PLHeaderRow = styled(Typography)`
  padding: 1rem;
  font-weight: ${(props) => (props.bold ? '700' : '400')};
  background-color: ${(props) =>
    props.noBackground ? 'none' : props.theme.PRIMARY_GRAY}80;
  opacity: ${(props) => (props.optedOut || props.grayedOut ? '.6' : '1')};
`;
const LineItemContainer = styled.div`
  background: rgba(255, 255, 255, 0.8);

  color: rgba(
    ${(props) => {
  let rgb = convertHexToRgb(props.PRIMARY_TEXT_COLOR);
  if (props.grayedOut) {
    rgb += ',0.4';
  } else {
    rgb += ',1';
  }
  return rgb;
}}
  );
  border: solid 1px ${(props) => props.theme.PRIMARY_GRAY}80;
`;
const LIDetailsRow = styled(Typography)`
  font-weight: ${(props) => (props.bold ? '700' : '400')};
  display: flex;
  padding: 0.5rem 1rem 0.5rem 2rem;
  opacity: ${(props) => (props.optedOut || props.grayedOut ? '.6' : '1')};
  border-top: ${(props) =>
    props.hasTopBorder
        ? 'solid 1px' + props.theme.PRIMARY_GRAY + '80'
        : 'none'};

  border-bottom: ${(props) =>
    props.hasBottomerBorder
        ? 'solid 1px' + props.theme.PRIMARY_GRAY + '80'
        : 'none'};

  border-left: ${(props) =>
    props.hasLeftBorder
        ? 'solid 1px' + props.theme.PRIMARY_GRAY + '80'
        : 'none'};

  border-right: ${(props) =>
    props.hasRightBorder
        ? 'solid 1px' + props.theme.PRIMARY_GRAY + '80'
        : 'none'};
`;

const Label = styled.div`
  flex: 1;
`;
const LabelDescription = styled.div`
  display: flex;
  font-weight: normal;
`;
const Total = styled.div`
  padding: 0 0 0 0.5rem;
`;
const IconContainerStyled = styled.span`
  cursor: pointer;
  color: ${(props) => props.theme.PRIMARY_LINK_COLOR};
  svg {
    color: ${(props) => props.theme.PRIMARY_LINK_COLOR};
    fill: none;
    width: 10px;
    height: 10px;
  }
`;
const iconSize = 14;

export function OutletEventItemHeader({
                                        oei,
                                        onClickEdit,
                                        onClickRequestRefund,
                                        allowRefund,
                                        refundWindowOpen,
                                        refundRequested,
                                      }) {
  //console.log("OutletEventItemHeader ", oei);
  const name = oei.OutletEventItemName || oei.Name;
  let refundButtonText = refundRequested ? 'Refund Requested' : 'Request Refund';
  if (allowRefund && !refundWindowOpen && !refundRequested) {
    refundButtonText = 'Refund Window Closed';
  }
  return (
      <OEIHeaderRow bold>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div style={{ /*flex: 1*/ }}>{name}</div>
          {allowRefund ?
              <div><Button style={{ backgroundColor: refundRequested ? 'red' : 'blue' }} onClick={onClickRequestRefund}
                           disabled={!refundWindowOpen}>{refundButtonText}</Button></div> : null}
          {onClickEdit && (
              <div style={{ cursor: 'pointer' }} onClick={onClickEdit}>
                edit
              </div>
          )}
        </div>
      </OEIHeaderRow>
  );
}

export function PriceLevelHeader({ children, ...params }) {
  //const name = priceLevel.PriceLevelName || priceLevel.Name;
  return (
      <PLHeaderRow bold {...params}>
        {children}
      </PLHeaderRow>
  );
}

export function LineItemRow({ label, labelDescription, total, showTotal = true, ...params }) {
  return (
      <LIDetailsRow {...params}>
        <Label>{label}<LabelDescription>{labelDescription}</LabelDescription></Label>
        <Total>{showTotal ? formatCurrency(total) : null}</Total>
      </LIDetailsRow>
  );
}

export function FeeItemRow({ label, total, description, ...params }) {
  const [modal, setModal] = useState({
    title: '',
    content: '',
    constant: null,
    isVisible: false,
  });
  return (
      <LIDetailsRow {...params}>
        <Label>
          {label}{' '}
          {description && (
              <IconContainerStyled
                  onClick={() =>
                      setModal({
                        content: description,
                        isVisible: true,
                      })
                  }
              >
                <IconInfo height={iconSize} width={iconSize} title={description}/>
              </IconContainerStyled>
          )}
        </Label>
        <Total>{formatCurrency(total)}</Total>
        <Modal
            title="Fee Description"
            open={modal.isVisible}
            onClose={() => setModal({ isVisible: false })}
        >
          <div
              dangerouslySetInnerHTML={{
                __html: modal.content,
              }}
          />
        </Modal>
      </LIDetailsRow>
  );
}

function LineItemSummary(props) {
  const outlet = useSelector((state) => state.outlet);

  const {
    outletEvent,
    cart,
    shippingTotalIsVisible,
    grandTotalIsVisible,
    priceLevelTotalIsVisible,
    feeInfoIsVisible,
    itemTotalIsVisible,
    showConfirmedPriceLevel,
    showOutletEventItemHeader,
  } = props;

  if (!outletEvent || !cart) {
    return null;
  }
  const { OutletEventItems } = outletEvent;
  const { OutletDeliveryMethods } = outlet;

  let shippingTotal = 0;
  let deliveryMethod;
  if (cart.deliveryMethodUUID && OutletDeliveryMethods) {
    deliveryMethod = OutletDeliveryMethods.find(
        (dm) => dm.OutletDeliveryMethodUUID === cart.deliveryMethodUUID,
    );
    if (deliveryMethod && deliveryMethod.OverridePrice) {
      shippingTotal = deliveryMethod.OverridePrice;
    }
  }
  const lineItemsNormalizedByOEI = lineItemsNormalizedByOutletEventItem({
    OutletEventItems,
    cart,
  });
  // console.log("LineItemSummary ", { props, lineItemsNormalizedByOEI });
  // console.log("LineItemSummary outletEvent", outletEvent);
  // console.log("LineItemSummary cart", cart);

  // console.log(
  //   "LineItemSummary lineItemsNormalizedByOEI",
  //   lineItemsNormalizedByOEI
  // );
  let grandTotal = shippingTotal;
  let shippingLabel = 'Shipping';
  if (deliveryMethod && deliveryMethod.OverrideName) {
    shippingLabel += ` -  ${deliveryMethod.OverrideName}`;
  }
  return (
      <div>
        {lineItemsNormalizedByOEI.map((oei) => {
          return (
              <div key={oei.OutletEventItemID}>
                {showOutletEventItemHeader && <OutletEventItemHeader oei={oei}/>}
                {oei.lineItems &&
                    oei.lineItems.map((li, idx) => {
                      //console.log("NED li", li);
                      let grayedOut = false;

                      const status = li.StatusInfo && li.StatusInfo.ConstantValue;
                      if (
                          status === 'STATUS_REQUEST_DENIED' ||
                          status === 'STATUS_REQUEST_DENIED_INVENTORY'
                      ) {
                        grayedOut = true;
                      }

                      const quantity = li.quantity || li.Quantity || 0;
                      let total = li.PriceLevelValue * quantity;
                      grandTotal += total;
                      const label =
                          li.PriceLevelName +
                          ' ' +
                          formatCurrency(li.PriceLevelValue) +
                          ' x ' +
                          quantity;
                      //console.log("NED quantity", quantity);
                      //console.log("NED total", total);
                      let confirmedText;
                      if (
                          grayedOut === false &&
                          showConfirmedPriceLevel &&
                          li.ConfirmedPriceLevelID &&
                          Number(li.ConfirmedPriceLevelID) === Number(li.PriceLevelID)
                      ) {
                        confirmedText = 'CONFIRMED';
                      }
                      return (
                          <LineItemContainer key={idx} grayedOut={grayedOut}>
                            <PriceLevelHeader>
                              {li.PriceLevelName} x {quantity}
                              {confirmedText && (
                                  <span style={{ color: 'green' }}>
                          {' - '}
                                    {confirmedText}
                        </span>
                              )}
                            </PriceLevelHeader>
                            <LineItemRow
                                label={label}
                                showTotal={priceLevelTotalIsVisible}
                                total={li.PriceLevelValue * quantity}
                            />

                            {feeInfoIsVisible &&
                                li.FeeGroups &&
                                Array.isArray(li.FeeGroups) &&
                                li.FeeGroups.map((fg, idx2) => {
                                  //console.log("NED fg", fg);
                                  const feeGroupTotal = fg.Total
                                      ? fg.Total
                                      : fg.Value
                                          ? fg.Value
                                          : 0;
                                  total += feeGroupTotal * quantity;
                                  grandTotal += feeGroupTotal * quantity;
                                  return (
                                      <FeeItemRow
                                          key={idx2}
                                          label={fg.Name}
                                          total={feeGroupTotal ? feeGroupTotal * quantity : 0}
                                          description={fg.Description}
                                      />
                                  );
                                })}
                            {itemTotalIsVisible && (
                                <LineItemRow
                                    bold
                                    label="Total"
                                    total={total}
                                    //hasTopBorder
                                />
                            )}
                          </LineItemContainer>
                      );
                    })}
              </div>
          );
        })}
        {shippingTotalIsVisible && (
            <LineItemRow
                bold
                total={shippingTotal}
                label={shippingLabel}
                hasBottomerBorder
                hasLeftBorder
                hasRightBorder
            />
        )}

        {grandTotalIsVisible && (
            <LineItemRow
                bold
                label="Grand Total"
                total={grandTotal}
                hasBottomerBorder
                hasLeftBorder
                hasRightBorder
            />
        )}
      </div>
  );
}

LineItemSummary.propTypes = {
  cart: PropTypes.object,
  outletEvent: PropTypes.object,
  shippingTotalIsVisible: PropTypes.bool,
  grandTotalIsVisible: PropTypes.bool,
  priceLevelTotalIsVisible: PropTypes.bool,
  feeInfoIsVisible: PropTypes.bool,
  itemTotalIsVisible: PropTypes.bool,
};

LineItemSummary.defaultProps = {
  cart: null,
  outletEvent: null,
  shippingTotalIsVisible: true,
  grandTotalIsVisible: true,
  priceLevelTotalIsVisible: true,
  feeInfoIsVisible: true,
  itemTotalIsVisible: true,
  showOutletEventItemHeader: true,
};
export default LineItemSummary;
