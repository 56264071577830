/* eslint-disable require-jsdoc */
import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import AddressForm from '../utils/AddressForm';
import Alert from '../utils/Alert';
import Attention from '../utils/Attention';
import AuthContainer from '../components/auth/AuthContainer';
import Button from '../utils/Button';
import CheckoutAddons from '../components/checkout/CheckoutAddons';
import CheckoutBreadcrumbs from '../components/checkout/CheckoutBreadcrumbs';
import CheckoutContactEmails from '../components/checkout/CheckoutContactEmails';
import CheckoutDeliveryMethods from '../components/checkout/CheckoutDeliveryMethods';
import GuestListCheckoutDeliveryInfo from '../components/checkout/GuestListCheckoutDeliveryInfo';
import CheckoutTotalsSummaryFanRequest from '../components/checkout/CheckoutTotalsSummaryFanRequest';
import Columns from '../utils/Columns';
import Contain from '../components/app/Contain';
import DisplayError from '../utils/Error';
import EditRequestLineItems from '../components/fanrequest/EditRequestLineItems';
import LineItemPreferenceList from '../components/cart/LineItemPreferenceList';
import LineItemSummary, { LineItemRow, OutletEventItemHeader } from '../components/cart/LineItemSummary';
import Modal from '../utils/Modal';
import OutletEventSummary from '../components/outletEvent/OutletEventSummary';
import PaymentMethodForm from '../utils/PaymentMethodForm';
import PaymentPlanEnrollment from '../components/fanrequest/PaymentPlanEnrollment';
import PaymentPlanForm from '../components/fanrequest/PaymentPlanForm';
import SeatAssignmentSummary from '../components/checkout/SeatAssignmentSummary';
import Timer from '../utils/Timer';
import Typography from '../utils/Typography';
import { CheckboxInput } from '../utils/Form';
import { DisplayFees, DisplayHeader, DisplayPaymentMethod, DisplayShippingAddress } from '../utils/DisplayContent';
import {
  createGuestListRequest,
  deleteInstantRequest,
  resetFanRequestCart,
  setFanRequestError,
  updateFanRequest,
  updateGuestListRequestCart,
  updateInstantRequest,
} from '../components/cart/actions';
import {
  formatCurrency,
  formatDate, getMobileDeliveryMethodFromOutlet,
  goToUrl, isFanRequestUsingMobileDelivery,
  lineItemsNormalizedByOutletEventItem,
  normalizeAddress,
} from '../utils/utilities';
import { getGuestListInvitation } from '../components/event/actions';
import { getUserAddressList, getUserFanRequests, getUserPaymentMethodList } from '../components/user/actions';
import GuestListRequestItemsDetails from '../components/guestList/GuestListRequestItemsDetails';
import GuestListRequestShippingDetails from '../components/guestList/GuestListRequestShippingDetails';
import { FanRequestCheckoutHeader } from '../components/fanrequest/FanRequestCheckoutHeader';
import { FanRequestMobileDeliveryDetails } from '../components/fanrequest/FanRequestMobileDeliveryDetails';

const RightColumnStyled = styled.div`
  padding: 0 0 3rem 0;
  @media (min-width: ${(props) => props.theme.BREAKPOINT_MOBILE_WIDTH}px) {
    // padding: 3rem 2rem;
  }
`;
const LeftColumnStyled = styled.div`
  padding: 0 0 2rem 0;
  @media (min-width: ${(props) => props.theme.BREAKPOINT_MOBILE_WIDTH}px) {
    padding: 0 0 3rem 0;
  }
`;
const TimerContainerStyled = styled.div`
  background-color: #fff;
  margin: 0 0 3.5rem 0;
  display: flex;
  justify-content: center;
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -ms-sticky;
  position: -o-sticky;
  position: sticky;
  top: 0;
`;
const ActionBtnContainerStyled = styled.div`
  padding: 0rem 0;
`;
const EditRequestRiboon = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 1.8rem;
  padding: 1rem;
  color: #fff;
  font-weight: 700;
  background-color: ${(props) => props.theme.PRIMARY_LINK_COLOR};
`;

const instantStageList = [
  {
    sort: 1,
    stage: 'review',
    label: 'Review',
    header: 'Confirm Selection',
    disabled: true,
  },
  { sort: 2, stage: 'addons', label: 'Addons', header: 'Add to Order' },
  {
    sort: 3,
    stage: 'shipping-address',
    label: 'Shipping',
    header: 'Shipping Details',
  },
  {
    sort: 4,
    stage: 'delivery',
    label: 'Delivery',
    header: 'Choose your Delivery Method',
  },
  {
    sort: 5,
    stage: 'payment-method',
    label: 'Billing',
    header: 'Payment Details',
  },
  { sort: 6, stage: 'confirm', label: 'Checkout', header: 'Place Order' },
];

const fanStageList = [
  // {
  //   sort: 1,
  //   stage: "review",
  //   label: "Review",
  //   header: "Confirm Ticket Selection",
  // },
  { sort: 1, stage: 'addons', label: 'Addons', header: 'Add to Request' },
  {
    sort: 2,
    stage: 'shipping-address',
    label: 'Shipping',
    header: 'Shipping Details',
  },
  {
    sort: 3,
    stage: 'payment-method',
    label: 'Billing',
    header: 'Payment Details',
  },
  { sort: 4, stage: 'confirm', label: 'Confirm', header: 'Place Request' },
];

const guestListStageList = [
  {
    sort: 1,
    stage: 'contact-info',
    label: 'Contact',
    header: 'Contact Information',
  },
  {
    sort: 2,
    stage: 'delivery',
    label: 'Delivery',
    header: 'Choose your Delivery Method',
  },
];

function CheckoutView() {
  const { type, stage } = useParams();
  const outlet = useSelector((state) => state.outlet);
  const session = useSelector((state) => state.session);
  const eventList = useSelector((state) => state.eventList);
  const fanRequest = useSelector((state) => state.fanRequest);
  const guestRequest = useSelector((state) => state.guestRequest);
  const instantRequest = useSelector((state) => state.instantRequest);
  const userAddressList = useSelector((state) => state.userAddressList);
  const userPaymentMethodList = useSelector(
      (state) => state.userPaymentMethodList,
  );
  const [selectedAddons, setSelectedAddons] = useState([]);
  const [userAcceptsTermsOfService, setUserAcceptsTermsOfService] =
    useState(false);
  const [userHasViewedAddons, setUserHasViewedAddons] = useState(false);
  const userIsAuthenticated = useSelector(
      (state) => state.auth.userIsAuthenticated,
  );
  const guestListInvitation = useSelector(
      (state) => state.auth.guestListInvitation,
  );
  const guestListInvitationDeliveryMethodList = useSelector(
      (state) => state.auth.guestListInvitationDeliveryMethodList,
  );
  const guestListToken = useSelector((state) => state.auth.guestListToken);
  const [outletEventDetailsVisible, setOutletEventDetailsVisible] =
    useState(false);
  const [modal, setModal] = useState({
    title: '',
    content: '',
    constant: null,
    isVisible: false,
  });

  useEffect(() => {
    const scrollToElement = document.querySelector(
        '#breadcrumbs-root-container',
    );
    if (scrollToElement) {
      scrollToElement.scrollIntoView({ behavior: 'smooth' });
    }

    return () => {
    };
  }, [stage]);

  if (!stage || !type) {
    return <Redirect to="/" />;
  }

  let cart;
  let error;
  let loading;
  let cartUUID;
  let stageList;
  let outletEvent;
  let priceLevels;
  let currentStage;
  // let contactEmails;
  let paymentMethod;
  let deliveryMethod;
  let shippingAddress;
  let ticketGuidlines;
  // let shippingGuidlines;
  let seriesSummary;
  // let paymenPlanTC;
  let paymentPlanRules;
  // let paymentPlanPolicy;
  let outletPrivacyPolicy;
  let outletTermsAndConditions;
  let lineItemsNormalizedByOEI;
  let outletEventItemsWithAddons;
  let guestListOutletEvents = [];
  let paymentList = [];
  let paymentPlanIsAvailable = false;
  let paymentCollectionIsRequired = true;
  let userIsEnrolledInPaymentPlan = false;
  let fanRequestIsMobileDelivery = false;
  const mobileDeliveryMethod = getMobileDeliveryMethodFromOutlet(outlet);

  if (type === 'i') {
    if (instantRequest) {
      cart = instantRequest.cart;
      // cart = instantCartStubData;
      error = instantRequest.error;
      stageList = instantStageList;
      loading = instantRequest.loading;
      outletEvent = instantRequest.outletEvent;
      // outletEvent = outletEventStubData;
      const addonsAvailable =
        outletEvent &&
        outletEvent.OutletEventItems &&
        outletEvent.OutletEventItems.some(
            (oei) => oei?.EventItem?.SuppressOnEDP,
        );
      if (!addonsAvailable) {
        stageList = stageList.filter((x) => x.stage !== 'addons').map((x, idx) => ({ ...x, sort: idx + 1 }));
        // stageList = fanStageList.splice(0, 1);
        // stageList = fanStageList.shift();
        // stageList.splice(0, 1);
      }
      currentStage = stageList.find((x) => x.stage === stage);

      if (cart) {
        const { paymentMethodUUID, deliveryMethodUUID } = cart;
        cartUUID = cart.cartUUID;
        shippingAddress = cart.shippingAddress;
        if (Array.isArray(userPaymentMethodList) && paymentMethodUUID) {
          paymentMethod = userPaymentMethodList.find(
              (pm) => pm.PaymentMethodUUID === paymentMethodUUID,
          );
        }
        if (deliveryMethodUUID && Array.isArray(outlet.OutletDeliveryMethods)) {
          deliveryMethod = outlet.OutletDeliveryMethods.find(
              (dm) => dm.OutletDeliveryMethodUUID === deliveryMethodUUID,
          );
        }
      }
    }
  }
  if (type === 'f') {
    if (fanRequest) {
      cart = fanRequest.cart;
      error = fanRequest.error;
      stageList = [...fanStageList];
      loading = fanRequest.loading;
      outletEvent = fanRequest.outletEvent;
      const addonsAvailable =
        outletEvent &&
        outletEvent.OutletEventItems &&
        outletEvent.OutletEventItems.some(
            (oei) => oei?.EventItem?.SuppressOnEDP,
        );
      if (!addonsAvailable) {
        stageList = stageList.filter((x) => x.stage !== 'addons').map((x, idx) => ({ ...x, sort: idx + 1 }));
        // stageList = fanStageList.splice(0, 1);
        // stageList = fanStageList.shift();
        // stageList.splice(0, 1);
      }
      currentStage = stageList.find((x) => x.stage === stage);
      seriesSummary = fanRequest.seriesSummary;
      fanRequestIsMobileDelivery = Boolean(mobileDeliveryMethod?.Active && isFanRequestUsingMobileDelivery({ seriesSummary }));
      if (cart) {
        cartUUID = 'FanrequestUUUD';
        paymentList = cart.paymentList;
        shippingAddress = cart.shippingAddress;

        if (
          paymentList &&
          paymentList[0] &&
          paymentList[0].PaymentMethodUUID &&
          Array.isArray(userPaymentMethodList)
        ) {
          paymentMethod = userPaymentMethodList.find(
              (pm) => pm.PaymentMethodUUID === paymentList[0].PaymentMethodUUID,
          );
        }
        if (seriesSummary) {
          const {
            Totals,
            // RequestID,
            // Shipping,
            PaymentInfo,
            // OutletEventGroup,
            // LotteryOERequests,
            PaymentPlanEnrolled,
            // PriorityOERequestUUID,
          } = seriesSummary;

          // PaymentPlanEnrolled = seriesSummary.PaymentPlanEnrolled;

          // if shipping is NOT set in the cart, default it to the series shipping method
          if (!shippingAddress && seriesSummary.Shipping) {
            // if shipping address is found via lottery summary,
            // normalize the keys because they dont match the address table
            shippingAddress = normalizeAddress(seriesSummary.Shipping);
          }

          // if paymentList is NOT set in the cart, default it to the series payment info
          if (
            !paymentList ||
            !paymentList.length ||
            !Array.isArray(paymentList)
          ) {
            paymentList = PaymentInfo;
          }

          if (
            outlet &&
            Array.isArray(outlet.OutletEventGroups) &&
            Boolean(
                outletEvent.OutletEventGroup &&
                outletEvent.OutletEventGroup.OfferPaymentPlan,
            )
          ) {
            const oegFound = outlet.OutletEventGroups.find(
                (oeg) =>
                    Number(oeg.OutletEventGroupID) ===
                    Number(outletEvent.OutletEventGroup.OutletEventGroupID),
            );

            if (oegFound && oegFound.PaymentPlanRules) {
              paymentPlanRules = oegFound.PaymentPlanRules;
              paymentPlanIsAvailable =
                Number(Totals.total) >=
                Number(paymentPlanRules.eligibilityMinimum);
            }
          }

          if (cart.enrollInPaymentPlan === undefined) {
            userIsEnrolledInPaymentPlan = Boolean(PaymentPlanEnrolled);
          } else {
            userIsEnrolledInPaymentPlan = cart.enrollInPaymentPlan;
          }
        }
      }
    }
  }

  if (type === 'g') {
    if (guestRequest) {
      cart = guestRequest.cart;
      // cart = guestListCartStubData;
      error = guestRequest.error;
      loading = guestRequest.loading;
      stageList = guestListStageList;

      if (
        guestListInvitation &&
        Boolean(guestListInvitation.CollectPayment)
      ) {
        stageList = [...stageList, {
          sort: 3,
          stage: 'payment-method',
          label: 'Billing',
          header: 'Payment Details',
        }, {
          sort: 4,
          stage: 'confirm',
          label: 'Confirm',
          header: 'Complete Request',
        }];
      } else {
        stageList = [...stageList, {
          sort: 3,
          stage: 'confirm',
          label: 'Confirm',
          header: 'Complete Request',
        }];
        paymentCollectionIsRequired = false;
      }
      currentStage = stageList.find((x) => x.stage === stage);
      if (cart) {
        const { paymentMethodUUID, deliveryMethodUUID } = cart;
        cartUUID = 'guestListRequest';
        shippingAddress = cart.shippingAddress;
        // contactEmails = cart.contactEmails;
        priceLevels = cart.priceLevels;
        //   deliveryMethod: app.cartDeliveryMethod,
        //   paymentMethod: app.cartPaymentMethod,
        //   priceLevels: app.cartPriceLevels,
        //   shippingAddress: app.cartShippingAddress,
        //   contactEmails: app.cartContactEmails,

        if (Array.isArray(userPaymentMethodList) && paymentMethodUUID) {
          paymentMethod = userPaymentMethodList.find(
              (pm) => pm.PaymentMethodUUID === paymentMethodUUID,
          );
        }
        if (deliveryMethodUUID && Array.isArray(outlet.OutletDeliveryMethods)) {
          deliveryMethod = outlet.OutletDeliveryMethods.find(
              (dm) => dm.OutletDeliveryMethodUUID === deliveryMethodUUID,
          );
        }
      }
    }
  }
  let userHasValidCart = false;

  if (type === 'g') {
    if (Array.isArray(priceLevels) && priceLevels.length) {
      if (stage === 'contact-info') {
        userHasValidCart = true;
      }
      if (stage === 'delivery') {
        userHasValidCart = true;
      }
      if (stage === 'payment-method' && deliveryMethod) {
        userHasValidCart = true;
      }
      if (stage === 'confirm' && deliveryMethod) {
        if (paymentCollectionIsRequired) {
          if (paymentMethod) {
            userHasValidCart = true;
          }
        } else {
          userHasValidCart = true;
        }
      }
    }
  } else {
    userHasValidCart = cart && cartUUID && outletEvent;
  }
  if (!userHasValidCart) {
    return <Redirect to="/" />;
  }

  const handleClickEditFanRequestLineItems = (params = {}) => {
    const { title, outletEventItems } = params;
    setModal({
      ...modal,
      constant: 'EDIT_REQUEST_LINE_ITEMS',
      title: title || 'Edit Line Items',
      isVisible: true,
      content: { outletEventItems },
    });
  };

  const handleClickPolicy = (policy) => {
    setModal({
      title: policy && policy.Headline,
      content: policy && policy.PolicyText,
      constant: 'POLICY',
      isVisible: true,
    });
  };

  const handleOnChangeAcceptsTermsOfService = (e) =>
    setUserAcceptsTermsOfService(e.target.checked);

  const handleClickCloseModal = () => {
    if (modal.constant === 'INSTANT_CART_TIMER_EXPIRED') {
      handleClickDeleteInstantCart();
    }
    setModal({ ...modal, isVisible: false });
  };

  const handleClickChangeSeats = () => {
    setModal({
      ...modal,
      constant: 'CANCEL_INSTANT_CART',
      title: 'Are you sure?',
      isVisible: true,
    });
  };

  const handleExpiredTimer = () => {
    setModal({
      ...modal,
      constant: 'INSTANT_CART_TIMER_EXPIRED',
      title: 'Time\'s Up',
      isVisible: true,
    });
  };

  const handleClickDeleteInstantCart = () => {
    deleteInstantRequest();
    goToUrl('/', { replace: true });
  };

  const handleClickConfirmSeatAssignment = () => {
    const config = {
      action: 'INSTANT_REQUEST_CONFIRM_SEATS',
      requestBody: { cartUUID },
    };
    updateInstantRequest(config).then((res) => {
      if (!userIsAuthenticated) {
        goToUrl('/checkout/i/login');
      } else if (
        outletEventItemsWithAddons &&
        Array.isArray(outletEventItemsWithAddons) &&
        outletEventItemsWithAddons.length &&
        userHasViewedAddons === false
      ) {
        goToUrl('/checkout/i/addons');
      } else {
        goToUrl('/checkout/i/shipping-address');
      }
    });
  };

  const handleSuccessfullAuthentication = () => {
    if (
        outletEventItemsWithAddons &&
        Array.isArray(outletEventItemsWithAddons) &&
        outletEventItemsWithAddons.length &&
        userHasViewedAddons === false
    ) {
      goToUrl('/checkout/' + type + '/addons');
    } else {
      goToUrl('/checkout/' + type + '/shipping-address');
    }
  };

  const handleAddOnQtyChange = (selectedPriceLevels) => {
    const selectedAddonsFormattted = selectedPriceLevels
        // .filter((p) => p.isAddOn)
        .map((spl) => {
          const quantity = parseInt(Number(spl.quantity || spl.Quantity, 10));
          const priceLevelID = parseInt(Number(spl['PriceLevelID'], 10));
          let outletEventItemID =
              spl.OutletEventItemID || (spl.oei && spl.oei.OutletEventItemID);
          outletEventItemID = parseInt(Number(outletEventItemID, 10));
          return {
            ...spl,
            quantity,
            Quantity: quantity,
            priceLevelID,
            isAddOn: true,
            outletEventItemID,
          };
        });
    setSelectedAddons(selectedAddonsFormattted);
  };

  const handleClickSaveAddons = () => {
    setUserHasViewedAddons(true);
    const lineItems = selectedAddons.map((a) => {
      const formattedItem = {
        ...a,
        quantity: a.quantity,
        priceLevelID: a.priceLevelID,
        isAddOn: true,
      };
      return formattedItem;
    });
    if (type === 'i') {
      const config = {
        action: 'INSTANT_REQUEST_UPDATE_LINEITEMS',
        requestBody: { cartUUID, lineItems },
      };

      updateInstantRequest(config).then((res) => {
        goToUrl('/checkout/' + type + '/shipping-address');
      });
    }
    if (type === 'f') {
      const config = {
        action: 'CREATE_FAN_REQUEST_WITH_SERIES_SUMMARY',
        addons: lineItems,
      };
      updateFanRequest(config).then((res) => {
        setSelectedAddons([]);
        goToUrl('/checkout/' + type + '/shipping-address');
      });
    }
  };

  const handleOnSubmitGuestListDeliveryInfo = (params = {}) => {
    const config = {
      action: 'GUEST_REQUEST_UPDATE_DELIVERY_METHOD',
      cart: params,
    };

    updateGuestListRequestCart(config).then((res) => {
      paymentCollectionIsRequired ?
          goToUrl('/checkout/g/payment-method') :
          goToUrl('/checkout/g/confirm');
    });
  };
  const handleOnSubmitDeliveryMethod = (dm) => {
    if (dm && dm.OutletDeliveryMethodUUID) {
      const deliveryMethodUUID = dm.OutletDeliveryMethodUUID;
      if (type === 'i') {
        const config = {
          action: 'INSTANT_REQUEST_UPDATE_DELIVERY_METHOD',
          requestBody: { cartUUID, deliveryMethodUUID },
        };

        updateInstantRequest(config).then((res) => {
          goToUrl('/checkout/i/payment-method');
        });
      }
    } else {
      alert('error missing delivery method details');
    }
  };
  const handleOnSubmitContactInfo = (payload = {}) => {
    const config = {
      action: 'GUEST_REQUEST_UPDATE_CONTACT_INFO',
      cart: payload,
    };
    updateGuestListRequestCart(config).then((res) => {
      goToUrl('/checkout/g/delivery');
    });
  };
  const handleOnSubmitShippingAddress = (shippingAddress) => {
    if (shippingAddress) {
      if (type === 'g') {
        const config = {
          action: 'GUEST_REQUEST_UPDATE_SHIPPING_ADDRESS',
          cart: { shippingAddress },
        };

        updateGuestListRequestCart(config).then((res) => {
          goToUrl('/checkout/g/delivery');
        });
      }

      if (type === 'i') {
        const config = {
          action: 'INSTANT_REQUEST_UPDATE_SHIPPING_ADDRESS',
          requestBody: { cartUUID, shippingAddress },
        };

        updateInstantRequest(config).then((res) => {
          goToUrl('/checkout/i/delivery');
        });
      }
      if (type === 'f') {
        const LotteryRequestShippingAddressUUID =
          fanRequest &&
          fanRequest.seriesSummary &&
          fanRequest.seriesSummary.Shipping &&
          fanRequest.seriesSummary.Shipping.LotteryRequestShippingAddressUUID;
        const LotteryRequestShippingAddressID =
          fanRequest &&
          fanRequest.seriesSummary &&
          fanRequest.seriesSummary.Shipping &&
          fanRequest.seriesSummary.Shipping.LotteryRequestShippingAddressID;
        const config = {
          action: 'FAN_REQUEST_UPDATE_SHIPPING_ADDRESS',
          cart: {
            shippingAddress: {
              ...shippingAddress,
              LotteryRequestShippingAddressID,
              LotteryRequestShippingAddressUUID,
            },
          },
        };

        updateFanRequest(config).then((res) => {
          goToUrl('/checkout/f/payment-method');
        });
      }
    } else {
      alert('error missing shipping address details');
    }
  };

  const handleOnCreateNewPaymentMethodViaIFrame = (iFrameResponse) => {
    const UserID = session && session.UserID;
    getUserPaymentMethodList({ UserID }).then((userPaymentMethods) => {
      if (
        userPaymentMethods &&
        Array.isArray(userPaymentMethods) &&
        userPaymentMethods.length
      ) {
        if (userPaymentMethods[0]) {
          const pmAdjusted = { ...userPaymentMethods[0] };
          if (iFrameResponse.Sort) {
            pmAdjusted['Sort'] = iFrameResponse.Sort;
          }
          handleOnSelectPaymentMethod(pmAdjusted);
        }
      }
    });
  };

  const handleOnSelectPaymentMethod = (pm) => {
    if (pm && pm.PaymentMethodUUID) {
      const paymentMethodUUID = pm.PaymentMethodUUID;

      if (type === 'g') {
        const config = {
          action: 'GUEST_REQUEST_UPDATE_PAYMENT_METHOD',
          cart: { paymentMethod: pm, paymentMethodUUID },
        };

        updateGuestListRequestCart(config).then((res) => {
          goToUrl('/checkout/g/confirm');
        });
      }

      if (type === 'i') {
        const config = {
          action: 'INSTANT_REQUEST_UPDATE_PAYMENT_METHOD',
          requestBody: { cartUUID, paymentMethodUUID },
        };

        updateInstantRequest(config).then((res) => {
          goToUrl('/checkout/i/confirm');
        });
      }
      if (type === 'f') {
        if (!pm.Sort) {
          pm.Sort = 1;
        }

        let LotteryOEGRequestPaymentMethodUUID;
        let LotteryOEGRequestPaymentMethodID;
        if (
          fanRequest &&
          fanRequest.seriesSummary &&
          Array.isArray(fanRequest.seriesSummary.PaymentInfo)
        ) {
          const paymentIndex = fanRequest.seriesSummary.PaymentInfo.findIndex(
              (p) => Number(p.Sort) === Number(pm.Sort),
          );

          if (paymentIndex > -1) {
            LotteryOEGRequestPaymentMethodUUID =
              fanRequest.seriesSummary.PaymentInfo[paymentIndex].LotteryOEGRequestPaymentMethodUUID;
            LotteryOEGRequestPaymentMethodID =
              fanRequest.seriesSummary.PaymentInfo[paymentIndex].LotteryOEGRequestPaymentMethodID;
          }
        }
        const config = {
          action: 'FAN_REQUEST_UPDATE_PAYMENT_METHOD',
          paymentMethod: {
            ...pm,
            LotteryOEGRequestPaymentMethodID,
            LotteryOEGRequestPaymentMethodUUID,
          },
          // cart: { paymentList: [{ ...pm, Sort: 1 }] },
        };

        updateFanRequest(config).then((res) => {
          if (!userIsEnrolledInPaymentPlan) {
            goToUrl('/checkout/f/confirm');
          }
        });
      }
    } else {
      alert('error missing payment method details');
    }
  };

  const handleClickCompleteRequest = () => {
    if (type === 'g') {
      // const request = {
      //   token: app.token,
      //   deliveryMethod: app.cartDeliveryMethod,
      //   paymentMethod: app.cartPaymentMethod,
      //   priceLevels: app.cartPriceLevels,
      //   shippingAddress: app.cartShippingAddress,
      //   contactEmails: app.cartContactEmails,
      //   outletID: app.user.OutletID,
      //   user: {
      //     UserID: app.user.UserID,
      //     FirstName: app.user.FirstName,
      //     LastName: app.user.LastName,
      //     EmailAddress: app.user.EmailAddress,
      //   },
      // };
      const payload = guestRequest.cart;
      createGuestListRequest(payload).then((res) => {
        const glCartConfig = {
          action: 'GUEST_REQUEST_CLEAR_CART',
          cart: null,
        };
        updateGuestListRequestCart(glCartConfig);
        getGuestListInvitation({
          InvitationToken: guestListToken,
        });
        let GuestListRequestID = res && res.GuestListRequestID;
        if (Array.isArray(res) && res.length) {
          GuestListRequestID = res[0].GuestListRequestID;
        }
        goToUrl('/guestlistreceipt/' + GuestListRequestID, {
          replace: true,
        });
      });
    }
    if (type === 'i') {
      const config = {
        action: 'INSTANT_REQUEST_CONFIRM_ORDER',
        requestBody: { cartUUID },
      };

      updateInstantRequest(config).then((res) => {
        deleteInstantRequest();
        const OrderID = res && res.order && res.order.OrderID;
        getUserAddressList();
        if (OrderID) {
          goToUrl('/user/history/orders/' + OrderID + '/receipt', {
            replace: true,
          });
        } else {
          goToUrl('/user/history/orders ', { replace: true });
        }
      });
    }
    if (type === 'f') {
      const config = {
        action: 'SAVE_FAN_REQUEST',
      };

      updateFanRequest(config).then((res) => {
        resetFanRequestCart();
        getUserAddressList();
        getUserFanRequests();
        if (res && res.RequestID && res.LotteryOERequestUUID) {
          goToUrl(
              '/user/history/requests/' +
              res.RequestID +
              '/receipt/' +
              res.LotteryOERequestUUID,
              {
                replace: true,
              },
          );
        } else {
          goToUrl('/user/history/requests ', { replace: true });
        }
      });
    }
  };
  const handleClickContinueFromPaymentMethod = () => {
    goToUrl('/checkout/' + type + '/confirm');
  };
  const handleClickBreadcrumb = (crumb) => {
    if (crumb && crumb.stage) {
      goToUrl('/checkout/' + type + '/' + crumb.stage);
    }
    return false;
  };

  const handleOnChangePaymentPlanEnrollment = (e) => {
    const isChecked = e.target.checked;
    const config = {
      action: 'ENROLL_IN_PAYMENTPLAN',
      cart: { enrollInPaymentPlan: Boolean(isChecked) },
    };
    updateFanRequest(config).then((res) => {
    });
  };

  if (Array.isArray(outlet.OutletPolicies)) {
    outlet.OutletPolicies.map((policy) => {
      // OutletPolicyTypeID to Name:
      // 1 Privacy Policy
      // 2 Terms & Conditions
      // 3 Payment Plan Policy
      // 4 Ticket Guidelines
      // 5 Shipping Address
      // 6 External Events
      // 7 Payment Plan T&C
      // 8 Priority Select
      // 9 Pit Upgrade
      // 10 Estimated Shipping Date Text

      // SalesTypeID to Name:
      // -1 All (Realtime & Lottery && External)
      // 1 Realtime (instant)
      // 2 Lottery (fan request)
      // 3 External (ie. ticketmaster link)

      if (policy.SalesTypeID === -1) {
        if (policy.OutletPolicyTypeID === 1) {
          outletPrivacyPolicy = policy;
        }
        if (policy.OutletPolicyTypeID === 2) {
          outletTermsAndConditions = policy;
        }
      }

      if (
          (policy.SalesTypeID === 2 || policy.SalesTypeID === -1) &&
          type === 'f'
      ) {
        if (policy.OutletPolicyTypeID === 4) {
          ticketGuidlines = policy;
        }
        if (policy.OutletPolicyTypeID === 5) {
          // shippingGuidlines = policy;
        }
      }
      if (
          (policy.SalesTypeID === 1 || policy.SalesTypeID === -1) &&
          type === 'i'
      ) {
        if (policy.OutletPolicyTypeID === 4) {
          ticketGuidlines = policy;
        }
        if (policy.OutletPolicyTypeID === 5) {
          // shippingGuidlines = policy;
        }
      }
      if (policy.OutletPolicyTypeID === 3) {
        // paymentPlanPolicy = policy;
      }
      if (policy.OutletPolicyTypeID === 7) {
        // paymenPlanTC = policy;
      }
      return null;
    });
  }

  const userAddressListSorted = Array.isArray(userAddressList)
      ? userAddressList.sort((a, b) => a.AddressID - b.AddressID)
      : [];
  const remainingTimeOnClock =
      cart && cart.clock && cart.clock.running && cart.clock.secondsToExpire
          ? cart.clock.secondsToExpire
          : 0;
  let maximumTimeOnClock = outlet.CheckoutTimer * 60;
  if (stage === 'review') {
    maximumTimeOnClock = outlet.ConfirmTicketSelectionTimer * 60;
    maximumTimeOnClock = maximumTimeOnClock + 1;
  }

  if (type !== 'g') {
    outletEventItemsWithAddons = outletEvent ?
      outletEvent.OutletEventItems.filter(
          (oei) => oei?.EventItem?.SuppressOnEDP,
      ).filter((oei) => (oei.PriceLevels || []).length !== 0) :
      [];
    lineItemsNormalizedByOEI = lineItemsNormalizedByOutletEventItem({
      OutletEventItems: outletEvent ? outletEvent.OutletEventItems : [],
      cart: { lineItems: cart.lineItems },
      // cart: { lineItems: cart.lineItems.filter((li) => !li.isAddOn) },
    });
  }
  let filteredDeliveryMethods = Array.isArray(outlet.OutletDeliveryMethods) ?
    outlet.OutletDeliveryMethods.filter((x) => x.Active) :
    [];
  if (shippingAddress && shippingAddress.CountryID) {
    // DeliveryMethodTypeID: 1 is 'Will Call'
    // DeliveryMethodTypeID: 2 is 'Shippable - Domestic'
    // DeliveryMethodTypeID: 3 is 'Shippable - International'
    if (Number(shippingAddress.CountryID) === 1) {
      // Unitied States is CountryID === 1
      filteredDeliveryMethods = filteredDeliveryMethods.filter(
          (x) => x.DeliveryMethodTypeID === 1 || x.DeliveryMethodTypeID === 2,
      );
    } else {
      filteredDeliveryMethods = filteredDeliveryMethods.filter(
          (x) => x.DeliveryMethodTypeID === 1 || x.DeliveryMethodTypeID === 3,
      );
    }
  }

  const guestListRequestShippingTotal =
      (deliveryMethod && deliveryMethod.OverridePrice) || 0;
  let guestListRequestGrandTotal = guestListRequestShippingTotal;
  let guestListRequestShippingLabel = 'Shipping';
  if (deliveryMethod && deliveryMethod.OverrideName) {
    guestListRequestShippingLabel += ` -  ${deliveryMethod.OverrideName}`;
  }

  let guestListOutletEventItems = [];
  if (type === 'g') {
    if (Array.isArray(eventList) && eventList.length) {
      priceLevels.map((pl) => {
        const quantity = pl.quantity || pl.Quantity || 0;
        guestListRequestGrandTotal += pl.PriceLevelValue * quantity;
        {
          pl.FeeGroups &&
            Array.isArray(pl.FeeGroups) &&
            pl.FeeGroups.map((fg) => {
              const feeGroupTotal = fg.Total ?
                fg.Total :
                fg.Value ?
                  fg.Value :
                  0;
              guestListRequestGrandTotal += feeGroupTotal * quantity;
            });
        }

        if (pl.OutletEventItemID) {
          let oei;
          eventList.map((e) => {
            if (Array.isArray(e.OutletEventItems)) {
              oei = e.OutletEventItems.find(
                  (x) => x.OutletEventItemID === pl.OutletEventItemID,
              );
            }
            if (oei) {
              guestListOutletEvents.push(e);
              guestListOutletEventItems.push(oei);
            }
          });
        }
      });
      guestListOutletEvents = guestListOutletEvents.filter(
          (value, index, self) =>
              index ===
              self.findIndex((t) => t.OutletEventID === value.OutletEventID),
      );

      guestListOutletEventItems = guestListOutletEventItems.filter(
          (value, index, self) =>
              index ===
              self.findIndex((t) => t.OutletEventItemID === value.OutletEventItemID),
      );

      guestListOutletEventItems = guestListOutletEventItems.map((x) => {
        const adjustedObj = { ...x, lineItems: [] };
        priceLevels.map((pl) => {
          if (x.OutletEventItemID === pl.OutletEventItemID) {
            adjustedObj.lineItems.push(pl);
          }
        });
        return adjustedObj;
      });
      guestListOutletEvents = guestListOutletEvents.map((x) => {
        const adjustedObj = { ...x, OutletEventItems: [] };
        guestListOutletEventItems.map((y) => {
          if (x.OutletEventID === y.OutletEventID) {
            adjustedObj.OutletEventItems.push(y);
          }
        });
        return adjustedObj;
      });
    }
  }

  return (
    <div>
      {ticketGuidlines && (
        <Attention onClick={() => handleClickPolicy(ticketGuidlines)}>
          <Typography
            style={{
              fontWeight: 'bold',
              textAlign: 'center',
              cursor: 'pointer',
            }}
          >
            {ticketGuidlines.Headline}
          </Typography>
        </Attention>
      )}
      <Contain fillHeight hasPadding>
        <div id="breadcrumbs-root-container">
          <CheckoutBreadcrumbs
            stage={stage}
            stageList={stageList}
            onClickCrumb={(stage) => handleClickBreadcrumb(stage)}
          />
        </div>
        {type === 'i' && (
          <TimerContainerStyled>
            <Timer
                onTimerEnd={handleExpiredTimer}
                maximumTime={maximumTimeOnClock}
                remainingTime={remainingTimeOnClock}
                // TODO: OutletTheme can be undefined for new outlets. Updating themes and saving will
                //  create the property for the outlet. Need to update backend outlet create method to
                //  initialize default theme values for the outlet.
                //  https://grokworks.atlassian.net/browse/RLMTS-3706
                progressColor={outlet?.OutletTheme?.PRIMARY_LINK_COLOR}
            />
          </TimerContainerStyled>
        )}
        <Columns qty={2}>
          <LeftColumnStyled>
            <Typography tag="h1" style={{ marginBottom: '2rem' }}>
              Current Request
            </Typography>

            {type === 'g' && (
              <div>
                <div style={{ margin: '0 0 1rem 0' }}>
                  <Typography
                    tag="a"
                    onClick={() =>
                      setOutletEventDetailsVisible(!outletEventDetailsVisible)
                    }
                  >
                    {outletEventDetailsVisible ?
                      '- Less Details' :
                      '+ More Details'}
                  </Typography>
                </div>
                <GuestListRequestItemsDetails
                    priceLevels={priceLevels}
                    deliveryMethod={deliveryMethod}
                    expandDetails={outletEventDetailsVisible}
                    guestListInvitation={guestListInvitation}
                    guestListOutletEvents={guestListOutletEvents}
                    guestListRequestGrandTotal={guestListRequestGrandTotal}
                />
              </div>
            )}
            {type === 'i' && (
              <>
                <OutletEventSummary outletEvent={outletEvent} />
                <EditRequestRiboon>
                  <div>Your Request</div>
                  <div>
                    <Typography
                      tag="a"
                      style={{ color: 'white' }}
                      href={'/event/' + outletEvent.OutletEventID}
                      onClick={(e) => {
                        e.preventDefault();
                        handleClickChangeSeats();
                      }}
                    >
                      Cancel
                    </Typography>
                  </div>
                </EditRequestRiboon>
                <LineItemSummary outletEvent={outletEvent} cart={cart} />
              </>
            )}
            {type === 'f' && (
              <div>
                <OutletEventSummary outletEvent={outletEvent} />
                <EditRequestRiboon>
                  <div>Your Request</div>
                  <div>
                    <Typography
                      tag="a"
                      style={{ color: 'white' }}
                      // href={"/event/" + outletEvent.OutletEventID}
                      onClick={(e) => {
                        e.preventDefault();
                        handleClickEditFanRequestLineItems({
                          title: 'Edit Line Items',
                          outletEventItems: outletEvent.OutletEventItems,
                        });
                        // setEditLineItemsIsVisible(true);
                        // goToUrl("/event/" + outletEvent.OutletEventID);
                      }}
                    >
                      Edit
                    </Typography>
                  </div>
                </EditRequestRiboon>
                {lineItemsNormalizedByOEI.length ?
                  lineItemsNormalizedByOEI.map((oei, idx) => {
                    return (
                      <div key={idx}>
                        <OutletEventItemHeader
                          oei={oei}
                          onClickEdit={() => {
                            const title =
                              'Edit ' + oei.OutletEventItemName || oei.Name;
                            handleClickEditFanRequestLineItems({
                              title,
                              outletEventItems: [oei],
                            });
                          }}
                        />

                        {oei.lineItems && Array.isArray(oei.lineItems) ?
                          oei.lineItems.map((lineItem, idx2) => {
                            if (lineItem.isAddOn) {
                              const quantity =
                                lineItem.quantity || lineItem.Quantity || 0;

                              const label =
                                lineItem.PriceLevelName +
                                ' ' +
                                formatCurrency(lineItem.PriceLevelValue) +
                                ' x ' +
                                quantity;
                              return (
                                <div key={idx2}>
                                  <LineItemRow
                                    hasLeftBorder
                                    hasRightBorder
                                    label={label}
                                    showTotal={true}
                                    total={
                                      lineItem.PriceLevelValue * quantity
                                    }
                                  />
                                   <DisplayFees
                                       FeeGroups={lineItem?.FeeGroups}
                                       quantity={quantity}
                                       hasLeftBorder={true}
                                       hasRightBorder={true} />
                                </div>
                              );
                            }
                            return (
                              <LineItemPreferenceList
                                key={idx2}
                                selectedQty={
                                  lineItem.quantity || lineItem.Quantity
                                }
                                outletEventItem={oei}
                                selectedPriceLevelId={lineItem.PriceLevelID}
                                optOutIsSelected={Boolean(
                                    lineItem.PriceLevelOptOut,
                                )}
                                onlyPreferenceIsVisible={false}
                              />
                            );
                          }) :
                          null}
                      </div>
                    );
                  }) :
                  null}
                <CheckoutTotalsSummaryFanRequest
                  cart={cart}
                  outletEvent={outletEvent}
                  seriesSummary={fanRequest.seriesSummary}
                />
              </div>
            )}
          </LeftColumnStyled>

          <RightColumnStyled>
            {type === 'f' ?
                <FanRequestCheckoutHeader
                    stage={stage}
                    stageList={stageList}
                    isMobileDelivery={fanRequestIsMobileDelivery}/> :
                stage !== 'login' &&
                stage !== 'payment-method' &&
                stage !== 'confirm' ? (
                    <Typography tag="h1" style={{ marginBottom: '2rem' }}>
                      {currentStage.header}
                    </Typography>
                ) : null}

            {stage === 'login' && (
              <AuthContainer
                view="checkout"
                onLogin={() => handleSuccessfullAuthentication()}
                onCreateAccount={() => handleSuccessfullAuthentication()}
              />
            )}

            {stage === 'review' && (
              <>
                <SeatAssignmentSummary outletEvent={outletEvent} cart={cart} />{' '}
                <Button
                  fullWidth
                  loading={loading}
                  style={{ margin: '0 0 4rem 0' }}
                  onClick={handleClickConfirmSeatAssignment}
                  id="save-realtime-seat-assignment-btn"
                >
                  Continue
                </Button>
                <div style={{ textAlign: 'center' }}>
                  <Typography
                    tag="a"
                    onClick={handleClickChangeSeats}
                    id="cancel-realtime-seat-assignment-btn"
                  >
                    Change Selection
                  </Typography>
                </div>
                {' '}
              </>
            )}
            {stage === 'addons' && (
              <>
                <CheckoutAddons
                  // expandedOutletEventItems={expandedOutletEventItems}
                  cart={cart}
                  selectedPriceLevels={cart.lineItems.filter(
                      (li) => li.isAddOn !== false,
                  )}
                  outletEventItems={outletEventItemsWithAddons}
                  handleAddOnQtyChange={handleAddOnQtyChange}
                  modal={modal}
                  displayFees={type === 'f'}
                />
                <Button
                  fullWidth
                  loading={loading}
                  style={{ margin: '2rem 0' }}
                  onClick={handleClickSaveAddons}
                  id="save-realtime-addon-btn"
                >
                  Continue
                </Button>
              </>
            )}
            {stage === 'delivery' && (
              <>
                {type === 'g' ?
                  <GuestListCheckoutDeliveryInfo
                    cart={cart}
                    loading={loading}
                    guestListInvitation={guestListInvitation}
                    defaultDeliveryMethod={deliveryMethod}
                    defaultAddress={shippingAddress}
                    userAddressList={userAddressListSorted}
                    deliveryMethodList={guestListInvitationDeliveryMethodList}
                    onSubmit={(p) => handleOnSubmitGuestListDeliveryInfo(p)}
                    outletEvents={guestListOutletEvents}
                    outlet={outlet}
                  // error={cartError}
                  /> :
                  <CheckoutDeliveryMethods
                    loading={loading}
                    deliveryMethod={deliveryMethod}
                    deliveryMethods={filteredDeliveryMethods}
                    onSubmit={(p) => handleOnSubmitDeliveryMethod(p)}
                  // error={cartError}
                  />
                }
              </>
            )}

            {stage === 'contact-info' && (
              <>
                <CheckoutContactEmails
                  cart={cart}
                  showNote={true}
                  showPhone={true}
                  showConnectionToArtist={true}
                  onSubmit={handleOnSubmitContactInfo}
                  guestListInvitation={guestListInvitation}
                />
              </>
            )}

            {stage === 'shipping-address' && (
              <>
                {type === 'f' && fanRequestIsMobileDelivery &&
                    (<FanRequestMobileDeliveryDetails
                      fanRequest={fanRequest}
                      isShippingHeaderVisible={true}
                      mobileDeliveryMethod={mobileDeliveryMethod}/>)}
                <AddressForm
                  loading={loading}
                  // error={cartError}
                  buttonText="Continue"
                  defaultAddress={shippingAddress}
                  addresses={userAddressListSorted}
                  onSubmit={handleOnSubmitShippingAddress}
                  showConnectionToArtist={type === 'g'}
                  showNote={type === 'g'}
                />
              </>
            )}
            {stage === 'payment-method' && (
              <>
                {type === 'g' ?
                    <GuestListRequestShippingDetails
                        request={cart}
                        userSession={session}
                        shippingAddress={shippingAddress}
                        onClickHeader={() => goToUrl('/checkout/g/delivery')}
                    />
                    :
                    <>
                      {type === 'f' && fanRequestIsMobileDelivery &&
                          (<FanRequestMobileDeliveryDetails
                              fanRequest={fanRequest}
                              mobileDeliveryMethod={mobileDeliveryMethod}/>)}
                      <DisplayHeader
                          btnText="Edit"
                          btnName="btn-shipping"
                          title="Shipping Address"
                          onClickBtn={() => goToUrl('/checkout/' + type + '/shipping-address')}
                      />
                      <DisplayShippingAddress address={shippingAddress}/>
                    </>
                }


                <Typography tag="h1" style={{ margin: '2rem 0' }}>
                  {currentStage.header}
                </Typography>
                {paymentPlanIsAvailable ? (
                    <PaymentPlanEnrollment
                        userIsEnrolledInPaymentPlan={userIsEnrolledInPaymentPlan}
                        onChangeCheckboxEnrollment={
                          handleOnChangePaymentPlanEnrollment
                        }
                    />
                ) : null}
                {userIsEnrolledInPaymentPlan ? (
                  <>
                    <PaymentPlanForm
                      paymentPlanRules={paymentPlanRules}
                      total={(seriesSummary && seriesSummary.Totals.total) || 0}
                      userPaymentMethodList={userPaymentMethodList}
                      paymentList={paymentList}
                      onChangePaymentMethod={(pm) =>
                        handleOnSelectPaymentMethod(pm)
                      }
                      onCreateViaIFrame={
                        handleOnCreateNewPaymentMethodViaIFrame
                      }
                      outletEventGroup={
                        outletEvent && outletEvent.OutletEventGroup
                      }
                    />
                    <Button
                      fullWidth
                      loading={loading}
                      disabled={
                        !paymentList || paymentList.length < 3 || loading
                      }
                      onClick={handleClickContinueFromPaymentMethod}
                    >
                      Continue
                    </Button>
                  </>
                ) : (
                  <>
                    <PaymentMethodForm
                      defaultMethod={paymentMethod}
                      paymentMethods={userPaymentMethodList}
                      onSelect={(pm) => handleOnSelectPaymentMethod(pm)}
                      onCreateViaIFrame={
                        handleOnCreateNewPaymentMethodViaIFrame
                      }
                    />
                    <Button
                      fullWidth
                      loading={loading}
                      disabled={!paymentMethod || loading}
                      onClick={handleClickContinueFromPaymentMethod}
                    >
                      Continue
                    </Button>
                  </>
                )}
              </>
            )}

            {stage === 'confirm' && (
              <>
                <div style={{ margin: '0 0 2rem 0' }}>
                  {type === 'g' ?
                      <GuestListRequestShippingDetails
                          request={cart}
                          userSession={session}
                          shippingAddress={shippingAddress}
                          onClickHeader={() => goToUrl('/checkout/g/delivery')}
                      />
                      :
                      <>
                        {type === 'f' && fanRequestIsMobileDelivery &&
                            (<FanRequestMobileDeliveryDetails
                                fanRequest={fanRequest}
                                mobileDeliveryMethod={mobileDeliveryMethod}/>)}
                        <DisplayHeader
                            btnText="Edit"
                            btnName="btn-shipping"
                            title="Shipping Address"
                            onClickBtn={() => goToUrl('/checkout/' + type + '/shipping-address')}
                        />
                        <DisplayShippingAddress address={shippingAddress}/>
                      </>
                  }
                </div>
                {paymentCollectionIsRequired && (
                  <div style={{ margin: '0 0 2rem 0' }}>
                    <DisplayHeader
                      btnText="Edit"
                      title="Payment Details"
                      btnName="btn-shipping"
                      onClickBtn={() =>
                        goToUrl('/checkout/' + type + '/payment-method')
                      }
                    />
                    {userIsEnrolledInPaymentPlan && paymentList ? (
                      paymentList.map((p, pidx) => {
                        let processDate;
                        if (outletEvent && outletEvent.OutletEventGroup) {
                          if (pidx === 0) {
                            processDate =
                              outletEvent.OutletEventGroup.PaymentPlanInstallment1Date;
                          }
                          if (pidx === 1) {
                            processDate =
                              outletEvent.OutletEventGroup.PaymentPlanInstallment2Date;
                          }
                          if (pidx === 2) {
                            processDate =
                              outletEvent.OutletEventGroup.PaymentPlanInstallment3Date;
                          }
                        }
                        let processText =
                          pidx === 0 ?
                            'Process on or around ' :
                            'Processes on ';
                        if (processDate) {
                          processText =
                            processText +
                            formatDate(processDate, {
                              timeZone: 'UTC',
                            });
                        }

                        return (
                          <div style={{ margin: '0 0 1.5rem 0' }} key={pidx}>
                            <div style={{ fontWeight: 700 }}>
                              Installment {pidx + 1}-{' '}
                              {processDate && processText}
                            </div>
                            <DisplayPaymentMethod paymentMethod={p} />
                          </div>
                        );
                      })
                    ) : (
                      <DisplayPaymentMethod paymentMethod={paymentMethod} />
                    )}
                  </div>
                )}

                <div style={{ margin: '0 0 2rem 0' }}>
                  <DisplayHeader title="Terms &amp; Conditions" />
                  <ActionBtnContainerStyled>
                    <div
                      style={{
                        display: 'flex',
                      }}
                    >
                      <CheckboxInput
                        name="userAccepts"
                        checked={userAcceptsTermsOfService}
                        onChange={handleOnChangeAcceptsTermsOfService}
                      />
                      <div
                        style={{
                          flex: 1,
                          padding: '0 0 0 .5rem',
                        }}
                      >
                        <div>
                          I accept the{' '}
                          {outletTermsAndConditions &&
                            outletTermsAndConditions.Headline ? (
                            <Typography
                              tag="a"
                              onClick={() =>
                                handleClickPolicy(outletTermsAndConditions)
                              }
                            >
                              {outletTermsAndConditions.Headline}
                            </Typography>
                          ) : (
                            ' terms and conditions'
                          )}{' '}
                          and{' '}
                          {outletPrivacyPolicy &&
                            outletPrivacyPolicy.Headline ? (
                            <Typography
                              tag="a"
                              onClick={() =>
                                handleClickPolicy(outletPrivacyPolicy)
                              }
                            >
                              {outletPrivacyPolicy.Headline}
                            </Typography>
                          ) : (
                            ' privacy policy'
                          )}
                          {ticketGuidlines && (
                            <span>
                              {' '}
                              and{' '}
                              {
                                <Typography
                                  tag="a"
                                  onClick={() =>
                                    handleClickPolicy(ticketGuidlines)
                                  }
                                >
                                  {ticketGuidlines.Headline ||
                                    'Ticket Guidlines'}
                                </Typography>
                              }
                            </span>
                          )}
                        </div>
                      </div>
                    </div>

                    <Button
                      fullWidth
                      name="btn-complete"
                      loading={loading}
                      id="complete-order-request"
                      // id="place-realtime-order-btn"
                      onClick={handleClickCompleteRequest}
                      disabled={!userAcceptsTermsOfService || loading}
                    >
                      {type === 'i' ? 'Place Order' : 'Submit Request'}
                    </Button>
                  </ActionBtnContainerStyled>
                </div>
              </>
            )}
          </RightColumnStyled>
        </Columns>
      </Contain>

      <Modal
        title={modal.title}
        open={modal.isVisible}
        onClose={handleClickCloseModal}
      >
        {modal.constant === 'EDIT_REQUEST_LINE_ITEMS' && (
          <div>
            <EditRequestLineItems
              loading={fanRequest.loading}
              outletEventItems={modal.content && modal.content.outletEventItems}
              defaultUserSelectedPriceLevels={fanRequest.cart.lineItems}
              checkoutSelectedPriceLevels={selectedAddons}
              // checkoutOutletEventItemsState={}//todo
              onClickCancel={() => {
                setFanRequestError(null);
                handleClickCloseModal();
              }}
              onClickSave={(lineItems) => {
                setFanRequestError(null);
                updateFanRequest({
                  outletEvent,
                  // action: "EDIT_REQUES_LINE_ITEMS",
                  action: 'CREATE_FAN_REQUEST_WITH_SERIES_SUMMARY',
                  cart: {
                    lineItems: lineItems.filter((x) => Number(x.Quantity) > 0),
                  },
                }).then((res) => {
                  setModal({ ...modal, isVisible: false });
                });

                //   editExistingFanRequest({
                //     LotteryOEIRequests,
                //     LotteryOERequestUUID: request.LotteryOERequestUUID,
                //   }).then((res) => {
                //     getUserFanRequestSeriesSummary();

                //   });

                // setModal({ ...modal, isVisible: false })
              }}
            >
              {fanRequest && fanRequest.error && (
                <DisplayError>{fanRequest.error}</DisplayError>
              )}
            </EditRequestLineItems>
          </div>
        )}
        {modal.constant === 'POLICY' && (
          <div
            dangerouslySetInnerHTML={{
              __html: modal.content,
            }}
          />
        )}
        {modal.constant === 'INSTANT_CART_TIMER_EXPIRED' && (
          <div>
            <Typography
              style={{ margin: '1rem 0 3rem 0', textAlign: 'center' }}
            >
              The transaction time has expired. <br />
              Please restart your ticket search.
            </Typography>
            <Button
              fullWidth
              loading={loading}
              onClick={handleClickDeleteInstantCart}
              id="expired-cart-search-again-btn"
            >
              Search Again
            </Button>
          </div>
        )}

        {modal.constant === 'CANCEL_INSTANT_CART' && (
          <div>
            {error && (
              <Alert style={{ margin: '2rem 0' }} s>
                {error}
              </Alert>
            )}
            <Button
              fullWidth
              loading={loading}
              onClick={handleClickCloseModal}
              style={{ margin: '2rem 0 4rem 0' }}
              id="cancel-cart-keep-going-btn"
            >
              Proceed to Checkout
            </Button>
            <div style={{ textAlign: 'center' }}>
              <Typography
                tag="a"
                loading={loading}
                onClick={handleClickDeleteInstantCart}
                id="cancel-cart-confirm-btn"
              >
                Yes, release my tickets and start over
              </Typography>
            </div>
            {' '}
          </div>
        )}
      </Modal>
    </div>
  );
}

export default CheckoutView;
