import styled from 'styled-components';
import React, { useState } from 'react';

import Button from '../../utils/Button';
import Collapse from '../../utils/Collapse';
import PriceLevelSelectorFanRequest from '../priceLevel/PriceLevelSelectorFanRequest';
import Typography from '../../utils/Typography';

const ErrorTextStyled = styled.div`
  font-size: 1.4rem;
  margin: 0;
  padding: 0.5rem 0rem;
  font-style: italic;
  color: ${(props) => props.theme.ERROR_TEXT_COLOR};
`;

function EditRequestLineItems(props = {}) {
  console.log('EditRequestLineItems props', props);
  const {
    loading,

    children,

    outletEventItems,

    onClickSave,
    onClickCancel,

    // userFanRequest,
    // userFanRequestSeriesSummary,

    defaultUserSelectedPriceLevels,
    checkoutSelectedPriceLevels,
  } = props;

  const [selectedPriceLevels, setSelectedPriceLevels] = useState(
      defaultUserSelectedPriceLevels || [],
  );

  const handleOnChangePriceLevel = (
      formdata = {},
      outletEventItem,
      existingLotteryOEIRequest,
  ) => {
    console.log(
        'EditRequestLineItems handleOnChangePriceLevel  formdata ',
        formdata,
    );
    console.log(
        'EditRequestLineItems handleOnChangePriceLevel  outletEventItem ',
        outletEventItem,
    );
    console.log(
        'EditRequestLineItems handleOnChangePriceLevel  existingLotteryOEIRequest ',
        existingLotteryOEIRequest,
    );
    const OutletEventItemID =
        outletEventItem && parseInt(outletEventItem.OutletEventItemID, 10);
    const LotteryOEIRequestUUID =
        existingLotteryOEIRequest &&
        existingLotteryOEIRequest.LotteryOEIRequestUUID;
    const Quantity = parseInt(formdata.selectedQty, 10);

    if (OutletEventItemID) {
      const selectedPriceLevelsUpdated = [...selectedPriceLevels];
      const index = selectedPriceLevels.findIndex(
          (item) => item.OutletEventItemID === OutletEventItemID,
      );

      const requestedItem = {
        Quantity,
        OutletEventItemID,
        LotteryOEIRequestUUID,
        PriceLevelID: parseInt(Number(formdata.selectedPriceLevelId), 10),
        PriceLevelOptOut: formdata.optOutIsSelected,
        //isAddOn: false,
      };
      if (index > -1) {
        // if(!Quantity || Number(Quantity)<1){
        //     selectedPriceLevelsUpdated.splice(index);
        // }else{

        // }

        selectedPriceLevelsUpdated[index] = requestedItem;
      } else {
        selectedPriceLevelsUpdated.push(requestedItem);
      }
      setSelectedPriceLevels(selectedPriceLevelsUpdated);
    }
  };

  const handleOnSubmit = () => {
    onClickSave && onClickSave(selectedPriceLevels);

    return false;
  };

  //const api = useSelector((state) => state.api));
  //const orderIsLoading = api && api.loading.includes("GET_USER_ORDER");
  //if (!request || !seriesSummary) return null;
  console.log('EditRequestLineItems selectedPriceLevels', selectedPriceLevels);
  console.log('EditRequestLineItems selectedPriceLevels', selectedPriceLevels);
  let continueButtonIsDisabled = false;
  //   if (selectedPriceLevels.length) {
  //     const preferredPriceLevelIsSelectedOnAllItems = selectedPriceLevels.every(
  //       (l) => l.PriceLevelID && l.Quantity && Number(l.Quantity) > 0
  //     );
  //     if (preferredPriceLevelIsSelectedOnAllItems) {
  //       continueButtonIsDisabled = false;
  //     }
  //     console.log(
  //       "EditRequestLineItems preferredPriceLevelIsSelectedOnAllItems",
  //       preferredPriceLevelIsSelectedOnAllItems
  //     );
  //   }

  if (Array.isArray(outletEventItems) && outletEventItems.length) {
    return (
        <div>
          {outletEventItems.map((oei, idx) => {
            console.log('EditRequestLineItems oei', oei);
            let priceLevelsAreAvailable = false;
            let defaultSelectedPriceLevel;

            // const EventItemTypeID =
            //   oei.EventItem && oei.EventItem.EventItemTypeID;

            // const outletEventItemIsATicket =
            //   EventItemTypeID && EventItemTypeID === 1;
            //if there is a currently selected pricelevel show OEI regardless of valid series or Price level rules
            if (
                selectedPriceLevels &&
                Array.isArray(selectedPriceLevels) &&
                Array.isArray(oei.PriceLevels) &&
                oei.PriceLevels.length
            ) {
              selectedPriceLevels.map((spl) => {
                if (spl.OutletEventItemID == oei.OutletEventItemID) {
                  priceLevelsAreAvailable = true;
                  defaultSelectedPriceLevel = spl;
                }
                return null;
              });
            }
            if (
                oei.PriceLevels &&
                oei.PriceLevels.length &&
                priceLevelsAreAvailable === false
            ) {
              oei.PriceLevels.map((priceLevel) => {
                if (priceLevel.SaleStatus !== 'OFF_SALE') {
                  priceLevelsAreAvailable = true;
                }
                return null;
              });
            }
            if (checkoutSelectedPriceLevels &&
                Array.isArray(selectedPriceLevels) &&
                Array.isArray(oei.PriceLevels) &&
                oei.PriceLevels.length) {
              checkoutSelectedPriceLevels.forEach((cslp) => {
                if (cslp.OutletEventItemID == oei.OutletEventItemID) {
                  priceLevelsAreAvailable = true;
                  defaultSelectedPriceLevel = cslp;
                }
              });
            }

            return (
                <div key={idx} style={{ margin: '2rem 0' }}>
                  <Collapse
                      heading={oei.OutletEventItemName || oei.Name}
                      subHeading={oei.OutletEventItemDescription || oei.Description}
                  >
                    {priceLevelsAreAvailable ? (
                        <div>
                          <PriceLevelSelectorFanRequest
                              lotteryOEIRequest={defaultSelectedPriceLevel}
                              outletEventItem={oei}
                              onChangeCallback={(formData) =>
                                  handleOnChangePriceLevel(
                                      formData,
                                      oei,
                                      defaultSelectedPriceLevel,
                                  )
                              }
                          />
                        </div>
                    ) : (
                        <ErrorTextStyled>Currently unavailable</ErrorTextStyled>
                    )}
                  </Collapse>
                </div>
            );
          })}

          {children && <div>{children}</div>}

          <Button
              disabled={continueButtonIsDisabled || loading}
              onClick={() => handleOnSubmit()}
              fullWidth
              loading={loading}
          >
            Save
          </Button>

          <Typography
              style={{
                display: 'block',
                textAlign: 'center',
                margin: ' 3rem 0',
              }}
              tag="a"
              onClick={() => (loading ? false : onClickCancel())}
              disabled={loading}
          >
            Cancel
          </Typography>
        </div>
    );
  }

  return <div>No items available</div>;
}

export default EditRequestLineItems;
