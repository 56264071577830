import PropTypes from 'prop-types';
import React from 'react';
import { LineItemRow } from '../cart/LineItemSummary';
import {
  StyledOrderTotalsSummaryBlock,
  StyledOrderTotalsSummaryContainer,
  StyledOrderTotalsSummaryContent,
} from './shared-styled-components';


function OrderTotalSummary(props = {}) {
  const { order } = props;

  if (!order || !Object.keys(order).length) {
    return null;
  }

  const { TotalSummary, OrderSummary } = order;

  return (
    <StyledOrderTotalsSummaryContainer>
      <StyledOrderTotalsSummaryContent>
        <LineItemRow
          bold
          label="Fees Total:"
          total={TotalSummary?.fees || 0}
        />
        {OrderSummary?.PaymentPlan?.PaymentPlanEnrolled && (
          <LineItemRow
            bold
            total={TotalSummary?.ppFee || 0}
            label={'Payment Plan Fee:'}
          />
        )}
        {Number(TotalSummary?.taxes) > 0 ? (
          <LineItemRow
            bold
            total={TotalSummary.taxes}
            label={'Taxes:'}
          />
        ) : null}

        <LineItemRow
          bold
          total={TotalSummary?.shipping || 0}
          label={'Shipping:'}
        />

        <LineItemRow
          bold
          label="Grand Total:"
          total={TotalSummary?.total || 0}
        />

        <StyledOrderTotalsSummaryBlock>
          <LineItemRow
            bold
            label="Paid:"
            total={(TotalSummary?.UserPaid?.total) || 0}
          />
          <LineItemRow
            bold
            label="Remaining Balance:"
            total={(TotalSummary?.UserDue?.total) || 0}
          />
        </StyledOrderTotalsSummaryBlock>
      </StyledOrderTotalsSummaryContent>
    </StyledOrderTotalsSummaryContainer>
  );
}

OrderTotalSummary.defaultProps = {
  loading: false,
  order: null,
};

OrderTotalSummary.propTypes = {
  loading: PropTypes.bool,
  order: PropTypes.object,
};
export default OrderTotalSummary;
