import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import Typography from '../../utils/Typography';

const RootStyled = styled.div`
  max-width: 600px;
  padding: 4rem 2rem;
  margin: 0 auto;
  text-align: center;
`;

const ContactUsLinkStyled = styled.a``;

export const OutletMaintenancePage = ({ ...props }) => {
  const outlet = useSelector((state) => state.outlet);
  const userSession = useSelector((state) => state.session);

  let message =
      (userSession &&
          userSession.maintenance &&
          userSession.maintenance.message) ||
      'Sorry for the inconvenience but we are performing some maintenance';

  return (
      <RootStyled>
        <Typography tag="h1" style={{ margin: '0 0 2rem 0' }}>
          We'll be back soon!
        </Typography>
        <Typography style={{ margin: '0 0 1rem 0' }}>{message}</Typography>
        {outlet && outlet.ContactUsEmail && (
            <Typography style={{ margin: '0 0 1rem 0' }}>
              If you need to you can always{' '}
              <Typography
                  tag="a"
                  onClick={() =>
                      (window.location.href = 'mailto:' + outlet.ContactUsEmail)
                  }
              >
                contact us
              </Typography>
              , otherwise we will be back online shortly
            </Typography>
        )}
        {outlet && outlet.OutletName && (
            <Typography>-- {outlet.OutletName} Team</Typography>
        )}
      </RootStyled>
  );
};
export default OutletMaintenancePage;
