import PropTypes from 'prop-types';
import React from 'react';
//import styled from "styled-components";

// const ContentBlockRoot = styled.div`
//   margin: 0 0 ${props => (props.hasMarginBottom ? "0.5rem" : "0")} 0;
// `;

export const SvgIcon = ({
                          title,
                          fill,
                          color,
                          width,
                          height,
                          verticalAlign,
                          strokeWidth,
                          children,
                        }) => {
  //console.log("SvgIcon fill = ", fill);
  return (
      <svg
          xmlns="http://www.w3.org/2000/svg"
          // width={width}
          // height={height}
          // viewBox={"0 0 " + width + " " + height}
          width="24"
          height="24"
          viewBox={'0 0 24 24'}
          fill={fill}
          stroke={color}
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
          aria-labelledby="title"
          style={{ width: width, height: height, verticalAlign: verticalAlign }}
      >
        <title id="title">{title}</title>
        {children}
      </svg>
  );
};

SvgIcon.defaultProps = {
  height: 24,
  width: 24,
  verticalAlign: 'middle',
  fill: 'none',
  title: 'icon',
  color: 'currentColor',
  strokeWidth: 2,
};

SvgIcon.propTypes = {
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fill: PropTypes.string,
  color: PropTypes.string,
  title: PropTypes.string,
  strokeWidth: PropTypes.number,
};

export default SvgIcon;

export const IconPlusCircle = (props) => {
  return (
      <SvgIcon {...props}>
        <circle cx="12" cy="12" r="10"></circle>
        <line x1="12" y1="8" x2="12" y2="16"></line>
        <line x1="8" y1="12" x2="16" y2="12"></line>
      </SvgIcon>
  );
};

export const IconSave = (props) => {
  return (
      <SvgIcon {...props}>
        <path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z"></path>
        <polyline points="17 21 17 13 7 13 7 21"></polyline>
        <polyline points="7 3 7 8 15 8"></polyline>
      </SvgIcon>
  );
};

export const IconDelete = (props) => {
  return (
      <SvgIcon {...props}>
        <path d="M21 4H8l-7 8 7 8h13a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2z"></path>
        <line x1="18" y1="9" x2="12" y2="15"></line>
        <line x1="12" y1="9" x2="18" y2="15"></line>
      </SvgIcon>
  );
};

export const IconCheck = (props) => {
  return (
      <SvgIcon {...props}>
        <polyline points="20 6 9 17 4 12"></polyline>
      </SvgIcon>
  );
};

export const IconInfo = (props) => {
  return (
      <SvgIcon {...props}>
        <circle cx="12" cy="12" r="10"></circle>
        <line x1="12" y1="16" x2="12" y2="12"></line>
        <line x1="12" y1="8" x2="12.01" y2="8"></line>
      </SvgIcon>
  );
};

export const IconHash = (props) => {
  return (
      <SvgIcon {...props}>
        <line x1="4" y1="9" x2="20" y2="9"></line>
        <line x1="4" y1="15" x2="20" y2="15"></line>
        <line x1="10" y1="3" x2="8" y2="21"></line>
        <line x1="16" y1="3" x2="14" y2="21"></line>
      </SvgIcon>
  );
};

export const IconClock = (props) => {
  return (
      <SvgIcon {...props}>
        <circle cx="12" cy="12" r="10"></circle>
        <polyline points="12 6 12 12 16 14"></polyline>
      </SvgIcon>
  );
};

export const IconHelpCircle = (props) => {
  return (
      <SvgIcon {...props}>
        <circle cx="12" cy="12" r="10"></circle>
        <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
        <line x1="12" y1="17" x2="12.01" y2="17"></line>
      </SvgIcon>
  );
};

export const IconPencil = (props) => {
  return (
      <SvgIcon {...props}>
        <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path>
      </SvgIcon>
  );
};

export const IconStar = (props) => {
  return (
      <SvgIcon {...props}>
        <polygon
            points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
      </SvgIcon>
  );
};

export const IconAlertTriangle = (props) => {
  return (
      <SvgIcon {...props}>
        <path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"></path>
        <line x1="12" y1="9" x2="12" y2="13"></line>
        <line x1="12" y1="17" x2="12" y2="17"></line>
      </SvgIcon>
  );
};

export const IconChevronDown = (props) => {
  return (
      <SvgIcon {...props}>
        <polyline points="6 9 12 15 18 9"/>
      </SvgIcon>
  );
};

export const IconChevronUp = (props) => {
  return (
      <SvgIcon {...props}>
        <polyline points="18 15 12 9 6 15"/>
      </SvgIcon>
  );
};

export const IconChevronLeft = (props) => {
  return (
      <SvgIcon {...props}>
        <polyline points="15 18 9 12 15 6"/>
      </SvgIcon>
  );
};

export const IconChevronRight = (props) => {
  return (
      <SvgIcon {...props}>
        <polyline points="9 18 15 12 9 6"/>
      </SvgIcon>
  );
};

export const IconFacebook = (props) => {
  return (
      <SvgIcon {...props}>
        <path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"/>
      </SvgIcon>
  );
};
export const IconTwitter = (props) => {
  return (
      <SvgIcon {...props}>
        <path
            d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z"/>
      </SvgIcon>
  );
};
export const IconInstagram = (props) => {
  return (
      <SvgIcon {...props}>
        <rect x="2" y="2" width="20" height="20" rx="5" ry="5"/>
        <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"/>
        <line x1="17.5" y1="6.5" x2="17.5" y2="6.5"/>
      </SvgIcon>
  );
};

export const IconCopy = (props) => {
  return (
      <SvgIcon {...props}>
        <rect x="9" y="9" width="13" height="13" rx="2" ry="2"/>
        <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"/>
      </SvgIcon>
  );
};

export const IconSlash = (props) => {
  return (
      <SvgIcon {...props}>
        <circle cx="12" cy="12" r="10"/>
        <line x1="4.93" y1="4.93" x2="19.07" y2="19.07"/>
      </SvgIcon>
  );
};

export const IconSend = (props) => {
  return (
      <SvgIcon {...props}>
        <line x1="22" y1="2" x2="11" y2="13"/>
        <polygon points="22 2 15 22 11 13 2 9 22 2"/>
      </SvgIcon>
  );
};

export const IconClipboard = (props) => {
  return (
      <SvgIcon {...props}>
        <path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"/>
        <rect x="8" y="2" width="8" height="4" rx="1" ry="1"/>
      </SvgIcon>
  );
};

export const IconCalendar = (props) => {
  return (
      <SvgIcon {...props}>
        <rect x="3" y="4" width="18" height="18" rx="2" ry="2"/>
        <line x1="16" y1="2" x2="16" y2="6"/>
        <line x1="8" y1="2" x2="8" y2="6"/>
        <line x1="3" y1="10" x2="21" y2="10"/>
      </SvgIcon>
  );
};

export const IconMapPin = (props) => {
  return (
      <SvgIcon {...props}>
        <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"/>
        <circle cx="12" cy="10" r="3"/>
      </SvgIcon>
  );
};

export const IconExternalLink = (props) => {
  return (
      <SvgIcon {...props}>
        <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"/>
        <polyline points="15 3 21 3 21 9"/>
        <line x1="10" y1="14" x2="21" y2="3"/>
      </SvgIcon>
  );
};

export const IconMaximize = (props) => {
  return (
      <SvgIcon {...props}>
        <path d="M8 3H5a2 2 0 0 0-2 2v3m18 0V5a2 2 0 0 0-2-2h-3m0 18h3a2 2 0 0 0 2-2v-3M3 16v3a2 2 0 0 0 2 2h3"/>
      </SvgIcon>
  );
};

export const IconPlus = (props) => {
  return (
      <SvgIcon {...props}>
        <line x1="12" y1="5" x2="12" y2="19"/>
        <line x1="5" y1="12" x2="19" y2="12"/>
      </SvgIcon>
  );
};

export const IconMinus = (props) => {
  return (
      <SvgIcon {...props}>
        <line x1="5" y1="12" x2="19" y2="12"/>
      </SvgIcon>
  );
};

export const IconClose = (props) => {
  return (
      <SvgIcon {...props}>
        <line x1="18" y1="6" x2="6" y2="18"/>
        <line x1="6" y1="6" x2="18" y2="18"/>
      </SvgIcon>
  );
};

export const IconMenu = (props) => {
  return (
      <SvgIcon {...props}>
        <line x1="3" y1="12" x2="21" y2="12"/>
        <line x1="3" y1="6" x2="21" y2="6"/>
        <line x1="3" y1="18" x2="21" y2="18"/>
      </SvgIcon>
  );
};

export const IconMoreVertical = (props) => {
  return (
      <SvgIcon {...props}>
        <circle cx="12" cy="12" r="1"/>
        <circle cx="12" cy="5" r="1"/>
        <circle cx="12" cy="19" r="1"/>
      </SvgIcon>
  );
};

export const IconLoader = (props) => {
  return (
      <SvgIcon {...props}>
        <line x1="12" y1="2" x2="12" y2="6"/>
        <line x1="12" y1="18" x2="12" y2="22"/>
        <line x1="4.93" y1="4.93" x2="7.76" y2="7.76"/>
        <line x1="16.24" y1="16.24" x2="19.07" y2="19.07"/>
        <line x1="2" y1="12" x2="6" y2="12"/>
        <line x1="18" y1="12" x2="22" y2="12"/>
        <line x1="4.93" y1="19.07" x2="7.76" y2="16.24"/>
        <line x1="16.24" y1="7.76" x2="19.07" y2="4.93"/>
      </SvgIcon>
  );
};

export const IconSpinner = (props) => {
  return (
      <SvgIcon width="54" height="54">
        <g fill="none" fill-rule="evenodd">
          <g transform="translate(2 1)" stroke="#FF0000" stroke-width="1.5">
            <circle cx="42.601" cy="11.462" r="5" fill-opacity="1" fill="#FF0000">
              <animate
                  attributeName="fill-opacity"
                  begin="0s"
                  dur="1.3s"
                  values="1;0;0;0;0;0;0;0"
                  calcMode="linear"
                  repeatCount="indefinite"
              />
            </circle>
            <circle cx="49.063" cy="27.063" r="5" fill-opacity="0" fill="#FF0000">
              <animate
                  attributeName="fill-opacity"
                  begin="0s"
                  dur="1.3s"
                  values="0;1;0;0;0;0;0;0"
                  calcMode="linear"
                  repeatCount="indefinite"
              />
            </circle>
            <circle cx="42.601" cy="42.663" r="5" fill-opacity="0" fill="#FF0000">
              <animate
                  attributeName="fill-opacity"
                  begin="0s"
                  dur="1.3s"
                  values="0;0;1;0;0;0;0;0"
                  calcMode="linear"
                  repeatCount="indefinite"
              />
            </circle>
            <circle cx="27" cy="49.125" r="5" fill-opacity="0" fill="#FF0000">
              <animate
                  attributeName="fill-opacity"
                  begin="0s"
                  dur="1.3s"
                  values="0;0;0;1;0;0;0;0"
                  calcMode="linear"
                  repeatCount="indefinite"
              />
            </circle>
            <circle cx="11.399" cy="42.663" r="5" fill-opacity="0" fill="#FF0000">
              <animate
                  attributeName="fill-opacity"
                  begin="0s"
                  dur="1.3s"
                  values="0;0;0;0;1;0;0;0"
                  calcMode="linear"
                  repeatCount="indefinite"
              />
            </circle>
            <circle cx="4.938" cy="27.063" r="5" fill-opacity="0" fill="#FF0000">
              <animate
                  attributeName="fill-opacity"
                  begin="0s"
                  dur="1.3s"
                  values="0;0;0;0;0;1;0;0"
                  calcMode="linear"
                  repeatCount="indefinite"
              />
            </circle>
            <circle cx="11.399" cy="11.462" r="5" fill-opacity="0" fill="#FF0000">
              <animate
                  attributeName="fill-opacity"
                  begin="0s"
                  dur="1.3s"
                  values="0;0;0;0;0;0;1;0"
                  calcMode="linear"
                  repeatCount="indefinite"
              />
            </circle>
            <circle cx="27" cy="5" r="5" fill-opacity="0" fill="#FF0000">
              <animate
                  attributeName="fill-opacity"
                  begin="0s"
                  dur="1.3s"
                  values="0;0;0;0;0;0;0;1"
                  calcMode="linear"
                  repeatCount="indefinite"
              />
            </circle>
          </g>
        </g>
      </SvgIcon>
  );
};
