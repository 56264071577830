import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router';

import ForgotPassword from './ForgotPassword';
import Login from './Login';
import Typography from '../../utils/Typography';
import UserProfileCreate from '../user/UserProfileCreate';

class AuthContainer extends React.Component {
  state = {
    showForgotPassword: false,
    showCreateNewAccount: false,
  };

  handleShowForgotPassword = () =>
      this.setState({
        showForgotPassword: !this.state.showForgotPassword,
        showCreateNewAccount: false,
      });

  handleShowCreateNewAccount = (e) => {
    this.setState({
      showCreateNewAccount: !this.state.showCreateNewAccount,
      showForgotPassword: false,
    });
  };

  render() {
    //console.log("Auth Container props =>", this.props);

    const {
      allowForgotPassword,
      allowCreateAccount,
      onLogin,
      onForgotPassword,
      onCreateAccount,
      match,
      showFailedLoginAttempts,
      view,
    } = this.props;

    const { showForgotPassword, showCreateNewAccount } = this.state;

    const title = showForgotPassword
        ? 'Forgot Password'
        : showCreateNewAccount && !match.params.email
            ? 'Create Account'
            : 'Sign In';

    const header = (
        <Typography tag="h1" style={{ textAlign: 'center' }}>
          {title}
        </Typography>
    );

    if (showForgotPassword && allowForgotPassword) {
      return (
          <>
            {header}
            <div style={{ marginTop: '3rem' }}>
              <ForgotPassword
                  view={view}
                  onSuccess={onForgotPassword}
                  onCancel={this.handleShowForgotPassword}
              />
            </div>
          </>
      );
    }

    if (showCreateNewAccount && allowCreateAccount && !match.params.email) {
      return (
          <>
            {header}
            <div style={{ marginTop: '3rem' }}>
              <UserProfileCreate
                  view={view}
                  onSuccess={onCreateAccount}
                  onCancel={this.handleShowCreateNewAccount}
              />
            </div>
          </>
      );
    }

    return (
        <>
          {header}
          <div style={{ marginTop: '3rem' }}>
            <Login
                view={view}
                onLogin={onLogin ? onLogin : () => false}
                showFailedLoginAttempts={showFailedLoginAttempts}
            />
          </div>
          {allowForgotPassword && (
              <div style={{ margin: '3rem 0 0 0', textAlign: 'center' }}>
                <Typography
                    tag="a"
                    secondary
                    onClick={this.handleShowForgotPassword}
                    id="forgot-pw-link"
                >
                  Forgot Password
                </Typography>
              </div>
          )}
          {allowCreateAccount && (
              <div style={{ margin: '2rem 0 0 0', textAlign: 'center' }}>
                <Typography
                    tag="a"
                    onClick={this.handleShowCreateNewAccount}
                    id="create-account-link"
                    style={{ fontSize: '1.6rem' }}
                >
                  Create Account
                </Typography>
              </div>
          )}
        </>
    );
  }
}

AuthContainer.propTypes = {
  allowCreateAccount: PropTypes.bool,
  allowForgotPassword: PropTypes.bool,
  onLogin: PropTypes.func,
  onCreateAccount: PropTypes.func,
  onForgotPassword: PropTypes.func,
  view: PropTypes.string,
};

AuthContainer.defaultProps = {
  allowCreateAccount: true,
  allowForgotPassword: true,
  onLogin: null,
  onCreateAccount: null,
  onForgotPassword: null,
  view: '',
};

export default withRouter(AuthContainer);
