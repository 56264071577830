import React from 'react';
import styled from 'styled-components';

const LoaderStyled = styled.div`
  border: 10px solid #ddd; /* Light grey */
  border-top: 10px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1.5s linear infinite;
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
export const Loader = ({ loading, children, ...props }) => {
  return <LoaderStyled {...props}> </LoaderStyled>;
};
export default Loader;
