import PropTypes from 'prop-types';
import styled from 'styled-components';
import React, { Component } from 'react';

import LineItemPreferenceList from '../cart/LineItemPreferenceList';
import Typography from '../../utils/Typography';
import { CheckboxInput, SelectInput } from '../../utils/Form';
import { IconHelpCircle, IconMinus, IconPlus } from '../../utils/Icon';
import { formatCurrency } from '../../utils/utilities';
import { connect } from 'react-redux';

import { getPriceLevelLabel } from './PriceLevelSelectorDisplay.util';

const Root = styled.div`
  /* display: flex;
  padding: 1rem 0; */
  margin: 0;
  -webkit-user-select: none; /* Chrome/Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+ */
  user-select: none;
  outline-style: none;
`;

const QuantitySelectorContainerStyled = styled.div`
  padding: 2rem 1rem;
  text-align: center;
  border-bottom: solid 1px #eee;
`;
const SelectorContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
`;
const Selector = styled.div`
  color: ${(props) => props.theme.PRIMARY_LINK_COLOR};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  opacity: ${(props) => (props.disabled ? 0.3 : 1)};
`;
const QtyDisplay = styled.div`
  padding: 0.5rem 1rem;
  font-size: 2rem;
`;

const PreferenceContainerStyled = styled.span`
  padding: 2rem 1rem;
`;

// const PreferenceListStyled = styled.div`
//   margin: 2rem 0rem;
// `;

// const PreferenceStyled = styled.div`
//   border: solid 1px ${(props) => props.theme.columns.left.backgroundColor};
//   margin: 1rem 0 0 0;
//   text-decoration: ${(props) =>
//     props.optedOut ? "line-through!important" : "none"};
// `;

// const PreferenceTitle = styled.div`
//   padding: 1rem 2rem;
//   background-color: ${(props) => props.theme.columns.left.backgroundColor};
//   /* background-color: ${(props) =>
//     props.alreadyRequested
//       ? props.theme.lottery.card.header.backgroundColorSelected2
//       : props.theme.lottery.card.header3.backgroundColor}; */
//   cursor: pointer;
//   display: flex;
//   align-items: center;
//   justify-items: space-between;
// `;

// const PreferenceContent = styled.div`
//   padding: 1rem 2rem;
// `;

// const LineItemStyled = styled.div`
//   display: flex;
//   margin: 0 0 0.5rem 0;
// `;

// const Title = styled(Typography)`
//   flex: 1;
// `;
// const Total = styled(Typography)`
//   padding: 0 0 0 0.5rem;
// `;

//const feeSum = (paramArr) => paramArr.reduce((sum, unit) => (sum += unit), 0);

const getValidQtys = (oei, selectedPriceLevelId, isOptedOut = false) => {
  const selectedPl = oei.PriceLevels.find(
      (pl) => Number(pl.PriceLevelID) === Number(selectedPriceLevelId),
  );
  let validPls = selectedPl
      ? oei.PriceLevels.filter((pl) => pl.Sort >= selectedPl.Sort)
      : oei.PriceLevels;

  if (isOptedOut) {
    validPls = validPls.filter((pl) => !pl.EnableOptOut);
  }
  return [
    ...validPls.reduce(
        (qtys, pl) =>
            new Set([
              ...qtys,
              ...Array.from({ length: pl.PriceLevelLimit }).
                  map((x, idx) => idx + 1).
                  filter((x) => x >= pl.PriceLevelMinimum).
                  filter((x) => x % pl.PriceLevelMultiplier === 0),
            ]),
        [0],
    ),
  ].sort((a, b) => a - b);
};

const getClosest = (val, xs) =>
    xs.filter((x) => x !== 0).reduce((closest, x) => {
      return Math.abs(val - x) > Math.abs(val - closest) ? closest : x;
    }, Infinity);

export class PriceLevelSelectorFanRequest extends Component {

  state = {
    selectedQty: this.props.lotteryOEIRequest.Quantity || 0,
    selectedPriceLevelId: this.props.lotteryOEIRequest.PriceLevelID || '',
    optOutIsSelected: this.props.lotteryOEIRequest.PriceLevelOptOut || false,
    optOutBoxIsEnabled: true,
  };

  componentDidMount() {
    let optOutIsSelected = false;
    let selectedPriceLevelId = '';
    let selectedQty = 0;
    let validQtys = [];

    if (this.props.lotteryOEIRequest) {
      optOutIsSelected = this.props.lotteryOEIRequest.PriceLevelOptOut || false;
      selectedPriceLevelId = this.props.lotteryOEIRequest.PriceLevelID || '';
      selectedQty = this.props.lotteryOEIRequest.Quantity || 0;
      validQtys = getValidQtys(
          this.props.outletEventItem,
          null,
          optOutIsSelected,
      );
    }

    this.setState({
      validQtys,
      optOutIsSelected,
      selectedPriceLevelId,
      selectedQty,
    });
  }

  handleOnChangeQty = (qty = 0) => {
    // let selectedQty = this.state.selectedQty + qty;
    const nextIdx =
        this.state.validQtys.map((x, idx) => ({ x, idx })).filter((y) => y.x === this.state.selectedQty)[0].idx + qty;
    const selectedQty =
        this.state.selectedQty < 0 ? 0 : this.state.validQtys[nextIdx];

    this.setState({ selectedQty }, () => this.handleOnChangeCallback());
  };

  handleOnChangePriceLevel = (e, oei) => {

    const selectedPriceLevelId = e.target.value || '';
    //const outletEventItemId = oei && oei.OutletEventItemID;
    let optOutBoxIsEnabled = true;

    const highestPlSort = Math.max(...oei.PriceLevels.map((pl) => pl.Sort));
    const validOei = {
      ...oei,
      PriceLevels: this.state.optOutIsSelected
          ? oei.PriceLevels.filter((pl) => pl.Sort !== highestPlSort)
          : oei.PriceLevels,
    };
    const validQtys = getValidQtys(validOei, selectedPriceLevelId);

    oei.PriceLevels.map((pl, idx) => {
      if (Number(selectedPriceLevelId) === Number(pl.PriceLevelID)) {
        if (pl.EnableOptOut) {
          optOutBoxIsEnabled = true;
        }
      }
      return null;
    });

    const selectedQty =
        this.state.selectedQty === 0
            ? 0
            : getClosest(this.state.selectedQty, validQtys);

    this.setState(
        { selectedPriceLevelId, validQtys, selectedQty, optOutBoxIsEnabled },
        () => this.handleOnChangeCallback(),
    );
  };

  handleOnChangeOptOut = (e, oei) => {
    const optOutIsSelected = !this.state.optOutIsSelected;

    const highestPlSort = Math.max(...oei.PriceLevels.map((pl) => pl.Sort));

    const priceLevelsSorted =
        oei && Array.isArray(oei.PriceLevels) && oei.PriceLevels.length
            ? [...oei.PriceLevels].sort((a, b) => Number(a.Sort) - Number(b.Sort))
            : [];

    let priceLevelsFiltered = optOutIsSelected
        ? priceLevelsSorted.filter((pl) => !pl.EnableOptOut)
        : priceLevelsSorted;
    const validOei = {
      ...oei,
      PriceLevels: priceLevelsFiltered,
    };
    const validQtys = getValidQtys(
        validOei,
        this.state.selectedPriceLevelId,
        optOutIsSelected,
    );
    let selectedQty =
        this.state.selectedQty === 0
            ? 0
            : getClosest(this.state.selectedQty, validQtys);
    // if (!optOutIsSelected && this.state.selectedQty !== selectedQty) {
    //   selectedQty = this.state.selectedQty;
    // }

    this.setState({ validQtys, selectedQty, optOutIsSelected }, () =>
        this.handleOnChangeCallback(),
    );
  };

  handleOnChangeCallback = () => {
    // const outletEventItemId =
    //   this.props.outletEventItem &&
    //   this.props.outletEventItem.OutletEventItemID;
    // const req = {
    //   LotteryOEIRequestUUID: null,
    //   PriceLevelID: this.state.selectedPriceLevelId,
    //   Quantity: this.state.selectedQty,
    //   PriceLevelOptOut: this.state.optOutIsSelected,
    //   outletEventItemId
    // };
    this.props.onChangeCallback &&
    this.props.onChangeCallback({ ...this.state });
  };

  render() {
    const { selectedQty } = this.state;
    const { outletEventItem } = this.props;

    if (!outletEventItem || !Object.keys(outletEventItem).length) {
      return null;
    }

    const priceLevelsSorted =
        Array.isArray(outletEventItem.PriceLevels) &&
        outletEventItem.PriceLevels.length
            ? [...outletEventItem.PriceLevels].sort(
                (a, b) => Number(a.Sort) - Number(b.Sort),
            )
            : null;

    if (!priceLevelsSorted) {
      return (
          <Typography>There are currently no price levels for sale</Typography>
      );
    }
    //let prefCounter = 1;
    let qtyLimit = 0;
    //let priceLevelIndexSelected = 0;

    let numberOfPriceLevelsWithOptOutEnabled = 0;
    priceLevelsSorted.map((x) => {
      if (x.EnableOptOut) {
        numberOfPriceLevelsWithOptOutEnabled += 1;
      }
    });

    let optOutBoxIsVisible = Boolean(numberOfPriceLevelsWithOptOutEnabled > 0);

    priceLevelsSorted.map((pl, idx) => {
      if (qtyLimit < pl.PriceLevelLimit) {
        qtyLimit = pl.PriceLevelLimit;
      }
      if (this.state.validQtys) {
        qtyLimit = [...this.state.validQtys].reverse()[0];
      }

      if (pl.EnableOptOut) {
        if (
            Number(this.state.selectedPriceLevelId) === Number(pl.PriceLevelID)
        ) {
          optOutBoxIsVisible = false;
        } else {
          //optOutBoxIsVisible = true;
        }
        if (numberOfPriceLevelsWithOptOutEnabled > 1) {
          // if (
          //   Number(this.state.selectedPriceLevelId) === Number(pl.PriceLevelID)
          // ) {
          //   optOutBoxIsVisible = false;
          // }
        }
      }
      return null;
    });

    const { eventList } = this.props;

    return (
        <Root>
          <QuantitySelectorContainerStyled>
            <Typography tag="h3">Select Quantity</Typography>
            <div>
              <SelectorContainer>
                <Selector
                    name={'sub-qty-' + outletEventItem.OutletEventItemID}
                    className={'price-level-qty-minus-btn'}
                    id={
                        'price-level-qty-minus-' + outletEventItem.OutletEventItemID
                    }
                    aria-label="Remove Quantity"
                    disabled={selectedQty < 1}
                    onClick={() =>
                        selectedQty > 0 ? this.handleOnChangeQty(-1) : false
                    }
                >
                  <IconMinus/>
                </Selector>
                <QtyDisplay>{selectedQty}</QtyDisplay>
                <Selector
                    name={'add-qty-' + outletEventItem.OutletEventItemID}
                    className={'price-level-qty-add-btn'}
                    id={'price-level-qty-add-' + outletEventItem.OutletEventItemID}
                    aria-label="Add Quantity"
                    disabled={selectedQty >= qtyLimit}
                    onClick={() =>
                        selectedQty < qtyLimit ? this.handleOnChangeQty(1) : false
                    }
                >
                  <IconPlus/>
                </Selector>
              </SelectorContainer>
            </div>

            <div
                style={{ cursor: 'pointer' }}
                onClick={() => alert('Some tickets may have a lesser limit')}
            >
              Limit: {qtyLimit} <IconHelpCircle height={15} width={15}/>
            </div>
          </QuantitySelectorContainerStyled>

          <PreferenceContainerStyled>
            <SelectInput
                style={{ width: '100%' }}
                name="priceLevel"
                value={this.state.selectedPriceLevelId}
                onChange={(e) => this.handleOnChangePriceLevel(e, outletEventItem)}
                optionList={[{ value: '', label: 'Select Level' }].concat(
                    priceLevelsSorted.map((pl) => ({
                      value: pl.PriceLevelID,
                      label: getPriceLevelLabel(pl, outletEventItem.OutletEventID, eventList, true, true),
                      disabled: pl.EnableOptOut && this.state.optOutIsSelected,
                    })),
                )}
            />
            {this.state.optOutBoxIsEnabled && optOutBoxIsVisible && (
                <CheckboxInput
                    name="priceLevelsOptOut"
                    type="checkbox"
                    checked={this.state.optOutIsSelected}
                    onChange={(e) => this.handleOnChangeOptOut(e, outletEventItem)}
                    disabled={!this.state.optOutBoxIsEnabled}
                >
                  <React.Fragment>
                    <div>
                      {' '}
                      ({numberOfPriceLevelsWithOptOutEnabled}) Opt-out of{' '}
                      {priceLevelsSorted.filter((unit) => unit.EnableOptOut).map(
                          (unit) =>
                              unit.PriceLevelName +
                              ' - ' + getPriceLevelLabel(unit, outletEventItem.OutletEventID, eventList),
                      ).join(' and ')}
                    </div>
                    <div>*This may decrease your chances of getting tickets</div>
                  </React.Fragment>
                </CheckboxInput>
            )}

            {this.state.selectedPriceLevelId && (
                <LineItemPreferenceList
                    outletEventItem={outletEventItem}
                    selectedQty={selectedQty}
                    selectedPriceLevelId={
                        this.state.selectedPriceLevelId ||
                        this.props.lotteryOEIRequest.PriceLevelID
                    }
                    optOutIsSelected={this.state.optOutIsSelected}
                />
            )}
          </PreferenceContainerStyled>
        </Root>
    );
  }
}

PriceLevelSelectorFanRequest.defaultProps = {
  displayFees: true,
  lotteryOEIRequest: {},
  outletEventItem: {},
  onChangeCallback: null,
};

PriceLevelSelectorFanRequest.propTypes = {
  displayFees: PropTypes.bool,
  lotteryOEIRequest: PropTypes.object,
  outletEventItem: PropTypes.object,
  onChangeCallback: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
};

const mapStateToProps = (state) => ({
  eventList: state.eventList,
});

export default connect(mapStateToProps)(PriceLevelSelectorFanRequest);
