import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const ContainerStyled = styled.div`
  display: none;
  @media (min-width: ${(props) =>
    props.breakpoint
        ? props.breakpoint
        : props.theme.BREAKPOINT_MOBILE_WIDTH}px) {
    display: block;
  }
`;

const HideOnMobile = ({ breakpoint, children }) => {
  // //console.log("HideOnMobile", breakpoint);
  // //console.log("HideOnMobile children", children);
  return <ContainerStyled breakpoint={breakpoint}>{children}</ContainerStyled>;
};

HideOnMobile.propTypes = {
  breakpoint: PropTypes.number,
};

export default HideOnMobile;
