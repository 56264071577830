import store from '../../store';
import { api } from '../api/actions';

export function setOrderItemUpgradeFlag(params = {}) {
  const response = api({
    action: 'UPDATE_OUTLET_EVENT_ITEM_UPGRADE',
    payload: params,
    showToast: true,
  });
  response.then((res) => {
    store.dispatch({
      type: 'SET_USER_ORDER',
      data: res,
    });
  }).catch((res) => {
  });
  return response;
}

export function setOEIRefundRequestFlag(params = {}) {
  const response = api({
    action: 'UPDATE_OUTLET_EVENT_ITEM_REFUND_REQUEST',
    payload: params,
    //showToast: true,
  });
  response.then((res) => {
    store.dispatch({
      type: 'SET_USER_ORDER',
      data: res,
    });
  }).catch((res) => {
  });
  return response;
}
