import React from 'react';
import styled from 'styled-components';

import leftArrow from './left-arrow.svg';
import rightArrow from './right-arrow.svg';

const RootStyled = styled.div`
  display: flex;
  position: absolute;
  top: 50%;
  ${(props) => (props.direction === 'right' ? `right: 25px` : `left: 25px`)};
  height: 30px;
  width: 30px;
  justify-content: center;
  background: white;
  border-radius: 50%;
  cursor: pointer;
  align-items: center;
  transition: transform ease-in 0.1s;
  &:hover {
    transform: scale(1.1);
  }
  img {
    transform: translateX(
      ${(props) => (props.direction === 'left' ? '-2' : '2')}px
    );
    &:focus {
      outline: 0;
    }
  }
`;
const Arrow = ({ direction, handleClick }) => (
    <RootStyled onClick={handleClick} direction={direction}>
      {direction === 'right' ? (
          <img src={rightArrow} alt="next"/>
      ) : (
          <img src={leftArrow} alt="previous"/>
      )}
    </RootStyled>
);

export default Arrow;
