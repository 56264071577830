import store from '../../store';

export const toggleAppDrawerVisibility = (isVisible) => {
  store.dispatch({
    type: 'TOGGLE_DRAWER_VISIBILITY',
    appDrawerIsVisible: isVisible,
  });
};

export const toggleAppDrawerIconVisibility = (isVisible) => {
  store.dispatch({
    type: 'TOGGLE_DRAWER_ICON_VISIBILITY',
    appDrawerIconIsVisible: isVisible,
  });
};

export const toggleAuthnetIframeVisibility = (isVisible) => {
  store.dispatch({
    type: 'TOGGLE_AUTHNET_IFRAME_VISIBILITY',
    authnetIframeIsVisible: isVisible,
  });
};
